/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Antd global theme
 */

import { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
	cssVar: true,
	token: {
		colorPrimary: '#006357',
		colorPrimaryHover: '#109888',
		// colorText: '#323837',
		colorSplit: '#e8efef',
		borderRadius: 5,
		borderRadiusLG: 10,
		colorLinkHover: '#13b2a0',
		colorLink: '#006357',
		colorLinkActive: '#13b2a0',
		colorTextSecondary: '#162325',
		fontFamily: 'Inter, sans-serif',
		controlItemBgHover: '#EBEBEB',
		controlItemBgActive: 'inherit',
		// colorBorder: '#d9d9d9',
		// colorBorderSecondary: '#f1f1f1',
		fontWeightStrong: 500,
		controlItemBgActiveHover: '#dcf1ee',
	},
	components: {
		Layout: {
			headerBg: '#FFFFFF99',
			siderBg: 'inherit',
			bodyBg: '#fff',
		},
		Radio: {
			colorPrimary: '#109888',
		},
		Modal: {
			borderRadius: 10,
			colorTextHeading: '#323837',
			titleFontSize: 16,
		},
		Table: {
			paddingContentVertical: 8,
			paddingContentHorizontal: 16,
			controlItemBgActive: '#f2f8f7',
			controlItemBgActiveHover: '#f2f8f7',
		},
		Input: {
			activeBorderColor: '#189477',
			activeShadow: '0px 0px 0px 2px #1890ff33',
			colorTextPlaceholder: '#00000073',
		},
		DatePicker: {
			activeBorderColor: '#189477',
			activeShadow: '0px 0px 0px 2px #1890ff33',
			colorTextPlaceholder: '#00000073',
		},
		InputNumber: {
			activeBorderColor: '#189477',
			activeShadow: '0px 0px 0px 2px #1890ff33',
			colorTextPlaceholder: '#00000073',
		},
		Select: {
			colorTextPlaceholder: '#00000073',
		},
		Button: {
			colorText: '#006357',
		},
		Card: {
			boxShadowTertiary: 'none',
		},
		Checkbox: {
			colorPrimary: '#109888',
		},
		Switch: {
			colorPrimary: '#109888',
		},
		Mentions: {
			colorBgContainer: '#f9fbfc',
		},
		Form: {
			colorTextLabel: '#595959',
			labelColor: '#434343',
		},
		Breadcrumb: {
			fontSize: 16,
		},
		// Divider: {
		// 	colorSplit: '#d9d9d9',
		// },
		// DatePicker: {
		// 	colorBgContainer: '#f9fbfc',
		// },
		Result: {
			colorInfo: '#006357',
		},
		Typography: {
			colorLinkHover: '#13b2a0',
			colorLink: '#006357',
			colorLinkActive: '#13b2a0',
			colorInfo: '#006357',
			colorPrimary: '#006357',
		},
		Menu: {
			itemSelectedBg: '#EDF8F6',
			itemBorderRadius: 4,
			activeBarBorderWidth: 0,
			itemDisabledColor: '#00000040',
		},
	},
};

export default theme;
