/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description mupdf-wasm
 */

/* eslint-disable */


var libmupdf_wasm = (() => {
	var _scriptDir = import.meta.url;

	return async function (moduleArg = {}) {
		// include: shell.js
		// The Module object: Our interface to the outside world. We import
		// and export values on it. There are various ways Module can be used:
		// 1. Not defined. We create it here
		// 2. A function parameter, function(Module) { ..generated code.. }
		// 3. pre-run appended it, var Module = {}; ..generated code..
		// 4. External script tag defines var Module.
		// We need to check if Module already exists (e.g. case 3 above).
		// Substitution will be replaced with actual code on later stage of the build,
		// this way Closure Compiler will not mangle it (e.g. case 4. above).
		// Note that if you want to run closure, and also to use Module
		// after the generated code, you will need to define   var Module = {};
		// before the code. Then that object will be used in the code, and you
		// can continue to use Module afterwards as well.
		var Module = moduleArg;

		// Set up the promise that indicates the Module is initialized
		var readyPromiseResolve, readyPromiseReject;
		Module['ready'] = new Promise((resolve, reject) => {
			readyPromiseResolve = resolve;
			readyPromiseReject = reject;
		});
		[
			'_memory',
			'_wasm_init_context',
			'_wasm_malloc',
			'_wasm_free',
			'_wasm_enable_icc',
			'_wasm_disable_icc',
			'_wasm_set_user_css',
			'_wasm_keep_buffer',
			'_wasm_drop_buffer',
			'_wasm_keep_stream',
			'_wasm_drop_stream',
			'_wasm_keep_colorspace',
			'_wasm_drop_colorspace',
			'_wasm_keep_pixmap',
			'_wasm_drop_pixmap',
			'_wasm_keep_font',
			'_wasm_drop_font',
			'_wasm_keep_stroke_state',
			'_wasm_drop_stroke_state',
			'_wasm_keep_image',
			'_wasm_drop_image',
			'_wasm_keep_shade',
			'_wasm_drop_shade',
			'_wasm_keep_path',
			'_wasm_drop_path',
			'_wasm_keep_text',
			'_wasm_drop_text',
			'_wasm_keep_device',
			'_wasm_drop_device',
			'_wasm_keep_display_list',
			'_wasm_drop_display_list',
			'_wasm_drop_stext_page',
			'_wasm_drop_document_writer',
			'_wasm_drop_outline_iterator',
			'_wasm_keep_document',
			'_wasm_drop_document',
			'_wasm_keep_page',
			'_wasm_drop_page',
			'_wasm_keep_link',
			'_wasm_drop_link',
			'_wasm_keep_outline',
			'_wasm_drop_outline',
			'_wasm_pdf_keep_annot',
			'_wasm_pdf_drop_annot',
			'_wasm_pdf_keep_obj',
			'_wasm_pdf_drop_obj',
			'_wasm_pdf_keep_graft_map',
			'_wasm_pdf_drop_graft_map',
			'_wasm_buffer_get_data',
			'_wasm_buffer_get_len',
			'_wasm_colorspace_get_type',
			'_wasm_colorspace_get_n',
			'_wasm_colorspace_get_name',
			'_wasm_pixmap_get_w',
			'_wasm_pixmap_get_h',
			'_wasm_pixmap_get_x',
			'_wasm_pixmap_get_y',
			'_wasm_pixmap_get_n',
			'_wasm_pixmap_get_stride',
			'_wasm_pixmap_get_alpha',
			'_wasm_pixmap_get_xres',
			'_wasm_pixmap_get_yres',
			'_wasm_pixmap_get_colorspace',
			'_wasm_pixmap_get_samples',
			'_wasm_pixmap_set_xres',
			'_wasm_pixmap_set_yres',
			'_wasm_font_get_name',
			'_wasm_stroke_state_get_start_cap',
			'_wasm_stroke_state_set_start_cap',
			'_wasm_stroke_state_get_dash_cap',
			'_wasm_stroke_state_set_dash_cap',
			'_wasm_stroke_state_get_end_cap',
			'_wasm_stroke_state_set_end_cap',
			'_wasm_stroke_state_get_linejoin',
			'_wasm_stroke_state_set_linejoin',
			'_wasm_stroke_state_get_linewidth',
			'_wasm_stroke_state_set_linewidth',
			'_wasm_stroke_state_get_miterlimit',
			'_wasm_stroke_state_set_miterlimit',
			'_wasm_stroke_state_get_dash_phase',
			'_wasm_stroke_state_set_dash_phase',
			'_wasm_image_get_w',
			'_wasm_image_get_h',
			'_wasm_image_get_n',
			'_wasm_image_get_bpc',
			'_wasm_image_get_xres',
			'_wasm_image_get_yres',
			'_wasm_image_get_imagemask',
			'_wasm_image_get_colorspace',
			'_wasm_image_get_mask',
			'_wasm_outline_get_title',
			'_wasm_outline_get_uri',
			'_wasm_outline_get_next',
			'_wasm_outline_get_down',
			'_wasm_outline_get_is_open',
			'_wasm_outline_item_get_title',
			'_wasm_outline_item_get_uri',
			'_wasm_outline_item_get_is_open',
			'_wasm_link_get_rect',
			'_wasm_link_get_uri',
			'_wasm_link_get_next',
			'_wasm_stext_page_get_mediabox',
			'_wasm_stext_page_get_first_block',
			'_wasm_stext_block_get_next',
			'_wasm_stext_block_get_type',
			'_wasm_stext_block_get_bbox',
			'_wasm_stext_block_get_first_line',
			'_wasm_stext_block_get_transform',
			'_wasm_stext_block_get_image',
			'_wasm_stext_line_get_next',
			'_wasm_stext_line_get_wmode',
			'_wasm_stext_line_get_dir',
			'_wasm_stext_line_get_bbox',
			'_wasm_stext_line_get_first_char',
			'_wasm_stext_char_get_next',
			'_wasm_stext_char_get_c',
			'_wasm_stext_char_get_origin',
			'_wasm_stext_char_get_quad',
			'_wasm_stext_char_get_size',
			'_wasm_stext_char_get_font',
			'_wasm_link_dest_get_chapter',
			'_wasm_link_dest_get_page',
			'_wasm_link_dest_get_type',
			'_wasm_link_dest_get_x',
			'_wasm_link_dest_get_y',
			'_wasm_link_dest_get_w',
			'_wasm_link_dest_get_h',
			'_wasm_link_dest_get_zoom',
			'_wasm_pdf_embedded_file_params_get_filename',
			'_wasm_pdf_embedded_file_params_get_mimetype',
			'_wasm_pdf_embedded_file_params_get_size',
			'_wasm_pdf_embedded_file_params_get_created',
			'_wasm_pdf_embedded_file_params_get_modified',
			'_wasm_pdf_page_get_obj',
			'_wasm_new_buffer',
			'_wasm_new_buffer_from_data',
			'_wasm_append_string',
			'_wasm_append_byte',
			'_wasm_append_buffer',
			'_wasm_slice_buffer',
			'_wasm_string_from_buffer',
			'_wasm_device_gray',
			'_wasm_device_rgb',
			'_wasm_device_bgr',
			'_wasm_device_cmyk',
			'_wasm_device_lab',
			'_wasm_new_icc_colorspace',
			'_wasm_new_stroke_state',
			'_wasm_new_base14_font',
			'_wasm_new_font_from_buffer',
			'_wasm_encode_character',
			'_wasm_advance_glyph',
			'_wasm_font_is_monospaced',
			'_wasm_font_is_serif',
			'_wasm_font_is_bold',
			'_wasm_font_is_italic',
			'_wasm_new_image_from_pixmap',
			'_wasm_new_image_from_buffer',
			'_wasm_get_pixmap_from_image',
			'_wasm_new_pixmap_from_page',
			'_wasm_new_pixmap_from_page_contents',
			'_wasm_pdf_new_pixmap_from_page_with_usage',
			'_wasm_pdf_new_pixmap_from_page_contents_with_usage',
			'_wasm_new_pixmap_with_bbox',
			'_wasm_clear_pixmap',
			'_wasm_clear_pixmap_with_value',
			'_wasm_invert_pixmap',
			'_wasm_invert_pixmap_luminance',
			'_wasm_gamma_pixmap',
			'_wasm_tint_pixmap',
			'_wasm_new_buffer_from_pixmap_as_png',
			'_wasm_new_buffer_from_pixmap_as_pam',
			'_wasm_new_buffer_from_pixmap_as_psd',
			'_wasm_new_buffer_from_pixmap_as_jpeg',
			'_wasm_convert_pixmap',
			'_wasm_warp_pixmap',
			'_wasm_bound_shade',
			'_wasm_new_display_list',
			'_wasm_bound_display_list',
			'_wasm_run_display_list',
			'_wasm_new_pixmap_from_display_list',
			'_wasm_new_stext_page_from_display_list',
			'_wasm_search_display_list',
			'_wasm_new_path',
			'_wasm_moveto',
			'_wasm_lineto',
			'_wasm_curveto',
			'_wasm_curvetov',
			'_wasm_curvetoy',
			'_wasm_closepath',
			'_wasm_rectto',
			'_wasm_transform_path',
			'_wasm_bound_path',
			'_wasm_new_text',
			'_wasm_bound_text',
			'_wasm_show_glyph',
			'_wasm_show_string',
			'_wasm_new_draw_device',
			'_wasm_new_display_list_device',
			'_wasm_close_device',
			'_wasm_fill_path',
			'_wasm_stroke_path',
			'_wasm_clip_path',
			'_wasm_clip_stroke_path',
			'_wasm_fill_text',
			'_wasm_stroke_text',
			'_wasm_clip_text',
			'_wasm_clip_stroke_text',
			'_wasm_ignore_text',
			'_wasm_fill_shade',
			'_wasm_fill_image',
			'_wasm_fill_image_mask',
			'_wasm_clip_image_mask',
			'_wasm_pop_clip',
			'_wasm_begin_mask',
			'_wasm_end_mask',
			'_wasm_begin_group',
			'_wasm_end_group',
			'_wasm_begin_tile',
			'_wasm_end_tile',
			'_wasm_begin_layer',
			'_wasm_end_layer',
			'_wasm_new_document_writer_with_buffer',
			'_wasm_begin_page',
			'_wasm_end_page',
			'_wasm_close_document_writer',
			'_wasm_print_stext_page_as_json',
			'_wasm_search_stext_page',
			'_wasm_copy_selection',
			'_wasm_highlight_selection',
			'_wasm_print_stext_page_as_html',
			'_wasm_print_stext_page_as_text',
			'_wasm_open_document_with_buffer',
			'_wasm_open_document_with_stream',
			'_wasm_format_link_uri',
			'_wasm_needs_password',
			'_wasm_authenticate_password',
			'_wasm_has_permission',
			'_wasm_count_pages',
			'_wasm_load_page',
			'_wasm_lookup_metadata',
			'_wasm_set_metadata',
			'_wasm_resolve_link',
			'_wasm_resolve_link_dest',
			'_wasm_load_outline',
			'_wasm_outline_get_page',
			'_wasm_layout_document',
			'_wasm_is_document_reflowable',
			'_wasm_link_set_rect',
			'_wasm_link_set_uri',
			'_wasm_bound_page',
			'_wasm_load_links',
			'_wasm_create_link',
			'_wasm_delete_link',
			'_wasm_run_page',
			'_wasm_run_page_contents',
			'_wasm_run_page_annots',
			'_wasm_run_page_widgets',
			'_wasm_new_stext_page_from_page',
			'_wasm_new_display_list_from_page',
			'_wasm_new_display_list_from_page_contents',
			'_wasm_page_label',
			'_wasm_search_page',
			'_wasm_new_outline_iterator',
			'_wasm_outline_iterator_next',
			'_wasm_outline_iterator_prev',
			'_wasm_outline_iterator_up',
			'_wasm_outline_iterator_down',
			'_wasm_outline_iterator_delete',
			'_wasm_outline_iterator_item',
			'_wasm_outline_iterator_insert',
			'_wasm_outline_iterator_update',
			'_wasm_pdf_document_from_fz_document',
			'_wasm_pdf_page_from_fz_page',
			'_wasm_pdf_create_document',
			'_wasm_pdf_version',
			'_wasm_pdf_was_repaired',
			'_wasm_pdf_has_unsaved_changes',
			'_wasm_pdf_can_be_saved_incrementally',
			'_wasm_pdf_count_versions',
			'_wasm_pdf_count_unsaved_versions',
			'_wasm_pdf_validate_change_history',
			'_wasm_pdf_enable_journal',
			'_wasm_pdf_undoredo_state_position',
			'_wasm_pdf_undoredo_state_count',
			'_wasm_pdf_undoredo_step',
			'_wasm_pdf_begin_operation',
			'_wasm_pdf_begin_implicit_operation',
			'_wasm_pdf_end_operation',
			'_wasm_pdf_abandon_operation',
			'_wasm_pdf_undo',
			'_wasm_pdf_redo',
			'_wasm_pdf_can_undo',
			'_wasm_pdf_can_redo',
			'_wasm_pdf_document_language',
			'_wasm_pdf_set_document_language',
			'_wasm_pdf_trailer',
			'_wasm_pdf_xref_len',
			'_wasm_pdf_lookup_page_obj',
			'_wasm_pdf_add_object',
			'_wasm_pdf_create_object',
			'_wasm_pdf_delete_object',
			'_wasm_pdf_add_stream',
			'_wasm_pdf_add_simple_font',
			'_wasm_pdf_add_cjk_font',
			'_wasm_pdf_add_cid_font',
			'_wasm_pdf_add_image',
			'_wasm_pdf_load_image',
			'_wasm_pdf_add_page',
			'_wasm_pdf_insert_page',
			'_wasm_pdf_delete_page',
			'_wasm_pdf_set_page_labels',
			'_wasm_pdf_delete_page_labels',
			'_wasm_pdf_is_embedded_file',
			'_wasm_pdf_get_embedded_file_params',
			'_wasm_pdf_add_embedded_file',
			'_wasm_pdf_load_embedded_file_contents',
			'_wasm_pdf_write_document_buffer',
			'_wasm_pdf_js_supported',
			'_wasm_pdf_enable_js',
			'_wasm_pdf_disable_js',
			'_wasm_pdf_rearrange_pages',
			'_wasm_pdf_bake_document',
			'_wasm_pdf_page_transform',
			'_wasm_pdf_set_page_box',
			'_wasm_pdf_first_annot',
			'_wasm_pdf_next_annot',
			'_wasm_pdf_first_widget',
			'_wasm_pdf_next_widget',
			'_wasm_pdf_create_annot',
			'_wasm_pdf_delete_annot',
			'_wasm_pdf_update_page',
			'_wasm_pdf_redact_page',
			'_wasm_pdf_new_graft_map',
			'_wasm_pdf_graft_mapped_object',
			'_wasm_pdf_graft_object',
			'_wasm_pdf_graft_mapped_page',
			'_wasm_pdf_graft_page',
			'_wasm_pdf_bound_annot',
			'_wasm_pdf_run_annot',
			'_wasm_pdf_new_pixmap_from_annot',
			'_wasm_pdf_new_display_list_from_annot',
			'_wasm_pdf_update_annot',
			'_wasm_pdf_annot_obj',
			'_wasm_pdf_annot_type',
			'_wasm_pdf_annot_flags',
			'_wasm_pdf_set_annot_flags',
			'_wasm_pdf_annot_contents',
			'_wasm_pdf_set_annot_contents',
			'_wasm_pdf_annot_author',
			'_wasm_pdf_set_annot_author',
			'_wasm_pdf_annot_creation_date',
			'_wasm_pdf_set_annot_creation_date',
			'_wasm_pdf_annot_modification_date',
			'_wasm_pdf_set_annot_modification_date',
			'_wasm_pdf_annot_border_width',
			'_wasm_pdf_set_annot_border_width',
			'_wasm_pdf_annot_border_style',
			'_wasm_pdf_set_annot_border_style',
			'_wasm_pdf_annot_border_effect',
			'_wasm_pdf_set_annot_border_effect',
			'_wasm_pdf_annot_border_effect_intensity',
			'_wasm_pdf_set_annot_border_effect_intensity',
			'_wasm_pdf_annot_opacity',
			'_wasm_pdf_set_annot_opacity',
			'_wasm_pdf_annot_filespec',
			'_wasm_pdf_set_annot_filespec',
			'_wasm_pdf_annot_quadding',
			'_wasm_pdf_set_annot_quadding',
			'_wasm_pdf_annot_is_open',
			'_wasm_pdf_set_annot_is_open',
			'_wasm_pdf_annot_hidden_for_editing',
			'_wasm_pdf_set_annot_hidden_for_editing',
			'_wasm_pdf_annot_icon_name',
			'_wasm_pdf_set_annot_icon_name',
			'_wasm_pdf_annot_intent',
			'_wasm_pdf_set_annot_intent',
			'_wasm_pdf_annot_rect',
			'_wasm_pdf_annot_popup',
			'_wasm_pdf_annot_quad_point_count',
			'_wasm_pdf_annot_quad_point',
			'_wasm_pdf_annot_vertex_count',
			'_wasm_pdf_annot_vertex',
			'_wasm_pdf_annot_ink_list_count',
			'_wasm_pdf_annot_ink_list_stroke_count',
			'_wasm_pdf_annot_ink_list_stroke_vertex',
			'_wasm_pdf_annot_border_dash_count',
			'_wasm_pdf_annot_border_dash_item',
			'_wasm_pdf_annot_has_rect',
			'_wasm_pdf_annot_has_ink_list',
			'_wasm_pdf_annot_has_quad_points',
			'_wasm_pdf_annot_has_vertices',
			'_wasm_pdf_annot_has_line',
			'_wasm_pdf_annot_has_interior_color',
			'_wasm_pdf_annot_has_line_ending_styles',
			'_wasm_pdf_annot_has_border',
			'_wasm_pdf_annot_has_border_effect',
			'_wasm_pdf_annot_has_icon_name',
			'_wasm_pdf_annot_has_open',
			'_wasm_pdf_annot_has_author',
			'_wasm_pdf_annot_has_filespec',
			'_wasm_pdf_annot_language',
			'_wasm_pdf_set_annot_language',
			'_wasm_pdf_set_annot_popup',
			'_wasm_pdf_set_annot_rect',
			'_wasm_pdf_clear_annot_quad_points',
			'_wasm_pdf_clear_annot_vertices',
			'_wasm_pdf_clear_annot_ink_list',
			'_wasm_pdf_clear_annot_border_dash',
			'_wasm_pdf_add_annot_quad_point',
			'_wasm_pdf_add_annot_vertex',
			'_wasm_pdf_add_annot_ink_list_stroke',
			'_wasm_pdf_add_annot_ink_list_stroke_vertex',
			'_wasm_pdf_add_annot_border_dash_item',
			'_wasm_pdf_annot_line_ending_styles_start',
			'_wasm_pdf_annot_line_1',
			'_wasm_pdf_annot_line_2',
			'_wasm_pdf_set_annot_line',
			'_wasm_pdf_annot_line_ending_styles_end',
			'_wasm_pdf_set_annot_line_ending_styles',
			'_wasm_pdf_annot_color',
			'_wasm_pdf_annot_interior_color',
			'_wasm_pdf_set_annot_color',
			'_wasm_pdf_set_annot_interior_color',
			'_wasm_pdf_set_annot_default_appearance',
			'_wasm_pdf_annot_default_appearance_font',
			'_wasm_pdf_annot_default_appearance_size',
			'_wasm_pdf_annot_default_appearance_color',
			'_wasm_pdf_set_annot_appearance_from_display_list',
			'_wasm_pdf_set_annot_appearance',
			'_wasm_pdf_apply_redaction',
			'_wasm_pdf_annot_field_type',
			'_wasm_pdf_annot_field_flags',
			'_wasm_pdf_annot_field_label',
			'_wasm_pdf_annot_field_value',
			'_wasm_pdf_load_field_name',
			'_wasm_pdf_annot_text_widget_max_len',
			'_wasm_pdf_set_annot_text_field_value',
			'_wasm_pdf_set_annot_choice_field_value',
			'_wasm_pdf_annot_choice_field_option_count',
			'_wasm_pdf_annot_choice_field_option',
			'_wasm_pdf_toggle_widget',
			'_wasm_pdf_is_indirect',
			'_wasm_pdf_is_bool',
			'_wasm_pdf_is_int',
			'_wasm_pdf_is_number',
			'_wasm_pdf_is_name',
			'_wasm_pdf_is_string',
			'_wasm_pdf_is_array',
			'_wasm_pdf_is_dict',
			'_wasm_pdf_is_stream',
			'_wasm_pdf_to_num',
			'_wasm_pdf_to_bool',
			'_wasm_pdf_to_real',
			'_wasm_pdf_to_name',
			'_wasm_pdf_to_text_string',
			'_wasm_pdf_new_indirect',
			'_wasm_pdf_new_array',
			'_wasm_pdf_new_dict',
			'_wasm_pdf_new_bool',
			'_wasm_pdf_new_int',
			'_wasm_pdf_new_real',
			'_wasm_pdf_new_name',
			'_wasm_pdf_new_text_string',
			'_wasm_pdf_new_string',
			'_wasm_pdf_resolve_indirect',
			'_wasm_pdf_array_len',
			'_wasm_pdf_array_get',
			'_wasm_pdf_dict_get',
			'_wasm_pdf_dict_len',
			'_wasm_pdf_dict_get_key',
			'_wasm_pdf_dict_get_val',
			'_wasm_pdf_dict_get_inheritable',
			'_wasm_pdf_dict_gets',
			'_wasm_pdf_dict_gets_inheritable',
			'_wasm_pdf_dict_put',
			'_wasm_pdf_dict_puts',
			'_wasm_pdf_dict_del',
			'_wasm_pdf_dict_dels',
			'_wasm_pdf_array_put',
			'_wasm_pdf_array_push',
			'_wasm_pdf_array_delete',
			'_wasm_pdf_sprint_obj',
			'_wasm_pdf_load_stream',
			'_wasm_pdf_load_raw_stream',
			'_wasm_pdf_update_object',
			'_wasm_pdf_update_stream',
			'_wasm_pdf_to_string',
			'_wasm_new_stream',
			'___indirect_function_table',
			'onRuntimeInitialized',
		].forEach((prop) => {
			if (!Object.getOwnPropertyDescriptor(Module['ready'], prop)) {
				Object.defineProperty(Module['ready'], prop, {
					get: () =>
						abort(
							'You are getting ' +
								prop +
								' on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js',
						),
					set: () =>
						abort(
							'You are setting ' +
								prop +
								' on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js',
						),
				});
			}
		});

		// --pre-jses are emitted after the Module integration code, so that they can
		// refer to Module (if they choose; they can also define Module)

		// Sometimes an existing Module object exists with properties
		// meant to overwrite the default module functionality. Here
		// we collect those properties and reapply _after_ we configure
		// the current environment's defaults to avoid having to be so
		// defensive during initialization.
		var moduleOverrides = Object.assign({}, Module);

		var arguments_ = [];
		var thisProgram = './this.program';
		var quit_ = (status, toThrow) => {
			throw toThrow;
		};

		// Determine the runtime environment we are in. You can customize this by
		// setting the ENVIRONMENT setting at compile time (see settings.js).

		// Attempt to auto-detect the environment
		var ENVIRONMENT_IS_WEB = typeof window == 'object';
		var ENVIRONMENT_IS_WORKER = typeof importScripts == 'function';
		// N.b. Electron.js environment is simultaneously a NODE-environment, but
		// also a web environment.
		var ENVIRONMENT_IS_NODE =
			typeof process == 'object' &&
			typeof process.versions == 'object' &&
			typeof process.versions.node == 'string';
		var ENVIRONMENT_IS_SHELL = !ENVIRONMENT_IS_WEB && !ENVIRONMENT_IS_NODE && !ENVIRONMENT_IS_WORKER;

		if (Module['ENVIRONMENT']) {
			throw new Error(
				'Module.ENVIRONMENT has been deprecated. To force the environment, use the ENVIRONMENT compile-time option (for example, -sENVIRONMENT=web or -sENVIRONMENT=node)',
			);
		}

		// `/` should be present at the end if `scriptDirectory` is not empty
		var scriptDirectory = '';
		function locateFile(path) {
			if (Module['locateFile']) {
				return Module['locateFile'](path, scriptDirectory);
			}
			return scriptDirectory + path;
		}

		// Hooks that are implemented differently in different runtime environments.
		var read_, readAsync, readBinary;

		if (ENVIRONMENT_IS_NODE) {
			if (typeof process == 'undefined' || !process.release || process.release.name !== 'node')
				throw new Error(
					'not compiled for this environment (did you build to HTML and try to run it not on the web, or set ENVIRONMENT to something - like node - and run it someplace else - like on the web?)',
				);

			var nodeVersion = process.versions.node;
			var numericVersion = nodeVersion.split('.').slice(0, 3);
			numericVersion = numericVersion[0] * 10000 + numericVersion[1] * 100 + numericVersion[2].split('-')[0] * 1;
			var minVersion = 160000;
			if (numericVersion < 160000) {
				throw new Error('This emscripten-generated code requires node v16.0.0 (detected v' + nodeVersion + ')');
			}

			// `require()` is no-op in an ESM module, use `createRequire()` to construct
			// the require()` function.  This is only necessary for multi-environment
			// builds, `-sENVIRONMENT=node` emits a static import declaration instead.
			// TODO: Swap all `require()`'s with `import()`'s?
			const { createRequire } = await import('module');
			/** @suppress{duplicate} */
			var require = createRequire(import.meta.url);
			// These modules will usually be used on Node.js. Load them eagerly to avoid
			// the complexity of lazy-loading.
			var fs = require('fs');
			var nodePath = require('path');

			if (ENVIRONMENT_IS_WORKER) {
				scriptDirectory = nodePath.dirname(scriptDirectory) + '/';
			} else {
				// EXPORT_ES6 + ENVIRONMENT_IS_NODE always requires use of import.meta.url,
				// since there's no way getting the current absolute path of the module when
				// support for that is not available.
				scriptDirectory = require('url').fileURLToPath(new URL('./', import.meta.url)); // includes trailing slash
			}

			// include: node_shell_read.js
			read_ = (filename, binary) => {
				// We need to re-wrap `file://` strings to URLs. Normalizing isn't
				// necessary in that case, the path should already be absolute.
				filename = isFileURI(filename) ? new URL(filename) : nodePath.normalize(filename);
				return fs.readFileSync(filename, binary ? undefined : 'utf8');
			};

			readBinary = (filename) => {
				var ret = read_(filename, true);
				if (!ret.buffer) {
					ret = new Uint8Array(ret);
				}
				assert(ret.buffer);
				return ret;
			};

			readAsync = (filename, onload, onerror, binary = true) => {
				// See the comment in the `read_` function.
				filename = isFileURI(filename) ? new URL(filename) : nodePath.normalize(filename);
				fs.readFile(filename, binary ? undefined : 'utf8', (err, data) => {
					if (err) onerror(err);
					else onload(binary ? data.buffer : data);
				});
			};
			// end include: node_shell_read.js
			if (!Module['thisProgram'] && process.argv.length > 1) {
				thisProgram = process.argv[1].replace(/\\/g, '/');
			}

			arguments_ = process.argv.slice(2);

			// MODULARIZE will export the module in the proper place outside, we don't need to export here

			quit_ = (status, toThrow) => {
				process.exitCode = status;
				throw toThrow;
			};
		} else if (ENVIRONMENT_IS_SHELL) {
			if (
				(typeof process == 'object' && typeof require === 'function') ||
				typeof window == 'object' ||
				typeof importScripts == 'function'
			)
				throw new Error(
					'not compiled for this environment (did you build to HTML and try to run it not on the web, or set ENVIRONMENT to something - like node - and run it someplace else - like on the web?)',
				);

			if (typeof read != 'undefined') {
				read_ = read;
			}

			readBinary = (f) => {
				if (typeof readbuffer == 'function') {
					return new Uint8Array(readbuffer(f));
				}
				let data = read(f, 'binary');
				assert(typeof data == 'object');
				return data;
			};

			readAsync = (f, onload, onerror) => {
				setTimeout(() => onload(readBinary(f)));
			};

			if (typeof clearTimeout == 'undefined') {
				globalThis.clearTimeout = (id) => {};
			}

			if (typeof setTimeout == 'undefined') {
				// spidermonkey lacks setTimeout but we use it above in readAsync.
				globalThis.setTimeout = (f) => (typeof f == 'function' ? f() : abort());
			}

			if (typeof scriptArgs != 'undefined') {
				arguments_ = scriptArgs;
			} else if (typeof arguments != 'undefined') {
				arguments_ = arguments;
			}

			if (typeof quit == 'function') {
				quit_ = (status, toThrow) => {
					// Unlike node which has process.exitCode, d8 has no such mechanism. So we
					// have no way to set the exit code and then let the program exit with
					// that code when it naturally stops running (say, when all setTimeouts
					// have completed). For that reason, we must call `quit` - the only way to
					// set the exit code - but quit also halts immediately.  To increase
					// consistency with node (and the web) we schedule the actual quit call
					// using a setTimeout to give the current stack and any exception handlers
					// a chance to run.  This enables features such as addOnPostRun (which
					// expected to be able to run code after main returns).
					setTimeout(() => {
						if (!(toThrow instanceof ExitStatus)) {
							let toLog = toThrow;
							if (toThrow && typeof toThrow == 'object' && toThrow.stack) {
								toLog = [toThrow, toThrow.stack];
							}
							err(`exiting due to exception: ${toLog}`);
						}
						quit(status);
					});
					throw toThrow;
				};
			}

			if (typeof print != 'undefined') {
				// Prefer to use print/printErr where they exist, as they usually work better.
				if (typeof console == 'undefined') console = /** @type{!Console} */ ({});
				console.log = /** @type{!function(this:Console, ...*): undefined} */ (print);
				console.warn = console.error = /** @type{!function(this:Console, ...*): undefined} */ (
					typeof printErr != 'undefined' ? printErr : print
				);
			}
		}

		// Note that this includes Node.js workers when relevant (pthreads is enabled).
		// Node.js workers are detected as a combination of ENVIRONMENT_IS_WORKER and
		// ENVIRONMENT_IS_NODE.
		else if (ENVIRONMENT_IS_WEB || ENVIRONMENT_IS_WORKER) {
			if (ENVIRONMENT_IS_WORKER) {
				// Check worker, not web, since window could be polyfilled
				scriptDirectory = self.location.href;
			} else if (typeof document != 'undefined' && document.currentScript) {
				// web
				scriptDirectory = document.currentScript.src;
			}
			// When MODULARIZE, this JS may be executed later, after document.currentScript
			// is gone, so we saved it, and we use it here instead of any other info.
			if (_scriptDir) {
				scriptDirectory = _scriptDir;
			}
			// blob urls look like blob:http://site.com/etc/etc and we cannot infer anything from them.
			// otherwise, slice off the final part of the url to find the script directory.
			// if scriptDirectory does not contain a slash, lastIndexOf will return -1,
			// and scriptDirectory will correctly be replaced with an empty string.
			// If scriptDirectory contains a query (starting with ?) or a fragment (starting with #),
			// they are removed because they could contain a slash.
			if (scriptDirectory.startsWith('blob:')) {
				scriptDirectory = '';
			} else {
				scriptDirectory = scriptDirectory.substr(0, scriptDirectory.replace(/[?#].*/, '').lastIndexOf('/') + 1);
			}

			if (!(typeof window == 'object' || typeof importScripts == 'function'))
				throw new Error(
					'not compiled for this environment (did you build to HTML and try to run it not on the web, or set ENVIRONMENT to something - like node - and run it someplace else - like on the web?)',
				);

			// Differentiate the Web Worker from the Node Worker case, as reading must
			// be done differently.
			{
				// include: web_or_worker_shell_read.js
				read_ = (url) => {
					var xhr = new XMLHttpRequest();
					xhr.open('GET', url, false);
					xhr.send(null);
					return xhr.responseText;
				};

				if (ENVIRONMENT_IS_WORKER) {
					readBinary = (url) => {
						var xhr = new XMLHttpRequest();
						xhr.open('GET', url, false);
						xhr.responseType = 'arraybuffer';
						xhr.send(null);
						return new Uint8Array(/** @type{!ArrayBuffer} */ (xhr.response));
					};
				}

				readAsync = (url, onload, onerror) => {
					var xhr = new XMLHttpRequest();
					xhr.open('GET', url, true);
					xhr.responseType = 'arraybuffer';
					xhr.onload = () => {
						if (xhr.status == 200 || (xhr.status == 0 && xhr.response)) {
							// file URLs can return 0
							onload(xhr.response);
							return;
						}
						onerror();
					};
					xhr.onerror = onerror;
					xhr.send(null);
				};

				// end include: web_or_worker_shell_read.js
			}
		} else {
			throw new Error('environment detection error');
		}

		var out = Module['print'] || console.log.bind(console);
		var err = Module['printErr'] || console.error.bind(console);

		// Merge back in the overrides
		Object.assign(Module, moduleOverrides);
		// Free the object hierarchy contained in the overrides, this lets the GC
		// reclaim data used.
		moduleOverrides = null;
		checkIncomingModuleAPI();

		// Emit code to handle expected values on the Module object. This applies Module.x
		// to the proper local x. This has two benefits: first, we only emit it if it is
		// expected to arrive, and second, by using a local everywhere else that can be
		// minified.

		if (Module['arguments']) arguments_ = Module['arguments'];
		legacyModuleProp('arguments', 'arguments_');

		if (Module['thisProgram']) thisProgram = Module['thisProgram'];
		legacyModuleProp('thisProgram', 'thisProgram');

		if (Module['quit']) quit_ = Module['quit'];
		legacyModuleProp('quit', 'quit_');

		// perform assertions in shell.js after we set up out() and err(), as otherwise if an assertion fails it cannot print the message
		// Assertions on removed incoming Module JS APIs.
		assert(
			typeof Module['memoryInitializerPrefixURL'] == 'undefined',
			'Module.memoryInitializerPrefixURL option was removed, use Module.locateFile instead',
		);
		assert(
			typeof Module['pthreadMainPrefixURL'] == 'undefined',
			'Module.pthreadMainPrefixURL option was removed, use Module.locateFile instead',
		);
		assert(
			typeof Module['cdInitializerPrefixURL'] == 'undefined',
			'Module.cdInitializerPrefixURL option was removed, use Module.locateFile instead',
		);
		assert(
			typeof Module['filePackagePrefixURL'] == 'undefined',
			'Module.filePackagePrefixURL option was removed, use Module.locateFile instead',
		);
		assert(typeof Module['read'] == 'undefined', 'Module.read option was removed (modify read_ in JS)');
		assert(
			typeof Module['readAsync'] == 'undefined',
			'Module.readAsync option was removed (modify readAsync in JS)',
		);
		assert(
			typeof Module['readBinary'] == 'undefined',
			'Module.readBinary option was removed (modify readBinary in JS)',
		);
		assert(
			typeof Module['setWindowTitle'] == 'undefined',
			'Module.setWindowTitle option was removed (modify emscripten_set_window_title in JS)',
		);
		assert(
			typeof Module['TOTAL_MEMORY'] == 'undefined',
			'Module.TOTAL_MEMORY has been renamed Module.INITIAL_MEMORY',
		);
		legacyModuleProp('asm', 'wasmExports');
		legacyModuleProp('read', 'read_');
		legacyModuleProp('readAsync', 'readAsync');
		legacyModuleProp('readBinary', 'readBinary');
		legacyModuleProp('setWindowTitle', 'setWindowTitle');
		var IDBFS = 'IDBFS is no longer included by default; build with -lidbfs.js';
		var PROXYFS = 'PROXYFS is no longer included by default; build with -lproxyfs.js';
		var WORKERFS = 'WORKERFS is no longer included by default; build with -lworkerfs.js';
		var FETCHFS = 'FETCHFS is no longer included by default; build with -lfetchfs.js';
		var ICASEFS = 'ICASEFS is no longer included by default; build with -licasefs.js';
		var JSFILEFS = 'JSFILEFS is no longer included by default; build with -ljsfilefs.js';
		var OPFS = 'OPFS is no longer included by default; build with -lopfs.js';

		var NODEFS = 'NODEFS is no longer included by default; build with -lnodefs.js';

		assert(
			!ENVIRONMENT_IS_SHELL,
			'shell environment detected but not enabled at build time.  Add `shell` to `-sENVIRONMENT` to enable.',
		);

		// end include: shell.js

		// include: preamble.js
		// === Preamble library stuff ===

		// Documentation for the public APIs defined in this file must be updated in:
		//    site/source/docs/api_reference/preamble.js.rst
		// A prebuilt local version of the documentation is available at:
		//    site/build/text/docs/api_reference/preamble.js.txt
		// You can also build docs locally as HTML or other formats in site/
		// An online HTML version (which may be of a different version of Emscripten)
		//    is up at http://kripken.github.io/emscripten-site/docs/api_reference/preamble.js.html

		var wasmBinary;
		if (Module['wasmBinary']) wasmBinary = Module['wasmBinary'];
		legacyModuleProp('wasmBinary', 'wasmBinary');

		if (typeof WebAssembly != 'object') {
			abort('no native wasm support detected');
		}

		// Wasm globals

		var wasmMemory;

		//========================================
		// Runtime essentials
		//========================================

		// whether we are quitting the application. no code should run after this.
		// set in exit() and abort()
		var ABORT = false;

		// set by exit() and abort().  Passed to 'onExit' handler.
		// NOTE: This is also used as the process return code code in shell environments
		// but only when noExitRuntime is false.
		var EXITSTATUS;

		// In STRICT mode, we only define assert() when ASSERTIONS is set.  i.e. we
		// don't define it at all in release modes.  This matches the behaviour of
		// MINIMAL_RUNTIME.
		// TODO(sbc): Make this the default even without STRICT enabled.
		/** @type {function(*, string=)} */
		function assert(condition, text) {
			if (!condition) {
				abort('Assertion failed' + (text ? ': ' + text : ''));
			}
		}

		// We used to include malloc/free by default in the past. Show a helpful error in
		// builds with assertions.
		function _malloc() {
			abort('malloc() called but not included in the build - add `_malloc` to EXPORTED_FUNCTIONS');
		}
		function _free() {
			// Show a helpful error since we used to include free by default in the past.
			abort('free() called but not included in the build - add `_free` to EXPORTED_FUNCTIONS');
		}

		// Memory management

		var HEAP,
			/** @type {!Int8Array} */
			HEAP8,
			/** @type {!Uint8Array} */
			HEAPU8,
			/** @type {!Int16Array} */
			HEAP16,
			/** @type {!Uint16Array} */
			HEAPU16,
			/** @type {!Int32Array} */
			HEAP32,
			/** @type {!Uint32Array} */
			HEAPU32,
			/** @type {!Float32Array} */
			HEAPF32,
			/** @type {!Float64Array} */
			HEAPF64;

		// include: runtime_shared.js
		function updateMemoryViews() {
			var b = wasmMemory.buffer;
			Module['HEAP8'] = HEAP8 = new Int8Array(b);
			Module['HEAP16'] = HEAP16 = new Int16Array(b);
			Module['HEAPU8'] = HEAPU8 = new Uint8Array(b);
			Module['HEAPU16'] = HEAPU16 = new Uint16Array(b);
			Module['HEAP32'] = HEAP32 = new Int32Array(b);
			Module['HEAPU32'] = HEAPU32 = new Uint32Array(b);
			Module['HEAPF32'] = HEAPF32 = new Float32Array(b);
			Module['HEAPF64'] = HEAPF64 = new Float64Array(b);
		}
		// end include: runtime_shared.js
		assert(!Module['STACK_SIZE'], 'STACK_SIZE can no longer be set at runtime.  Use -sSTACK_SIZE at link time');

		assert(
			typeof Int32Array != 'undefined' &&
				typeof Float64Array !== 'undefined' &&
				Int32Array.prototype.subarray != undefined &&
				Int32Array.prototype.set != undefined,
			'JS engine does not provide full typed array support',
		);

		// If memory is defined in wasm, the user can't provide it, or set INITIAL_MEMORY
		assert(
			!Module['wasmMemory'],
			'Use of `wasmMemory` detected.  Use -sIMPORTED_MEMORY to define wasmMemory externally',
		);
		assert(
			!Module['INITIAL_MEMORY'],
			'Detected runtime INITIAL_MEMORY setting.  Use -sIMPORTED_MEMORY to define wasmMemory dynamically',
		);

		// include: runtime_stack_check.js
		// Initializes the stack cookie. Called at the startup of main and at the startup of each thread in pthreads mode.
		function writeStackCookie() {
			var max = _emscripten_stack_get_end();
			assert((max & 3) == 0);
			// If the stack ends at address zero we write our cookies 4 bytes into the
			// stack.  This prevents interference with SAFE_HEAP and ASAN which also
			// monitor writes to address zero.
			if (max == 0) {
				max += 4;
			}
			// The stack grow downwards towards _emscripten_stack_get_end.
			// We write cookies to the final two words in the stack and detect if they are
			// ever overwritten.
			HEAPU32[max >> 2] = 0x02135467;
			HEAPU32[(max + 4) >> 2] = 0x89bacdfe;
			// Also test the global address 0 for integrity.
			HEAPU32[0 >> 2] = 1668509029;
		}

		function checkStackCookie() {
			if (ABORT) return;
			var max = _emscripten_stack_get_end();
			// See writeStackCookie().
			if (max == 0) {
				max += 4;
			}
			var cookie1 = HEAPU32[max >> 2];
			var cookie2 = HEAPU32[(max + 4) >> 2];
			if (cookie1 != 0x02135467 || cookie2 != 0x89bacdfe) {
				abort(
					`Stack overflow! Stack cookie has been overwritten at ${ptrToString(max)}, expected hex dwords 0x89BACDFE and 0x2135467, but received ${ptrToString(cookie2)} ${ptrToString(cookie1)}`,
				);
			}
			// Also test the global address 0 for integrity.
			if (HEAPU32[0 >> 2] != 0x63736d65 /* 'emsc' */) {
				abort('Runtime error: The application has corrupted its heap memory area (address zero)!');
			}
		}
		// end include: runtime_stack_check.js
		// include: runtime_assertions.js
		// Endianness check
		(function () {
			var h16 = new Int16Array(1);
			var h8 = new Int8Array(h16.buffer);
			h16[0] = 0x6373;
			if (h8[0] !== 0x73 || h8[1] !== 0x63)
				throw 'Runtime error: expected the system to be little-endian! (Run with -sSUPPORT_BIG_ENDIAN to bypass)';
		})();

		// end include: runtime_assertions.js
		var __ATPRERUN__ = []; // functions called before the runtime is initialized
		var __ATINIT__ = []; // functions called during startup
		var __ATEXIT__ = []; // functions called during shutdown
		var __ATPOSTRUN__ = []; // functions called after the main() is called

		var runtimeInitialized = false;

		function preRun() {
			if (Module['preRun']) {
				if (typeof Module['preRun'] == 'function') Module['preRun'] = [Module['preRun']];
				while (Module['preRun'].length) {
					addOnPreRun(Module['preRun'].shift());
				}
			}
			callRuntimeCallbacks(__ATPRERUN__);
		}

		function initRuntime() {
			assert(!runtimeInitialized);
			runtimeInitialized = true;

			checkStackCookie();

			if (!Module['noFSInit'] && !FS.init.initialized) FS.init();
			FS.ignorePermissions = false;

			TTY.init();
			callRuntimeCallbacks(__ATINIT__);
		}

		function postRun() {
			checkStackCookie();

			if (Module['postRun']) {
				if (typeof Module['postRun'] == 'function') Module['postRun'] = [Module['postRun']];
				while (Module['postRun'].length) {
					addOnPostRun(Module['postRun'].shift());
				}
			}

			callRuntimeCallbacks(__ATPOSTRUN__);
		}

		function addOnPreRun(cb) {
			__ATPRERUN__.unshift(cb);
		}

		function addOnInit(cb) {
			__ATINIT__.unshift(cb);
		}

		function addOnExit(cb) {}

		function addOnPostRun(cb) {
			__ATPOSTRUN__.unshift(cb);
		}

		// include: runtime_math.js
		// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/imul

		// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/fround

		// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/clz32

		// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/trunc

		assert(
			Math.imul,
			'This browser does not support Math.imul(), build with LEGACY_VM_SUPPORT or POLYFILL_OLD_MATH_FUNCTIONS to add in a polyfill',
		);
		assert(
			Math.fround,
			'This browser does not support Math.fround(), build with LEGACY_VM_SUPPORT or POLYFILL_OLD_MATH_FUNCTIONS to add in a polyfill',
		);
		assert(
			Math.clz32,
			'This browser does not support Math.clz32(), build with LEGACY_VM_SUPPORT or POLYFILL_OLD_MATH_FUNCTIONS to add in a polyfill',
		);
		assert(
			Math.trunc,
			'This browser does not support Math.trunc(), build with LEGACY_VM_SUPPORT or POLYFILL_OLD_MATH_FUNCTIONS to add in a polyfill',
		);
		// end include: runtime_math.js
		// A counter of dependencies for calling run(). If we need to
		// do asynchronous work before running, increment this and
		// decrement it. Incrementing must happen in a place like
		// Module.preRun (used by emcc to add file preloading).
		// Note that you can add dependencies in preRun, even though
		// it happens right before run - run will be postponed until
		// the dependencies are met.
		var runDependencies = 0;
		var runDependencyWatcher = null;
		var dependenciesFulfilled = null; // overridden to take different actions when all run dependencies are fulfilled
		var runDependencyTracking = {};

		function getUniqueRunDependency(id) {
			var orig = id;
			while (1) {
				if (!runDependencyTracking[id]) return id;
				id = orig + Math.random();
			}
		}

		function addRunDependency(id) {
			runDependencies++;

			Module['monitorRunDependencies']?.(runDependencies);

			if (id) {
				assert(!runDependencyTracking[id]);
				runDependencyTracking[id] = 1;
				if (runDependencyWatcher === null && typeof setInterval != 'undefined') {
					// Check for missing dependencies every few seconds
					runDependencyWatcher = setInterval(() => {
						if (ABORT) {
							clearInterval(runDependencyWatcher);
							runDependencyWatcher = null;
							return;
						}
						var shown = false;
						for (var dep in runDependencyTracking) {
							if (!shown) {
								shown = true;
								err('still waiting on run dependencies:');
							}
							err(`dependency: ${dep}`);
						}
						if (shown) {
							err('(end of list)');
						}
					}, 10000);
				}
			} else {
				err('warning: run dependency added without ID');
			}
		}

		function removeRunDependency(id) {
			runDependencies--;

			Module['monitorRunDependencies']?.(runDependencies);

			if (id) {
				assert(runDependencyTracking[id]);
				delete runDependencyTracking[id];
			} else {
				err('warning: run dependency removed without ID');
			}
			if (runDependencies == 0) {
				if (runDependencyWatcher !== null) {
					clearInterval(runDependencyWatcher);
					runDependencyWatcher = null;
				}
				if (dependenciesFulfilled) {
					var callback = dependenciesFulfilled;
					dependenciesFulfilled = null;
					callback(); // can add another dependenciesFulfilled
				}
			}
		}

		/** @param {string|number=} what */
		function abort(what) {
			Module['onAbort']?.(what);

			what = 'Aborted(' + what + ')';
			// TODO(sbc): Should we remove printing and leave it up to whoever
			// catches the exception?
			err(what);

			ABORT = true;
			EXITSTATUS = 1;

			// Use a wasm runtime error, because a JS error might be seen as a foreign
			// exception, which means we'd run destructors on it. We need the error to
			// simply make the program stop.
			// FIXME This approach does not work in Wasm EH because it currently does not assume
			// all RuntimeErrors are from traps; it decides whether a RuntimeError is from
			// a trap or not based on a hidden field within the object. So at the moment
			// we don't have a way of throwing a wasm trap from JS. TODO Make a JS API that
			// allows this in the wasm spec.

			// Suppress closure compiler warning here. Closure compiler's builtin extern
			// definition for WebAssembly.RuntimeError claims it takes no arguments even
			// though it can.
			// TODO(https://github.com/google/closure-compiler/pull/3913): Remove if/when upstream closure gets fixed.
			/** @suppress {checkTypes} */
			var e = new WebAssembly.RuntimeError(what);

			readyPromiseReject(e);
			// Throw the error whether or not MODULARIZE is set because abort is used
			// in code paths apart from instantiation where an exception is expected
			// to be thrown when abort is called.
			throw e;
		}

		// include: memoryprofiler.js
		// end include: memoryprofiler.js
		// include: URIUtils.js
		// Prefix of data URIs emitted by SINGLE_FILE and related options.
		var dataURIPrefix = 'data:application/octet-stream;base64,';

		/**
		 * Indicates whether filename is a base64 data URI.
		 * @noinline
		 */
		var isDataURI = (filename) => filename.startsWith(dataURIPrefix);

		/**
		 * Indicates whether filename is delivered via file protocol (as opposed to http/https)
		 * @noinline
		 */
		var isFileURI = (filename) => filename.startsWith('file://');
		// end include: URIUtils.js
		function createExportWrapper(name) {
			return (...args) => {
				assert(runtimeInitialized, `native function \`${name}\` called before runtime initialization`);
				var f = wasmExports[name];
				assert(f, `exported native function \`${name}\` not found`);
				return f(...args);
			};
		}

		// include: runtime_exceptions.js
		// end include: runtime_exceptions.js
		var wasmBinaryFile;
		if (Module['locateFile']) {
			wasmBinaryFile = 'mupdf-wasm.wasm';
			if (!isDataURI(wasmBinaryFile)) {
				wasmBinaryFile = locateFile(wasmBinaryFile);
			}
		} else {
			// Use bundler-friendly `new URL(..., import.meta.url)` pattern; works in browsers too.
			wasmBinaryFile = new URL('mupdf-wasm.wasm', import.meta.url).href;
		}

		function getBinarySync(file) {
			if (file == wasmBinaryFile && wasmBinary) {
				return new Uint8Array(wasmBinary);
			}
			if (readBinary) {
				return readBinary(file);
			}
			throw 'both async and sync fetching of the wasm failed';
		}

		function getBinaryPromise(binaryFile) {
			// If we don't have the binary yet, try to load it asynchronously.
			// Fetch has some additional restrictions over XHR, like it can't be used on a file:// url.
			// See https://github.com/github/fetch/pull/92#issuecomment-140665932
			// Cordova or Electron apps are typically loaded from a file:// url.
			// So use fetch if it is available and the url is not a file, otherwise fall back to XHR.
			if (!wasmBinary && (ENVIRONMENT_IS_WEB || ENVIRONMENT_IS_WORKER)) {
				if (typeof fetch == 'function' && !isFileURI(binaryFile)) {
					return fetch(binaryFile, { credentials: 'same-origin' })
						.then((response) => {
							if (!response['ok']) {
								throw `failed to load wasm binary file at '${binaryFile}'`;
							}
							return response['arrayBuffer']();
						})
						.catch(() => getBinarySync(binaryFile));
				} else if (readAsync) {
					// fetch is not available or url is file => try XHR (readAsync uses XHR internally)
					return new Promise((resolve, reject) => {
						readAsync(
							binaryFile,
							(response) => resolve(new Uint8Array(/** @type{!ArrayBuffer} */ (response))),
							reject,
						);
					});
				}
			}

			// Otherwise, getBinarySync should be able to get it synchronously
			return Promise.resolve().then(() => getBinarySync(binaryFile));
		}

		function instantiateArrayBuffer(binaryFile, imports, receiver) {
			return getBinaryPromise(binaryFile)
				.then((binary) => {
					return WebAssembly.instantiate(binary, imports);
				})
				.then(receiver, (reason) => {
					err(`failed to asynchronously prepare wasm: ${reason}`);

					// Warn on some common problems.
					if (isFileURI(wasmBinaryFile)) {
						err(
							`warning: Loading from a file URI (${wasmBinaryFile}) is not supported in most browsers. See https://emscripten.org/docs/getting_started/FAQ.html#how-do-i-run-a-local-webserver-for-testing-why-does-my-program-stall-in-downloading-or-preparing`,
						);
					}
					abort(reason);
				});
		}

		function instantiateAsync(binary, binaryFile, imports, callback) {
			if (
				!binary &&
				typeof WebAssembly.instantiateStreaming == 'function' &&
				!isDataURI(binaryFile) &&
				// Don't use streaming for file:// delivered objects in a webview, fetch them synchronously.
				!isFileURI(binaryFile) &&
				// Avoid instantiateStreaming() on Node.js environment for now, as while
				// Node.js v18.1.0 implements it, it does not have a full fetch()
				// implementation yet.
				//
				// Reference:
				//   https://github.com/emscripten-core/emscripten/pull/16917
				!ENVIRONMENT_IS_NODE &&
				typeof fetch == 'function'
			) {
				return fetch(binaryFile, { credentials: 'same-origin' }).then((response) => {
					// Suppress closure warning here since the upstream definition for
					// instantiateStreaming only allows Promise<Repsponse> rather than
					// an actual Response.
					// TODO(https://github.com/google/closure-compiler/pull/3913): Remove if/when upstream closure is fixed.
					/** @suppress {checkTypes} */
					var result = WebAssembly.instantiateStreaming(response, imports);

					return result.then(callback, function (reason) {
						// We expect the most common failure cause to be a bad MIME type for the binary,
						// in which case falling back to ArrayBuffer instantiation should work.
						err(`wasm streaming compile failed: ${reason}`);
						err('falling back to ArrayBuffer instantiation');
						return instantiateArrayBuffer(binaryFile, imports, callback);
					});
				});
			}
			return instantiateArrayBuffer(binaryFile, imports, callback);
		}

		// Create the wasm instance.
		// Receives the wasm imports, returns the exports.
		function createWasm() {
			// prepare imports
			var info = {
				env: wasmImports,
				wasi_snapshot_preview1: wasmImports,
			};
			// Load the wasm module and create an instance of using native support in the JS engine.
			// handle a generated wasm instance, receiving its exports and
			// performing other necessary setup
			/** @param {WebAssembly.Module=} module*/
			function receiveInstance(instance, module) {
				wasmExports = instance.exports;

				wasmMemory = wasmExports['memory'];

				assert(wasmMemory, 'memory not found in wasm exports');
				// This assertion doesn't hold when emscripten is run in --post-link
				// mode.
				// TODO(sbc): Read INITIAL_MEMORY out of the wasm file in post-link mode.
				//assert(wasmMemory.buffer.byteLength === 16777216);
				updateMemoryViews();

				wasmTable = wasmExports['__indirect_function_table'];

				assert(wasmTable, 'table not found in wasm exports');

				addOnInit(wasmExports['__wasm_call_ctors']);

				removeRunDependency('wasm-instantiate');
				return wasmExports;
			}
			// wait for the pthread pool (if any)
			addRunDependency('wasm-instantiate');

			// Prefer streaming instantiation if available.
			// Async compilation can be confusing when an error on the page overwrites Module
			// (for example, if the order of elements is wrong, and the one defining Module is
			// later), so we save Module and check it later.
			var trueModule = Module;
			function receiveInstantiationResult(result) {
				// 'result' is a ResultObject object which has both the module and instance.
				// receiveInstance() will swap in the exports (to Module.asm) so they can be called
				assert(
					Module === trueModule,
					'the Module object should not be replaced during async compilation - perhaps the order of HTML elements is wrong?',
				);
				trueModule = null;
				// TODO: Due to Closure regression https://github.com/google/closure-compiler/issues/3193, the above line no longer optimizes out down to the following line.
				// When the regression is fixed, can restore the above PTHREADS-enabled path.
				receiveInstance(result['instance']);
			}

			// User shell pages can write their own Module.instantiateWasm = function(imports, successCallback) callback
			// to manually instantiate the Wasm module themselves. This allows pages to
			// run the instantiation parallel to any other async startup actions they are
			// performing.
			// Also pthreads and wasm workers initialize the wasm instance through this
			// path.
			if (Module['instantiateWasm']) {
				try {
					return Module['instantiateWasm'](info, receiveInstance);
				} catch (e) {
					err(`Module.instantiateWasm callback failed with error: ${e}`);
					// If instantiation fails, reject the module ready promise.
					readyPromiseReject(e);
				}
			}

			// If instantiation fails, reject the module ready promise.
			instantiateAsync(wasmBinary, wasmBinaryFile, info, receiveInstantiationResult).catch(readyPromiseReject);
			return {}; // no exports yet; we'll fill them in later
		}

		// Globals used by JS i64 conversions (see makeSetValue)
		var tempDouble;
		var tempI64;

		// include: runtime_debug.js
		function legacyModuleProp(prop, newName, incoming = true) {
			if (!Object.getOwnPropertyDescriptor(Module, prop)) {
				Object.defineProperty(Module, prop, {
					configurable: true,
					get() {
						let extra = incoming
							? ' (the initial value can be provided on Module, but after startup the value is only looked for on a local variable of that name)'
							: '';
						abort(`\`Module.${prop}\` has been replaced by \`${newName}\`` + extra);
					},
				});
			}
		}

		function ignoredModuleProp(prop) {
			if (Object.getOwnPropertyDescriptor(Module, prop)) {
				abort(`\`Module.${prop}\` was supplied but \`${prop}\` not included in INCOMING_MODULE_JS_API`);
			}
		}

		// forcing the filesystem exports a few things by default
		function isExportedByForceFilesystem(name) {
			return (
				name === 'FS_createPath' ||
				name === 'FS_createDataFile' ||
				name === 'FS_createPreloadedFile' ||
				name === 'FS_unlink' ||
				name === 'addRunDependency' ||
				// The old FS has some functionality that WasmFS lacks.
				name === 'FS_createLazyFile' ||
				name === 'FS_createDevice' ||
				name === 'removeRunDependency'
			);
		}

		function missingGlobal(sym, msg) {
			if (typeof globalThis !== 'undefined') {
				Object.defineProperty(globalThis, sym, {
					configurable: true,
					get() {
						warnOnce(`\`${sym}\` is not longer defined by emscripten. ${msg}`);
						return undefined;
					},
				});
			}
		}

		missingGlobal('buffer', 'Please use HEAP8.buffer or wasmMemory.buffer');
		missingGlobal('asm', 'Please use wasmExports instead');

		function missingLibrarySymbol(sym) {
			if (typeof globalThis !== 'undefined' && !Object.getOwnPropertyDescriptor(globalThis, sym)) {
				Object.defineProperty(globalThis, sym, {
					configurable: true,
					get() {
						// Can't `abort()` here because it would break code that does runtime
						// checks.  e.g. `if (typeof SDL === 'undefined')`.
						var msg = `\`${sym}\` is a library symbol and not included by default; add it to your library.js __deps or to DEFAULT_LIBRARY_FUNCS_TO_INCLUDE on the command line`;
						// DEFAULT_LIBRARY_FUNCS_TO_INCLUDE requires the name as it appears in
						// library.js, which means $name for a JS name with no prefix, or name
						// for a JS name like _name.
						var librarySymbol = sym;
						if (!librarySymbol.startsWith('_')) {
							librarySymbol = '$' + sym;
						}
						msg += ` (e.g. -sDEFAULT_LIBRARY_FUNCS_TO_INCLUDE='${librarySymbol}')`;
						if (isExportedByForceFilesystem(sym)) {
							msg +=
								'. Alternatively, forcing filesystem support (-sFORCE_FILESYSTEM) can export this for you';
						}
						warnOnce(msg);
						return undefined;
					},
				});
			}
			// Any symbol that is not included from the JS library is also (by definition)
			// not exported on the Module object.
			unexportedRuntimeSymbol(sym);
		}

		function unexportedRuntimeSymbol(sym) {
			if (!Object.getOwnPropertyDescriptor(Module, sym)) {
				Object.defineProperty(Module, sym, {
					configurable: true,
					get() {
						var msg = `'${sym}' was not exported. add it to EXPORTED_RUNTIME_METHODS (see the Emscripten FAQ)`;
						if (isExportedByForceFilesystem(sym)) {
							msg +=
								'. Alternatively, forcing filesystem support (-sFORCE_FILESYSTEM) can export this for you';
						}
						abort(msg);
					},
				});
			}
		}

		// Used by XXXXX_DEBUG settings to output debug messages.
		function dbg(...args) {
			// TODO(sbc): Make this configurable somehow.  Its not always convenient for
			// logging to show up as warnings.
			console.warn(...args);
		}
		// end include: runtime_debug.js
		// === Body ===

		var ASM_CONSTS = {
			5735504: () => {
				throw 'TRYLATER';
			},
			5735526: () => {
				throw 'ABORT';
			},
			5735545: ($0) => {
				throw new Error(UTF8ToString($0));
			},
			5735584: () => {
				throw new Error('Cannot create MuPDF context!');
			},
			5735637: ($0, $1, $2, $3) => {
				return globalThis.$libmupdf_stm_read($0, $1, $2, $3);
			},
			5735695: ($0) => {
				globalThis.$libmupdf_stm_close($0);
			},
			5735735: ($0, $1, $2, $3) => {
				return globalThis.$libmupdf_stm_seek($0, $1, $2, $3);
			},
		};

		// end include: preamble.js

		/** @constructor */
		function ExitStatus(status) {
			this.name = 'ExitStatus';
			this.message = `Program terminated with exit(${status})`;
			this.status = status;
		}

		var callRuntimeCallbacks = (callbacks) => {
			while (callbacks.length > 0) {
				// Pass the module as the first argument.
				callbacks.shift()(Module);
			}
		};

		/**
		 * @param {number} ptr
		 * @param {string} type
		 */
		function getValue(ptr, type = 'i8') {
			if (type.endsWith('*')) type = '*';
			switch (type) {
				case 'i1':
					return HEAP8[ptr];
				case 'i8':
					return HEAP8[ptr];
				case 'i16':
					return HEAP16[ptr >> 1];
				case 'i32':
					return HEAP32[ptr >> 2];
				case 'i64':
					abort('to do getValue(i64) use WASM_BIGINT');
				case 'float':
					return HEAPF32[ptr >> 2];
				case 'double':
					return HEAPF64[ptr >> 3];
				case '*':
					return HEAPU32[ptr >> 2];
				default:
					abort(`invalid type for getValue: ${type}`);
			}
		}

		var noExitRuntime = Module['noExitRuntime'] || true;

		var ptrToString = (ptr) => {
			assert(typeof ptr === 'number');
			// With CAN_ADDRESS_2GB or MEMORY64, pointers are already unsigned.
			ptr >>>= 0;
			return '0x' + ptr.toString(16).padStart(8, '0');
		};

		/**
		 * @param {number} ptr
		 * @param {number} value
		 * @param {string} type
		 */
		function setValue(ptr, value, type = 'i8') {
			if (type.endsWith('*')) type = '*';
			switch (type) {
				case 'i1':
					HEAP8[ptr] = value;
					break;
				case 'i8':
					HEAP8[ptr] = value;
					break;
				case 'i16':
					HEAP16[ptr >> 1] = value;
					break;
				case 'i32':
					HEAP32[ptr >> 2] = value;
					break;
				case 'i64':
					abort('to do setValue(i64) use WASM_BIGINT');
				case 'float':
					HEAPF32[ptr >> 2] = value;
					break;
				case 'double':
					HEAPF64[ptr >> 3] = value;
					break;
				case '*':
					HEAPU32[ptr >> 2] = value;
					break;
				default:
					abort(`invalid type for setValue: ${type}`);
			}
		}

		var warnOnce = (text) => {
			warnOnce.shown ||= {};
			if (!warnOnce.shown[text]) {
				warnOnce.shown[text] = 1;
				if (ENVIRONMENT_IS_NODE) text = 'warning: ' + text;
				err(text);
			}
		};

		var PATH = {
			isAbs: (path) => path.charAt(0) === '/',
			splitPath: (filename) => {
				var splitPathRe = /^(\/?|)([\s\S]*?)((?:\.{1,2}|[^\/]+?|)(\.[^.\/]*|))(?:[\/]*)$/;
				return splitPathRe.exec(filename).slice(1);
			},
			normalizeArray: (parts, allowAboveRoot) => {
				// if the path tries to go above the root, `up` ends up > 0
				var up = 0;
				for (var i = parts.length - 1; i >= 0; i--) {
					var last = parts[i];
					if (last === '.') {
						parts.splice(i, 1);
					} else if (last === '..') {
						parts.splice(i, 1);
						up++;
					} else if (up) {
						parts.splice(i, 1);
						up--;
					}
				}
				// if the path is allowed to go above the root, restore leading ..s
				if (allowAboveRoot) {
					for (; up; up--) {
						parts.unshift('..');
					}
				}
				return parts;
			},
			normalize: (path) => {
				var isAbsolute = PATH.isAbs(path),
					trailingSlash = path.substr(-1) === '/';
				// Normalize the path
				path = PATH.normalizeArray(
					path.split('/').filter((p) => !!p),
					!isAbsolute,
				).join('/');
				if (!path && !isAbsolute) {
					path = '.';
				}
				if (path && trailingSlash) {
					path += '/';
				}
				return (isAbsolute ? '/' : '') + path;
			},
			dirname: (path) => {
				var result = PATH.splitPath(path),
					root = result[0],
					dir = result[1];
				if (!root && !dir) {
					// No dirname whatsoever
					return '.';
				}
				if (dir) {
					// It has a dirname, strip trailing slash
					dir = dir.substr(0, dir.length - 1);
				}
				return root + dir;
			},
			basename: (path) => {
				// EMSCRIPTEN return '/'' for '/', not an empty string
				if (path === '/') return '/';
				path = PATH.normalize(path);
				path = path.replace(/\/$/, '');
				var lastSlash = path.lastIndexOf('/');
				if (lastSlash === -1) return path;
				return path.substr(lastSlash + 1);
			},
			join: (...paths) => PATH.normalize(paths.join('/')),
			join2: (l, r) => PATH.normalize(l + '/' + r),
		};

		var initRandomFill = () => {
			if (typeof crypto == 'object' && typeof crypto['getRandomValues'] == 'function') {
				// for modern web browsers
				return (view) => crypto.getRandomValues(view);
			} else if (ENVIRONMENT_IS_NODE) {
				// for nodejs with or without crypto support included
				try {
					var crypto_module = require('crypto');
					var randomFillSync = crypto_module['randomFillSync'];
					if (randomFillSync) {
						// nodejs with LTS crypto support
						return (view) => crypto_module['randomFillSync'](view);
					}
					// very old nodejs with the original crypto API
					var randomBytes = crypto_module['randomBytes'];
					return (view) => (
						view.set(randomBytes(view.byteLength)),
						// Return the original view to match modern native implementations.
						view
					);
				} catch (e) {
					// nodejs doesn't have crypto support
				}
			}
			// we couldn't find a proper implementation, as Math.random() is not suitable for /dev/random, see emscripten-core/emscripten/pull/7096
			abort(
				'no cryptographic support found for randomDevice. consider polyfilling it if you want to use something insecure like Math.random(), e.g. put this in a --pre-js: var crypto = { getRandomValues: (array) => { for (var i = 0; i < array.length; i++) array[i] = (Math.random()*256)|0 } };',
			);
		};
		var randomFill = (view) => {
			// Lazily init on the first invocation.
			return (randomFill = initRandomFill())(view);
		};

		var PATH_FS = {
			resolve: (...args) => {
				var resolvedPath = '',
					resolvedAbsolute = false;
				for (var i = args.length - 1; i >= -1 && !resolvedAbsolute; i--) {
					var path = i >= 0 ? args[i] : FS.cwd();
					// Skip empty and invalid entries
					if (typeof path != 'string') {
						throw new TypeError('Arguments to path.resolve must be strings');
					} else if (!path) {
						return ''; // an invalid portion invalidates the whole thing
					}
					resolvedPath = path + '/' + resolvedPath;
					resolvedAbsolute = PATH.isAbs(path);
				}
				// At this point the path should be resolved to a full absolute path, but
				// handle relative paths to be safe (might happen when process.cwd() fails)
				resolvedPath = PATH.normalizeArray(
					resolvedPath.split('/').filter((p) => !!p),
					!resolvedAbsolute,
				).join('/');
				return (resolvedAbsolute ? '/' : '') + resolvedPath || '.';
			},
			relative: (from, to) => {
				from = PATH_FS.resolve(from).substr(1);
				to = PATH_FS.resolve(to).substr(1);
				function trim(arr) {
					var start = 0;
					for (; start < arr.length; start++) {
						if (arr[start] !== '') break;
					}
					var end = arr.length - 1;
					for (; end >= 0; end--) {
						if (arr[end] !== '') break;
					}
					if (start > end) return [];
					return arr.slice(start, end - start + 1);
				}
				var fromParts = trim(from.split('/'));
				var toParts = trim(to.split('/'));
				var length = Math.min(fromParts.length, toParts.length);
				var samePartsLength = length;
				for (var i = 0; i < length; i++) {
					if (fromParts[i] !== toParts[i]) {
						samePartsLength = i;
						break;
					}
				}
				var outputParts = [];
				for (var i = samePartsLength; i < fromParts.length; i++) {
					outputParts.push('..');
				}
				outputParts = outputParts.concat(toParts.slice(samePartsLength));
				return outputParts.join('/');
			},
		};

		var UTF8Decoder = typeof TextDecoder != 'undefined' ? new TextDecoder('utf8') : undefined;

		/**
		 * Given a pointer 'idx' to a null-terminated UTF8-encoded string in the given
		 * array that contains uint8 values, returns a copy of that string as a
		 * Javascript String object.
		 * heapOrArray is either a regular array, or a JavaScript typed array view.
		 * @param {number} idx
		 * @param {number=} maxBytesToRead
		 * @return {string}
		 */
		var UTF8ArrayToString = (heapOrArray, idx, maxBytesToRead) => {
			var endIdx = idx + maxBytesToRead;
			var endPtr = idx;
			// TextDecoder needs to know the byte length in advance, it doesn't stop on
			// null terminator by itself.  Also, use the length info to avoid running tiny
			// strings through TextDecoder, since .subarray() allocates garbage.
			// (As a tiny code save trick, compare endPtr against endIdx using a negation,
			// so that undefined means Infinity)
			while (heapOrArray[endPtr] && !(endPtr >= endIdx)) ++endPtr;

			if (endPtr - idx > 16 && heapOrArray.buffer && UTF8Decoder) {
				return UTF8Decoder.decode(heapOrArray.subarray(idx, endPtr));
			}
			var str = '';
			// If building with TextDecoder, we have already computed the string length
			// above, so test loop end condition against that
			while (idx < endPtr) {
				// For UTF8 byte structure, see:
				// http://en.wikipedia.org/wiki/UTF-8#Description
				// https://www.ietf.org/rfc/rfc2279.txt
				// https://tools.ietf.org/html/rfc3629
				var u0 = heapOrArray[idx++];
				if (!(u0 & 0x80)) {
					str += String.fromCharCode(u0);
					continue;
				}
				var u1 = heapOrArray[idx++] & 63;
				if ((u0 & 0xe0) == 0xc0) {
					str += String.fromCharCode(((u0 & 31) << 6) | u1);
					continue;
				}
				var u2 = heapOrArray[idx++] & 63;
				if ((u0 & 0xf0) == 0xe0) {
					u0 = ((u0 & 15) << 12) | (u1 << 6) | u2;
				} else {
					if ((u0 & 0xf8) != 0xf0)
						warnOnce(
							'Invalid UTF-8 leading byte ' +
								ptrToString(u0) +
								' encountered when deserializing a UTF-8 string in wasm memory to a JS string!',
						);
					u0 = ((u0 & 7) << 18) | (u1 << 12) | (u2 << 6) | (heapOrArray[idx++] & 63);
				}

				if (u0 < 0x10000) {
					str += String.fromCharCode(u0);
				} else {
					var ch = u0 - 0x10000;
					str += String.fromCharCode(0xd800 | (ch >> 10), 0xdc00 | (ch & 0x3ff));
				}
			}
			return str;
		};

		var FS_stdin_getChar_buffer = [];

		var lengthBytesUTF8 = (str) => {
			var len = 0;
			for (var i = 0; i < str.length; ++i) {
				// Gotcha: charCodeAt returns a 16-bit word that is a UTF-16 encoded code
				// unit, not a Unicode code point of the character! So decode
				// UTF16->UTF32->UTF8.
				// See http://unicode.org/faq/utf_bom.html#utf16-3
				var c = str.charCodeAt(i); // possibly a lead surrogate
				if (c <= 0x7f) {
					len++;
				} else if (c <= 0x7ff) {
					len += 2;
				} else if (c >= 0xd800 && c <= 0xdfff) {
					len += 4;
					++i;
				} else {
					len += 3;
				}
			}
			return len;
		};

		var stringToUTF8Array = (str, heap, outIdx, maxBytesToWrite) => {
			assert(typeof str === 'string', `stringToUTF8Array expects a string (got ${typeof str})`);
			// Parameter maxBytesToWrite is not optional. Negative values, 0, null,
			// undefined and false each don't write out any bytes.
			if (!(maxBytesToWrite > 0)) return 0;

			var startIdx = outIdx;
			var endIdx = outIdx + maxBytesToWrite - 1; // -1 for string null terminator.
			for (var i = 0; i < str.length; ++i) {
				// Gotcha: charCodeAt returns a 16-bit word that is a UTF-16 encoded code
				// unit, not a Unicode code point of the character! So decode
				// UTF16->UTF32->UTF8.
				// See http://unicode.org/faq/utf_bom.html#utf16-3
				// For UTF8 byte structure, see http://en.wikipedia.org/wiki/UTF-8#Description
				// and https://www.ietf.org/rfc/rfc2279.txt
				// and https://tools.ietf.org/html/rfc3629
				var u = str.charCodeAt(i); // possibly a lead surrogate
				if (u >= 0xd800 && u <= 0xdfff) {
					var u1 = str.charCodeAt(++i);
					u = (0x10000 + ((u & 0x3ff) << 10)) | (u1 & 0x3ff);
				}
				if (u <= 0x7f) {
					if (outIdx >= endIdx) break;
					heap[outIdx++] = u;
				} else if (u <= 0x7ff) {
					if (outIdx + 1 >= endIdx) break;
					heap[outIdx++] = 0xc0 | (u >> 6);
					heap[outIdx++] = 0x80 | (u & 63);
				} else if (u <= 0xffff) {
					if (outIdx + 2 >= endIdx) break;
					heap[outIdx++] = 0xe0 | (u >> 12);
					heap[outIdx++] = 0x80 | ((u >> 6) & 63);
					heap[outIdx++] = 0x80 | (u & 63);
				} else {
					if (outIdx + 3 >= endIdx) break;
					if (u > 0x10ffff)
						warnOnce(
							'Invalid Unicode code point ' +
								ptrToString(u) +
								' encountered when serializing a JS string to a UTF-8 string in wasm memory! (Valid unicode code points should be in range 0-0x10FFFF).',
						);
					heap[outIdx++] = 0xf0 | (u >> 18);
					heap[outIdx++] = 0x80 | ((u >> 12) & 63);
					heap[outIdx++] = 0x80 | ((u >> 6) & 63);
					heap[outIdx++] = 0x80 | (u & 63);
				}
			}
			// Null-terminate the pointer to the buffer.
			heap[outIdx] = 0;
			return outIdx - startIdx;
		};
		/** @type {function(string, boolean=, number=)} */
		function intArrayFromString(stringy, dontAddNull, length) {
			var len = length > 0 ? length : lengthBytesUTF8(stringy) + 1;
			var u8array = new Array(len);
			var numBytesWritten = stringToUTF8Array(stringy, u8array, 0, u8array.length);
			if (dontAddNull) u8array.length = numBytesWritten;
			return u8array;
		}
		var FS_stdin_getChar = () => {
			if (!FS_stdin_getChar_buffer.length) {
				var result = null;
				if (ENVIRONMENT_IS_NODE) {
					// we will read data by chunks of BUFSIZE
					var BUFSIZE = 256;
					var buf = Buffer.alloc(BUFSIZE);
					var bytesRead = 0;

					// For some reason we must suppress a closure warning here, even though
					// fd definitely exists on process.stdin, and is even the proper way to
					// get the fd of stdin,
					// https://github.com/nodejs/help/issues/2136#issuecomment-523649904
					// This started to happen after moving this logic out of library_tty.js,
					// so it is related to the surrounding code in some unclear manner.
					/** @suppress {missingProperties} */
					var fd = process.stdin.fd;

					try {
						bytesRead = fs.readSync(fd, buf);
					} catch (e) {
						// Cross-platform differences: on Windows, reading EOF throws an exception, but on other OSes,
						// reading EOF returns 0. Uniformize behavior by treating the EOF exception to return 0.
						if (e.toString().includes('EOF')) bytesRead = 0;
						else throw e;
					}

					if (bytesRead > 0) {
						result = buf.slice(0, bytesRead).toString('utf-8');
					} else {
						result = null;
					}
				} else if (typeof window != 'undefined' && typeof window.prompt == 'function') {
					// Browser.
					result = window.prompt('Input: '); // returns null on cancel
					if (result !== null) {
						result += '\n';
					}
				} else if (typeof readline == 'function') {
					// Command line.
					result = readline();
					if (result !== null) {
						result += '\n';
					}
				}
				if (!result) {
					return null;
				}
				FS_stdin_getChar_buffer = intArrayFromString(result, true);
			}
			return FS_stdin_getChar_buffer.shift();
		};
		var TTY = {
			ttys: [],
			init() {
				// https://github.com/emscripten-core/emscripten/pull/1555
				// if (ENVIRONMENT_IS_NODE) {
				//   // currently, FS.init does not distinguish if process.stdin is a file or TTY
				//   // device, it always assumes it's a TTY device. because of this, we're forcing
				//   // process.stdin to UTF8 encoding to at least make stdin reading compatible
				//   // with text files until FS.init can be refactored.
				//   process.stdin.setEncoding('utf8');
				// }
			},
			shutdown() {
				// https://github.com/emscripten-core/emscripten/pull/1555
				// if (ENVIRONMENT_IS_NODE) {
				//   // inolen: any idea as to why node -e 'process.stdin.read()' wouldn't exit immediately (with process.stdin being a tty)?
				//   // isaacs: because now it's reading from the stream, you've expressed interest in it, so that read() kicks off a _read() which creates a ReadReq operation
				//   // inolen: I thought read() in that case was a synchronous operation that just grabbed some amount of buffered data if it exists?
				//   // isaacs: it is. but it also triggers a _read() call, which calls readStart() on the handle
				//   // isaacs: do process.stdin.pause() and i'd think it'd probably close the pending call
				//   process.stdin.pause();
				// }
			},
			register(dev, ops) {
				TTY.ttys[dev] = { input: [], output: [], ops: ops };
				FS.registerDevice(dev, TTY.stream_ops);
			},
			stream_ops: {
				open(stream) {
					var tty = TTY.ttys[stream.node.rdev];
					if (!tty) {
						throw new FS.ErrnoError(43);
					}
					stream.tty = tty;
					stream.seekable = false;
				},
				close(stream) {
					// flush any pending line data
					stream.tty.ops.fsync(stream.tty);
				},
				fsync(stream) {
					stream.tty.ops.fsync(stream.tty);
				},
				read(stream, buffer, offset, length, pos /* ignored */) {
					if (!stream.tty || !stream.tty.ops.get_char) {
						throw new FS.ErrnoError(60);
					}
					var bytesRead = 0;
					for (var i = 0; i < length; i++) {
						var result;
						try {
							result = stream.tty.ops.get_char(stream.tty);
						} catch (e) {
							throw new FS.ErrnoError(29);
						}
						if (result === undefined && bytesRead === 0) {
							throw new FS.ErrnoError(6);
						}
						if (result === null || result === undefined) break;
						bytesRead++;
						buffer[offset + i] = result;
					}
					if (bytesRead) {
						stream.node.timestamp = Date.now();
					}
					return bytesRead;
				},
				write(stream, buffer, offset, length, pos) {
					if (!stream.tty || !stream.tty.ops.put_char) {
						throw new FS.ErrnoError(60);
					}
					try {
						for (var i = 0; i < length; i++) {
							stream.tty.ops.put_char(stream.tty, buffer[offset + i]);
						}
					} catch (e) {
						throw new FS.ErrnoError(29);
					}
					if (length) {
						stream.node.timestamp = Date.now();
					}
					return i;
				},
			},
			default_tty_ops: {
				get_char(tty) {
					return FS_stdin_getChar();
				},
				put_char(tty, val) {
					if (val === null || val === 10) {
						out(UTF8ArrayToString(tty.output, 0));
						tty.output = [];
					} else {
						if (val != 0) tty.output.push(val); // val == 0 would cut text output off in the middle.
					}
				},
				fsync(tty) {
					if (tty.output && tty.output.length > 0) {
						out(UTF8ArrayToString(tty.output, 0));
						tty.output = [];
					}
				},
				ioctl_tcgets(tty) {
					// typical setting
					return {
						c_iflag: 25856,
						c_oflag: 5,
						c_cflag: 191,
						c_lflag: 35387,
						c_cc: [
							0x03, 0x1c, 0x7f, 0x15, 0x04, 0x00, 0x01, 0x00, 0x11, 0x13, 0x1a, 0x00, 0x12, 0x0f, 0x17,
							0x16, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
							0x00, 0x00,
						],
					};
				},
				ioctl_tcsets(tty, optional_actions, data) {
					// currently just ignore
					return 0;
				},
				ioctl_tiocgwinsz(tty) {
					return [24, 80];
				},
			},
			default_tty1_ops: {
				put_char(tty, val) {
					if (val === null || val === 10) {
						err(UTF8ArrayToString(tty.output, 0));
						tty.output = [];
					} else {
						if (val != 0) tty.output.push(val);
					}
				},
				fsync(tty) {
					if (tty.output && tty.output.length > 0) {
						err(UTF8ArrayToString(tty.output, 0));
						tty.output = [];
					}
				},
			},
		};

		var zeroMemory = (address, size) => {
			HEAPU8.fill(0, address, address + size);
			return address;
		};

		var alignMemory = (size, alignment) => {
			assert(alignment, 'alignment argument is required');
			return Math.ceil(size / alignment) * alignment;
		};
		var mmapAlloc = (size) => {
			abort('internal error: mmapAlloc called but `emscripten_builtin_memalign` native symbol not exported');
		};
		var MEMFS = {
			ops_table: null,
			mount(mount) {
				return MEMFS.createNode(null, '/', 16384 | 511 /* 0777 */, 0);
			},
			createNode(parent, name, mode, dev) {
				if (FS.isBlkdev(mode) || FS.isFIFO(mode)) {
					// no supported
					throw new FS.ErrnoError(63);
				}
				MEMFS.ops_table ||= {
					dir: {
						node: {
							getattr: MEMFS.node_ops.getattr,
							setattr: MEMFS.node_ops.setattr,
							lookup: MEMFS.node_ops.lookup,
							mknod: MEMFS.node_ops.mknod,
							rename: MEMFS.node_ops.rename,
							unlink: MEMFS.node_ops.unlink,
							rmdir: MEMFS.node_ops.rmdir,
							readdir: MEMFS.node_ops.readdir,
							symlink: MEMFS.node_ops.symlink,
						},
						stream: {
							llseek: MEMFS.stream_ops.llseek,
						},
					},
					file: {
						node: {
							getattr: MEMFS.node_ops.getattr,
							setattr: MEMFS.node_ops.setattr,
						},
						stream: {
							llseek: MEMFS.stream_ops.llseek,
							read: MEMFS.stream_ops.read,
							write: MEMFS.stream_ops.write,
							allocate: MEMFS.stream_ops.allocate,
							mmap: MEMFS.stream_ops.mmap,
							msync: MEMFS.stream_ops.msync,
						},
					},
					link: {
						node: {
							getattr: MEMFS.node_ops.getattr,
							setattr: MEMFS.node_ops.setattr,
							readlink: MEMFS.node_ops.readlink,
						},
						stream: {},
					},
					chrdev: {
						node: {
							getattr: MEMFS.node_ops.getattr,
							setattr: MEMFS.node_ops.setattr,
						},
						stream: FS.chrdev_stream_ops,
					},
				};
				var node = FS.createNode(parent, name, mode, dev);
				if (FS.isDir(node.mode)) {
					node.node_ops = MEMFS.ops_table.dir.node;
					node.stream_ops = MEMFS.ops_table.dir.stream;
					node.contents = {};
				} else if (FS.isFile(node.mode)) {
					node.node_ops = MEMFS.ops_table.file.node;
					node.stream_ops = MEMFS.ops_table.file.stream;
					node.usedBytes = 0; // The actual number of bytes used in the typed array, as opposed to contents.length which gives the whole capacity.
					// When the byte data of the file is populated, this will point to either a typed array, or a normal JS array. Typed arrays are preferred
					// for performance, and used by default. However, typed arrays are not resizable like normal JS arrays are, so there is a small disk size
					// penalty involved for appending file writes that continuously grow a file similar to std::vector capacity vs used -scheme.
					node.contents = null;
				} else if (FS.isLink(node.mode)) {
					node.node_ops = MEMFS.ops_table.link.node;
					node.stream_ops = MEMFS.ops_table.link.stream;
				} else if (FS.isChrdev(node.mode)) {
					node.node_ops = MEMFS.ops_table.chrdev.node;
					node.stream_ops = MEMFS.ops_table.chrdev.stream;
				}
				node.timestamp = Date.now();
				// add the new node to the parent
				if (parent) {
					parent.contents[name] = node;
					parent.timestamp = node.timestamp;
				}
				return node;
			},
			getFileDataAsTypedArray(node) {
				if (!node.contents) return new Uint8Array(0);
				if (node.contents.subarray) return node.contents.subarray(0, node.usedBytes); // Make sure to not return excess unused bytes.
				return new Uint8Array(node.contents);
			},
			expandFileStorage(node, newCapacity) {
				var prevCapacity = node.contents ? node.contents.length : 0;
				if (prevCapacity >= newCapacity) return; // No need to expand, the storage was already large enough.
				// Don't expand strictly to the given requested limit if it's only a very small increase, but instead geometrically grow capacity.
				// For small filesizes (<1MB), perform size*2 geometric increase, but for large sizes, do a much more conservative size*1.125 increase to
				// avoid overshooting the allocation cap by a very large margin.
				var CAPACITY_DOUBLING_MAX = 1024 * 1024;
				newCapacity = Math.max(
					newCapacity,
					(prevCapacity * (prevCapacity < CAPACITY_DOUBLING_MAX ? 2.0 : 1.125)) >>> 0,
				);
				if (prevCapacity != 0) newCapacity = Math.max(newCapacity, 256); // At minimum allocate 256b for each file when expanding.
				var oldContents = node.contents;
				node.contents = new Uint8Array(newCapacity); // Allocate new storage.
				if (node.usedBytes > 0) node.contents.set(oldContents.subarray(0, node.usedBytes), 0); // Copy old data over to the new storage.
			},
			resizeFileStorage(node, newSize) {
				if (node.usedBytes == newSize) return;
				if (newSize == 0) {
					node.contents = null; // Fully decommit when requesting a resize to zero.
					node.usedBytes = 0;
				} else {
					var oldContents = node.contents;
					node.contents = new Uint8Array(newSize); // Allocate new storage.
					if (oldContents) {
						node.contents.set(oldContents.subarray(0, Math.min(newSize, node.usedBytes))); // Copy old data over to the new storage.
					}
					node.usedBytes = newSize;
				}
			},
			node_ops: {
				getattr(node) {
					var attr = {};
					// device numbers reuse inode numbers.
					attr.dev = FS.isChrdev(node.mode) ? node.id : 1;
					attr.ino = node.id;
					attr.mode = node.mode;
					attr.nlink = 1;
					attr.uid = 0;
					attr.gid = 0;
					attr.rdev = node.rdev;
					if (FS.isDir(node.mode)) {
						attr.size = 4096;
					} else if (FS.isFile(node.mode)) {
						attr.size = node.usedBytes;
					} else if (FS.isLink(node.mode)) {
						attr.size = node.link.length;
					} else {
						attr.size = 0;
					}
					attr.atime = new Date(node.timestamp);
					attr.mtime = new Date(node.timestamp);
					attr.ctime = new Date(node.timestamp);
					// NOTE: In our implementation, st_blocks = Math.ceil(st_size/st_blksize),
					//       but this is not required by the standard.
					attr.blksize = 4096;
					attr.blocks = Math.ceil(attr.size / attr.blksize);
					return attr;
				},
				setattr(node, attr) {
					if (attr.mode !== undefined) {
						node.mode = attr.mode;
					}
					if (attr.timestamp !== undefined) {
						node.timestamp = attr.timestamp;
					}
					if (attr.size !== undefined) {
						MEMFS.resizeFileStorage(node, attr.size);
					}
				},
				lookup(parent, name) {
					throw FS.genericErrors[44];
				},
				mknod(parent, name, mode, dev) {
					return MEMFS.createNode(parent, name, mode, dev);
				},
				rename(old_node, new_dir, new_name) {
					// if we're overwriting a directory at new_name, make sure it's empty.
					if (FS.isDir(old_node.mode)) {
						var new_node;
						try {
							new_node = FS.lookupNode(new_dir, new_name);
						} catch (e) {}
						if (new_node) {
							for (var i in new_node.contents) {
								throw new FS.ErrnoError(55);
							}
						}
					}
					// do the internal rewiring
					delete old_node.parent.contents[old_node.name];
					old_node.parent.timestamp = Date.now();
					old_node.name = new_name;
					new_dir.contents[new_name] = old_node;
					new_dir.timestamp = old_node.parent.timestamp;
					old_node.parent = new_dir;
				},
				unlink(parent, name) {
					delete parent.contents[name];
					parent.timestamp = Date.now();
				},
				rmdir(parent, name) {
					var node = FS.lookupNode(parent, name);
					for (var i in node.contents) {
						throw new FS.ErrnoError(55);
					}
					delete parent.contents[name];
					parent.timestamp = Date.now();
				},
				readdir(node) {
					var entries = ['.', '..'];
					for (var key of Object.keys(node.contents)) {
						entries.push(key);
					}
					return entries;
				},
				symlink(parent, newname, oldpath) {
					var node = MEMFS.createNode(parent, newname, 511 /* 0777 */ | 40960, 0);
					node.link = oldpath;
					return node;
				},
				readlink(node) {
					if (!FS.isLink(node.mode)) {
						throw new FS.ErrnoError(28);
					}
					return node.link;
				},
			},
			stream_ops: {
				read(stream, buffer, offset, length, position) {
					var contents = stream.node.contents;
					if (position >= stream.node.usedBytes) return 0;
					var size = Math.min(stream.node.usedBytes - position, length);
					assert(size >= 0);
					if (size > 8 && contents.subarray) {
						// non-trivial, and typed array
						buffer.set(contents.subarray(position, position + size), offset);
					} else {
						for (var i = 0; i < size; i++) buffer[offset + i] = contents[position + i];
					}
					return size;
				},
				write(stream, buffer, offset, length, position, canOwn) {
					// The data buffer should be a typed array view
					assert(!(buffer instanceof ArrayBuffer));
					// If the buffer is located in main memory (HEAP), and if
					// memory can grow, we can't hold on to references of the
					// memory buffer, as they may get invalidated. That means we
					// need to do copy its contents.
					if (buffer.buffer === HEAP8.buffer) {
						canOwn = false;
					}

					if (!length) return 0;
					var node = stream.node;
					node.timestamp = Date.now();

					if (buffer.subarray && (!node.contents || node.contents.subarray)) {
						// This write is from a typed array to a typed array?
						if (canOwn) {
							assert(position === 0, 'canOwn must imply no weird position inside the file');
							node.contents = buffer.subarray(offset, offset + length);
							node.usedBytes = length;
							return length;
						} else if (node.usedBytes === 0 && position === 0) {
							// If this is a simple first write to an empty file, do a fast set since we don't need to care about old data.
							node.contents = buffer.slice(offset, offset + length);
							node.usedBytes = length;
							return length;
						} else if (position + length <= node.usedBytes) {
							// Writing to an already allocated and used subrange of the file?
							node.contents.set(buffer.subarray(offset, offset + length), position);
							return length;
						}
					}

					// Appending to an existing file and we need to reallocate, or source data did not come as a typed array.
					MEMFS.expandFileStorage(node, position + length);
					if (node.contents.subarray && buffer.subarray) {
						// Use typed array write which is available.
						node.contents.set(buffer.subarray(offset, offset + length), position);
					} else {
						for (var i = 0; i < length; i++) {
							node.contents[position + i] = buffer[offset + i]; // Or fall back to manual write if not.
						}
					}
					node.usedBytes = Math.max(node.usedBytes, position + length);
					return length;
				},
				llseek(stream, offset, whence) {
					var position = offset;
					if (whence === 1) {
						position += stream.position;
					} else if (whence === 2) {
						if (FS.isFile(stream.node.mode)) {
							position += stream.node.usedBytes;
						}
					}
					if (position < 0) {
						throw new FS.ErrnoError(28);
					}
					return position;
				},
				allocate(stream, offset, length) {
					MEMFS.expandFileStorage(stream.node, offset + length);
					stream.node.usedBytes = Math.max(stream.node.usedBytes, offset + length);
				},
				mmap(stream, length, position, prot, flags) {
					if (!FS.isFile(stream.node.mode)) {
						throw new FS.ErrnoError(43);
					}
					var ptr;
					var allocated;
					var contents = stream.node.contents;
					// Only make a new copy when MAP_PRIVATE is specified.
					if (!(flags & 2) && contents.buffer === HEAP8.buffer) {
						// We can't emulate MAP_SHARED when the file is not backed by the
						// buffer we're mapping to (e.g. the HEAP buffer).
						allocated = false;
						ptr = contents.byteOffset;
					} else {
						// Try to avoid unnecessary slices.
						if (position > 0 || position + length < contents.length) {
							if (contents.subarray) {
								contents = contents.subarray(position, position + length);
							} else {
								contents = Array.prototype.slice.call(contents, position, position + length);
							}
						}
						allocated = true;
						ptr = mmapAlloc(length);
						if (!ptr) {
							throw new FS.ErrnoError(48);
						}
						HEAP8.set(contents, ptr);
					}
					return { ptr, allocated };
				},
				msync(stream, buffer, offset, length, mmapFlags) {
					MEMFS.stream_ops.write(stream, buffer, 0, length, offset, false);
					// should we check if bytesWritten and length are the same?
					return 0;
				},
			},
		};

		/** @param {boolean=} noRunDep */
		var asyncLoad = (url, onload, onerror, noRunDep) => {
			var dep = !noRunDep ? getUniqueRunDependency(`al ${url}`) : '';
			readAsync(
				url,
				(arrayBuffer) => {
					assert(arrayBuffer, `Loading data file "${url}" failed (no arrayBuffer).`);
					onload(new Uint8Array(arrayBuffer));
					if (dep) removeRunDependency(dep);
				},
				(event) => {
					if (onerror) {
						onerror();
					} else {
						throw `Loading data file "${url}" failed.`;
					}
				},
			);
			if (dep) addRunDependency(dep);
		};

		var FS_createDataFile = (parent, name, fileData, canRead, canWrite, canOwn) => {
			FS.createDataFile(parent, name, fileData, canRead, canWrite, canOwn);
		};

		var preloadPlugins = Module['preloadPlugins'] || [];
		var FS_handledByPreloadPlugin = (byteArray, fullname, finish, onerror) => {
			// Ensure plugins are ready.
			if (typeof Browser != 'undefined') Browser.init();

			var handled = false;
			preloadPlugins.forEach((plugin) => {
				if (handled) return;
				if (plugin['canHandle'](fullname)) {
					plugin['handle'](byteArray, fullname, finish, onerror);
					handled = true;
				}
			});
			return handled;
		};
		var FS_createPreloadedFile = (
			parent,
			name,
			url,
			canRead,
			canWrite,
			onload,
			onerror,
			dontCreateFile,
			canOwn,
			preFinish,
		) => {
			// TODO we should allow people to just pass in a complete filename instead
			// of parent and name being that we just join them anyways
			var fullname = name ? PATH_FS.resolve(PATH.join2(parent, name)) : parent;
			var dep = getUniqueRunDependency(`cp ${fullname}`); // might have several active requests for the same fullname
			function processData(byteArray) {
				function finish(byteArray) {
					preFinish?.();
					if (!dontCreateFile) {
						FS_createDataFile(parent, name, byteArray, canRead, canWrite, canOwn);
					}
					onload?.();
					removeRunDependency(dep);
				}
				if (
					FS_handledByPreloadPlugin(byteArray, fullname, finish, () => {
						onerror?.();
						removeRunDependency(dep);
					})
				) {
					return;
				}
				finish(byteArray);
			}
			addRunDependency(dep);
			if (typeof url == 'string') {
				asyncLoad(url, processData, onerror);
			} else {
				processData(url);
			}
		};

		var FS_modeStringToFlags = (str) => {
			var flagModes = {
				r: 0,
				'r+': 2,
				w: 512 | 64 | 1,
				'w+': 512 | 64 | 2,
				a: 1024 | 64 | 1,
				'a+': 1024 | 64 | 2,
			};
			var flags = flagModes[str];
			if (typeof flags == 'undefined') {
				throw new Error(`Unknown file open mode: ${str}`);
			}
			return flags;
		};

		var FS_getMode = (canRead, canWrite) => {
			var mode = 0;
			if (canRead) mode |= 292 | 73;
			if (canWrite) mode |= 146;
			return mode;
		};

		var ERRNO_MESSAGES = {
			0: 'Success',
			1: 'Arg list too long',
			2: 'Permission denied',
			3: 'Address already in use',
			4: 'Address not available',
			5: 'Address family not supported by protocol family',
			6: 'No more processes',
			7: 'Socket already connected',
			8: 'Bad file number',
			9: 'Trying to read unreadable message',
			10: 'Mount device busy',
			11: 'Operation canceled',
			12: 'No children',
			13: 'Connection aborted',
			14: 'Connection refused',
			15: 'Connection reset by peer',
			16: 'File locking deadlock error',
			17: 'Destination address required',
			18: 'Math arg out of domain of func',
			19: 'Quota exceeded',
			20: 'File exists',
			21: 'Bad address',
			22: 'File too large',
			23: 'Host is unreachable',
			24: 'Identifier removed',
			25: 'Illegal byte sequence',
			26: 'Connection already in progress',
			27: 'Interrupted system call',
			28: 'Invalid argument',
			29: 'I/O error',
			30: 'Socket is already connected',
			31: 'Is a directory',
			32: 'Too many symbolic links',
			33: 'Too many open files',
			34: 'Too many links',
			35: 'Message too long',
			36: 'Multihop attempted',
			37: 'File or path name too long',
			38: 'Network interface is not configured',
			39: 'Connection reset by network',
			40: 'Network is unreachable',
			41: 'Too many open files in system',
			42: 'No buffer space available',
			43: 'No such device',
			44: 'No such file or directory',
			45: 'Exec format error',
			46: 'No record locks available',
			47: 'The link has been severed',
			48: 'Not enough core',
			49: 'No message of desired type',
			50: 'Protocol not available',
			51: 'No space left on device',
			52: 'Function not implemented',
			53: 'Socket is not connected',
			54: 'Not a directory',
			55: 'Directory not empty',
			56: 'State not recoverable',
			57: 'Socket operation on non-socket',
			59: 'Not a typewriter',
			60: 'No such device or address',
			61: 'Value too large for defined data type',
			62: 'Previous owner died',
			63: 'Not super-user',
			64: 'Broken pipe',
			65: 'Protocol error',
			66: 'Unknown protocol',
			67: 'Protocol wrong type for socket',
			68: 'Math result not representable',
			69: 'Read only file system',
			70: 'Illegal seek',
			71: 'No such process',
			72: 'Stale file handle',
			73: 'Connection timed out',
			74: 'Text file busy',
			75: 'Cross-device link',
			100: 'Device not a stream',
			101: 'Bad font file fmt',
			102: 'Invalid slot',
			103: 'Invalid request code',
			104: 'No anode',
			105: 'Block device required',
			106: 'Channel number out of range',
			107: 'Level 3 halted',
			108: 'Level 3 reset',
			109: 'Link number out of range',
			110: 'Protocol driver not attached',
			111: 'No CSI structure available',
			112: 'Level 2 halted',
			113: 'Invalid exchange',
			114: 'Invalid request descriptor',
			115: 'Exchange full',
			116: 'No data (for no delay io)',
			117: 'Timer expired',
			118: 'Out of streams resources',
			119: 'Machine is not on the network',
			120: 'Package not installed',
			121: 'The object is remote',
			122: 'Advertise error',
			123: 'Srmount error',
			124: 'Communication error on send',
			125: 'Cross mount point (not really error)',
			126: 'Given log. name not unique',
			127: 'f.d. invalid for this operation',
			128: 'Remote address changed',
			129: 'Can   access a needed shared lib',
			130: 'Accessing a corrupted shared lib',
			131: '.lib section in a.out corrupted',
			132: 'Attempting to link in too many libs',
			133: 'Attempting to exec a shared library',
			135: 'Streams pipe error',
			136: 'Too many users',
			137: 'Socket type not supported',
			138: 'Not supported',
			139: 'Protocol family not supported',
			140: "Can't send after socket shutdown",
			141: 'Too many references',
			142: 'Host is down',
			148: 'No medium (in tape drive)',
			156: 'Level 2 not synchronized',
		};

		var ERRNO_CODES = {
			EPERM: 63,
			ENOENT: 44,
			ESRCH: 71,
			EINTR: 27,
			EIO: 29,
			ENXIO: 60,
			E2BIG: 1,
			ENOEXEC: 45,
			EBADF: 8,
			ECHILD: 12,
			EAGAIN: 6,
			EWOULDBLOCK: 6,
			ENOMEM: 48,
			EACCES: 2,
			EFAULT: 21,
			ENOTBLK: 105,
			EBUSY: 10,
			EEXIST: 20,
			EXDEV: 75,
			ENODEV: 43,
			ENOTDIR: 54,
			EISDIR: 31,
			EINVAL: 28,
			ENFILE: 41,
			EMFILE: 33,
			ENOTTY: 59,
			ETXTBSY: 74,
			EFBIG: 22,
			ENOSPC: 51,
			ESPIPE: 70,
			EROFS: 69,
			EMLINK: 34,
			EPIPE: 64,
			EDOM: 18,
			ERANGE: 68,
			ENOMSG: 49,
			EIDRM: 24,
			ECHRNG: 106,
			EL2NSYNC: 156,
			EL3HLT: 107,
			EL3RST: 108,
			ELNRNG: 109,
			EUNATCH: 110,
			ENOCSI: 111,
			EL2HLT: 112,
			EDEADLK: 16,
			ENOLCK: 46,
			EBADE: 113,
			EBADR: 114,
			EXFULL: 115,
			ENOANO: 104,
			EBADRQC: 103,
			EBADSLT: 102,
			EDEADLOCK: 16,
			EBFONT: 101,
			ENOSTR: 100,
			ENODATA: 116,
			ETIME: 117,
			ENOSR: 118,
			ENONET: 119,
			ENOPKG: 120,
			EREMOTE: 121,
			ENOLINK: 47,
			EADV: 122,
			ESRMNT: 123,
			ECOMM: 124,
			EPROTO: 65,
			EMULTIHOP: 36,
			EDOTDOT: 125,
			EBADMSG: 9,
			ENOTUNIQ: 126,
			EBADFD: 127,
			EREMCHG: 128,
			ELIBACC: 129,
			ELIBBAD: 130,
			ELIBSCN: 131,
			ELIBMAX: 132,
			ELIBEXEC: 133,
			ENOSYS: 52,
			ENOTEMPTY: 55,
			ENAMETOOLONG: 37,
			ELOOP: 32,
			EOPNOTSUPP: 138,
			EPFNOSUPPORT: 139,
			ECONNRESET: 15,
			ENOBUFS: 42,
			EAFNOSUPPORT: 5,
			EPROTOTYPE: 67,
			ENOTSOCK: 57,
			ENOPROTOOPT: 50,
			ESHUTDOWN: 140,
			ECONNREFUSED: 14,
			EADDRINUSE: 3,
			ECONNABORTED: 13,
			ENETUNREACH: 40,
			ENETDOWN: 38,
			ETIMEDOUT: 73,
			EHOSTDOWN: 142,
			EHOSTUNREACH: 23,
			EINPROGRESS: 26,
			EALREADY: 7,
			EDESTADDRREQ: 17,
			EMSGSIZE: 35,
			EPROTONOSUPPORT: 66,
			ESOCKTNOSUPPORT: 137,
			EADDRNOTAVAIL: 4,
			ENETRESET: 39,
			EISCONN: 30,
			ENOTCONN: 53,
			ETOOMANYREFS: 141,
			EUSERS: 136,
			EDQUOT: 19,
			ESTALE: 72,
			ENOTSUP: 138,
			ENOMEDIUM: 148,
			EILSEQ: 25,
			EOVERFLOW: 61,
			ECANCELED: 11,
			ENOTRECOVERABLE: 56,
			EOWNERDEAD: 62,
			ESTRPIPE: 135,
		};
		var FS = {
			root: null,
			mounts: [],
			devices: {},
			streams: [],
			nextInode: 1,
			nameTable: null,
			currentPath: '/',
			initialized: false,
			ignorePermissions: true,
			ErrnoError: class extends Error {
				// We set the `name` property to be able to identify `FS.ErrnoError`
				// - the `name` is a standard ECMA-262 property of error objects. Kind of good to have it anyway.
				// - when using PROXYFS, an error can come from an underlying FS
				// as different FS objects have their own FS.ErrnoError each,
				// the test `err instanceof FS.ErrnoError` won't detect an error coming from another filesystem, causing bugs.
				// we'll use the reliable test `err.name == "ErrnoError"` instead
				constructor(errno) {
					super(ERRNO_MESSAGES[errno]);
					// TODO(sbc): Use the inline member declaration syntax once we
					// support it in acorn and closure.
					this.name = 'ErrnoError';
					this.errno = errno;
					for (var key in ERRNO_CODES) {
						if (ERRNO_CODES[key] === errno) {
							this.code = key;
							break;
						}
					}
				}
			},
			genericErrors: {},
			filesystems: null,
			syncFSRequests: 0,
			FSStream: class {
				constructor() {
					// TODO(https://github.com/emscripten-core/emscripten/issues/21414):
					// Use inline field declarations.
					this.shared = {};
				}
				get object() {
					return this.node;
				}
				set object(val) {
					this.node = val;
				}
				get isRead() {
					return (this.flags & 2097155) !== 1;
				}
				get isWrite() {
					return (this.flags & 2097155) !== 0;
				}
				get isAppend() {
					return this.flags & 1024;
				}
				get flags() {
					return this.shared.flags;
				}
				set flags(val) {
					this.shared.flags = val;
				}
				get position() {
					return this.shared.position;
				}
				set position(val) {
					this.shared.position = val;
				}
			},
			FSNode: class {
				constructor(parent, name, mode, rdev) {
					if (!parent) {
						parent = this; // root node sets parent to itself
					}
					this.parent = parent;
					this.mount = parent.mount;
					this.mounted = null;
					this.id = FS.nextInode++;
					this.name = name;
					this.mode = mode;
					this.node_ops = {};
					this.stream_ops = {};
					this.rdev = rdev;
					this.readMode = 292 /*292*/ | 73 /*73*/;
					this.writeMode = 146 /*146*/;
				}
				get read() {
					return (this.mode & this.readMode) === this.readMode;
				}
				set read(val) {
					val ? (this.mode |= this.readMode) : (this.mode &= ~this.readMode);
				}
				get write() {
					return (this.mode & this.writeMode) === this.writeMode;
				}
				set write(val) {
					val ? (this.mode |= this.writeMode) : (this.mode &= ~this.writeMode);
				}
				get isFolder() {
					return FS.isDir(this.mode);
				}
				get isDevice() {
					return FS.isChrdev(this.mode);
				}
			},
			lookupPath(path, opts = {}) {
				path = PATH_FS.resolve(path);

				if (!path) return { path: '', node: null };

				var defaults = {
					follow_mount: true,
					recurse_count: 0,
				};
				opts = Object.assign(defaults, opts);

				if (opts.recurse_count > 8) {
					// max recursive lookup of 8
					throw new FS.ErrnoError(32);
				}

				// split the absolute path
				var parts = path.split('/').filter((p) => !!p);

				// start at the root
				var current = FS.root;
				var current_path = '/';

				for (var i = 0; i < parts.length; i++) {
					var islast = i === parts.length - 1;
					if (islast && opts.parent) {
						// stop resolving
						break;
					}

					current = FS.lookupNode(current, parts[i]);
					current_path = PATH.join2(current_path, parts[i]);

					// jump to the mount's root node if this is a mountpoint
					if (FS.isMountpoint(current)) {
						if (!islast || (islast && opts.follow_mount)) {
							current = current.mounted.root;
						}
					}

					// by default, lookupPath will not follow a symlink if it is the final path component.
					// setting opts.follow = true will override this behavior.
					if (!islast || opts.follow) {
						var count = 0;
						while (FS.isLink(current.mode)) {
							var link = FS.readlink(current_path);
							current_path = PATH_FS.resolve(PATH.dirname(current_path), link);

							var lookup = FS.lookupPath(current_path, { recurse_count: opts.recurse_count + 1 });
							current = lookup.node;

							if (count++ > 40) {
								// limit max consecutive symlinks to 40 (SYMLOOP_MAX).
								throw new FS.ErrnoError(32);
							}
						}
					}
				}

				return { path: current_path, node: current };
			},
			getPath(node) {
				var path;
				while (true) {
					if (FS.isRoot(node)) {
						var mount = node.mount.mountpoint;
						if (!path) return mount;
						return mount[mount.length - 1] !== '/' ? `${mount}/${path}` : mount + path;
					}
					path = path ? `${node.name}/${path}` : node.name;
					node = node.parent;
				}
			},
			hashName(parentid, name) {
				var hash = 0;

				for (var i = 0; i < name.length; i++) {
					hash = ((hash << 5) - hash + name.charCodeAt(i)) | 0;
				}
				return ((parentid + hash) >>> 0) % FS.nameTable.length;
			},
			hashAddNode(node) {
				var hash = FS.hashName(node.parent.id, node.name);
				node.name_next = FS.nameTable[hash];
				FS.nameTable[hash] = node;
			},
			hashRemoveNode(node) {
				var hash = FS.hashName(node.parent.id, node.name);
				if (FS.nameTable[hash] === node) {
					FS.nameTable[hash] = node.name_next;
				} else {
					var current = FS.nameTable[hash];
					while (current) {
						if (current.name_next === node) {
							current.name_next = node.name_next;
							break;
						}
						current = current.name_next;
					}
				}
			},
			lookupNode(parent, name) {
				var errCode = FS.mayLookup(parent);
				if (errCode) {
					throw new FS.ErrnoError(errCode);
				}
				var hash = FS.hashName(parent.id, name);
				for (var node = FS.nameTable[hash]; node; node = node.name_next) {
					var nodeName = node.name;
					if (node.parent.id === parent.id && nodeName === name) {
						return node;
					}
				}
				// if we failed to find it in the cache, call into the VFS
				return FS.lookup(parent, name);
			},
			createNode(parent, name, mode, rdev) {
				assert(typeof parent == 'object');
				var node = new FS.FSNode(parent, name, mode, rdev);

				FS.hashAddNode(node);

				return node;
			},
			destroyNode(node) {
				FS.hashRemoveNode(node);
			},
			isRoot(node) {
				return node === node.parent;
			},
			isMountpoint(node) {
				return !!node.mounted;
			},
			isFile(mode) {
				return (mode & 61440) === 32768;
			},
			isDir(mode) {
				return (mode & 61440) === 16384;
			},
			isLink(mode) {
				return (mode & 61440) === 40960;
			},
			isChrdev(mode) {
				return (mode & 61440) === 8192;
			},
			isBlkdev(mode) {
				return (mode & 61440) === 24576;
			},
			isFIFO(mode) {
				return (mode & 61440) === 4096;
			},
			isSocket(mode) {
				return (mode & 49152) === 49152;
			},
			flagsToPermissionString(flag) {
				var perms = ['r', 'w', 'rw'][flag & 3];
				if (flag & 512) {
					perms += 'w';
				}
				return perms;
			},
			nodePermissions(node, perms) {
				if (FS.ignorePermissions) {
					return 0;
				}
				// return 0 if any user, group or owner bits are set.
				if (perms.includes('r') && !(node.mode & 292)) {
					return 2;
				} else if (perms.includes('w') && !(node.mode & 146)) {
					return 2;
				} else if (perms.includes('x') && !(node.mode & 73)) {
					return 2;
				}
				return 0;
			},
			mayLookup(dir) {
				if (!FS.isDir(dir.mode)) return 54;
				var errCode = FS.nodePermissions(dir, 'x');
				if (errCode) return errCode;
				if (!dir.node_ops.lookup) return 2;
				return 0;
			},
			mayCreate(dir, name) {
				try {
					var node = FS.lookupNode(dir, name);
					return 20;
				} catch (e) {}
				return FS.nodePermissions(dir, 'wx');
			},
			mayDelete(dir, name, isdir) {
				var node;
				try {
					node = FS.lookupNode(dir, name);
				} catch (e) {
					return e.errno;
				}
				var errCode = FS.nodePermissions(dir, 'wx');
				if (errCode) {
					return errCode;
				}
				if (isdir) {
					if (!FS.isDir(node.mode)) {
						return 54;
					}
					if (FS.isRoot(node) || FS.getPath(node) === FS.cwd()) {
						return 10;
					}
				} else {
					if (FS.isDir(node.mode)) {
						return 31;
					}
				}
				return 0;
			},
			mayOpen(node, flags) {
				if (!node) {
					return 44;
				}
				if (FS.isLink(node.mode)) {
					return 32;
				} else if (FS.isDir(node.mode)) {
					if (
						FS.flagsToPermissionString(flags) !== 'r' || // opening for write
						flags & 512
					) {
						// TODO: check for O_SEARCH? (== search for dir only)
						return 31;
					}
				}
				return FS.nodePermissions(node, FS.flagsToPermissionString(flags));
			},
			MAX_OPEN_FDS: 4096,
			nextfd() {
				for (var fd = 0; fd <= FS.MAX_OPEN_FDS; fd++) {
					if (!FS.streams[fd]) {
						return fd;
					}
				}
				throw new FS.ErrnoError(33);
			},
			getStreamChecked(fd) {
				var stream = FS.getStream(fd);
				if (!stream) {
					throw new FS.ErrnoError(8);
				}
				return stream;
			},
			getStream: (fd) => FS.streams[fd],
			createStream(stream, fd = -1) {
				// clone it, so we can return an instance of FSStream
				stream = Object.assign(new FS.FSStream(), stream);
				if (fd == -1) {
					fd = FS.nextfd();
				}
				stream.fd = fd;
				FS.streams[fd] = stream;
				return stream;
			},
			closeStream(fd) {
				FS.streams[fd] = null;
			},
			dupStream(origStream, fd = -1) {
				var stream = FS.createStream(origStream, fd);
				stream.stream_ops?.dup?.(stream);
				return stream;
			},
			chrdev_stream_ops: {
				open(stream) {
					var device = FS.getDevice(stream.node.rdev);
					// override node's stream ops with the device's
					stream.stream_ops = device.stream_ops;
					// forward the open call
					stream.stream_ops.open?.(stream);
				},
				llseek() {
					throw new FS.ErrnoError(70);
				},
			},
			major: (dev) => dev >> 8,
			minor: (dev) => dev & 0xff,
			makedev: (ma, mi) => (ma << 8) | mi,
			registerDevice(dev, ops) {
				FS.devices[dev] = { stream_ops: ops };
			},
			getDevice: (dev) => FS.devices[dev],
			getMounts(mount) {
				var mounts = [];
				var check = [mount];

				while (check.length) {
					var m = check.pop();

					mounts.push(m);

					check.push(...m.mounts);
				}

				return mounts;
			},
			syncfs(populate, callback) {
				if (typeof populate == 'function') {
					callback = populate;
					populate = false;
				}

				FS.syncFSRequests++;

				if (FS.syncFSRequests > 1) {
					err(
						`warning: ${FS.syncFSRequests} FS.syncfs operations in flight at once, probably just doing extra work`,
					);
				}

				var mounts = FS.getMounts(FS.root.mount);
				var completed = 0;

				function doCallback(errCode) {
					assert(FS.syncFSRequests > 0);
					FS.syncFSRequests--;
					return callback(errCode);
				}

				function done(errCode) {
					if (errCode) {
						if (!done.errored) {
							done.errored = true;
							return doCallback(errCode);
						}
						return;
					}
					if (++completed >= mounts.length) {
						doCallback(null);
					}
				}

				// sync all mounts
				mounts.forEach((mount) => {
					if (!mount.type.syncfs) {
						return done(null);
					}
					mount.type.syncfs(mount, populate, done);
				});
			},
			mount(type, opts, mountpoint) {
				if (typeof type == 'string') {
					// The filesystem was not included, and instead we have an error
					// message stored in the variable.
					throw type;
				}
				var root = mountpoint === '/';
				var pseudo = !mountpoint;
				var node;

				if (root && FS.root) {
					throw new FS.ErrnoError(10);
				} else if (!root && !pseudo) {
					var lookup = FS.lookupPath(mountpoint, { follow_mount: false });

					mountpoint = lookup.path; // use the absolute path
					node = lookup.node;

					if (FS.isMountpoint(node)) {
						throw new FS.ErrnoError(10);
					}

					if (!FS.isDir(node.mode)) {
						throw new FS.ErrnoError(54);
					}
				}

				var mount = {
					type,
					opts,
					mountpoint,
					mounts: [],
				};

				// create a root node for the fs
				var mountRoot = type.mount(mount);
				mountRoot.mount = mount;
				mount.root = mountRoot;

				if (root) {
					FS.root = mountRoot;
				} else if (node) {
					// set as a mountpoint
					node.mounted = mount;

					// add the new mount to the current mount's children
					if (node.mount) {
						node.mount.mounts.push(mount);
					}
				}

				return mountRoot;
			},
			unmount(mountpoint) {
				var lookup = FS.lookupPath(mountpoint, { follow_mount: false });

				if (!FS.isMountpoint(lookup.node)) {
					throw new FS.ErrnoError(28);
				}

				// destroy the nodes for this mount, and all its child mounts
				var node = lookup.node;
				var mount = node.mounted;
				var mounts = FS.getMounts(mount);

				Object.keys(FS.nameTable).forEach((hash) => {
					var current = FS.nameTable[hash];

					while (current) {
						var next = current.name_next;

						if (mounts.includes(current.mount)) {
							FS.destroyNode(current);
						}

						current = next;
					}
				});

				// no longer a mountpoint
				node.mounted = null;

				// remove this mount from the child mounts
				var idx = node.mount.mounts.indexOf(mount);
				assert(idx !== -1);
				node.mount.mounts.splice(idx, 1);
			},
			lookup(parent, name) {
				return parent.node_ops.lookup(parent, name);
			},
			mknod(path, mode, dev) {
				var lookup = FS.lookupPath(path, { parent: true });
				var parent = lookup.node;
				var name = PATH.basename(path);
				if (!name || name === '.' || name === '..') {
					throw new FS.ErrnoError(28);
				}
				var errCode = FS.mayCreate(parent, name);
				if (errCode) {
					throw new FS.ErrnoError(errCode);
				}
				if (!parent.node_ops.mknod) {
					throw new FS.ErrnoError(63);
				}
				return parent.node_ops.mknod(parent, name, mode, dev);
			},
			create(path, mode) {
				mode = mode !== undefined ? mode : 438 /* 0666 */;
				mode &= 4095;
				mode |= 32768;
				return FS.mknod(path, mode, 0);
			},
			mkdir(path, mode) {
				mode = mode !== undefined ? mode : 511 /* 0777 */;
				mode &= 511 | 512;
				mode |= 16384;
				return FS.mknod(path, mode, 0);
			},
			mkdirTree(path, mode) {
				var dirs = path.split('/');
				var d = '';
				for (var i = 0; i < dirs.length; ++i) {
					if (!dirs[i]) continue;
					d += '/' + dirs[i];
					try {
						FS.mkdir(d, mode);
					} catch (e) {
						if (e.errno != 20) throw e;
					}
				}
			},
			mkdev(path, mode, dev) {
				if (typeof dev == 'undefined') {
					dev = mode;
					mode = 438 /* 0666 */;
				}
				mode |= 8192;
				return FS.mknod(path, mode, dev);
			},
			symlink(oldpath, newpath) {
				if (!PATH_FS.resolve(oldpath)) {
					throw new FS.ErrnoError(44);
				}
				var lookup = FS.lookupPath(newpath, { parent: true });
				var parent = lookup.node;
				if (!parent) {
					throw new FS.ErrnoError(44);
				}
				var newname = PATH.basename(newpath);
				var errCode = FS.mayCreate(parent, newname);
				if (errCode) {
					throw new FS.ErrnoError(errCode);
				}
				if (!parent.node_ops.symlink) {
					throw new FS.ErrnoError(63);
				}
				return parent.node_ops.symlink(parent, newname, oldpath);
			},
			rename(old_path, new_path) {
				var old_dirname = PATH.dirname(old_path);
				var new_dirname = PATH.dirname(new_path);
				var old_name = PATH.basename(old_path);
				var new_name = PATH.basename(new_path);
				// parents must exist
				var lookup, old_dir, new_dir;

				// let the errors from non existent directories percolate up
				lookup = FS.lookupPath(old_path, { parent: true });
				old_dir = lookup.node;
				lookup = FS.lookupPath(new_path, { parent: true });
				new_dir = lookup.node;

				if (!old_dir || !new_dir) throw new FS.ErrnoError(44);
				// need to be part of the same mount
				if (old_dir.mount !== new_dir.mount) {
					throw new FS.ErrnoError(75);
				}
				// source must exist
				var old_node = FS.lookupNode(old_dir, old_name);
				// old path should not be an ancestor of the new path
				var relative = PATH_FS.relative(old_path, new_dirname);
				if (relative.charAt(0) !== '.') {
					throw new FS.ErrnoError(28);
				}
				// new path should not be an ancestor of the old path
				relative = PATH_FS.relative(new_path, old_dirname);
				if (relative.charAt(0) !== '.') {
					throw new FS.ErrnoError(55);
				}
				// see if the new path already exists
				var new_node;
				try {
					new_node = FS.lookupNode(new_dir, new_name);
				} catch (e) {
					// not fatal
				}
				// early out if nothing needs to change
				if (old_node === new_node) {
					return;
				}
				// we'll need to delete the old entry
				var isdir = FS.isDir(old_node.mode);
				var errCode = FS.mayDelete(old_dir, old_name, isdir);
				if (errCode) {
					throw new FS.ErrnoError(errCode);
				}
				// need delete permissions if we'll be overwriting.
				// need create permissions if new doesn't already exist.
				errCode = new_node ? FS.mayDelete(new_dir, new_name, isdir) : FS.mayCreate(new_dir, new_name);
				if (errCode) {
					throw new FS.ErrnoError(errCode);
				}
				if (!old_dir.node_ops.rename) {
					throw new FS.ErrnoError(63);
				}
				if (FS.isMountpoint(old_node) || (new_node && FS.isMountpoint(new_node))) {
					throw new FS.ErrnoError(10);
				}
				// if we are going to change the parent, check write permissions
				if (new_dir !== old_dir) {
					errCode = FS.nodePermissions(old_dir, 'w');
					if (errCode) {
						throw new FS.ErrnoError(errCode);
					}
				}
				// remove the node from the lookup hash
				FS.hashRemoveNode(old_node);
				// do the underlying fs rename
				try {
					old_dir.node_ops.rename(old_node, new_dir, new_name);
				} catch (e) {
					throw e;
				} finally {
					// add the node back to the hash (in case node_ops.rename
					// changed its name)
					FS.hashAddNode(old_node);
				}
			},
			rmdir(path) {
				var lookup = FS.lookupPath(path, { parent: true });
				var parent = lookup.node;
				var name = PATH.basename(path);
				var node = FS.lookupNode(parent, name);
				var errCode = FS.mayDelete(parent, name, true);
				if (errCode) {
					throw new FS.ErrnoError(errCode);
				}
				if (!parent.node_ops.rmdir) {
					throw new FS.ErrnoError(63);
				}
				if (FS.isMountpoint(node)) {
					throw new FS.ErrnoError(10);
				}
				parent.node_ops.rmdir(parent, name);
				FS.destroyNode(node);
			},
			readdir(path) {
				var lookup = FS.lookupPath(path, { follow: true });
				var node = lookup.node;
				if (!node.node_ops.readdir) {
					throw new FS.ErrnoError(54);
				}
				return node.node_ops.readdir(node);
			},
			unlink(path) {
				var lookup = FS.lookupPath(path, { parent: true });
				var parent = lookup.node;
				if (!parent) {
					throw new FS.ErrnoError(44);
				}
				var name = PATH.basename(path);
				var node = FS.lookupNode(parent, name);
				var errCode = FS.mayDelete(parent, name, false);
				if (errCode) {
					// According to POSIX, we should map EISDIR to EPERM, but
					// we instead do what Linux does (and we must, as we use
					// the musl linux libc).
					throw new FS.ErrnoError(errCode);
				}
				if (!parent.node_ops.unlink) {
					throw new FS.ErrnoError(63);
				}
				if (FS.isMountpoint(node)) {
					throw new FS.ErrnoError(10);
				}
				parent.node_ops.unlink(parent, name);
				FS.destroyNode(node);
			},
			readlink(path) {
				var lookup = FS.lookupPath(path);
				var link = lookup.node;
				if (!link) {
					throw new FS.ErrnoError(44);
				}
				if (!link.node_ops.readlink) {
					throw new FS.ErrnoError(28);
				}
				return PATH_FS.resolve(FS.getPath(link.parent), link.node_ops.readlink(link));
			},
			stat(path, dontFollow) {
				var lookup = FS.lookupPath(path, { follow: !dontFollow });
				var node = lookup.node;
				if (!node) {
					throw new FS.ErrnoError(44);
				}
				if (!node.node_ops.getattr) {
					throw new FS.ErrnoError(63);
				}
				return node.node_ops.getattr(node);
			},
			lstat(path) {
				return FS.stat(path, true);
			},
			chmod(path, mode, dontFollow) {
				var node;
				if (typeof path == 'string') {
					var lookup = FS.lookupPath(path, { follow: !dontFollow });
					node = lookup.node;
				} else {
					node = path;
				}
				if (!node.node_ops.setattr) {
					throw new FS.ErrnoError(63);
				}
				node.node_ops.setattr(node, {
					mode: (mode & 4095) | (node.mode & ~4095),
					timestamp: Date.now(),
				});
			},
			lchmod(path, mode) {
				FS.chmod(path, mode, true);
			},
			fchmod(fd, mode) {
				var stream = FS.getStreamChecked(fd);
				FS.chmod(stream.node, mode);
			},
			chown(path, uid, gid, dontFollow) {
				var node;
				if (typeof path == 'string') {
					var lookup = FS.lookupPath(path, { follow: !dontFollow });
					node = lookup.node;
				} else {
					node = path;
				}
				if (!node.node_ops.setattr) {
					throw new FS.ErrnoError(63);
				}
				node.node_ops.setattr(node, {
					timestamp: Date.now(),
					// we ignore the uid / gid for now
				});
			},
			lchown(path, uid, gid) {
				FS.chown(path, uid, gid, true);
			},
			fchown(fd, uid, gid) {
				var stream = FS.getStreamChecked(fd);
				FS.chown(stream.node, uid, gid);
			},
			truncate(path, len) {
				if (len < 0) {
					throw new FS.ErrnoError(28);
				}
				var node;
				if (typeof path == 'string') {
					var lookup = FS.lookupPath(path, { follow: true });
					node = lookup.node;
				} else {
					node = path;
				}
				if (!node.node_ops.setattr) {
					throw new FS.ErrnoError(63);
				}
				if (FS.isDir(node.mode)) {
					throw new FS.ErrnoError(31);
				}
				if (!FS.isFile(node.mode)) {
					throw new FS.ErrnoError(28);
				}
				var errCode = FS.nodePermissions(node, 'w');
				if (errCode) {
					throw new FS.ErrnoError(errCode);
				}
				node.node_ops.setattr(node, {
					size: len,
					timestamp: Date.now(),
				});
			},
			ftruncate(fd, len) {
				var stream = FS.getStreamChecked(fd);
				if ((stream.flags & 2097155) === 0) {
					throw new FS.ErrnoError(28);
				}
				FS.truncate(stream.node, len);
			},
			utime(path, atime, mtime) {
				var lookup = FS.lookupPath(path, { follow: true });
				var node = lookup.node;
				node.node_ops.setattr(node, {
					timestamp: Math.max(atime, mtime),
				});
			},
			open(path, flags, mode) {
				if (path === '') {
					throw new FS.ErrnoError(44);
				}
				flags = typeof flags == 'string' ? FS_modeStringToFlags(flags) : flags;
				mode = typeof mode == 'undefined' ? 438 /* 0666 */ : mode;
				if (flags & 64) {
					mode = (mode & 4095) | 32768;
				} else {
					mode = 0;
				}
				var node;
				if (typeof path == 'object') {
					node = path;
				} else {
					path = PATH.normalize(path);
					try {
						var lookup = FS.lookupPath(path, {
							follow: !(flags & 131072),
						});
						node = lookup.node;
					} catch (e) {
						// ignore
					}
				}
				// perhaps we need to create the node
				var created = false;
				if (flags & 64) {
					if (node) {
						// if O_CREAT and O_EXCL are set, error out if the node already exists
						if (flags & 128) {
							throw new FS.ErrnoError(20);
						}
					} else {
						// node doesn't exist, try to create it
						node = FS.mknod(path, mode, 0);
						created = true;
					}
				}
				if (!node) {
					throw new FS.ErrnoError(44);
				}
				// can't truncate a device
				if (FS.isChrdev(node.mode)) {
					flags &= ~512;
				}
				// if asked only for a directory, then this must be one
				if (flags & 65536 && !FS.isDir(node.mode)) {
					throw new FS.ErrnoError(54);
				}
				// check permissions, if this is not a file we just created now (it is ok to
				// create and write to a file with read-only permissions; it is read-only
				// for later use)
				if (!created) {
					var errCode = FS.mayOpen(node, flags);
					if (errCode) {
						throw new FS.ErrnoError(errCode);
					}
				}
				// do truncation if necessary
				if (flags & 512 && !created) {
					FS.truncate(node, 0);
				}
				// we've already handled these, don't pass down to the underlying vfs
				flags &= ~(128 | 512 | 131072);

				// register the stream with the filesystem
				var stream = FS.createStream({
					node,
					path: FS.getPath(node), // we want the absolute path to the node
					flags,
					seekable: true,
					position: 0,
					stream_ops: node.stream_ops,
					// used by the file family libc calls (fopen, fwrite, ferror, etc.)
					ungotten: [],
					error: false,
				});
				// call the new stream's open function
				if (stream.stream_ops.open) {
					stream.stream_ops.open(stream);
				}
				if (Module['logReadFiles'] && !(flags & 1)) {
					if (!FS.readFiles) FS.readFiles = {};
					if (!(path in FS.readFiles)) {
						FS.readFiles[path] = 1;
					}
				}
				return stream;
			},
			close(stream) {
				if (FS.isClosed(stream)) {
					throw new FS.ErrnoError(8);
				}
				if (stream.getdents) stream.getdents = null; // free readdir state
				try {
					if (stream.stream_ops.close) {
						stream.stream_ops.close(stream);
					}
				} catch (e) {
					throw e;
				} finally {
					FS.closeStream(stream.fd);
				}
				stream.fd = null;
			},
			isClosed(stream) {
				return stream.fd === null;
			},
			llseek(stream, offset, whence) {
				if (FS.isClosed(stream)) {
					throw new FS.ErrnoError(8);
				}
				if (!stream.seekable || !stream.stream_ops.llseek) {
					throw new FS.ErrnoError(70);
				}
				if (whence != 0 && whence != 1 && whence != 2) {
					throw new FS.ErrnoError(28);
				}
				stream.position = stream.stream_ops.llseek(stream, offset, whence);
				stream.ungotten = [];
				return stream.position;
			},
			read(stream, buffer, offset, length, position) {
				assert(offset >= 0);
				if (length < 0 || position < 0) {
					throw new FS.ErrnoError(28);
				}
				if (FS.isClosed(stream)) {
					throw new FS.ErrnoError(8);
				}
				if ((stream.flags & 2097155) === 1) {
					throw new FS.ErrnoError(8);
				}
				if (FS.isDir(stream.node.mode)) {
					throw new FS.ErrnoError(31);
				}
				if (!stream.stream_ops.read) {
					throw new FS.ErrnoError(28);
				}
				var seeking = typeof position != 'undefined';
				if (!seeking) {
					position = stream.position;
				} else if (!stream.seekable) {
					throw new FS.ErrnoError(70);
				}
				var bytesRead = stream.stream_ops.read(stream, buffer, offset, length, position);
				if (!seeking) stream.position += bytesRead;
				return bytesRead;
			},
			write(stream, buffer, offset, length, position, canOwn) {
				assert(offset >= 0);
				if (length < 0 || position < 0) {
					throw new FS.ErrnoError(28);
				}
				if (FS.isClosed(stream)) {
					throw new FS.ErrnoError(8);
				}
				if ((stream.flags & 2097155) === 0) {
					throw new FS.ErrnoError(8);
				}
				if (FS.isDir(stream.node.mode)) {
					throw new FS.ErrnoError(31);
				}
				if (!stream.stream_ops.write) {
					throw new FS.ErrnoError(28);
				}
				if (stream.seekable && stream.flags & 1024) {
					// seek to the end before writing in append mode
					FS.llseek(stream, 0, 2);
				}
				var seeking = typeof position != 'undefined';
				if (!seeking) {
					position = stream.position;
				} else if (!stream.seekable) {
					throw new FS.ErrnoError(70);
				}
				var bytesWritten = stream.stream_ops.write(stream, buffer, offset, length, position, canOwn);
				if (!seeking) stream.position += bytesWritten;
				return bytesWritten;
			},
			allocate(stream, offset, length) {
				if (FS.isClosed(stream)) {
					throw new FS.ErrnoError(8);
				}
				if (offset < 0 || length <= 0) {
					throw new FS.ErrnoError(28);
				}
				if ((stream.flags & 2097155) === 0) {
					throw new FS.ErrnoError(8);
				}
				if (!FS.isFile(stream.node.mode) && !FS.isDir(stream.node.mode)) {
					throw new FS.ErrnoError(43);
				}
				if (!stream.stream_ops.allocate) {
					throw new FS.ErrnoError(138);
				}
				stream.stream_ops.allocate(stream, offset, length);
			},
			mmap(stream, length, position, prot, flags) {
				// User requests writing to file (prot & PROT_WRITE != 0).
				// Checking if we have permissions to write to the file unless
				// MAP_PRIVATE flag is set. According to POSIX spec it is possible
				// to write to file opened in read-only mode with MAP_PRIVATE flag,
				// as all modifications will be visible only in the memory of
				// the current process.
				if ((prot & 2) !== 0 && (flags & 2) === 0 && (stream.flags & 2097155) !== 2) {
					throw new FS.ErrnoError(2);
				}
				if ((stream.flags & 2097155) === 1) {
					throw new FS.ErrnoError(2);
				}
				if (!stream.stream_ops.mmap) {
					throw new FS.ErrnoError(43);
				}
				return stream.stream_ops.mmap(stream, length, position, prot, flags);
			},
			msync(stream, buffer, offset, length, mmapFlags) {
				assert(offset >= 0);
				if (!stream.stream_ops.msync) {
					return 0;
				}
				return stream.stream_ops.msync(stream, buffer, offset, length, mmapFlags);
			},
			ioctl(stream, cmd, arg) {
				if (!stream.stream_ops.ioctl) {
					throw new FS.ErrnoError(59);
				}
				return stream.stream_ops.ioctl(stream, cmd, arg);
			},
			readFile(path, opts = {}) {
				opts.flags = opts.flags || 0;
				opts.encoding = opts.encoding || 'binary';
				if (opts.encoding !== 'utf8' && opts.encoding !== 'binary') {
					throw new Error(`Invalid encoding type "${opts.encoding}"`);
				}
				var ret;
				var stream = FS.open(path, opts.flags);
				var stat = FS.stat(path);
				var length = stat.size;
				var buf = new Uint8Array(length);
				FS.read(stream, buf, 0, length, 0);
				if (opts.encoding === 'utf8') {
					ret = UTF8ArrayToString(buf, 0);
				} else if (opts.encoding === 'binary') {
					ret = buf;
				}
				FS.close(stream);
				return ret;
			},
			writeFile(path, data, opts = {}) {
				opts.flags = opts.flags || 577;
				var stream = FS.open(path, opts.flags, opts.mode);
				if (typeof data == 'string') {
					var buf = new Uint8Array(lengthBytesUTF8(data) + 1);
					var actualNumBytes = stringToUTF8Array(data, buf, 0, buf.length);
					FS.write(stream, buf, 0, actualNumBytes, undefined, opts.canOwn);
				} else if (ArrayBuffer.isView(data)) {
					FS.write(stream, data, 0, data.byteLength, undefined, opts.canOwn);
				} else {
					throw new Error('Unsupported data type');
				}
				FS.close(stream);
			},
			cwd: () => FS.currentPath,
			chdir(path) {
				var lookup = FS.lookupPath(path, { follow: true });
				if (lookup.node === null) {
					throw new FS.ErrnoError(44);
				}
				if (!FS.isDir(lookup.node.mode)) {
					throw new FS.ErrnoError(54);
				}
				var errCode = FS.nodePermissions(lookup.node, 'x');
				if (errCode) {
					throw new FS.ErrnoError(errCode);
				}
				FS.currentPath = lookup.path;
			},
			createDefaultDirectories() {
				FS.mkdir('/tmp');
				FS.mkdir('/home');
				FS.mkdir('/home/web_user');
			},
			createDefaultDevices() {
				// create /dev
				FS.mkdir('/dev');
				// setup /dev/null
				FS.registerDevice(FS.makedev(1, 3), {
					read: () => 0,
					write: (stream, buffer, offset, length, pos) => length,
				});
				FS.mkdev('/dev/null', FS.makedev(1, 3));
				// setup /dev/tty and /dev/tty1
				// stderr needs to print output using err() rather than out()
				// so we register a second tty just for it.
				TTY.register(FS.makedev(5, 0), TTY.default_tty_ops);
				TTY.register(FS.makedev(6, 0), TTY.default_tty1_ops);
				FS.mkdev('/dev/tty', FS.makedev(5, 0));
				FS.mkdev('/dev/tty1', FS.makedev(6, 0));
				// setup /dev/[u]random
				// use a buffer to avoid overhead of individual crypto calls per byte
				var randomBuffer = new Uint8Array(1024),
					randomLeft = 0;
				var randomByte = () => {
					if (randomLeft === 0) {
						randomLeft = randomFill(randomBuffer).byteLength;
					}
					return randomBuffer[--randomLeft];
				};
				FS.createDevice('/dev', 'random', randomByte);
				FS.createDevice('/dev', 'urandom', randomByte);
				// we're not going to emulate the actual shm device,
				// just create the tmp dirs that reside in it commonly
				FS.mkdir('/dev/shm');
				FS.mkdir('/dev/shm/tmp');
			},
			createSpecialDirectories() {
				// create /proc/self/fd which allows /proc/self/fd/6 => readlink gives the
				// name of the stream for fd 6 (see test_unistd_ttyname)
				FS.mkdir('/proc');
				var proc_self = FS.mkdir('/proc/self');
				FS.mkdir('/proc/self/fd');
				FS.mount(
					{
						mount() {
							var node = FS.createNode(proc_self, 'fd', 16384 | 511 /* 0777 */, 73);
							node.node_ops = {
								lookup(parent, name) {
									var fd = +name;
									var stream = FS.getStreamChecked(fd);
									var ret = {
										parent: null,
										mount: { mountpoint: 'fake' },
										node_ops: { readlink: () => stream.path },
									};
									ret.parent = ret; // make it look like a simple root node
									return ret;
								},
							};
							return node;
						},
					},
					{},
					'/proc/self/fd',
				);
			},
			createStandardStreams() {
				// TODO deprecate the old functionality of a single
				// input / output callback and that utilizes FS.createDevice
				// and instead require a unique set of stream ops

				// by default, we symlink the standard streams to the
				// default tty devices. however, if the standard streams
				// have been overwritten we create a unique device for
				// them instead.
				if (Module['stdin']) {
					FS.createDevice('/dev', 'stdin', Module['stdin']);
				} else {
					FS.symlink('/dev/tty', '/dev/stdin');
				}
				if (Module['stdout']) {
					FS.createDevice('/dev', 'stdout', null, Module['stdout']);
				} else {
					FS.symlink('/dev/tty', '/dev/stdout');
				}
				if (Module['stderr']) {
					FS.createDevice('/dev', 'stderr', null, Module['stderr']);
				} else {
					FS.symlink('/dev/tty1', '/dev/stderr');
				}

				// open default streams for the stdin, stdout and stderr devices
				var stdin = FS.open('/dev/stdin', 0);
				var stdout = FS.open('/dev/stdout', 1);
				var stderr = FS.open('/dev/stderr', 1);
				assert(stdin.fd === 0, `invalid handle for stdin (${stdin.fd})`);
				assert(stdout.fd === 1, `invalid handle for stdout (${stdout.fd})`);
				assert(stderr.fd === 2, `invalid handle for stderr (${stderr.fd})`);
			},
			staticInit() {
				// Some errors may happen quite a bit, to avoid overhead we reuse them (and suffer a lack of stack info)
				[44].forEach((code) => {
					FS.genericErrors[code] = new FS.ErrnoError(code);
					FS.genericErrors[code].stack = '<generic error, no stack>';
				});

				FS.nameTable = new Array(4096);

				FS.mount(MEMFS, {}, '/');

				FS.createDefaultDirectories();
				FS.createDefaultDevices();
				FS.createSpecialDirectories();

				FS.filesystems = {
					MEMFS: MEMFS,
				};
			},
			init(input, output, error) {
				assert(
					!FS.init.initialized,
					'FS.init was previously called. If you want to initialize later with custom parameters, remove any earlier calls (note that one is automatically added to the generated code)',
				);
				FS.init.initialized = true;

				// Allow Module.stdin etc. to provide defaults, if none explicitly passed to us here
				Module['stdin'] = input || Module['stdin'];
				Module['stdout'] = output || Module['stdout'];
				Module['stderr'] = error || Module['stderr'];

				FS.createStandardStreams();
			},
			quit() {
				FS.init.initialized = false;
				// force-flush all streams, so we get musl std streams printed out
				_fflush(0);
				// close all of our streams
				for (var i = 0; i < FS.streams.length; i++) {
					var stream = FS.streams[i];
					if (!stream) {
						continue;
					}
					FS.close(stream);
				}
			},
			findObject(path, dontResolveLastLink) {
				var ret = FS.analyzePath(path, dontResolveLastLink);
				if (!ret.exists) {
					return null;
				}
				return ret.object;
			},
			analyzePath(path, dontResolveLastLink) {
				// operate from within the context of the symlink's target
				try {
					var lookup = FS.lookupPath(path, { follow: !dontResolveLastLink });
					path = lookup.path;
				} catch (e) {}
				var ret = {
					isRoot: false,
					exists: false,
					error: 0,
					name: null,
					path: null,
					object: null,
					parentExists: false,
					parentPath: null,
					parentObject: null,
				};
				try {
					var lookup = FS.lookupPath(path, { parent: true });
					ret.parentExists = true;
					ret.parentPath = lookup.path;
					ret.parentObject = lookup.node;
					ret.name = PATH.basename(path);
					lookup = FS.lookupPath(path, { follow: !dontResolveLastLink });
					ret.exists = true;
					ret.path = lookup.path;
					ret.object = lookup.node;
					ret.name = lookup.node.name;
					ret.isRoot = lookup.path === '/';
				} catch (e) {
					ret.error = e.errno;
				}
				return ret;
			},
			createPath(parent, path, canRead, canWrite) {
				parent = typeof parent == 'string' ? parent : FS.getPath(parent);
				var parts = path.split('/').reverse();
				while (parts.length) {
					var part = parts.pop();
					if (!part) continue;
					var current = PATH.join2(parent, part);
					try {
						FS.mkdir(current);
					} catch (e) {
						// ignore EEXIST
					}
					parent = current;
				}
				return current;
			},
			createFile(parent, name, properties, canRead, canWrite) {
				var path = PATH.join2(typeof parent == 'string' ? parent : FS.getPath(parent), name);
				var mode = FS_getMode(canRead, canWrite);
				return FS.create(path, mode);
			},
			createDataFile(parent, name, data, canRead, canWrite, canOwn) {
				var path = name;
				if (parent) {
					parent = typeof parent == 'string' ? parent : FS.getPath(parent);
					path = name ? PATH.join2(parent, name) : parent;
				}
				var mode = FS_getMode(canRead, canWrite);
				var node = FS.create(path, mode);
				if (data) {
					if (typeof data == 'string') {
						var arr = new Array(data.length);
						for (var i = 0, len = data.length; i < len; ++i) arr[i] = data.charCodeAt(i);
						data = arr;
					}
					// make sure we can write to the file
					FS.chmod(node, mode | 146);
					var stream = FS.open(node, 577);
					FS.write(stream, data, 0, data.length, 0, canOwn);
					FS.close(stream);
					FS.chmod(node, mode);
				}
			},
			createDevice(parent, name, input, output) {
				var path = PATH.join2(typeof parent == 'string' ? parent : FS.getPath(parent), name);
				var mode = FS_getMode(!!input, !!output);
				if (!FS.createDevice.major) FS.createDevice.major = 64;
				var dev = FS.makedev(FS.createDevice.major++, 0);
				// Create a fake device that a set of stream ops to emulate
				// the old behavior.
				FS.registerDevice(dev, {
					open(stream) {
						stream.seekable = false;
					},
					close(stream) {
						// flush any pending line data
						if (output?.buffer?.length) {
							output(10);
						}
					},
					read(stream, buffer, offset, length, pos /* ignored */) {
						var bytesRead = 0;
						for (var i = 0; i < length; i++) {
							var result;
							try {
								result = input();
							} catch (e) {
								throw new FS.ErrnoError(29);
							}
							if (result === undefined && bytesRead === 0) {
								throw new FS.ErrnoError(6);
							}
							if (result === null || result === undefined) break;
							bytesRead++;
							buffer[offset + i] = result;
						}
						if (bytesRead) {
							stream.node.timestamp = Date.now();
						}
						return bytesRead;
					},
					write(stream, buffer, offset, length, pos) {
						for (var i = 0; i < length; i++) {
							try {
								output(buffer[offset + i]);
							} catch (e) {
								throw new FS.ErrnoError(29);
							}
						}
						if (length) {
							stream.node.timestamp = Date.now();
						}
						return i;
					},
				});
				return FS.mkdev(path, mode, dev);
			},
			forceLoadFile(obj) {
				if (obj.isDevice || obj.isFolder || obj.link || obj.contents) return true;
				if (typeof XMLHttpRequest != 'undefined') {
					throw new Error(
						'Lazy loading should have been performed (contents set) in createLazyFile, but it was not. Lazy loading only works in web workers. Use --embed-file or --preload-file in emcc on the main thread.',
					);
				} else if (read_) {
					// Command-line.
					try {
						// WARNING: Can't read binary files in V8's d8 or tracemonkey's js, as
						//          read() will try to parse UTF8.
						obj.contents = intArrayFromString(read_(obj.url), true);
						obj.usedBytes = obj.contents.length;
					} catch (e) {
						throw new FS.ErrnoError(29);
					}
				} else {
					throw new Error('Cannot load without read() or XMLHttpRequest.');
				}
			},
			createLazyFile(parent, name, url, canRead, canWrite) {
				// Lazy chunked Uint8Array (implements get and length from Uint8Array).
				// Actual getting is abstracted away for eventual reuse.
				class LazyUint8Array {
					constructor() {
						this.lengthKnown = false;
						this.chunks = []; // Loaded chunks. Index is the chunk number
					}
					get(idx) {
						if (idx > this.length - 1 || idx < 0) {
							return undefined;
						}
						var chunkOffset = idx % this.chunkSize;
						var chunkNum = (idx / this.chunkSize) | 0;
						return this.getter(chunkNum)[chunkOffset];
					}
					setDataGetter(getter) {
						this.getter = getter;
					}
					cacheLength() {
						// Find length
						var xhr = new XMLHttpRequest();
						xhr.open('HEAD', url, false);
						xhr.send(null);
						if (!((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304))
							throw new Error("Couldn't load " + url + '. Status: ' + xhr.status);
						var datalength = Number(xhr.getResponseHeader('Content-length'));
						var header;
						var hasByteServing = (header = xhr.getResponseHeader('Accept-Ranges')) && header === 'bytes';
						var usesGzip = (header = xhr.getResponseHeader('Content-Encoding')) && header === 'gzip';

						var chunkSize = 1024 * 1024; // Chunk size in bytes

						if (!hasByteServing) chunkSize = datalength;

						// Function to get a range from the remote URL.
						var doXHR = (from, to) => {
							if (from > to)
								throw new Error('invalid range (' + from + ', ' + to + ') or no bytes requested!');
							if (to > datalength - 1)
								throw new Error('only ' + datalength + ' bytes available! programmer error!');

							// TODO: Use mozResponseArrayBuffer, responseStream, etc. if available.
							var xhr = new XMLHttpRequest();
							xhr.open('GET', url, false);
							if (datalength !== chunkSize) xhr.setRequestHeader('Range', 'bytes=' + from + '-' + to);

							// Some hints to the browser that we want binary data.
							xhr.responseType = 'arraybuffer';
							if (xhr.overrideMimeType) {
								xhr.overrideMimeType('text/plain; charset=x-user-defined');
							}

							xhr.send(null);
							if (!((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304))
								throw new Error("Couldn't load " + url + '. Status: ' + xhr.status);
							if (xhr.response !== undefined) {
								return new Uint8Array(/** @type{Array<number>} */ (xhr.response || []));
							}
							return intArrayFromString(xhr.responseText || '', true);
						};
						var lazyArray = this;
						lazyArray.setDataGetter((chunkNum) => {
							var start = chunkNum * chunkSize;
							var end = (chunkNum + 1) * chunkSize - 1; // including this byte
							end = Math.min(end, datalength - 1); // if datalength-1 is selected, this is the last block
							if (typeof lazyArray.chunks[chunkNum] == 'undefined') {
								lazyArray.chunks[chunkNum] = doXHR(start, end);
							}
							if (typeof lazyArray.chunks[chunkNum] == 'undefined') throw new Error('doXHR failed!');
							return lazyArray.chunks[chunkNum];
						});

						if (usesGzip || !datalength) {
							// if the server uses gzip or doesn't supply the length, we have to download the whole file to get the (uncompressed) length
							chunkSize = datalength = 1; // this will force getter(0)/doXHR do download the whole file
							datalength = this.getter(0).length;
							chunkSize = datalength;
							out('LazyFiles on gzip forces download of the whole file when length is accessed');
						}

						this._length = datalength;
						this._chunkSize = chunkSize;
						this.lengthKnown = true;
					}
					get length() {
						if (!this.lengthKnown) {
							this.cacheLength();
						}
						return this._length;
					}
					get chunkSize() {
						if (!this.lengthKnown) {
							this.cacheLength();
						}
						return this._chunkSize;
					}
				}

				if (typeof XMLHttpRequest != 'undefined') {
					if (!ENVIRONMENT_IS_WORKER)
						throw 'Cannot do synchronous binary XHRs outside webworkers in modern browsers. Use --embed-file or --preload-file in emcc';
					var lazyArray = new LazyUint8Array();
					var properties = { isDevice: false, contents: lazyArray };
				} else {
					var properties = { isDevice: false, url: url };
				}

				var node = FS.createFile(parent, name, properties, canRead, canWrite);
				// This is a total hack, but I want to get this lazy file code out of the
				// core of MEMFS. If we want to keep this lazy file concept I feel it should
				// be its own thin LAZYFS proxying calls to MEMFS.
				if (properties.contents) {
					node.contents = properties.contents;
				} else if (properties.url) {
					node.contents = null;
					node.url = properties.url;
				}
				// Add a function that defers querying the file size until it is asked the first time.
				Object.defineProperties(node, {
					usedBytes: {
						get: function () {
							return this.contents.length;
						},
					},
				});
				// override each stream op with one that tries to force load the lazy file first
				var stream_ops = {};
				var keys = Object.keys(node.stream_ops);
				keys.forEach((key) => {
					var fn = node.stream_ops[key];
					stream_ops[key] = (...args) => {
						FS.forceLoadFile(node);
						return fn(...args);
					};
				});
				function writeChunks(stream, buffer, offset, length, position) {
					var contents = stream.node.contents;
					if (position >= contents.length) return 0;
					var size = Math.min(contents.length - position, length);
					assert(size >= 0);
					if (contents.slice) {
						// normal array
						for (var i = 0; i < size; i++) {
							buffer[offset + i] = contents[position + i];
						}
					} else {
						for (var i = 0; i < size; i++) {
							// LazyUint8Array from sync binary XHR
							buffer[offset + i] = contents.get(position + i);
						}
					}
					return size;
				}
				// use a custom read function
				stream_ops.read = (stream, buffer, offset, length, position) => {
					FS.forceLoadFile(node);
					return writeChunks(stream, buffer, offset, length, position);
				};
				// use a custom mmap function
				stream_ops.mmap = (stream, length, position, prot, flags) => {
					FS.forceLoadFile(node);
					var ptr = mmapAlloc(length);
					if (!ptr) {
						throw new FS.ErrnoError(48);
					}
					writeChunks(stream, HEAP8, ptr, length, position);
					return { ptr, allocated: true };
				};
				node.stream_ops = stream_ops;
				return node;
			},
			absolutePath() {
				abort('FS.absolutePath has been removed; use PATH_FS.resolve instead');
			},
			createFolder() {
				abort('FS.createFolder has been removed; use FS.mkdir instead');
			},
			createLink() {
				abort('FS.createLink has been removed; use FS.symlink instead');
			},
			joinPath() {
				abort('FS.joinPath has been removed; use PATH.join instead');
			},
			mmapAlloc() {
				abort('FS.mmapAlloc has been replaced by the top level function mmapAlloc');
			},
			standardizePath() {
				abort('FS.standardizePath has been removed; use PATH.normalize instead');
			},
		};

		/**
		 * Given a pointer 'ptr' to a null-terminated UTF8-encoded string in the
		 * emscripten HEAP, returns a copy of that string as a Javascript String object.
		 *
		 * @param {number} ptr
		 * @param {number=} maxBytesToRead - An optional length that specifies the
		 *   maximum number of bytes to read. You can omit this parameter to scan the
		 *   string until the first 0 byte. If maxBytesToRead is passed, and the string
		 *   at [ptr, ptr+maxBytesToReadr[ contains a null byte in the middle, then the
		 *   string will cut short at that byte index (i.e. maxBytesToRead will not
		 *   produce a string of exact length [ptr, ptr+maxBytesToRead[) N.B. mixing
		 *   frequent uses of UTF8ToString() with and without maxBytesToRead may throw
		 *   JS JIT optimizations off, so it is worth to consider consistently using one
		 * @return {string}
		 */
		var UTF8ToString = (ptr, maxBytesToRead) => {
			assert(typeof ptr == 'number', `UTF8ToString expects a number (got ${typeof ptr})`);
			return ptr ? UTF8ArrayToString(HEAPU8, ptr, maxBytesToRead) : '';
		};
		var SYSCALLS = {
			DEFAULT_POLLMASK: 5,
			calculateAt(dirfd, path, allowEmpty) {
				if (PATH.isAbs(path)) {
					return path;
				}
				// relative path
				var dir;
				if (dirfd === -100) {
					dir = FS.cwd();
				} else {
					var dirstream = SYSCALLS.getStreamFromFD(dirfd);
					dir = dirstream.path;
				}
				if (path.length == 0) {
					if (!allowEmpty) {
						throw new FS.ErrnoError(44);
					}
					return dir;
				}
				return PATH.join2(dir, path);
			},
			doStat(func, path, buf) {
				var stat = func(path);
				HEAP32[buf >> 2] = stat.dev;
				HEAP32[(buf + 4) >> 2] = stat.mode;
				HEAPU32[(buf + 8) >> 2] = stat.nlink;
				HEAP32[(buf + 12) >> 2] = stat.uid;
				HEAP32[(buf + 16) >> 2] = stat.gid;
				HEAP32[(buf + 20) >> 2] = stat.rdev;
				(tempI64 = [
					stat.size >>> 0,
					((tempDouble = stat.size),
					+Math.abs(tempDouble) >= 1.0
						? tempDouble > 0.0
							? +Math.floor(tempDouble / 4294967296.0) >>> 0
							: ~~+Math.ceil((tempDouble - +(~~tempDouble >>> 0)) / 4294967296.0) >>> 0
						: 0),
				]),
					(HEAP32[(buf + 24) >> 2] = tempI64[0]),
					(HEAP32[(buf + 28) >> 2] = tempI64[1]);
				HEAP32[(buf + 32) >> 2] = 4096;
				HEAP32[(buf + 36) >> 2] = stat.blocks;
				var atime = stat.atime.getTime();
				var mtime = stat.mtime.getTime();
				var ctime = stat.ctime.getTime();
				(tempI64 = [
					Math.floor(atime / 1000) >>> 0,
					((tempDouble = Math.floor(atime / 1000)),
					+Math.abs(tempDouble) >= 1.0
						? tempDouble > 0.0
							? +Math.floor(tempDouble / 4294967296.0) >>> 0
							: ~~+Math.ceil((tempDouble - +(~~tempDouble >>> 0)) / 4294967296.0) >>> 0
						: 0),
				]),
					(HEAP32[(buf + 40) >> 2] = tempI64[0]),
					(HEAP32[(buf + 44) >> 2] = tempI64[1]);
				HEAPU32[(buf + 48) >> 2] = (atime % 1000) * 1000;
				(tempI64 = [
					Math.floor(mtime / 1000) >>> 0,
					((tempDouble = Math.floor(mtime / 1000)),
					+Math.abs(tempDouble) >= 1.0
						? tempDouble > 0.0
							? +Math.floor(tempDouble / 4294967296.0) >>> 0
							: ~~+Math.ceil((tempDouble - +(~~tempDouble >>> 0)) / 4294967296.0) >>> 0
						: 0),
				]),
					(HEAP32[(buf + 56) >> 2] = tempI64[0]),
					(HEAP32[(buf + 60) >> 2] = tempI64[1]);
				HEAPU32[(buf + 64) >> 2] = (mtime % 1000) * 1000;
				(tempI64 = [
					Math.floor(ctime / 1000) >>> 0,
					((tempDouble = Math.floor(ctime / 1000)),
					+Math.abs(tempDouble) >= 1.0
						? tempDouble > 0.0
							? +Math.floor(tempDouble / 4294967296.0) >>> 0
							: ~~+Math.ceil((tempDouble - +(~~tempDouble >>> 0)) / 4294967296.0) >>> 0
						: 0),
				]),
					(HEAP32[(buf + 72) >> 2] = tempI64[0]),
					(HEAP32[(buf + 76) >> 2] = tempI64[1]);
				HEAPU32[(buf + 80) >> 2] = (ctime % 1000) * 1000;
				(tempI64 = [
					stat.ino >>> 0,
					((tempDouble = stat.ino),
					+Math.abs(tempDouble) >= 1.0
						? tempDouble > 0.0
							? +Math.floor(tempDouble / 4294967296.0) >>> 0
							: ~~+Math.ceil((tempDouble - +(~~tempDouble >>> 0)) / 4294967296.0) >>> 0
						: 0),
				]),
					(HEAP32[(buf + 88) >> 2] = tempI64[0]),
					(HEAP32[(buf + 92) >> 2] = tempI64[1]);
				return 0;
			},
			doMsync(addr, stream, len, flags, offset) {
				if (!FS.isFile(stream.node.mode)) {
					throw new FS.ErrnoError(43);
				}
				if (flags & 2) {
					// MAP_PRIVATE calls need not to be synced back to underlying fs
					return 0;
				}
				var buffer = HEAPU8.slice(addr, addr + len);
				FS.msync(stream, buffer, offset, len, flags);
			},
			varargs: undefined,
			get() {
				assert(SYSCALLS.varargs != undefined);
				// the `+` prepended here is necessary to convince the JSCompiler that varargs is indeed a number.
				var ret = HEAP32[+SYSCALLS.varargs >> 2];
				SYSCALLS.varargs += 4;
				return ret;
			},
			getp() {
				return SYSCALLS.get();
			},
			getStr(ptr) {
				var ret = UTF8ToString(ptr);
				return ret;
			},
			getStreamFromFD(fd) {
				var stream = FS.getStreamChecked(fd);
				return stream;
			},
		};
		function ___syscall_fcntl64(fd, cmd, varargs) {
			SYSCALLS.varargs = varargs;
			try {
				var stream = SYSCALLS.getStreamFromFD(fd);
				switch (cmd) {
					case 0: {
						var arg = SYSCALLS.get();
						if (arg < 0) {
							return -28;
						}
						while (FS.streams[arg]) {
							arg++;
						}
						var newStream;
						newStream = FS.dupStream(stream, arg);
						return newStream.fd;
					}
					case 1:
					case 2:
						return 0; // FD_CLOEXEC makes no sense for a single process.
					case 3:
						return stream.flags;
					case 4: {
						var arg = SYSCALLS.get();
						stream.flags |= arg;
						return 0;
					}
					case 12: {
						var arg = SYSCALLS.getp();
						var offset = 0;
						// We're always unlocked.
						HEAP16[(arg + offset) >> 1] = 2;
						return 0;
					}
					case 13:
					case 14:
						return 0; // Pretend that the locking is successful.
				}
				return -28;
			} catch (e) {
				if (typeof FS == 'undefined' || !(e.name === 'ErrnoError')) throw e;
				return -e.errno;
			}
		}

		var convertI32PairToI53Checked = (lo, hi) => {
			assert(lo == lo >>> 0 || lo == (lo | 0)); // lo should either be a i32 or a u32
			assert(hi === (hi | 0)); // hi should be a i32
			return (hi + 0x200000) >>> 0 < 0x400001 - !!lo ? (lo >>> 0) + hi * 4294967296 : NaN;
		};
		function ___syscall_ftruncate64(fd, length_low, length_high) {
			var length = convertI32PairToI53Checked(length_low, length_high);

			try {
				if (isNaN(length)) return 61;
				FS.ftruncate(fd, length);
				return 0;
			} catch (e) {
				if (typeof FS == 'undefined' || !(e.name === 'ErrnoError')) throw e;
				return -e.errno;
			}
		}

		function ___syscall_ioctl(fd, op, varargs) {
			SYSCALLS.varargs = varargs;
			try {
				var stream = SYSCALLS.getStreamFromFD(fd);
				switch (op) {
					case 21509: {
						if (!stream.tty) return -59;
						return 0;
					}
					case 21505: {
						if (!stream.tty) return -59;
						if (stream.tty.ops.ioctl_tcgets) {
							var termios = stream.tty.ops.ioctl_tcgets(stream);
							var argp = SYSCALLS.getp();
							HEAP32[argp >> 2] = termios.c_iflag || 0;
							HEAP32[(argp + 4) >> 2] = termios.c_oflag || 0;
							HEAP32[(argp + 8) >> 2] = termios.c_cflag || 0;
							HEAP32[(argp + 12) >> 2] = termios.c_lflag || 0;
							for (var i = 0; i < 32; i++) {
								HEAP8[argp + i + 17] = termios.c_cc[i] || 0;
							}
							return 0;
						}
						return 0;
					}
					case 21510:
					case 21511:
					case 21512: {
						if (!stream.tty) return -59;
						return 0; // no-op, not actually adjusting terminal settings
					}
					case 21506:
					case 21507:
					case 21508: {
						if (!stream.tty) return -59;
						if (stream.tty.ops.ioctl_tcsets) {
							var argp = SYSCALLS.getp();
							var c_iflag = HEAP32[argp >> 2];
							var c_oflag = HEAP32[(argp + 4) >> 2];
							var c_cflag = HEAP32[(argp + 8) >> 2];
							var c_lflag = HEAP32[(argp + 12) >> 2];
							var c_cc = [];
							for (var i = 0; i < 32; i++) {
								c_cc.push(HEAP8[argp + i + 17]);
							}
							return stream.tty.ops.ioctl_tcsets(stream.tty, op, {
								c_iflag,
								c_oflag,
								c_cflag,
								c_lflag,
								c_cc,
							});
						}
						return 0; // no-op, not actually adjusting terminal settings
					}
					case 21519: {
						if (!stream.tty) return -59;
						var argp = SYSCALLS.getp();
						HEAP32[argp >> 2] = 0;
						return 0;
					}
					case 21520: {
						if (!stream.tty) return -59;
						return -28; // not supported
					}
					case 21531: {
						var argp = SYSCALLS.getp();
						return FS.ioctl(stream, op, argp);
					}
					case 21523: {
						// TODO: in theory we should write to the winsize struct that gets
						// passed in, but for now musl doesn't read anything on it
						if (!stream.tty) return -59;
						if (stream.tty.ops.ioctl_tiocgwinsz) {
							var winsize = stream.tty.ops.ioctl_tiocgwinsz(stream.tty);
							var argp = SYSCALLS.getp();
							HEAP16[argp >> 1] = winsize[0];
							HEAP16[(argp + 2) >> 1] = winsize[1];
						}
						return 0;
					}
					case 21524: {
						// TODO: technically, this ioctl call should change the window size.
						// but, since emscripten doesn't have any concept of a terminal window
						// yet, we'll just silently throw it away as we do TIOCGWINSZ
						if (!stream.tty) return -59;
						return 0;
					}
					case 21515: {
						if (!stream.tty) return -59;
						return 0;
					}
					default:
						return -28; // not supported
				}
			} catch (e) {
				if (typeof FS == 'undefined' || !(e.name === 'ErrnoError')) throw e;
				return -e.errno;
			}
		}

		function ___syscall_openat(dirfd, path, flags, varargs) {
			SYSCALLS.varargs = varargs;
			try {
				path = SYSCALLS.getStr(path);
				path = SYSCALLS.calculateAt(dirfd, path);
				var mode = varargs ? SYSCALLS.get() : 0;
				return FS.open(path, flags, mode).fd;
			} catch (e) {
				if (typeof FS == 'undefined' || !(e.name === 'ErrnoError')) throw e;
				return -e.errno;
			}
		}

		function ___syscall_rmdir(path) {
			try {
				path = SYSCALLS.getStr(path);
				FS.rmdir(path);
				return 0;
			} catch (e) {
				if (typeof FS == 'undefined' || !(e.name === 'ErrnoError')) throw e;
				return -e.errno;
			}
		}

		function ___syscall_unlinkat(dirfd, path, flags) {
			try {
				path = SYSCALLS.getStr(path);
				path = SYSCALLS.calculateAt(dirfd, path);
				if (flags === 0) {
					FS.unlink(path);
				} else if (flags === 512) {
					FS.rmdir(path);
				} else {
					abort('Invalid flags passed to unlinkat');
				}
				return 0;
			} catch (e) {
				if (typeof FS == 'undefined' || !(e.name === 'ErrnoError')) throw e;
				return -e.errno;
			}
		}

		var __emscripten_throw_longjmp = () => {
			throw Infinity;
		};

		function __gmtime_js(time_low, time_high, tmPtr) {
			var time = convertI32PairToI53Checked(time_low, time_high);

			var date = new Date(time * 1000);
			HEAP32[tmPtr >> 2] = date.getUTCSeconds();
			HEAP32[(tmPtr + 4) >> 2] = date.getUTCMinutes();
			HEAP32[(tmPtr + 8) >> 2] = date.getUTCHours();
			HEAP32[(tmPtr + 12) >> 2] = date.getUTCDate();
			HEAP32[(tmPtr + 16) >> 2] = date.getUTCMonth();
			HEAP32[(tmPtr + 20) >> 2] = date.getUTCFullYear() - 1900;
			HEAP32[(tmPtr + 24) >> 2] = date.getUTCDay();
			var start = Date.UTC(date.getUTCFullYear(), 0, 1, 0, 0, 0, 0);
			var yday = ((date.getTime() - start) / (1000 * 60 * 60 * 24)) | 0;
			HEAP32[(tmPtr + 28) >> 2] = yday;
		}

		var __timegm_js = function (tmPtr) {
			var ret = (() => {
				var time = Date.UTC(
					HEAP32[(tmPtr + 20) >> 2] + 1900,
					HEAP32[(tmPtr + 16) >> 2],
					HEAP32[(tmPtr + 12) >> 2],
					HEAP32[(tmPtr + 8) >> 2],
					HEAP32[(tmPtr + 4) >> 2],
					HEAP32[tmPtr >> 2],
					0,
				);
				var date = new Date(time);

				HEAP32[(tmPtr + 24) >> 2] = date.getUTCDay();
				var start = Date.UTC(date.getUTCFullYear(), 0, 1, 0, 0, 0, 0);
				var yday = ((date.getTime() - start) / (1000 * 60 * 60 * 24)) | 0;
				HEAP32[(tmPtr + 28) >> 2] = yday;

				return date.getTime() / 1000;
			})();
			return (
				setTempRet0(
					((tempDouble = ret),
					+Math.abs(tempDouble) >= 1.0
						? tempDouble > 0.0
							? +Math.floor(tempDouble / 4294967296.0) >>> 0
							: ~~+Math.ceil((tempDouble - +(~~tempDouble >>> 0)) / 4294967296.0) >>> 0
						: 0),
				),
				ret >>> 0
			);
		};

		var stringToUTF8 = (str, outPtr, maxBytesToWrite) => {
			assert(
				typeof maxBytesToWrite == 'number',
				'stringToUTF8(str, outPtr, maxBytesToWrite) is missing the third parameter that specifies the length of the output buffer!',
			);
			return stringToUTF8Array(str, HEAPU8, outPtr, maxBytesToWrite);
		};
		var __tzset_js = (timezone, daylight, std_name, dst_name) => {
			// TODO: Use (malleable) environment variables instead of system settings.
			var currentYear = new Date().getFullYear();
			var winter = new Date(currentYear, 0, 1);
			var summer = new Date(currentYear, 6, 1);
			var winterOffset = winter.getTimezoneOffset();
			var summerOffset = summer.getTimezoneOffset();

			// Local standard timezone offset. Local standard time is not adjusted for
			// daylight savings.  This code uses the fact that getTimezoneOffset returns
			// a greater value during Standard Time versus Daylight Saving Time (DST).
			// Thus it determines the expected output during Standard Time, and it
			// compares whether the output of the given date the same (Standard) or less
			// (DST).
			var stdTimezoneOffset = Math.max(winterOffset, summerOffset);

			// timezone is specified as seconds west of UTC ("The external variable
			// `timezone` shall be set to the difference, in seconds, between
			// Coordinated Universal Time (UTC) and local standard time."), the same
			// as returned by stdTimezoneOffset.
			// See http://pubs.opengroup.org/onlinepubs/009695399/functions/tzset.html
			HEAPU32[timezone >> 2] = stdTimezoneOffset * 60;

			HEAP32[daylight >> 2] = Number(winterOffset != summerOffset);

			function extractZone(date) {
				var match = date.toTimeString().match(/\(([A-Za-z ]+)\)$/);
				return match ? match[1] : 'GMT';
			}
			var winterName = extractZone(winter);
			var summerName = extractZone(summer);
			if (summerOffset < winterOffset) {
				// Northern hemisphere
				stringToUTF8(winterName, std_name, 7);
				stringToUTF8(summerName, dst_name, 7);
			} else {
				stringToUTF8(winterName, dst_name, 7);
				stringToUTF8(summerName, std_name, 7);
			}
		};

		var readEmAsmArgsArray = [];
		var readEmAsmArgs = (sigPtr, buf) => {
			// Nobody should have mutated _readEmAsmArgsArray underneath us to be something else than an array.
			assert(Array.isArray(readEmAsmArgsArray));
			// The input buffer is allocated on the stack, so it must be stack-aligned.
			assert(buf % 16 == 0);
			readEmAsmArgsArray.length = 0;
			var ch;
			// Most arguments are i32s, so shift the buffer pointer so it is a plain
			// index into HEAP32.
			while ((ch = HEAPU8[sigPtr++])) {
				var chr = String.fromCharCode(ch);
				var validChars = ['d', 'f', 'i', 'p'];
				assert(
					validChars.includes(chr),
					`Invalid character ${ch}("${chr}") in readEmAsmArgs! Use only [${validChars}], and do not specify "v" for void return argument.`,
				);
				// Floats are always passed as doubles, so all types except for 'i'
				// are 8 bytes and require alignment.
				var wide = ch != 105;
				wide &= ch != 112;
				buf += wide && buf % 8 ? 4 : 0;
				readEmAsmArgsArray.push(
					// Special case for pointers under wasm64 or CAN_ADDRESS_2GB mode.
					ch == 112 ? HEAPU32[buf >> 2] : ch == 105 ? HEAP32[buf >> 2] : HEAPF64[buf >> 3],
				);
				buf += wide ? 8 : 4;
			}
			return readEmAsmArgsArray;
		};
		var runEmAsmFunction = (code, sigPtr, argbuf) => {
			var args = readEmAsmArgs(sigPtr, argbuf);
			assert(
				ASM_CONSTS.hasOwnProperty(code),
				`No EM_ASM constant found at address ${code}.  The loaded WebAssembly file is likely out of sync with the generated JavaScript.`,
			);
			return ASM_CONSTS[code](...args);
		};
		var _emscripten_asm_const_int = (code, sigPtr, argbuf) => {
			return runEmAsmFunction(code, sigPtr, argbuf);
		};

		var _emscripten_date_now = () => Date.now();

		var _emscripten_memcpy_js = (dest, src, num) => HEAPU8.copyWithin(dest, src, src + num);

		var getHeapMax = () =>
			// Stay one Wasm page short of 4GB: while e.g. Chrome is able to allocate
			// full 4GB Wasm memories, the size will wrap back to 0 bytes in Wasm side
			// for any code that deals with heap sizes, which would require special
			// casing all heap size related code to treat 0 specially.
			2147483648;

		var growMemory = (size) => {
			var b = wasmMemory.buffer;
			var pages = (size - b.byteLength + 65535) / 65536;
			try {
				// round size grow request up to wasm page size (fixed 64KB per spec)
				wasmMemory.grow(pages); // .grow() takes a delta compared to the previous size
				updateMemoryViews();
				return 1 /*success*/;
			} catch (e) {
				err(
					`growMemory: Attempted to grow heap from ${b.byteLength} bytes to ${size} bytes, but got error: ${e}`,
				);
			}
			// implicit 0 return to save code size (caller will cast "undefined" into 0
			// anyhow)
		};
		var _emscripten_resize_heap = (requestedSize) => {
			var oldSize = HEAPU8.length;
			// With CAN_ADDRESS_2GB or MEMORY64, pointers are already unsigned.
			requestedSize >>>= 0;
			// With multithreaded builds, races can happen (another thread might increase the size
			// in between), so return a failure, and let the caller retry.
			assert(requestedSize > oldSize);

			// Memory resize rules:
			// 1.  Always increase heap size to at least the requested size, rounded up
			//     to next page multiple.
			// 2a. If MEMORY_GROWTH_LINEAR_STEP == -1, excessively resize the heap
			//     geometrically: increase the heap size according to
			//     MEMORY_GROWTH_GEOMETRIC_STEP factor (default +20%), At most
			//     overreserve by MEMORY_GROWTH_GEOMETRIC_CAP bytes (default 96MB).
			// 2b. If MEMORY_GROWTH_LINEAR_STEP != -1, excessively resize the heap
			//     linearly: increase the heap size by at least
			//     MEMORY_GROWTH_LINEAR_STEP bytes.
			// 3.  Max size for the heap is capped at 2048MB-WASM_PAGE_SIZE, or by
			//     MAXIMUM_MEMORY, or by ASAN limit, depending on which is smallest
			// 4.  If we were unable to allocate as much memory, it may be due to
			//     over-eager decision to excessively reserve due to (3) above.
			//     Hence if an allocation fails, cut down on the amount of excess
			//     growth, in an attempt to succeed to perform a smaller allocation.

			// A limit is set for how much we can grow. We should not exceed that
			// (the wasm binary specifies it, so if we tried, we'd fail anyhow).
			var maxHeapSize = getHeapMax();
			if (requestedSize > maxHeapSize) {
				err(`Cannot enlarge memory, requested ${requestedSize} bytes, but the limit is ${maxHeapSize} bytes!`);
				return false;
			}

			var alignUp = (x, multiple) => x + ((multiple - (x % multiple)) % multiple);

			// Loop through potential heap size increases. If we attempt a too eager
			// reservation that fails, cut down on the attempted size and reserve a
			// smaller bump instead. (max 3 times, chosen somewhat arbitrarily)
			for (var cutDown = 1; cutDown <= 4; cutDown *= 2) {
				var overGrownHeapSize = oldSize * (1 + 0.2 / cutDown); // ensure geometric growth
				// but limit overreserving (default to capping at +96MB overgrowth at most)
				overGrownHeapSize = Math.min(overGrownHeapSize, requestedSize + 100663296);

				var newSize = Math.min(maxHeapSize, alignUp(Math.max(requestedSize, overGrownHeapSize), 65536));

				var replacement = growMemory(newSize);
				if (replacement) {
					return true;
				}
			}
			err(`Failed to grow the heap from ${oldSize} bytes to ${newSize} bytes, not enough memory!`);
			return false;
		};

		var ENV = {};

		var getExecutableName = () => {
			return thisProgram || './this.program';
		};
		var getEnvStrings = () => {
			if (!getEnvStrings.strings) {
				// Default values.
				// Browser language detection #8751
				var lang =
					((typeof navigator == 'object' && navigator.languages && navigator.languages[0]) || 'C').replace(
						'-',
						'_',
					) + '.UTF-8';
				var env = {
					USER: 'web_user',
					LOGNAME: 'web_user',
					PATH: '/',
					PWD: '/',
					HOME: '/home/web_user',
					LANG: lang,
					_: getExecutableName(),
				};
				// Apply the user-provided values, if any.
				for (var x in ENV) {
					// x is a key in ENV; if ENV[x] is undefined, that means it was
					// explicitly set to be so. We allow user code to do that to
					// force variables with default values to remain unset.
					if (ENV[x] === undefined) delete env[x];
					else env[x] = ENV[x];
				}
				var strings = [];
				for (var x in env) {
					strings.push(`${x}=${env[x]}`);
				}
				getEnvStrings.strings = strings;
			}
			return getEnvStrings.strings;
		};

		var stringToAscii = (str, buffer) => {
			for (var i = 0; i < str.length; ++i) {
				assert(str.charCodeAt(i) === (str.charCodeAt(i) & 0xff));
				HEAP8[buffer++] = str.charCodeAt(i);
			}
			// Null-terminate the string
			HEAP8[buffer] = 0;
		};
		var _environ_get = (__environ, environ_buf) => {
			var bufSize = 0;
			getEnvStrings().forEach((string, i) => {
				var ptr = environ_buf + bufSize;
				HEAPU32[(__environ + i * 4) >> 2] = ptr;
				stringToAscii(string, ptr);
				bufSize += string.length + 1;
			});
			return 0;
		};

		var _environ_sizes_get = (penviron_count, penviron_buf_size) => {
			var strings = getEnvStrings();
			HEAPU32[penviron_count >> 2] = strings.length;
			var bufSize = 0;
			strings.forEach((string) => (bufSize += string.length + 1));
			HEAPU32[penviron_buf_size >> 2] = bufSize;
			return 0;
		};

		var runtimeKeepaliveCounter = 0;
		var keepRuntimeAlive = () => noExitRuntime || runtimeKeepaliveCounter > 0;
		var _proc_exit = (code) => {
			EXITSTATUS = code;
			if (!keepRuntimeAlive()) {
				Module['onExit']?.(code);
				ABORT = true;
			}
			quit_(code, new ExitStatus(code));
		};

		/** @suppress {duplicate } */
		/** @param {boolean|number=} implicit */
		var exitJS = (status, implicit) => {
			EXITSTATUS = status;

			checkUnflushedContent();

			// if exit() was called explicitly, warn the user if the runtime isn't actually being shut down
			if (keepRuntimeAlive() && !implicit) {
				var msg = `program exited (with status: ${status}), but keepRuntimeAlive() is set (counter=${runtimeKeepaliveCounter}) due to an async operation, so halting execution but not exiting the runtime or preventing further async execution (you can use emscripten_force_exit, if you want to force a true shutdown)`;
				readyPromiseReject(msg);
				err(msg);
			}

			_proc_exit(status);
		};
		var _exit = exitJS;

		function _fd_close(fd) {
			try {
				var stream = SYSCALLS.getStreamFromFD(fd);
				FS.close(stream);
				return 0;
			} catch (e) {
				if (typeof FS == 'undefined' || !(e.name === 'ErrnoError')) throw e;
				return e.errno;
			}
		}

		/** @param {number=} offset */
		var doReadv = (stream, iov, iovcnt, offset) => {
			var ret = 0;
			for (var i = 0; i < iovcnt; i++) {
				var ptr = HEAPU32[iov >> 2];
				var len = HEAPU32[(iov + 4) >> 2];
				iov += 8;
				var curr = FS.read(stream, HEAP8, ptr, len, offset);
				if (curr < 0) return -1;
				ret += curr;
				if (curr < len) break; // nothing more to read
				if (typeof offset !== 'undefined') {
					offset += curr;
				}
			}
			return ret;
		};

		function _fd_read(fd, iov, iovcnt, pnum) {
			try {
				var stream = SYSCALLS.getStreamFromFD(fd);
				var num = doReadv(stream, iov, iovcnt);
				HEAPU32[pnum >> 2] = num;
				return 0;
			} catch (e) {
				if (typeof FS == 'undefined' || !(e.name === 'ErrnoError')) throw e;
				return e.errno;
			}
		}

		function _fd_seek(fd, offset_low, offset_high, whence, newOffset) {
			var offset = convertI32PairToI53Checked(offset_low, offset_high);

			try {
				if (isNaN(offset)) return 61;
				var stream = SYSCALLS.getStreamFromFD(fd);
				FS.llseek(stream, offset, whence);
				(tempI64 = [
					stream.position >>> 0,
					((tempDouble = stream.position),
					+Math.abs(tempDouble) >= 1.0
						? tempDouble > 0.0
							? +Math.floor(tempDouble / 4294967296.0) >>> 0
							: ~~+Math.ceil((tempDouble - +(~~tempDouble >>> 0)) / 4294967296.0) >>> 0
						: 0),
				]),
					(HEAP32[newOffset >> 2] = tempI64[0]),
					(HEAP32[(newOffset + 4) >> 2] = tempI64[1]);
				if (stream.getdents && offset === 0 && whence === 0) stream.getdents = null; // reset readdir state
				return 0;
			} catch (e) {
				if (typeof FS == 'undefined' || !(e.name === 'ErrnoError')) throw e;
				return e.errno;
			}
		}

		/** @param {number=} offset */
		var doWritev = (stream, iov, iovcnt, offset) => {
			var ret = 0;
			for (var i = 0; i < iovcnt; i++) {
				var ptr = HEAPU32[iov >> 2];
				var len = HEAPU32[(iov + 4) >> 2];
				iov += 8;
				var curr = FS.write(stream, HEAP8, ptr, len, offset);
				if (curr < 0) return -1;
				ret += curr;
				if (typeof offset !== 'undefined') {
					offset += curr;
				}
			}
			return ret;
		};

		function _fd_write(fd, iov, iovcnt, pnum) {
			try {
				var stream = SYSCALLS.getStreamFromFD(fd);
				var num = doWritev(stream, iov, iovcnt);
				HEAPU32[pnum >> 2] = num;
				return 0;
			} catch (e) {
				if (typeof FS == 'undefined' || !(e.name === 'ErrnoError')) throw e;
				return e.errno;
			}
		}

		var isLeapYear = (year) => year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

		var arraySum = (array, index) => {
			var sum = 0;
			for (var i = 0; i <= index; sum += array[i++]) {
				// no-op
			}
			return sum;
		};

		var MONTH_DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

		var MONTH_DAYS_REGULAR = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
		var addDays = (date, days) => {
			var newDate = new Date(date.getTime());
			while (days > 0) {
				var leap = isLeapYear(newDate.getFullYear());
				var currentMonth = newDate.getMonth();
				var daysInCurrentMonth = (leap ? MONTH_DAYS_LEAP : MONTH_DAYS_REGULAR)[currentMonth];

				if (days > daysInCurrentMonth - newDate.getDate()) {
					// we spill over to next month
					days -= daysInCurrentMonth - newDate.getDate() + 1;
					newDate.setDate(1);
					if (currentMonth < 11) {
						newDate.setMonth(currentMonth + 1);
					} else {
						newDate.setMonth(0);
						newDate.setFullYear(newDate.getFullYear() + 1);
					}
				} else {
					// we stay in current month
					newDate.setDate(newDate.getDate() + days);
					return newDate;
				}
			}

			return newDate;
		};

		var writeArrayToMemory = (array, buffer) => {
			assert(
				array.length >= 0,
				'writeArrayToMemory array must have a length (should be an array or typed array)',
			);
			HEAP8.set(array, buffer);
		};

		var _strftime = (s, maxsize, format, tm) => {
			// size_t strftime(char *restrict s, size_t maxsize, const char *restrict format, const struct tm *restrict timeptr);
			// http://pubs.opengroup.org/onlinepubs/009695399/functions/strftime.html

			var tm_zone = HEAPU32[(tm + 40) >> 2];

			var date = {
				tm_sec: HEAP32[tm >> 2],
				tm_min: HEAP32[(tm + 4) >> 2],
				tm_hour: HEAP32[(tm + 8) >> 2],
				tm_mday: HEAP32[(tm + 12) >> 2],
				tm_mon: HEAP32[(tm + 16) >> 2],
				tm_year: HEAP32[(tm + 20) >> 2],
				tm_wday: HEAP32[(tm + 24) >> 2],
				tm_yday: HEAP32[(tm + 28) >> 2],
				tm_isdst: HEAP32[(tm + 32) >> 2],
				tm_gmtoff: HEAP32[(tm + 36) >> 2],
				tm_zone: tm_zone ? UTF8ToString(tm_zone) : '',
			};

			var pattern = UTF8ToString(format);

			// expand format
			var EXPANSION_RULES_1 = {
				'%c': '%a %b %d %H:%M:%S %Y', // Replaced by the locale's appropriate date and time representation - e.g., Mon Aug  3 14:02:01 2013
				'%D': '%m/%d/%y', // Equivalent to %m / %d / %y
				'%F': '%Y-%m-%d', // Equivalent to %Y - %m - %d
				'%h': '%b', // Equivalent to %b
				'%r': '%I:%M:%S %p', // Replaced by the time in a.m. and p.m. notation
				'%R': '%H:%M', // Replaced by the time in 24-hour notation
				'%T': '%H:%M:%S', // Replaced by the time
				'%x': '%m/%d/%y', // Replaced by the locale's appropriate date representation
				'%X': '%H:%M:%S', // Replaced by the locale's appropriate time representation
				// Modified Conversion Specifiers
				'%Ec': '%c', // Replaced by the locale's alternative appropriate date and time representation.
				'%EC': '%C', // Replaced by the name of the base year (period) in the locale's alternative representation.
				'%Ex': '%m/%d/%y', // Replaced by the locale's alternative date representation.
				'%EX': '%H:%M:%S', // Replaced by the locale's alternative time representation.
				'%Ey': '%y', // Replaced by the offset from %EC (year only) in the locale's alternative representation.
				'%EY': '%Y', // Replaced by the full alternative year representation.
				'%Od': '%d', // Replaced by the day of the month, using the locale's alternative numeric symbols, filled as needed with leading zeros if there is any alternative symbol for zero; otherwise, with leading <space> characters.
				'%Oe': '%e', // Replaced by the day of the month, using the locale's alternative numeric symbols, filled as needed with leading <space> characters.
				'%OH': '%H', // Replaced by the hour (24-hour clock) using the locale's alternative numeric symbols.
				'%OI': '%I', // Replaced by the hour (12-hour clock) using the locale's alternative numeric symbols.
				'%Om': '%m', // Replaced by the month using the locale's alternative numeric symbols.
				'%OM': '%M', // Replaced by the minutes using the locale's alternative numeric symbols.
				'%OS': '%S', // Replaced by the seconds using the locale's alternative numeric symbols.
				'%Ou': '%u', // Replaced by the weekday as a number in the locale's alternative representation (Monday=1).
				'%OU': '%U', // Replaced by the week number of the year (Sunday as the first day of the week, rules corresponding to %U ) using the locale's alternative numeric symbols.
				'%OV': '%V', // Replaced by the week number of the year (Monday as the first day of the week, rules corresponding to %V ) using the locale's alternative numeric symbols.
				'%Ow': '%w', // Replaced by the number of the weekday (Sunday=0) using the locale's alternative numeric symbols.
				'%OW': '%W', // Replaced by the week number of the year (Monday as the first day of the week) using the locale's alternative numeric symbols.
				'%Oy': '%y', // Replaced by the year (offset from %C ) using the locale's alternative numeric symbols.
			};
			for (var rule in EXPANSION_RULES_1) {
				pattern = pattern.replace(new RegExp(rule, 'g'), EXPANSION_RULES_1[rule]);
			}

			var WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
			var MONTHS = [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
			];

			function leadingSomething(value, digits, character) {
				var str = typeof value == 'number' ? value.toString() : value || '';
				while (str.length < digits) {
					str = character[0] + str;
				}
				return str;
			}

			function leadingNulls(value, digits) {
				return leadingSomething(value, digits, '0');
			}

			function compareByDay(date1, date2) {
				function sgn(value) {
					return value < 0 ? -1 : value > 0 ? 1 : 0;
				}

				var compare;
				if ((compare = sgn(date1.getFullYear() - date2.getFullYear())) === 0) {
					if ((compare = sgn(date1.getMonth() - date2.getMonth())) === 0) {
						compare = sgn(date1.getDate() - date2.getDate());
					}
				}
				return compare;
			}

			function getFirstWeekStartDate(janFourth) {
				switch (janFourth.getDay()) {
					case 0: // Sunday
						return new Date(janFourth.getFullYear() - 1, 11, 29);
					case 1: // Monday
						return janFourth;
					case 2: // Tuesday
						return new Date(janFourth.getFullYear(), 0, 3);
					case 3: // Wednesday
						return new Date(janFourth.getFullYear(), 0, 2);
					case 4: // Thursday
						return new Date(janFourth.getFullYear(), 0, 1);
					case 5: // Friday
						return new Date(janFourth.getFullYear() - 1, 11, 31);
					case 6: // Saturday
						return new Date(janFourth.getFullYear() - 1, 11, 30);
				}
			}

			function getWeekBasedYear(date) {
				var thisDate = addDays(new Date(date.tm_year + 1900, 0, 1), date.tm_yday);

				var janFourthThisYear = new Date(thisDate.getFullYear(), 0, 4);
				var janFourthNextYear = new Date(thisDate.getFullYear() + 1, 0, 4);

				var firstWeekStartThisYear = getFirstWeekStartDate(janFourthThisYear);
				var firstWeekStartNextYear = getFirstWeekStartDate(janFourthNextYear);

				if (compareByDay(firstWeekStartThisYear, thisDate) <= 0) {
					// this date is after the start of the first week of this year
					if (compareByDay(firstWeekStartNextYear, thisDate) <= 0) {
						return thisDate.getFullYear() + 1;
					}
					return thisDate.getFullYear();
				}
				return thisDate.getFullYear() - 1;
			}

			var EXPANSION_RULES_2 = {
				'%a': (date) => WEEKDAYS[date.tm_wday].substring(0, 3),
				'%A': (date) => WEEKDAYS[date.tm_wday],
				'%b': (date) => MONTHS[date.tm_mon].substring(0, 3),
				'%B': (date) => MONTHS[date.tm_mon],
				'%C': (date) => {
					var year = date.tm_year + 1900;
					return leadingNulls((year / 100) | 0, 2);
				},
				'%d': (date) => leadingNulls(date.tm_mday, 2),
				'%e': (date) => leadingSomething(date.tm_mday, 2, ' '),
				'%g': (date) => {
					// %g, %G, and %V give values according to the ISO 8601:2000 standard week-based year.
					// In this system, weeks begin on a Monday and week 1 of the year is the week that includes
					// January 4th, which is also the week that includes the first Thursday of the year, and
					// is also the first week that contains at least four days in the year.
					// If the first Monday of January is the 2nd, 3rd, or 4th, the preceding days are part of
					// the last week of the preceding year; thus, for Saturday 2nd January 1999,
					// %G is replaced by 1998 and %V is replaced by 53. If December 29th, 30th,
					// or 31st is a Monday, it and any following days are part of week 1 of the following year.
					// Thus, for Tuesday 30th December 1997, %G is replaced by 1998 and %V is replaced by 01.

					return getWeekBasedYear(date).toString().substring(2);
				},
				'%G': getWeekBasedYear,
				'%H': (date) => leadingNulls(date.tm_hour, 2),
				'%I': (date) => {
					var twelveHour = date.tm_hour;
					if (twelveHour == 0) twelveHour = 12;
					else if (twelveHour > 12) twelveHour -= 12;
					return leadingNulls(twelveHour, 2);
				},
				'%j': (date) => {
					// Day of the year (001-366)
					return leadingNulls(
						date.tm_mday +
							arraySum(
								isLeapYear(date.tm_year + 1900) ? MONTH_DAYS_LEAP : MONTH_DAYS_REGULAR,
								date.tm_mon - 1,
							),
						3,
					);
				},
				'%m': (date) => leadingNulls(date.tm_mon + 1, 2),
				'%M': (date) => leadingNulls(date.tm_min, 2),
				'%n': () => '\n',
				'%p': (date) => {
					if (date.tm_hour >= 0 && date.tm_hour < 12) {
						return 'AM';
					}
					return 'PM';
				},
				'%S': (date) => leadingNulls(date.tm_sec, 2),
				'%t': () => '\t',
				'%u': (date) => date.tm_wday || 7,
				'%U': (date) => {
					var days = date.tm_yday + 7 - date.tm_wday;
					return leadingNulls(Math.floor(days / 7), 2);
				},
				'%V': (date) => {
					// Replaced by the week number of the year (Monday as the first day of the week)
					// as a decimal number [01,53]. If the week containing 1 January has four
					// or more days in the new year, then it is considered week 1.
					// Otherwise, it is the last week of the previous year, and the next week is week 1.
					// Both January 4th and the first Thursday of January are always in week 1. [ tm_year, tm_wday, tm_yday]
					var val = Math.floor((date.tm_yday + 7 - ((date.tm_wday + 6) % 7)) / 7);
					// If 1 Jan is just 1-3 days past Monday, the previous week
					// is also in this year.
					if ((date.tm_wday + 371 - date.tm_yday - 2) % 7 <= 2) {
						val++;
					}
					if (!val) {
						val = 52;
						// If 31 December of prev year a Thursday, or Friday of a
						// leap year, then the prev year has 53 weeks.
						var dec31 = (date.tm_wday + 7 - date.tm_yday - 1) % 7;
						if (dec31 == 4 || (dec31 == 5 && isLeapYear((date.tm_year % 400) - 1))) {
							val++;
						}
					} else if (val == 53) {
						// If 1 January is not a Thursday, and not a Wednesday of a
						// leap year, then this year has only 52 weeks.
						var jan1 = (date.tm_wday + 371 - date.tm_yday) % 7;
						if (jan1 != 4 && (jan1 != 3 || !isLeapYear(date.tm_year))) val = 1;
					}
					return leadingNulls(val, 2);
				},
				'%w': (date) => date.tm_wday,
				'%W': (date) => {
					var days = date.tm_yday + 7 - ((date.tm_wday + 6) % 7);
					return leadingNulls(Math.floor(days / 7), 2);
				},
				'%y': (date) => {
					// Replaced by the last two digits of the year as a decimal number [00,99]. [ tm_year]
					return (date.tm_year + 1900).toString().substring(2);
				},
				// Replaced by the year as a decimal number (for example, 1997). [ tm_year]
				'%Y': (date) => date.tm_year + 1900,
				'%z': (date) => {
					// Replaced by the offset from UTC in the ISO 8601:2000 standard format ( +hhmm or -hhmm ).
					// For example, "-0430" means 4 hours 30 minutes behind UTC (west of Greenwich).
					var off = date.tm_gmtoff;
					var ahead = off >= 0;
					off = Math.abs(off) / 60;
					// convert from minutes into hhmm format (which means 60 minutes = 100 units)
					off = (off / 60) * 100 + (off % 60);
					return (ahead ? '+' : '-') + String('0000' + off).slice(-4);
				},
				'%Z': (date) => date.tm_zone,
				'%%': () => '%',
			};

			// Replace %% with a pair of NULLs (which cannot occur in a C string), then
			// re-inject them after processing.
			pattern = pattern.replace(/%%/g, '\0\0');
			for (var rule in EXPANSION_RULES_2) {
				if (pattern.includes(rule)) {
					pattern = pattern.replace(new RegExp(rule, 'g'), EXPANSION_RULES_2[rule](date));
				}
			}
			pattern = pattern.replace(/\0\0/g, '%');

			var bytes = intArrayFromString(pattern, false);
			if (bytes.length > maxsize) {
				return 0;
			}

			writeArrayToMemory(bytes, s);
			return bytes.length - 1;
		};

		var wasmTableMirror = [];

		var wasmTable;
		var getWasmTableEntry = (funcPtr) => {
			var func = wasmTableMirror[funcPtr];
			if (!func) {
				if (funcPtr >= wasmTableMirror.length) wasmTableMirror.length = funcPtr + 1;
				wasmTableMirror[funcPtr] = func = wasmTable.get(funcPtr);
			}
			assert(wasmTable.get(funcPtr) == func, 'JavaScript-side Wasm function table mirror is out of date!');
			return func;
		};

		var getCFunc = (ident) => {
			var func = Module['_' + ident]; // closure exported function
			assert(func, 'Cannot call unknown function ' + ident + ', make sure it is exported');
			return func;
		};

		var stringToUTF8OnStack = (str) => {
			var size = lengthBytesUTF8(str) + 1;
			var ret = stackAlloc(size);
			stringToUTF8(str, ret, size);
			return ret;
		};

		/**
		 * @param {string|null=} returnType
		 * @param {Array=} argTypes
		 * @param {Arguments|Array=} args
		 * @param {Object=} opts
		 */
		var ccall = (ident, returnType, argTypes, args, opts) => {
			// For fast lookup of conversion functions
			var toC = {
				string: (str) => {
					var ret = 0;
					if (str !== null && str !== undefined && str !== 0) {
						// null string
						// at most 4 bytes per UTF-8 code point, +1 for the trailing '\0'
						ret = stringToUTF8OnStack(str);
					}
					return ret;
				},
				array: (arr) => {
					var ret = stackAlloc(arr.length);
					writeArrayToMemory(arr, ret);
					return ret;
				},
			};

			function convertReturnValue(ret) {
				if (returnType === 'string') {
					return UTF8ToString(ret);
				}
				if (returnType === 'boolean') return Boolean(ret);
				return ret;
			}

			var func = getCFunc(ident);
			var cArgs = [];
			var stack = 0;
			assert(returnType !== 'array', 'Return type should not be "array".');
			if (args) {
				for (var i = 0; i < args.length; i++) {
					var converter = toC[argTypes[i]];
					if (converter) {
						if (stack === 0) stack = stackSave();
						cArgs[i] = converter(args[i]);
					} else {
						cArgs[i] = args[i];
					}
				}
			}
			var ret = func(...cArgs);
			function onDone(ret) {
				if (stack !== 0) stackRestore(stack);
				return convertReturnValue(ret);
			}

			ret = onDone(ret);
			return ret;
		};

		FS.createPreloadedFile = FS_createPreloadedFile;
		FS.staticInit();
		function checkIncomingModuleAPI() {
			ignoredModuleProp('fetchSettings');
		}
		var wasmImports = {
			/** @export */
			__syscall_fcntl64: ___syscall_fcntl64,
			/** @export */
			__syscall_ftruncate64: ___syscall_ftruncate64,
			/** @export */
			__syscall_ioctl: ___syscall_ioctl,
			/** @export */
			__syscall_openat: ___syscall_openat,
			/** @export */
			__syscall_rmdir: ___syscall_rmdir,
			/** @export */
			__syscall_unlinkat: ___syscall_unlinkat,
			/** @export */
			_emscripten_throw_longjmp: __emscripten_throw_longjmp,
			/** @export */
			_gmtime_js: __gmtime_js,
			/** @export */
			_timegm_js: __timegm_js,
			/** @export */
			_tzset_js: __tzset_js,
			/** @export */
			emscripten_asm_const_int: _emscripten_asm_const_int,
			/** @export */
			emscripten_date_now: _emscripten_date_now,
			/** @export */
			emscripten_memcpy_js: _emscripten_memcpy_js,
			/** @export */
			emscripten_resize_heap: _emscripten_resize_heap,
			/** @export */
			environ_get: _environ_get,
			/** @export */
			environ_sizes_get: _environ_sizes_get,
			/** @export */
			exit: _exit,
			/** @export */
			fd_close: _fd_close,
			/** @export */
			fd_read: _fd_read,
			/** @export */
			fd_seek: _fd_seek,
			/** @export */
			fd_write: _fd_write,
			/** @export */
			invoke_ffi: invoke_ffi,
			/** @export */
			invoke_fi: invoke_fi,
			/** @export */
			invoke_fiffff: invoke_fiffff,
			/** @export */
			invoke_fii: invoke_fii,
			/** @export */
			invoke_fiii: invoke_fiii,
			/** @export */
			invoke_fiiif: invoke_fiiif,
			/** @export */
			invoke_fiiifiif: invoke_fiiifiif,
			/** @export */
			invoke_fiiii: invoke_fiiii,
			/** @export */
			invoke_fiiiii: invoke_fiiiii,
			/** @export */
			invoke_ii: invoke_ii,
			/** @export */
			invoke_iif: invoke_iif,
			/** @export */
			invoke_iii: invoke_iii,
			/** @export */
			invoke_iiidi: invoke_iiidi,
			/** @export */
			invoke_iiiffffiii: invoke_iiiffffiii,
			/** @export */
			invoke_iiiffiiiiiii: invoke_iiiffiiiiiii,
			/** @export */
			invoke_iiii: invoke_iiii,
			/** @export */
			invoke_iiiif: invoke_iiiif,
			/** @export */
			invoke_iiiifi: invoke_iiiifi,
			/** @export */
			invoke_iiiii: invoke_iiiii,
			/** @export */
			invoke_iiiiiffi: invoke_iiiiiffi,
			/** @export */
			invoke_iiiiiffii: invoke_iiiiiffii,
			/** @export */
			invoke_iiiiiffiifiii: invoke_iiiiiffiifiii,
			/** @export */
			invoke_iiiiifiiii: invoke_iiiiifiiii,
			/** @export */
			invoke_iiiiii: invoke_iiiiii,
			/** @export */
			invoke_iiiiiii: invoke_iiiiiii,
			/** @export */
			invoke_iiiiiiifi: invoke_iiiiiiifi,
			/** @export */
			invoke_iiiiiiii: invoke_iiiiiiii,
			/** @export */
			invoke_iiiiiiiii: invoke_iiiiiiiii,
			/** @export */
			invoke_iiiiiiiiii: invoke_iiiiiiiiii,
			/** @export */
			invoke_iiiiiiiiiii: invoke_iiiiiiiiiii,
			/** @export */
			invoke_iiiiiiiiiiii: invoke_iiiiiiiiiiii,
			/** @export */
			invoke_iiiiiiiiiiiiii: invoke_iiiiiiiiiiiiii,
			/** @export */
			invoke_iiiiiiiiiiiiiiiii: invoke_iiiiiiiiiiiiiiiii,
			/** @export */
			invoke_iiiiiiiij: invoke_iiiiiiiij,
			/** @export */
			invoke_iiiiiijji: invoke_iiiiiijji,
			/** @export */
			invoke_iiiiij: invoke_iiiiij,
			/** @export */
			invoke_iiiij: invoke_iiiij,
			/** @export */
			invoke_iiij: invoke_iiij,
			/** @export */
			invoke_iiijj: invoke_iiijj,
			/** @export */
			invoke_iij: invoke_iij,
			/** @export */
			invoke_ji: invoke_ji,
			/** @export */
			invoke_jii: invoke_jii,
			/** @export */
			invoke_jiii: invoke_jiii,
			/** @export */
			invoke_jiij: invoke_jiij,
			/** @export */
			invoke_v: invoke_v,
			/** @export */
			invoke_vi: invoke_vi,
			/** @export */
			invoke_vif: invoke_vif,
			/** @export */
			invoke_viff: invoke_viff,
			/** @export */
			invoke_viffi: invoke_viffi,
			/** @export */
			invoke_vii: invoke_vii,
			/** @export */
			invoke_viid: invoke_viid,
			/** @export */
			invoke_viif: invoke_viif,
			/** @export */
			invoke_viiff: invoke_viiff,
			/** @export */
			invoke_viifff: invoke_viifff,
			/** @export */
			invoke_viiffff: invoke_viiffff,
			/** @export */
			invoke_viiffffff: invoke_viiffffff,
			/** @export */
			invoke_viiffii: invoke_viiffii,
			/** @export */
			invoke_viifi: invoke_viifi,
			/** @export */
			invoke_viii: invoke_viii,
			/** @export */
			invoke_viiid: invoke_viiid,
			/** @export */
			invoke_viiif: invoke_viiif,
			/** @export */
			invoke_viiiffff: invoke_viiiffff,
			/** @export */
			invoke_viiifffffiii: invoke_viiifffffiii,
			/** @export */
			invoke_viiifii: invoke_viiifii,
			/** @export */
			invoke_viiii: invoke_viiii,
			/** @export */
			invoke_viiiif: invoke_viiiif,
			/** @export */
			invoke_viiiiff: invoke_viiiiff,
			/** @export */
			invoke_viiiifi: invoke_viiiifi,
			/** @export */
			invoke_viiiifii: invoke_viiiifii,
			/** @export */
			invoke_viiiii: invoke_viiiii,
			/** @export */
			invoke_viiiiiff: invoke_viiiiiff,
			/** @export */
			invoke_viiiiifii: invoke_viiiiifii,
			/** @export */
			invoke_viiiiii: invoke_viiiiii,
			/** @export */
			invoke_viiiiiifi: invoke_viiiiiifi,
			/** @export */
			invoke_viiiiiii: invoke_viiiiiii,
			/** @export */
			invoke_viiiiiiif: invoke_viiiiiiif,
			/** @export */
			invoke_viiiiiiifi: invoke_viiiiiiifi,
			/** @export */
			invoke_viiiiiiifiiifffffiii: invoke_viiiiiiifiiifffffiii,
			/** @export */
			invoke_viiiiiiii: invoke_viiiiiiii,
			/** @export */
			invoke_viiiiiiiii: invoke_viiiiiiiii,
			/** @export */
			invoke_viiiiiiiiii: invoke_viiiiiiiiii,
			/** @export */
			invoke_viiiiiiiiiii: invoke_viiiiiiiiiii,
			/** @export */
			invoke_viiiiiijj: invoke_viiiiiijj,
			/** @export */
			invoke_viiiij: invoke_viiiij,
			/** @export */
			invoke_viiij: invoke_viiij,
			/** @export */
			invoke_viij: invoke_viij,
			/** @export */
			invoke_viiji: invoke_viiji,
			/** @export */
			invoke_vij: invoke_vij,
			/** @export */
			strftime: _strftime,
		};
		var wasmExports = createWasm();
		var ___wasm_call_ctors = createExportWrapper('__wasm_call_ctors');
		var _wasm_init_context = (Module['_wasm_init_context'] = createExportWrapper('wasm_init_context'));
		var _wasm_malloc = (Module['_wasm_malloc'] = createExportWrapper('wasm_malloc'));
		var setTempRet0 = createExportWrapper('setTempRet0');
		var _wasm_free = (Module['_wasm_free'] = createExportWrapper('wasm_free'));
		var _wasm_enable_icc = (Module['_wasm_enable_icc'] = createExportWrapper('wasm_enable_icc'));
		var _wasm_disable_icc = (Module['_wasm_disable_icc'] = createExportWrapper('wasm_disable_icc'));
		var _wasm_set_user_css = (Module['_wasm_set_user_css'] = createExportWrapper('wasm_set_user_css'));
		var _wasm_keep_buffer = (Module['_wasm_keep_buffer'] = createExportWrapper('wasm_keep_buffer'));
		var _wasm_drop_buffer = (Module['_wasm_drop_buffer'] = createExportWrapper('wasm_drop_buffer'));
		var _wasm_keep_stream = (Module['_wasm_keep_stream'] = createExportWrapper('wasm_keep_stream'));
		var _wasm_drop_stream = (Module['_wasm_drop_stream'] = createExportWrapper('wasm_drop_stream'));
		var _wasm_keep_colorspace = (Module['_wasm_keep_colorspace'] = createExportWrapper('wasm_keep_colorspace'));
		var _wasm_drop_colorspace = (Module['_wasm_drop_colorspace'] = createExportWrapper('wasm_drop_colorspace'));
		var _wasm_keep_pixmap = (Module['_wasm_keep_pixmap'] = createExportWrapper('wasm_keep_pixmap'));
		var _wasm_drop_pixmap = (Module['_wasm_drop_pixmap'] = createExportWrapper('wasm_drop_pixmap'));
		var _wasm_keep_font = (Module['_wasm_keep_font'] = createExportWrapper('wasm_keep_font'));
		var _wasm_drop_font = (Module['_wasm_drop_font'] = createExportWrapper('wasm_drop_font'));
		var _wasm_keep_stroke_state = (Module['_wasm_keep_stroke_state'] =
			createExportWrapper('wasm_keep_stroke_state'));
		var _wasm_drop_stroke_state = (Module['_wasm_drop_stroke_state'] =
			createExportWrapper('wasm_drop_stroke_state'));
		var _wasm_keep_image = (Module['_wasm_keep_image'] = createExportWrapper('wasm_keep_image'));
		var _wasm_drop_image = (Module['_wasm_drop_image'] = createExportWrapper('wasm_drop_image'));
		var _wasm_keep_shade = (Module['_wasm_keep_shade'] = createExportWrapper('wasm_keep_shade'));
		var _wasm_drop_shade = (Module['_wasm_drop_shade'] = createExportWrapper('wasm_drop_shade'));
		var _wasm_keep_path = (Module['_wasm_keep_path'] = createExportWrapper('wasm_keep_path'));
		var _wasm_drop_path = (Module['_wasm_drop_path'] = createExportWrapper('wasm_drop_path'));
		var _wasm_keep_text = (Module['_wasm_keep_text'] = createExportWrapper('wasm_keep_text'));
		var _wasm_drop_text = (Module['_wasm_drop_text'] = createExportWrapper('wasm_drop_text'));
		var _wasm_keep_device = (Module['_wasm_keep_device'] = createExportWrapper('wasm_keep_device'));
		var _wasm_drop_device = (Module['_wasm_drop_device'] = createExportWrapper('wasm_drop_device'));
		var _wasm_keep_display_list = (Module['_wasm_keep_display_list'] =
			createExportWrapper('wasm_keep_display_list'));
		var _wasm_drop_display_list = (Module['_wasm_drop_display_list'] =
			createExportWrapper('wasm_drop_display_list'));
		var _wasm_drop_stext_page = (Module['_wasm_drop_stext_page'] = createExportWrapper('wasm_drop_stext_page'));
		var _wasm_drop_document_writer = (Module['_wasm_drop_document_writer'] =
			createExportWrapper('wasm_drop_document_writer'));
		var _wasm_drop_outline_iterator = (Module['_wasm_drop_outline_iterator'] =
			createExportWrapper('wasm_drop_outline_iterator'));
		var _wasm_keep_document = (Module['_wasm_keep_document'] = createExportWrapper('wasm_keep_document'));
		var _wasm_drop_document = (Module['_wasm_drop_document'] = createExportWrapper('wasm_drop_document'));
		var _wasm_keep_page = (Module['_wasm_keep_page'] = createExportWrapper('wasm_keep_page'));
		var _wasm_drop_page = (Module['_wasm_drop_page'] = createExportWrapper('wasm_drop_page'));
		var _wasm_keep_link = (Module['_wasm_keep_link'] = createExportWrapper('wasm_keep_link'));
		var _wasm_drop_link = (Module['_wasm_drop_link'] = createExportWrapper('wasm_drop_link'));
		var _wasm_keep_outline = (Module['_wasm_keep_outline'] = createExportWrapper('wasm_keep_outline'));
		var _wasm_drop_outline = (Module['_wasm_drop_outline'] = createExportWrapper('wasm_drop_outline'));
		var _wasm_pdf_keep_annot = (Module['_wasm_pdf_keep_annot'] = createExportWrapper('wasm_pdf_keep_annot'));
		var _wasm_pdf_drop_annot = (Module['_wasm_pdf_drop_annot'] = createExportWrapper('wasm_pdf_drop_annot'));
		var _wasm_pdf_keep_obj = (Module['_wasm_pdf_keep_obj'] = createExportWrapper('wasm_pdf_keep_obj'));
		var _wasm_pdf_drop_obj = (Module['_wasm_pdf_drop_obj'] = createExportWrapper('wasm_pdf_drop_obj'));
		var _wasm_pdf_keep_graft_map = (Module['_wasm_pdf_keep_graft_map'] =
			createExportWrapper('wasm_pdf_keep_graft_map'));
		var _wasm_pdf_drop_graft_map = (Module['_wasm_pdf_drop_graft_map'] =
			createExportWrapper('wasm_pdf_drop_graft_map'));
		var _wasm_buffer_get_data = (Module['_wasm_buffer_get_data'] = createExportWrapper('wasm_buffer_get_data'));
		var _wasm_buffer_get_len = (Module['_wasm_buffer_get_len'] = createExportWrapper('wasm_buffer_get_len'));
		var _wasm_colorspace_get_type = (Module['_wasm_colorspace_get_type'] =
			createExportWrapper('wasm_colorspace_get_type'));
		var _wasm_colorspace_get_n = (Module['_wasm_colorspace_get_n'] = createExportWrapper('wasm_colorspace_get_n'));
		var _wasm_colorspace_get_name = (Module['_wasm_colorspace_get_name'] =
			createExportWrapper('wasm_colorspace_get_name'));
		var _wasm_pixmap_get_w = (Module['_wasm_pixmap_get_w'] = createExportWrapper('wasm_pixmap_get_w'));
		var _wasm_pixmap_get_h = (Module['_wasm_pixmap_get_h'] = createExportWrapper('wasm_pixmap_get_h'));
		var _wasm_pixmap_get_x = (Module['_wasm_pixmap_get_x'] = createExportWrapper('wasm_pixmap_get_x'));
		var _wasm_pixmap_get_y = (Module['_wasm_pixmap_get_y'] = createExportWrapper('wasm_pixmap_get_y'));
		var _wasm_pixmap_get_n = (Module['_wasm_pixmap_get_n'] = createExportWrapper('wasm_pixmap_get_n'));
		var _wasm_pixmap_get_stride = (Module['_wasm_pixmap_get_stride'] =
			createExportWrapper('wasm_pixmap_get_stride'));
		var _wasm_pixmap_get_alpha = (Module['_wasm_pixmap_get_alpha'] = createExportWrapper('wasm_pixmap_get_alpha'));
		var _wasm_pixmap_get_xres = (Module['_wasm_pixmap_get_xres'] = createExportWrapper('wasm_pixmap_get_xres'));
		var _wasm_pixmap_get_yres = (Module['_wasm_pixmap_get_yres'] = createExportWrapper('wasm_pixmap_get_yres'));
		var _wasm_pixmap_get_colorspace = (Module['_wasm_pixmap_get_colorspace'] =
			createExportWrapper('wasm_pixmap_get_colorspace'));
		var _wasm_pixmap_get_samples = (Module['_wasm_pixmap_get_samples'] =
			createExportWrapper('wasm_pixmap_get_samples'));
		var _wasm_pixmap_set_xres = (Module['_wasm_pixmap_set_xres'] = createExportWrapper('wasm_pixmap_set_xres'));
		var _wasm_pixmap_set_yres = (Module['_wasm_pixmap_set_yres'] = createExportWrapper('wasm_pixmap_set_yres'));
		var _wasm_font_get_name = (Module['_wasm_font_get_name'] = createExportWrapper('wasm_font_get_name'));
		var _wasm_stroke_state_get_start_cap = (Module['_wasm_stroke_state_get_start_cap'] = createExportWrapper(
			'wasm_stroke_state_get_start_cap',
		));
		var _wasm_stroke_state_set_start_cap = (Module['_wasm_stroke_state_set_start_cap'] = createExportWrapper(
			'wasm_stroke_state_set_start_cap',
		));
		var _wasm_stroke_state_get_dash_cap = (Module['_wasm_stroke_state_get_dash_cap'] = createExportWrapper(
			'wasm_stroke_state_get_dash_cap',
		));
		var _wasm_stroke_state_set_dash_cap = (Module['_wasm_stroke_state_set_dash_cap'] = createExportWrapper(
			'wasm_stroke_state_set_dash_cap',
		));
		var _wasm_stroke_state_get_end_cap = (Module['_wasm_stroke_state_get_end_cap'] = createExportWrapper(
			'wasm_stroke_state_get_end_cap',
		));
		var _wasm_stroke_state_set_end_cap = (Module['_wasm_stroke_state_set_end_cap'] = createExportWrapper(
			'wasm_stroke_state_set_end_cap',
		));
		var _wasm_stroke_state_get_linejoin = (Module['_wasm_stroke_state_get_linejoin'] = createExportWrapper(
			'wasm_stroke_state_get_linejoin',
		));
		var _wasm_stroke_state_set_linejoin = (Module['_wasm_stroke_state_set_linejoin'] = createExportWrapper(
			'wasm_stroke_state_set_linejoin',
		));
		var _wasm_stroke_state_get_linewidth = (Module['_wasm_stroke_state_get_linewidth'] = createExportWrapper(
			'wasm_stroke_state_get_linewidth',
		));
		var _wasm_stroke_state_set_linewidth = (Module['_wasm_stroke_state_set_linewidth'] = createExportWrapper(
			'wasm_stroke_state_set_linewidth',
		));
		var _wasm_stroke_state_get_miterlimit = (Module['_wasm_stroke_state_get_miterlimit'] = createExportWrapper(
			'wasm_stroke_state_get_miterlimit',
		));
		var _wasm_stroke_state_set_miterlimit = (Module['_wasm_stroke_state_set_miterlimit'] = createExportWrapper(
			'wasm_stroke_state_set_miterlimit',
		));
		var _wasm_stroke_state_get_dash_phase = (Module['_wasm_stroke_state_get_dash_phase'] = createExportWrapper(
			'wasm_stroke_state_get_dash_phase',
		));
		var _wasm_stroke_state_set_dash_phase = (Module['_wasm_stroke_state_set_dash_phase'] = createExportWrapper(
			'wasm_stroke_state_set_dash_phase',
		));
		var _wasm_image_get_w = (Module['_wasm_image_get_w'] = createExportWrapper('wasm_image_get_w'));
		var _wasm_image_get_h = (Module['_wasm_image_get_h'] = createExportWrapper('wasm_image_get_h'));
		var _wasm_image_get_n = (Module['_wasm_image_get_n'] = createExportWrapper('wasm_image_get_n'));
		var _wasm_image_get_bpc = (Module['_wasm_image_get_bpc'] = createExportWrapper('wasm_image_get_bpc'));
		var _wasm_image_get_xres = (Module['_wasm_image_get_xres'] = createExportWrapper('wasm_image_get_xres'));
		var _wasm_image_get_yres = (Module['_wasm_image_get_yres'] = createExportWrapper('wasm_image_get_yres'));
		var _wasm_image_get_imagemask = (Module['_wasm_image_get_imagemask'] =
			createExportWrapper('wasm_image_get_imagemask'));
		var _wasm_image_get_colorspace = (Module['_wasm_image_get_colorspace'] =
			createExportWrapper('wasm_image_get_colorspace'));
		var _wasm_image_get_mask = (Module['_wasm_image_get_mask'] = createExportWrapper('wasm_image_get_mask'));
		var _wasm_outline_get_title = (Module['_wasm_outline_get_title'] =
			createExportWrapper('wasm_outline_get_title'));
		var _wasm_outline_get_uri = (Module['_wasm_outline_get_uri'] = createExportWrapper('wasm_outline_get_uri'));
		var _wasm_outline_get_next = (Module['_wasm_outline_get_next'] = createExportWrapper('wasm_outline_get_next'));
		var _wasm_outline_get_down = (Module['_wasm_outline_get_down'] = createExportWrapper('wasm_outline_get_down'));
		var _wasm_outline_get_is_open = (Module['_wasm_outline_get_is_open'] =
			createExportWrapper('wasm_outline_get_is_open'));
		var _wasm_outline_item_get_title = (Module['_wasm_outline_item_get_title'] =
			createExportWrapper('wasm_outline_item_get_title'));
		var _wasm_outline_item_get_uri = (Module['_wasm_outline_item_get_uri'] =
			createExportWrapper('wasm_outline_item_get_uri'));
		var _wasm_outline_item_get_is_open = (Module['_wasm_outline_item_get_is_open'] = createExportWrapper(
			'wasm_outline_item_get_is_open',
		));
		var _wasm_link_get_rect = (Module['_wasm_link_get_rect'] = createExportWrapper('wasm_link_get_rect'));
		var _wasm_link_get_uri = (Module['_wasm_link_get_uri'] = createExportWrapper('wasm_link_get_uri'));
		var _wasm_link_get_next = (Module['_wasm_link_get_next'] = createExportWrapper('wasm_link_get_next'));
		var _wasm_stext_page_get_mediabox = (Module['_wasm_stext_page_get_mediabox'] =
			createExportWrapper('wasm_stext_page_get_mediabox'));
		var _wasm_stext_page_get_first_block = (Module['_wasm_stext_page_get_first_block'] = createExportWrapper(
			'wasm_stext_page_get_first_block',
		));
		var _wasm_stext_block_get_next = (Module['_wasm_stext_block_get_next'] =
			createExportWrapper('wasm_stext_block_get_next'));
		var _wasm_stext_block_get_type = (Module['_wasm_stext_block_get_type'] =
			createExportWrapper('wasm_stext_block_get_type'));
		var _wasm_stext_block_get_bbox = (Module['_wasm_stext_block_get_bbox'] =
			createExportWrapper('wasm_stext_block_get_bbox'));
		var _wasm_stext_block_get_first_line = (Module['_wasm_stext_block_get_first_line'] = createExportWrapper(
			'wasm_stext_block_get_first_line',
		));
		var _wasm_stext_block_get_transform = (Module['_wasm_stext_block_get_transform'] = createExportWrapper(
			'wasm_stext_block_get_transform',
		));
		var _wasm_stext_block_get_image = (Module['_wasm_stext_block_get_image'] =
			createExportWrapper('wasm_stext_block_get_image'));
		var _wasm_stext_line_get_next = (Module['_wasm_stext_line_get_next'] =
			createExportWrapper('wasm_stext_line_get_next'));
		var _wasm_stext_line_get_wmode = (Module['_wasm_stext_line_get_wmode'] =
			createExportWrapper('wasm_stext_line_get_wmode'));
		var _wasm_stext_line_get_dir = (Module['_wasm_stext_line_get_dir'] =
			createExportWrapper('wasm_stext_line_get_dir'));
		var _wasm_stext_line_get_bbox = (Module['_wasm_stext_line_get_bbox'] =
			createExportWrapper('wasm_stext_line_get_bbox'));
		var _wasm_stext_line_get_first_char = (Module['_wasm_stext_line_get_first_char'] = createExportWrapper(
			'wasm_stext_line_get_first_char',
		));
		var _wasm_stext_char_get_next = (Module['_wasm_stext_char_get_next'] =
			createExportWrapper('wasm_stext_char_get_next'));
		var _wasm_stext_char_get_c = (Module['_wasm_stext_char_get_c'] = createExportWrapper('wasm_stext_char_get_c'));
		var _wasm_stext_char_get_origin = (Module['_wasm_stext_char_get_origin'] =
			createExportWrapper('wasm_stext_char_get_origin'));
		var _wasm_stext_char_get_quad = (Module['_wasm_stext_char_get_quad'] =
			createExportWrapper('wasm_stext_char_get_quad'));
		var _wasm_stext_char_get_size = (Module['_wasm_stext_char_get_size'] =
			createExportWrapper('wasm_stext_char_get_size'));
		var _wasm_stext_char_get_font = (Module['_wasm_stext_char_get_font'] =
			createExportWrapper('wasm_stext_char_get_font'));
		var _wasm_link_dest_get_chapter = (Module['_wasm_link_dest_get_chapter'] =
			createExportWrapper('wasm_link_dest_get_chapter'));
		var _wasm_link_dest_get_page = (Module['_wasm_link_dest_get_page'] =
			createExportWrapper('wasm_link_dest_get_page'));
		var _wasm_link_dest_get_type = (Module['_wasm_link_dest_get_type'] =
			createExportWrapper('wasm_link_dest_get_type'));
		var _wasm_link_dest_get_x = (Module['_wasm_link_dest_get_x'] = createExportWrapper('wasm_link_dest_get_x'));
		var _wasm_link_dest_get_y = (Module['_wasm_link_dest_get_y'] = createExportWrapper('wasm_link_dest_get_y'));
		var _wasm_link_dest_get_w = (Module['_wasm_link_dest_get_w'] = createExportWrapper('wasm_link_dest_get_w'));
		var _wasm_link_dest_get_h = (Module['_wasm_link_dest_get_h'] = createExportWrapper('wasm_link_dest_get_h'));
		var _wasm_link_dest_get_zoom = (Module['_wasm_link_dest_get_zoom'] =
			createExportWrapper('wasm_link_dest_get_zoom'));
		var _wasm_pdf_embedded_file_params_get_filename = (Module['_wasm_pdf_embedded_file_params_get_filename'] =
			createExportWrapper('wasm_pdf_embedded_file_params_get_filename'));
		var _wasm_pdf_embedded_file_params_get_mimetype = (Module['_wasm_pdf_embedded_file_params_get_mimetype'] =
			createExportWrapper('wasm_pdf_embedded_file_params_get_mimetype'));
		var _wasm_pdf_embedded_file_params_get_size = (Module['_wasm_pdf_embedded_file_params_get_size'] =
			createExportWrapper('wasm_pdf_embedded_file_params_get_size'));
		var _wasm_pdf_embedded_file_params_get_created = (Module['_wasm_pdf_embedded_file_params_get_created'] =
			createExportWrapper('wasm_pdf_embedded_file_params_get_created'));
		var _wasm_pdf_embedded_file_params_get_modified = (Module['_wasm_pdf_embedded_file_params_get_modified'] =
			createExportWrapper('wasm_pdf_embedded_file_params_get_modified'));
		var _wasm_pdf_page_get_obj = (Module['_wasm_pdf_page_get_obj'] = createExportWrapper('wasm_pdf_page_get_obj'));
		var _wasm_new_buffer = (Module['_wasm_new_buffer'] = createExportWrapper('wasm_new_buffer'));
		var _wasm_new_buffer_from_data = (Module['_wasm_new_buffer_from_data'] =
			createExportWrapper('wasm_new_buffer_from_data'));
		var _wasm_append_string = (Module['_wasm_append_string'] = createExportWrapper('wasm_append_string'));
		var _wasm_append_byte = (Module['_wasm_append_byte'] = createExportWrapper('wasm_append_byte'));
		var _wasm_append_buffer = (Module['_wasm_append_buffer'] = createExportWrapper('wasm_append_buffer'));
		var _wasm_slice_buffer = (Module['_wasm_slice_buffer'] = createExportWrapper('wasm_slice_buffer'));
		var _wasm_string_from_buffer = (Module['_wasm_string_from_buffer'] =
			createExportWrapper('wasm_string_from_buffer'));
		var _wasm_device_gray = (Module['_wasm_device_gray'] = createExportWrapper('wasm_device_gray'));
		var _wasm_device_rgb = (Module['_wasm_device_rgb'] = createExportWrapper('wasm_device_rgb'));
		var _wasm_device_bgr = (Module['_wasm_device_bgr'] = createExportWrapper('wasm_device_bgr'));
		var _wasm_device_cmyk = (Module['_wasm_device_cmyk'] = createExportWrapper('wasm_device_cmyk'));
		var _wasm_device_lab = (Module['_wasm_device_lab'] = createExportWrapper('wasm_device_lab'));
		var _wasm_new_icc_colorspace = (Module['_wasm_new_icc_colorspace'] =
			createExportWrapper('wasm_new_icc_colorspace'));
		var _wasm_new_stroke_state = (Module['_wasm_new_stroke_state'] = createExportWrapper('wasm_new_stroke_state'));
		var _wasm_new_base14_font = (Module['_wasm_new_base14_font'] = createExportWrapper('wasm_new_base14_font'));
		var _wasm_new_font_from_buffer = (Module['_wasm_new_font_from_buffer'] =
			createExportWrapper('wasm_new_font_from_buffer'));
		var _wasm_encode_character = (Module['_wasm_encode_character'] = createExportWrapper('wasm_encode_character'));
		var _wasm_advance_glyph = (Module['_wasm_advance_glyph'] = createExportWrapper('wasm_advance_glyph'));
		var _wasm_font_is_monospaced = (Module['_wasm_font_is_monospaced'] =
			createExportWrapper('wasm_font_is_monospaced'));
		var _wasm_font_is_serif = (Module['_wasm_font_is_serif'] = createExportWrapper('wasm_font_is_serif'));
		var _wasm_font_is_bold = (Module['_wasm_font_is_bold'] = createExportWrapper('wasm_font_is_bold'));
		var _wasm_font_is_italic = (Module['_wasm_font_is_italic'] = createExportWrapper('wasm_font_is_italic'));
		var _wasm_new_image_from_pixmap = (Module['_wasm_new_image_from_pixmap'] =
			createExportWrapper('wasm_new_image_from_pixmap'));
		var _wasm_new_image_from_buffer = (Module['_wasm_new_image_from_buffer'] =
			createExportWrapper('wasm_new_image_from_buffer'));
		var _wasm_get_pixmap_from_image = (Module['_wasm_get_pixmap_from_image'] =
			createExportWrapper('wasm_get_pixmap_from_image'));
		var _wasm_new_pixmap_from_page = (Module['_wasm_new_pixmap_from_page'] =
			createExportWrapper('wasm_new_pixmap_from_page'));
		var _wasm_new_pixmap_from_page_contents = (Module['_wasm_new_pixmap_from_page_contents'] = createExportWrapper(
			'wasm_new_pixmap_from_page_contents',
		));
		var _wasm_pdf_new_pixmap_from_page_with_usage = (Module['_wasm_pdf_new_pixmap_from_page_with_usage'] =
			createExportWrapper('wasm_pdf_new_pixmap_from_page_with_usage'));
		var _wasm_pdf_new_pixmap_from_page_contents_with_usage = (Module[
			'_wasm_pdf_new_pixmap_from_page_contents_with_usage'
		] = createExportWrapper('wasm_pdf_new_pixmap_from_page_contents_with_usage'));
		var _wasm_new_pixmap_with_bbox = (Module['_wasm_new_pixmap_with_bbox'] =
			createExportWrapper('wasm_new_pixmap_with_bbox'));
		var _wasm_clear_pixmap = (Module['_wasm_clear_pixmap'] = createExportWrapper('wasm_clear_pixmap'));
		var _wasm_clear_pixmap_with_value = (Module['_wasm_clear_pixmap_with_value'] =
			createExportWrapper('wasm_clear_pixmap_with_value'));
		var _wasm_invert_pixmap = (Module['_wasm_invert_pixmap'] = createExportWrapper('wasm_invert_pixmap'));
		var _wasm_invert_pixmap_luminance = (Module['_wasm_invert_pixmap_luminance'] =
			createExportWrapper('wasm_invert_pixmap_luminance'));
		var _wasm_gamma_pixmap = (Module['_wasm_gamma_pixmap'] = createExportWrapper('wasm_gamma_pixmap'));
		var _wasm_tint_pixmap = (Module['_wasm_tint_pixmap'] = createExportWrapper('wasm_tint_pixmap'));
		var _wasm_new_buffer_from_pixmap_as_png = (Module['_wasm_new_buffer_from_pixmap_as_png'] = createExportWrapper(
			'wasm_new_buffer_from_pixmap_as_png',
		));
		var _wasm_new_buffer_from_pixmap_as_pam = (Module['_wasm_new_buffer_from_pixmap_as_pam'] = createExportWrapper(
			'wasm_new_buffer_from_pixmap_as_pam',
		));
		var _wasm_new_buffer_from_pixmap_as_psd = (Module['_wasm_new_buffer_from_pixmap_as_psd'] = createExportWrapper(
			'wasm_new_buffer_from_pixmap_as_psd',
		));
		var _wasm_new_buffer_from_pixmap_as_jpeg = (Module['_wasm_new_buffer_from_pixmap_as_jpeg'] =
			createExportWrapper('wasm_new_buffer_from_pixmap_as_jpeg'));
		var _wasm_convert_pixmap = (Module['_wasm_convert_pixmap'] = createExportWrapper('wasm_convert_pixmap'));
		var _wasm_warp_pixmap = (Module['_wasm_warp_pixmap'] = createExportWrapper('wasm_warp_pixmap'));
		var _wasm_bound_shade = (Module['_wasm_bound_shade'] = createExportWrapper('wasm_bound_shade'));
		var _wasm_new_display_list = (Module['_wasm_new_display_list'] = createExportWrapper('wasm_new_display_list'));
		var _wasm_bound_display_list = (Module['_wasm_bound_display_list'] =
			createExportWrapper('wasm_bound_display_list'));
		var _wasm_run_display_list = (Module['_wasm_run_display_list'] = createExportWrapper('wasm_run_display_list'));
		var _wasm_new_pixmap_from_display_list = (Module['_wasm_new_pixmap_from_display_list'] = createExportWrapper(
			'wasm_new_pixmap_from_display_list',
		));
		var _wasm_new_stext_page_from_display_list = (Module['_wasm_new_stext_page_from_display_list'] =
			createExportWrapper('wasm_new_stext_page_from_display_list'));
		var _wasm_search_display_list = (Module['_wasm_search_display_list'] =
			createExportWrapper('wasm_search_display_list'));
		var _wasm_new_path = (Module['_wasm_new_path'] = createExportWrapper('wasm_new_path'));
		var _wasm_moveto = (Module['_wasm_moveto'] = createExportWrapper('wasm_moveto'));
		var _wasm_lineto = (Module['_wasm_lineto'] = createExportWrapper('wasm_lineto'));
		var _wasm_curveto = (Module['_wasm_curveto'] = createExportWrapper('wasm_curveto'));
		var _wasm_curvetov = (Module['_wasm_curvetov'] = createExportWrapper('wasm_curvetov'));
		var _wasm_curvetoy = (Module['_wasm_curvetoy'] = createExportWrapper('wasm_curvetoy'));
		var _wasm_closepath = (Module['_wasm_closepath'] = createExportWrapper('wasm_closepath'));
		var _wasm_rectto = (Module['_wasm_rectto'] = createExportWrapper('wasm_rectto'));
		var _wasm_transform_path = (Module['_wasm_transform_path'] = createExportWrapper('wasm_transform_path'));
		var _wasm_bound_path = (Module['_wasm_bound_path'] = createExportWrapper('wasm_bound_path'));
		var _wasm_new_text = (Module['_wasm_new_text'] = createExportWrapper('wasm_new_text'));
		var _wasm_bound_text = (Module['_wasm_bound_text'] = createExportWrapper('wasm_bound_text'));
		var _wasm_show_glyph = (Module['_wasm_show_glyph'] = createExportWrapper('wasm_show_glyph'));
		var _wasm_show_string = (Module['_wasm_show_string'] = createExportWrapper('wasm_show_string'));
		var _wasm_new_draw_device = (Module['_wasm_new_draw_device'] = createExportWrapper('wasm_new_draw_device'));
		var _wasm_new_display_list_device = (Module['_wasm_new_display_list_device'] =
			createExportWrapper('wasm_new_display_list_device'));
		var _wasm_close_device = (Module['_wasm_close_device'] = createExportWrapper('wasm_close_device'));
		var _wasm_fill_path = (Module['_wasm_fill_path'] = createExportWrapper('wasm_fill_path'));
		var _wasm_stroke_path = (Module['_wasm_stroke_path'] = createExportWrapper('wasm_stroke_path'));
		var _wasm_clip_path = (Module['_wasm_clip_path'] = createExportWrapper('wasm_clip_path'));
		var _wasm_clip_stroke_path = (Module['_wasm_clip_stroke_path'] = createExportWrapper('wasm_clip_stroke_path'));
		var _wasm_fill_text = (Module['_wasm_fill_text'] = createExportWrapper('wasm_fill_text'));
		var _wasm_stroke_text = (Module['_wasm_stroke_text'] = createExportWrapper('wasm_stroke_text'));
		var _wasm_clip_text = (Module['_wasm_clip_text'] = createExportWrapper('wasm_clip_text'));
		var _wasm_clip_stroke_text = (Module['_wasm_clip_stroke_text'] = createExportWrapper('wasm_clip_stroke_text'));
		var _wasm_ignore_text = (Module['_wasm_ignore_text'] = createExportWrapper('wasm_ignore_text'));
		var _wasm_fill_shade = (Module['_wasm_fill_shade'] = createExportWrapper('wasm_fill_shade'));
		var _wasm_fill_image = (Module['_wasm_fill_image'] = createExportWrapper('wasm_fill_image'));
		var _wasm_fill_image_mask = (Module['_wasm_fill_image_mask'] = createExportWrapper('wasm_fill_image_mask'));
		var _wasm_clip_image_mask = (Module['_wasm_clip_image_mask'] = createExportWrapper('wasm_clip_image_mask'));
		var _wasm_pop_clip = (Module['_wasm_pop_clip'] = createExportWrapper('wasm_pop_clip'));
		var _wasm_begin_mask = (Module['_wasm_begin_mask'] = createExportWrapper('wasm_begin_mask'));
		var _wasm_end_mask = (Module['_wasm_end_mask'] = createExportWrapper('wasm_end_mask'));
		var _wasm_begin_group = (Module['_wasm_begin_group'] = createExportWrapper('wasm_begin_group'));
		var _wasm_end_group = (Module['_wasm_end_group'] = createExportWrapper('wasm_end_group'));
		var _wasm_begin_tile = (Module['_wasm_begin_tile'] = createExportWrapper('wasm_begin_tile'));
		var _wasm_end_tile = (Module['_wasm_end_tile'] = createExportWrapper('wasm_end_tile'));
		var _wasm_begin_layer = (Module['_wasm_begin_layer'] = createExportWrapper('wasm_begin_layer'));
		var _wasm_end_layer = (Module['_wasm_end_layer'] = createExportWrapper('wasm_end_layer'));
		var _wasm_new_document_writer_with_buffer = (Module['_wasm_new_document_writer_with_buffer'] =
			createExportWrapper('wasm_new_document_writer_with_buffer'));
		var _wasm_begin_page = (Module['_wasm_begin_page'] = createExportWrapper('wasm_begin_page'));
		var _wasm_end_page = (Module['_wasm_end_page'] = createExportWrapper('wasm_end_page'));
		var _wasm_close_document_writer = (Module['_wasm_close_document_writer'] =
			createExportWrapper('wasm_close_document_writer'));
		var _wasm_print_stext_page_as_json = (Module['_wasm_print_stext_page_as_json'] = createExportWrapper(
			'wasm_print_stext_page_as_json',
		));
		var _wasm_search_stext_page = (Module['_wasm_search_stext_page'] =
			createExportWrapper('wasm_search_stext_page'));
		var _wasm_copy_selection = (Module['_wasm_copy_selection'] = createExportWrapper('wasm_copy_selection'));
		var _wasm_highlight_selection = (Module['_wasm_highlight_selection'] =
			createExportWrapper('wasm_highlight_selection'));
		var _wasm_print_stext_page_as_html = (Module['_wasm_print_stext_page_as_html'] = createExportWrapper(
			'wasm_print_stext_page_as_html',
		));
		var _wasm_print_stext_page_as_text = (Module['_wasm_print_stext_page_as_text'] = createExportWrapper(
			'wasm_print_stext_page_as_text',
		));
		var _wasm_open_document_with_buffer = (Module['_wasm_open_document_with_buffer'] = createExportWrapper(
			'wasm_open_document_with_buffer',
		));
		var _wasm_open_document_with_stream = (Module['_wasm_open_document_with_stream'] = createExportWrapper(
			'wasm_open_document_with_stream',
		));
		var _wasm_format_link_uri = (Module['_wasm_format_link_uri'] = createExportWrapper('wasm_format_link_uri'));
		var _wasm_needs_password = (Module['_wasm_needs_password'] = createExportWrapper('wasm_needs_password'));
		var _wasm_authenticate_password = (Module['_wasm_authenticate_password'] =
			createExportWrapper('wasm_authenticate_password'));
		var _wasm_has_permission = (Module['_wasm_has_permission'] = createExportWrapper('wasm_has_permission'));
		var _wasm_count_pages = (Module['_wasm_count_pages'] = createExportWrapper('wasm_count_pages'));
		var _wasm_load_page = (Module['_wasm_load_page'] = createExportWrapper('wasm_load_page'));
		var _wasm_lookup_metadata = (Module['_wasm_lookup_metadata'] = createExportWrapper('wasm_lookup_metadata'));
		var _wasm_set_metadata = (Module['_wasm_set_metadata'] = createExportWrapper('wasm_set_metadata'));
		var _wasm_resolve_link = (Module['_wasm_resolve_link'] = createExportWrapper('wasm_resolve_link'));
		var _wasm_resolve_link_dest = (Module['_wasm_resolve_link_dest'] =
			createExportWrapper('wasm_resolve_link_dest'));
		var _wasm_load_outline = (Module['_wasm_load_outline'] = createExportWrapper('wasm_load_outline'));
		var _wasm_outline_get_page = (Module['_wasm_outline_get_page'] = createExportWrapper('wasm_outline_get_page'));
		var _wasm_layout_document = (Module['_wasm_layout_document'] = createExportWrapper('wasm_layout_document'));
		var _wasm_is_document_reflowable = (Module['_wasm_is_document_reflowable'] =
			createExportWrapper('wasm_is_document_reflowable'));
		var _wasm_link_set_rect = (Module['_wasm_link_set_rect'] = createExportWrapper('wasm_link_set_rect'));
		var _wasm_link_set_uri = (Module['_wasm_link_set_uri'] = createExportWrapper('wasm_link_set_uri'));
		var _wasm_bound_page = (Module['_wasm_bound_page'] = createExportWrapper('wasm_bound_page'));
		var _wasm_load_links = (Module['_wasm_load_links'] = createExportWrapper('wasm_load_links'));
		var _wasm_create_link = (Module['_wasm_create_link'] = createExportWrapper('wasm_create_link'));
		var _wasm_delete_link = (Module['_wasm_delete_link'] = createExportWrapper('wasm_delete_link'));
		var _wasm_run_page = (Module['_wasm_run_page'] = createExportWrapper('wasm_run_page'));
		var _wasm_run_page_contents = (Module['_wasm_run_page_contents'] =
			createExportWrapper('wasm_run_page_contents'));
		var _wasm_run_page_annots = (Module['_wasm_run_page_annots'] = createExportWrapper('wasm_run_page_annots'));
		var _wasm_run_page_widgets = (Module['_wasm_run_page_widgets'] = createExportWrapper('wasm_run_page_widgets'));
		var _wasm_new_stext_page_from_page = (Module['_wasm_new_stext_page_from_page'] = createExportWrapper(
			'wasm_new_stext_page_from_page',
		));
		var _wasm_new_display_list_from_page = (Module['_wasm_new_display_list_from_page'] = createExportWrapper(
			'wasm_new_display_list_from_page',
		));
		var _wasm_new_display_list_from_page_contents = (Module['_wasm_new_display_list_from_page_contents'] =
			createExportWrapper('wasm_new_display_list_from_page_contents'));
		var _wasm_page_label = (Module['_wasm_page_label'] = createExportWrapper('wasm_page_label'));
		var _wasm_search_page = (Module['_wasm_search_page'] = createExportWrapper('wasm_search_page'));
		var _wasm_new_outline_iterator = (Module['_wasm_new_outline_iterator'] =
			createExportWrapper('wasm_new_outline_iterator'));
		var _wasm_outline_iterator_next = (Module['_wasm_outline_iterator_next'] =
			createExportWrapper('wasm_outline_iterator_next'));
		var _wasm_outline_iterator_prev = (Module['_wasm_outline_iterator_prev'] =
			createExportWrapper('wasm_outline_iterator_prev'));
		var _wasm_outline_iterator_up = (Module['_wasm_outline_iterator_up'] =
			createExportWrapper('wasm_outline_iterator_up'));
		var _wasm_outline_iterator_down = (Module['_wasm_outline_iterator_down'] =
			createExportWrapper('wasm_outline_iterator_down'));
		var _wasm_outline_iterator_delete = (Module['_wasm_outline_iterator_delete'] =
			createExportWrapper('wasm_outline_iterator_delete'));
		var _wasm_outline_iterator_item = (Module['_wasm_outline_iterator_item'] =
			createExportWrapper('wasm_outline_iterator_item'));
		var _wasm_outline_iterator_insert = (Module['_wasm_outline_iterator_insert'] =
			createExportWrapper('wasm_outline_iterator_insert'));
		var _wasm_outline_iterator_update = (Module['_wasm_outline_iterator_update'] =
			createExportWrapper('wasm_outline_iterator_update'));
		var _wasm_pdf_document_from_fz_document = (Module['_wasm_pdf_document_from_fz_document'] = createExportWrapper(
			'wasm_pdf_document_from_fz_document',
		));
		var _wasm_pdf_page_from_fz_page = (Module['_wasm_pdf_page_from_fz_page'] =
			createExportWrapper('wasm_pdf_page_from_fz_page'));
		var _wasm_pdf_create_document = (Module['_wasm_pdf_create_document'] =
			createExportWrapper('wasm_pdf_create_document'));
		var _wasm_pdf_version = (Module['_wasm_pdf_version'] = createExportWrapper('wasm_pdf_version'));
		var _wasm_pdf_was_repaired = (Module['_wasm_pdf_was_repaired'] = createExportWrapper('wasm_pdf_was_repaired'));
		var _wasm_pdf_has_unsaved_changes = (Module['_wasm_pdf_has_unsaved_changes'] =
			createExportWrapper('wasm_pdf_has_unsaved_changes'));
		var _wasm_pdf_can_be_saved_incrementally = (Module['_wasm_pdf_can_be_saved_incrementally'] =
			createExportWrapper('wasm_pdf_can_be_saved_incrementally'));
		var _wasm_pdf_count_versions = (Module['_wasm_pdf_count_versions'] =
			createExportWrapper('wasm_pdf_count_versions'));
		var _wasm_pdf_count_unsaved_versions = (Module['_wasm_pdf_count_unsaved_versions'] = createExportWrapper(
			'wasm_pdf_count_unsaved_versions',
		));
		var _wasm_pdf_validate_change_history = (Module['_wasm_pdf_validate_change_history'] = createExportWrapper(
			'wasm_pdf_validate_change_history',
		));
		var _wasm_pdf_enable_journal = (Module['_wasm_pdf_enable_journal'] =
			createExportWrapper('wasm_pdf_enable_journal'));
		var _wasm_pdf_undoredo_state_position = (Module['_wasm_pdf_undoredo_state_position'] = createExportWrapper(
			'wasm_pdf_undoredo_state_position',
		));
		var _wasm_pdf_undoredo_state_count = (Module['_wasm_pdf_undoredo_state_count'] = createExportWrapper(
			'wasm_pdf_undoredo_state_count',
		));
		var _wasm_pdf_undoredo_step = (Module['_wasm_pdf_undoredo_step'] =
			createExportWrapper('wasm_pdf_undoredo_step'));
		var _wasm_pdf_begin_operation = (Module['_wasm_pdf_begin_operation'] =
			createExportWrapper('wasm_pdf_begin_operation'));
		var _wasm_pdf_begin_implicit_operation = (Module['_wasm_pdf_begin_implicit_operation'] = createExportWrapper(
			'wasm_pdf_begin_implicit_operation',
		));
		var _wasm_pdf_end_operation = (Module['_wasm_pdf_end_operation'] =
			createExportWrapper('wasm_pdf_end_operation'));
		var _wasm_pdf_abandon_operation = (Module['_wasm_pdf_abandon_operation'] =
			createExportWrapper('wasm_pdf_abandon_operation'));
		var _wasm_pdf_undo = (Module['_wasm_pdf_undo'] = createExportWrapper('wasm_pdf_undo'));
		var _wasm_pdf_redo = (Module['_wasm_pdf_redo'] = createExportWrapper('wasm_pdf_redo'));
		var _wasm_pdf_can_undo = (Module['_wasm_pdf_can_undo'] = createExportWrapper('wasm_pdf_can_undo'));
		var _wasm_pdf_can_redo = (Module['_wasm_pdf_can_redo'] = createExportWrapper('wasm_pdf_can_redo'));
		var _wasm_pdf_document_language = (Module['_wasm_pdf_document_language'] =
			createExportWrapper('wasm_pdf_document_language'));
		var _wasm_pdf_set_document_language = (Module['_wasm_pdf_set_document_language'] = createExportWrapper(
			'wasm_pdf_set_document_language',
		));
		var _wasm_pdf_trailer = (Module['_wasm_pdf_trailer'] = createExportWrapper('wasm_pdf_trailer'));
		var _wasm_pdf_xref_len = (Module['_wasm_pdf_xref_len'] = createExportWrapper('wasm_pdf_xref_len'));
		var _wasm_pdf_lookup_page_obj = (Module['_wasm_pdf_lookup_page_obj'] =
			createExportWrapper('wasm_pdf_lookup_page_obj'));
		var _wasm_pdf_add_object = (Module['_wasm_pdf_add_object'] = createExportWrapper('wasm_pdf_add_object'));
		var _wasm_pdf_create_object = (Module['_wasm_pdf_create_object'] =
			createExportWrapper('wasm_pdf_create_object'));
		var _wasm_pdf_delete_object = (Module['_wasm_pdf_delete_object'] =
			createExportWrapper('wasm_pdf_delete_object'));
		var _wasm_pdf_add_stream = (Module['_wasm_pdf_add_stream'] = createExportWrapper('wasm_pdf_add_stream'));
		var _wasm_pdf_add_simple_font = (Module['_wasm_pdf_add_simple_font'] =
			createExportWrapper('wasm_pdf_add_simple_font'));
		var _wasm_pdf_add_cjk_font = (Module['_wasm_pdf_add_cjk_font'] = createExportWrapper('wasm_pdf_add_cjk_font'));
		var _wasm_pdf_add_cid_font = (Module['_wasm_pdf_add_cid_font'] = createExportWrapper('wasm_pdf_add_cid_font'));
		var _wasm_pdf_add_image = (Module['_wasm_pdf_add_image'] = createExportWrapper('wasm_pdf_add_image'));
		var _wasm_pdf_load_image = (Module['_wasm_pdf_load_image'] = createExportWrapper('wasm_pdf_load_image'));
		var _wasm_pdf_add_page = (Module['_wasm_pdf_add_page'] = createExportWrapper('wasm_pdf_add_page'));
		var _wasm_pdf_insert_page = (Module['_wasm_pdf_insert_page'] = createExportWrapper('wasm_pdf_insert_page'));
		var _wasm_pdf_delete_page = (Module['_wasm_pdf_delete_page'] = createExportWrapper('wasm_pdf_delete_page'));
		var _wasm_pdf_set_page_labels = (Module['_wasm_pdf_set_page_labels'] =
			createExportWrapper('wasm_pdf_set_page_labels'));
		var _wasm_pdf_delete_page_labels = (Module['_wasm_pdf_delete_page_labels'] =
			createExportWrapper('wasm_pdf_delete_page_labels'));
		var _wasm_pdf_is_embedded_file = (Module['_wasm_pdf_is_embedded_file'] =
			createExportWrapper('wasm_pdf_is_embedded_file'));
		var _wasm_pdf_get_embedded_file_params = (Module['_wasm_pdf_get_embedded_file_params'] = createExportWrapper(
			'wasm_pdf_get_embedded_file_params',
		));
		var _wasm_pdf_add_embedded_file = (Module['_wasm_pdf_add_embedded_file'] =
			createExportWrapper('wasm_pdf_add_embedded_file'));
		var _wasm_pdf_load_embedded_file_contents = (Module['_wasm_pdf_load_embedded_file_contents'] =
			createExportWrapper('wasm_pdf_load_embedded_file_contents'));
		var _wasm_pdf_write_document_buffer = (Module['_wasm_pdf_write_document_buffer'] = createExportWrapper(
			'wasm_pdf_write_document_buffer',
		));
		var _wasm_pdf_js_supported = (Module['_wasm_pdf_js_supported'] = createExportWrapper('wasm_pdf_js_supported'));
		var _wasm_pdf_enable_js = (Module['_wasm_pdf_enable_js'] = createExportWrapper('wasm_pdf_enable_js'));
		var _wasm_pdf_disable_js = (Module['_wasm_pdf_disable_js'] = createExportWrapper('wasm_pdf_disable_js'));
		var _wasm_pdf_rearrange_pages = (Module['_wasm_pdf_rearrange_pages'] =
			createExportWrapper('wasm_pdf_rearrange_pages'));
		var _wasm_pdf_bake_document = (Module['_wasm_pdf_bake_document'] =
			createExportWrapper('wasm_pdf_bake_document'));
		var _wasm_pdf_page_transform = (Module['_wasm_pdf_page_transform'] =
			createExportWrapper('wasm_pdf_page_transform'));
		var _wasm_pdf_set_page_box = (Module['_wasm_pdf_set_page_box'] = createExportWrapper('wasm_pdf_set_page_box'));
		var _wasm_pdf_first_annot = (Module['_wasm_pdf_first_annot'] = createExportWrapper('wasm_pdf_first_annot'));
		var _wasm_pdf_next_annot = (Module['_wasm_pdf_next_annot'] = createExportWrapper('wasm_pdf_next_annot'));
		var _wasm_pdf_first_widget = (Module['_wasm_pdf_first_widget'] = createExportWrapper('wasm_pdf_first_widget'));
		var _wasm_pdf_next_widget = (Module['_wasm_pdf_next_widget'] = createExportWrapper('wasm_pdf_next_widget'));
		var _wasm_pdf_create_annot = (Module['_wasm_pdf_create_annot'] = createExportWrapper('wasm_pdf_create_annot'));
		var _wasm_pdf_delete_annot = (Module['_wasm_pdf_delete_annot'] = createExportWrapper('wasm_pdf_delete_annot'));
		var _wasm_pdf_update_page = (Module['_wasm_pdf_update_page'] = createExportWrapper('wasm_pdf_update_page'));
		var _wasm_pdf_redact_page = (Module['_wasm_pdf_redact_page'] = createExportWrapper('wasm_pdf_redact_page'));
		var _wasm_pdf_new_graft_map = (Module['_wasm_pdf_new_graft_map'] =
			createExportWrapper('wasm_pdf_new_graft_map'));
		var _wasm_pdf_graft_mapped_object = (Module['_wasm_pdf_graft_mapped_object'] =
			createExportWrapper('wasm_pdf_graft_mapped_object'));
		var _wasm_pdf_graft_object = (Module['_wasm_pdf_graft_object'] = createExportWrapper('wasm_pdf_graft_object'));
		var _wasm_pdf_graft_mapped_page = (Module['_wasm_pdf_graft_mapped_page'] =
			createExportWrapper('wasm_pdf_graft_mapped_page'));
		var _wasm_pdf_graft_page = (Module['_wasm_pdf_graft_page'] = createExportWrapper('wasm_pdf_graft_page'));
		var _wasm_pdf_bound_annot = (Module['_wasm_pdf_bound_annot'] = createExportWrapper('wasm_pdf_bound_annot'));
		var _wasm_pdf_run_annot = (Module['_wasm_pdf_run_annot'] = createExportWrapper('wasm_pdf_run_annot'));
		var _wasm_pdf_new_pixmap_from_annot = (Module['_wasm_pdf_new_pixmap_from_annot'] = createExportWrapper(
			'wasm_pdf_new_pixmap_from_annot',
		));
		var _wasm_pdf_new_display_list_from_annot = (Module['_wasm_pdf_new_display_list_from_annot'] =
			createExportWrapper('wasm_pdf_new_display_list_from_annot'));
		var _wasm_pdf_update_annot = (Module['_wasm_pdf_update_annot'] = createExportWrapper('wasm_pdf_update_annot'));
		var _wasm_pdf_annot_obj = (Module['_wasm_pdf_annot_obj'] = createExportWrapper('wasm_pdf_annot_obj'));
		var _wasm_pdf_annot_type = (Module['_wasm_pdf_annot_type'] = createExportWrapper('wasm_pdf_annot_type'));
		var _wasm_pdf_annot_flags = (Module['_wasm_pdf_annot_flags'] = createExportWrapper('wasm_pdf_annot_flags'));
		var _wasm_pdf_set_annot_flags = (Module['_wasm_pdf_set_annot_flags'] =
			createExportWrapper('wasm_pdf_set_annot_flags'));
		var _wasm_pdf_annot_contents = (Module['_wasm_pdf_annot_contents'] =
			createExportWrapper('wasm_pdf_annot_contents'));
		var _wasm_pdf_set_annot_contents = (Module['_wasm_pdf_set_annot_contents'] =
			createExportWrapper('wasm_pdf_set_annot_contents'));
		var _wasm_pdf_annot_author = (Module['_wasm_pdf_annot_author'] = createExportWrapper('wasm_pdf_annot_author'));
		var _wasm_pdf_set_annot_author = (Module['_wasm_pdf_set_annot_author'] =
			createExportWrapper('wasm_pdf_set_annot_author'));
		var _wasm_pdf_annot_creation_date = (Module['_wasm_pdf_annot_creation_date'] =
			createExportWrapper('wasm_pdf_annot_creation_date'));
		var _wasm_pdf_set_annot_creation_date = (Module['_wasm_pdf_set_annot_creation_date'] = createExportWrapper(
			'wasm_pdf_set_annot_creation_date',
		));
		var _wasm_pdf_annot_modification_date = (Module['_wasm_pdf_annot_modification_date'] = createExportWrapper(
			'wasm_pdf_annot_modification_date',
		));
		var _wasm_pdf_set_annot_modification_date = (Module['_wasm_pdf_set_annot_modification_date'] =
			createExportWrapper('wasm_pdf_set_annot_modification_date'));
		var _wasm_pdf_annot_border_width = (Module['_wasm_pdf_annot_border_width'] =
			createExportWrapper('wasm_pdf_annot_border_width'));
		var _wasm_pdf_set_annot_border_width = (Module['_wasm_pdf_set_annot_border_width'] = createExportWrapper(
			'wasm_pdf_set_annot_border_width',
		));
		var _wasm_pdf_annot_border_style = (Module['_wasm_pdf_annot_border_style'] =
			createExportWrapper('wasm_pdf_annot_border_style'));
		var _wasm_pdf_set_annot_border_style = (Module['_wasm_pdf_set_annot_border_style'] = createExportWrapper(
			'wasm_pdf_set_annot_border_style',
		));
		var _wasm_pdf_annot_border_effect = (Module['_wasm_pdf_annot_border_effect'] =
			createExportWrapper('wasm_pdf_annot_border_effect'));
		var _wasm_pdf_set_annot_border_effect = (Module['_wasm_pdf_set_annot_border_effect'] = createExportWrapper(
			'wasm_pdf_set_annot_border_effect',
		));
		var _wasm_pdf_annot_border_effect_intensity = (Module['_wasm_pdf_annot_border_effect_intensity'] =
			createExportWrapper('wasm_pdf_annot_border_effect_intensity'));
		var _wasm_pdf_set_annot_border_effect_intensity = (Module['_wasm_pdf_set_annot_border_effect_intensity'] =
			createExportWrapper('wasm_pdf_set_annot_border_effect_intensity'));
		var _wasm_pdf_annot_opacity = (Module['_wasm_pdf_annot_opacity'] =
			createExportWrapper('wasm_pdf_annot_opacity'));
		var _wasm_pdf_set_annot_opacity = (Module['_wasm_pdf_set_annot_opacity'] =
			createExportWrapper('wasm_pdf_set_annot_opacity'));
		var _wasm_pdf_annot_filespec = (Module['_wasm_pdf_annot_filespec'] =
			createExportWrapper('wasm_pdf_annot_filespec'));
		var _wasm_pdf_set_annot_filespec = (Module['_wasm_pdf_set_annot_filespec'] =
			createExportWrapper('wasm_pdf_set_annot_filespec'));
		var _wasm_pdf_annot_quadding = (Module['_wasm_pdf_annot_quadding'] =
			createExportWrapper('wasm_pdf_annot_quadding'));
		var _wasm_pdf_set_annot_quadding = (Module['_wasm_pdf_set_annot_quadding'] =
			createExportWrapper('wasm_pdf_set_annot_quadding'));
		var _wasm_pdf_annot_is_open = (Module['_wasm_pdf_annot_is_open'] =
			createExportWrapper('wasm_pdf_annot_is_open'));
		var _wasm_pdf_set_annot_is_open = (Module['_wasm_pdf_set_annot_is_open'] =
			createExportWrapper('wasm_pdf_set_annot_is_open'));
		var _wasm_pdf_annot_hidden_for_editing = (Module['_wasm_pdf_annot_hidden_for_editing'] = createExportWrapper(
			'wasm_pdf_annot_hidden_for_editing',
		));
		var _wasm_pdf_set_annot_hidden_for_editing = (Module['_wasm_pdf_set_annot_hidden_for_editing'] =
			createExportWrapper('wasm_pdf_set_annot_hidden_for_editing'));
		var _wasm_pdf_annot_icon_name = (Module['_wasm_pdf_annot_icon_name'] =
			createExportWrapper('wasm_pdf_annot_icon_name'));
		var _wasm_pdf_set_annot_icon_name = (Module['_wasm_pdf_set_annot_icon_name'] =
			createExportWrapper('wasm_pdf_set_annot_icon_name'));
		var _wasm_pdf_annot_intent = (Module['_wasm_pdf_annot_intent'] = createExportWrapper('wasm_pdf_annot_intent'));
		var _wasm_pdf_set_annot_intent = (Module['_wasm_pdf_set_annot_intent'] =
			createExportWrapper('wasm_pdf_set_annot_intent'));
		var _wasm_pdf_annot_rect = (Module['_wasm_pdf_annot_rect'] = createExportWrapper('wasm_pdf_annot_rect'));
		var _wasm_pdf_annot_popup = (Module['_wasm_pdf_annot_popup'] = createExportWrapper('wasm_pdf_annot_popup'));
		var _wasm_pdf_annot_quad_point_count = (Module['_wasm_pdf_annot_quad_point_count'] = createExportWrapper(
			'wasm_pdf_annot_quad_point_count',
		));
		var _wasm_pdf_annot_quad_point = (Module['_wasm_pdf_annot_quad_point'] =
			createExportWrapper('wasm_pdf_annot_quad_point'));
		var _wasm_pdf_annot_vertex_count = (Module['_wasm_pdf_annot_vertex_count'] =
			createExportWrapper('wasm_pdf_annot_vertex_count'));
		var _wasm_pdf_annot_vertex = (Module['_wasm_pdf_annot_vertex'] = createExportWrapper('wasm_pdf_annot_vertex'));
		var _wasm_pdf_annot_ink_list_count = (Module['_wasm_pdf_annot_ink_list_count'] = createExportWrapper(
			'wasm_pdf_annot_ink_list_count',
		));
		var _wasm_pdf_annot_ink_list_stroke_count = (Module['_wasm_pdf_annot_ink_list_stroke_count'] =
			createExportWrapper('wasm_pdf_annot_ink_list_stroke_count'));
		var _wasm_pdf_annot_ink_list_stroke_vertex = (Module['_wasm_pdf_annot_ink_list_stroke_vertex'] =
			createExportWrapper('wasm_pdf_annot_ink_list_stroke_vertex'));
		var _wasm_pdf_annot_border_dash_count = (Module['_wasm_pdf_annot_border_dash_count'] = createExportWrapper(
			'wasm_pdf_annot_border_dash_count',
		));
		var _wasm_pdf_annot_border_dash_item = (Module['_wasm_pdf_annot_border_dash_item'] = createExportWrapper(
			'wasm_pdf_annot_border_dash_item',
		));
		var _wasm_pdf_annot_has_rect = (Module['_wasm_pdf_annot_has_rect'] =
			createExportWrapper('wasm_pdf_annot_has_rect'));
		var _wasm_pdf_annot_has_ink_list = (Module['_wasm_pdf_annot_has_ink_list'] =
			createExportWrapper('wasm_pdf_annot_has_ink_list'));
		var _wasm_pdf_annot_has_quad_points = (Module['_wasm_pdf_annot_has_quad_points'] = createExportWrapper(
			'wasm_pdf_annot_has_quad_points',
		));
		var _wasm_pdf_annot_has_vertices = (Module['_wasm_pdf_annot_has_vertices'] =
			createExportWrapper('wasm_pdf_annot_has_vertices'));
		var _wasm_pdf_annot_has_line = (Module['_wasm_pdf_annot_has_line'] =
			createExportWrapper('wasm_pdf_annot_has_line'));
		var _wasm_pdf_annot_has_interior_color = (Module['_wasm_pdf_annot_has_interior_color'] = createExportWrapper(
			'wasm_pdf_annot_has_interior_color',
		));
		var _wasm_pdf_annot_has_line_ending_styles = (Module['_wasm_pdf_annot_has_line_ending_styles'] =
			createExportWrapper('wasm_pdf_annot_has_line_ending_styles'));
		var _wasm_pdf_annot_has_border = (Module['_wasm_pdf_annot_has_border'] =
			createExportWrapper('wasm_pdf_annot_has_border'));
		var _wasm_pdf_annot_has_border_effect = (Module['_wasm_pdf_annot_has_border_effect'] = createExportWrapper(
			'wasm_pdf_annot_has_border_effect',
		));
		var _wasm_pdf_annot_has_icon_name = (Module['_wasm_pdf_annot_has_icon_name'] =
			createExportWrapper('wasm_pdf_annot_has_icon_name'));
		var _wasm_pdf_annot_has_open = (Module['_wasm_pdf_annot_has_open'] =
			createExportWrapper('wasm_pdf_annot_has_open'));
		var _wasm_pdf_annot_has_author = (Module['_wasm_pdf_annot_has_author'] =
			createExportWrapper('wasm_pdf_annot_has_author'));
		var _wasm_pdf_annot_has_filespec = (Module['_wasm_pdf_annot_has_filespec'] =
			createExportWrapper('wasm_pdf_annot_has_filespec'));
		var _wasm_pdf_annot_language = (Module['_wasm_pdf_annot_language'] =
			createExportWrapper('wasm_pdf_annot_language'));
		var _wasm_pdf_set_annot_language = (Module['_wasm_pdf_set_annot_language'] =
			createExportWrapper('wasm_pdf_set_annot_language'));
		var _wasm_pdf_set_annot_popup = (Module['_wasm_pdf_set_annot_popup'] =
			createExportWrapper('wasm_pdf_set_annot_popup'));
		var _wasm_pdf_set_annot_rect = (Module['_wasm_pdf_set_annot_rect'] =
			createExportWrapper('wasm_pdf_set_annot_rect'));
		var _wasm_pdf_clear_annot_quad_points = (Module['_wasm_pdf_clear_annot_quad_points'] = createExportWrapper(
			'wasm_pdf_clear_annot_quad_points',
		));
		var _wasm_pdf_clear_annot_vertices = (Module['_wasm_pdf_clear_annot_vertices'] = createExportWrapper(
			'wasm_pdf_clear_annot_vertices',
		));
		var _wasm_pdf_clear_annot_ink_list = (Module['_wasm_pdf_clear_annot_ink_list'] = createExportWrapper(
			'wasm_pdf_clear_annot_ink_list',
		));
		var _wasm_pdf_clear_annot_border_dash = (Module['_wasm_pdf_clear_annot_border_dash'] = createExportWrapper(
			'wasm_pdf_clear_annot_border_dash',
		));
		var _wasm_pdf_add_annot_quad_point = (Module['_wasm_pdf_add_annot_quad_point'] = createExportWrapper(
			'wasm_pdf_add_annot_quad_point',
		));
		var _wasm_pdf_add_annot_vertex = (Module['_wasm_pdf_add_annot_vertex'] =
			createExportWrapper('wasm_pdf_add_annot_vertex'));
		var _wasm_pdf_add_annot_ink_list_stroke = (Module['_wasm_pdf_add_annot_ink_list_stroke'] = createExportWrapper(
			'wasm_pdf_add_annot_ink_list_stroke',
		));
		var _wasm_pdf_add_annot_ink_list_stroke_vertex = (Module['_wasm_pdf_add_annot_ink_list_stroke_vertex'] =
			createExportWrapper('wasm_pdf_add_annot_ink_list_stroke_vertex'));
		var _wasm_pdf_add_annot_border_dash_item = (Module['_wasm_pdf_add_annot_border_dash_item'] =
			createExportWrapper('wasm_pdf_add_annot_border_dash_item'));
		var _wasm_pdf_annot_line_ending_styles_start = (Module['_wasm_pdf_annot_line_ending_styles_start'] =
			createExportWrapper('wasm_pdf_annot_line_ending_styles_start'));
		var _wasm_pdf_annot_line_1 = (Module['_wasm_pdf_annot_line_1'] = createExportWrapper('wasm_pdf_annot_line_1'));
		var _wasm_pdf_annot_line_2 = (Module['_wasm_pdf_annot_line_2'] = createExportWrapper('wasm_pdf_annot_line_2'));
		var _wasm_pdf_set_annot_line = (Module['_wasm_pdf_set_annot_line'] =
			createExportWrapper('wasm_pdf_set_annot_line'));
		var _wasm_pdf_annot_line_ending_styles_end = (Module['_wasm_pdf_annot_line_ending_styles_end'] =
			createExportWrapper('wasm_pdf_annot_line_ending_styles_end'));
		var _wasm_pdf_set_annot_line_ending_styles = (Module['_wasm_pdf_set_annot_line_ending_styles'] =
			createExportWrapper('wasm_pdf_set_annot_line_ending_styles'));
		var _wasm_pdf_annot_color = (Module['_wasm_pdf_annot_color'] = createExportWrapper('wasm_pdf_annot_color'));
		var _wasm_pdf_annot_interior_color = (Module['_wasm_pdf_annot_interior_color'] = createExportWrapper(
			'wasm_pdf_annot_interior_color',
		));
		var _wasm_pdf_set_annot_color = (Module['_wasm_pdf_set_annot_color'] =
			createExportWrapper('wasm_pdf_set_annot_color'));
		var _wasm_pdf_set_annot_interior_color = (Module['_wasm_pdf_set_annot_interior_color'] = createExportWrapper(
			'wasm_pdf_set_annot_interior_color',
		));
		var _wasm_pdf_set_annot_default_appearance = (Module['_wasm_pdf_set_annot_default_appearance'] =
			createExportWrapper('wasm_pdf_set_annot_default_appearance'));
		var _wasm_pdf_annot_default_appearance_font = (Module['_wasm_pdf_annot_default_appearance_font'] =
			createExportWrapper('wasm_pdf_annot_default_appearance_font'));
		var _wasm_pdf_annot_default_appearance_size = (Module['_wasm_pdf_annot_default_appearance_size'] =
			createExportWrapper('wasm_pdf_annot_default_appearance_size'));
		var _wasm_pdf_annot_default_appearance_color = (Module['_wasm_pdf_annot_default_appearance_color'] =
			createExportWrapper('wasm_pdf_annot_default_appearance_color'));
		var _wasm_pdf_set_annot_appearance_from_display_list = (Module[
			'_wasm_pdf_set_annot_appearance_from_display_list'
		] = createExportWrapper('wasm_pdf_set_annot_appearance_from_display_list'));
		var _wasm_pdf_set_annot_appearance = (Module['_wasm_pdf_set_annot_appearance'] = createExportWrapper(
			'wasm_pdf_set_annot_appearance',
		));
		var _wasm_pdf_apply_redaction = (Module['_wasm_pdf_apply_redaction'] =
			createExportWrapper('wasm_pdf_apply_redaction'));
		var _wasm_pdf_annot_field_type = (Module['_wasm_pdf_annot_field_type'] =
			createExportWrapper('wasm_pdf_annot_field_type'));
		var _wasm_pdf_annot_field_flags = (Module['_wasm_pdf_annot_field_flags'] =
			createExportWrapper('wasm_pdf_annot_field_flags'));
		var _wasm_pdf_annot_field_label = (Module['_wasm_pdf_annot_field_label'] =
			createExportWrapper('wasm_pdf_annot_field_label'));
		var _wasm_pdf_annot_field_value = (Module['_wasm_pdf_annot_field_value'] =
			createExportWrapper('wasm_pdf_annot_field_value'));
		var _wasm_pdf_load_field_name = (Module['_wasm_pdf_load_field_name'] =
			createExportWrapper('wasm_pdf_load_field_name'));
		var _wasm_pdf_annot_text_widget_max_len = (Module['_wasm_pdf_annot_text_widget_max_len'] = createExportWrapper(
			'wasm_pdf_annot_text_widget_max_len',
		));
		var _wasm_pdf_set_annot_text_field_value = (Module['_wasm_pdf_set_annot_text_field_value'] =
			createExportWrapper('wasm_pdf_set_annot_text_field_value'));
		var _wasm_pdf_set_annot_choice_field_value = (Module['_wasm_pdf_set_annot_choice_field_value'] =
			createExportWrapper('wasm_pdf_set_annot_choice_field_value'));
		var _wasm_pdf_annot_choice_field_option_count = (Module['_wasm_pdf_annot_choice_field_option_count'] =
			createExportWrapper('wasm_pdf_annot_choice_field_option_count'));
		var _wasm_pdf_annot_choice_field_option = (Module['_wasm_pdf_annot_choice_field_option'] = createExportWrapper(
			'wasm_pdf_annot_choice_field_option',
		));
		var _wasm_pdf_toggle_widget = (Module['_wasm_pdf_toggle_widget'] =
			createExportWrapper('wasm_pdf_toggle_widget'));
		var _wasm_pdf_is_indirect = (Module['_wasm_pdf_is_indirect'] = createExportWrapper('wasm_pdf_is_indirect'));
		var _wasm_pdf_is_bool = (Module['_wasm_pdf_is_bool'] = createExportWrapper('wasm_pdf_is_bool'));
		var _wasm_pdf_is_int = (Module['_wasm_pdf_is_int'] = createExportWrapper('wasm_pdf_is_int'));
		var _wasm_pdf_is_number = (Module['_wasm_pdf_is_number'] = createExportWrapper('wasm_pdf_is_number'));
		var _wasm_pdf_is_name = (Module['_wasm_pdf_is_name'] = createExportWrapper('wasm_pdf_is_name'));
		var _wasm_pdf_is_string = (Module['_wasm_pdf_is_string'] = createExportWrapper('wasm_pdf_is_string'));
		var _wasm_pdf_is_array = (Module['_wasm_pdf_is_array'] = createExportWrapper('wasm_pdf_is_array'));
		var _wasm_pdf_is_dict = (Module['_wasm_pdf_is_dict'] = createExportWrapper('wasm_pdf_is_dict'));
		var _wasm_pdf_is_stream = (Module['_wasm_pdf_is_stream'] = createExportWrapper('wasm_pdf_is_stream'));
		var _wasm_pdf_to_num = (Module['_wasm_pdf_to_num'] = createExportWrapper('wasm_pdf_to_num'));
		var _wasm_pdf_to_bool = (Module['_wasm_pdf_to_bool'] = createExportWrapper('wasm_pdf_to_bool'));
		var _wasm_pdf_to_real = (Module['_wasm_pdf_to_real'] = createExportWrapper('wasm_pdf_to_real'));
		var _wasm_pdf_to_name = (Module['_wasm_pdf_to_name'] = createExportWrapper('wasm_pdf_to_name'));
		var _wasm_pdf_to_text_string = (Module['_wasm_pdf_to_text_string'] =
			createExportWrapper('wasm_pdf_to_text_string'));
		var _wasm_pdf_new_indirect = (Module['_wasm_pdf_new_indirect'] = createExportWrapper('wasm_pdf_new_indirect'));
		var _wasm_pdf_new_array = (Module['_wasm_pdf_new_array'] = createExportWrapper('wasm_pdf_new_array'));
		var _wasm_pdf_new_dict = (Module['_wasm_pdf_new_dict'] = createExportWrapper('wasm_pdf_new_dict'));
		var _wasm_pdf_new_bool = (Module['_wasm_pdf_new_bool'] = createExportWrapper('wasm_pdf_new_bool'));
		var _wasm_pdf_new_int = (Module['_wasm_pdf_new_int'] = createExportWrapper('wasm_pdf_new_int'));
		var _wasm_pdf_new_real = (Module['_wasm_pdf_new_real'] = createExportWrapper('wasm_pdf_new_real'));
		var _wasm_pdf_new_name = (Module['_wasm_pdf_new_name'] = createExportWrapper('wasm_pdf_new_name'));
		var _wasm_pdf_new_text_string = (Module['_wasm_pdf_new_text_string'] =
			createExportWrapper('wasm_pdf_new_text_string'));
		var _wasm_pdf_new_string = (Module['_wasm_pdf_new_string'] = createExportWrapper('wasm_pdf_new_string'));
		var _wasm_pdf_resolve_indirect = (Module['_wasm_pdf_resolve_indirect'] =
			createExportWrapper('wasm_pdf_resolve_indirect'));
		var _wasm_pdf_array_len = (Module['_wasm_pdf_array_len'] = createExportWrapper('wasm_pdf_array_len'));
		var _wasm_pdf_array_get = (Module['_wasm_pdf_array_get'] = createExportWrapper('wasm_pdf_array_get'));
		var _wasm_pdf_dict_get = (Module['_wasm_pdf_dict_get'] = createExportWrapper('wasm_pdf_dict_get'));
		var _wasm_pdf_dict_len = (Module['_wasm_pdf_dict_len'] = createExportWrapper('wasm_pdf_dict_len'));
		var _wasm_pdf_dict_get_key = (Module['_wasm_pdf_dict_get_key'] = createExportWrapper('wasm_pdf_dict_get_key'));
		var _wasm_pdf_dict_get_val = (Module['_wasm_pdf_dict_get_val'] = createExportWrapper('wasm_pdf_dict_get_val'));
		var _wasm_pdf_dict_get_inheritable = (Module['_wasm_pdf_dict_get_inheritable'] = createExportWrapper(
			'wasm_pdf_dict_get_inheritable',
		));
		var _wasm_pdf_dict_gets = (Module['_wasm_pdf_dict_gets'] = createExportWrapper('wasm_pdf_dict_gets'));
		var _wasm_pdf_dict_gets_inheritable = (Module['_wasm_pdf_dict_gets_inheritable'] = createExportWrapper(
			'wasm_pdf_dict_gets_inheritable',
		));
		var _wasm_pdf_dict_put = (Module['_wasm_pdf_dict_put'] = createExportWrapper('wasm_pdf_dict_put'));
		var _wasm_pdf_dict_puts = (Module['_wasm_pdf_dict_puts'] = createExportWrapper('wasm_pdf_dict_puts'));
		var _wasm_pdf_dict_del = (Module['_wasm_pdf_dict_del'] = createExportWrapper('wasm_pdf_dict_del'));
		var _wasm_pdf_dict_dels = (Module['_wasm_pdf_dict_dels'] = createExportWrapper('wasm_pdf_dict_dels'));
		var _wasm_pdf_array_put = (Module['_wasm_pdf_array_put'] = createExportWrapper('wasm_pdf_array_put'));
		var _wasm_pdf_array_push = (Module['_wasm_pdf_array_push'] = createExportWrapper('wasm_pdf_array_push'));
		var _wasm_pdf_array_delete = (Module['_wasm_pdf_array_delete'] = createExportWrapper('wasm_pdf_array_delete'));
		var _wasm_pdf_sprint_obj = (Module['_wasm_pdf_sprint_obj'] = createExportWrapper('wasm_pdf_sprint_obj'));
		var _wasm_pdf_load_stream = (Module['_wasm_pdf_load_stream'] = createExportWrapper('wasm_pdf_load_stream'));
		var _wasm_pdf_load_raw_stream = (Module['_wasm_pdf_load_raw_stream'] =
			createExportWrapper('wasm_pdf_load_raw_stream'));
		var _wasm_pdf_update_object = (Module['_wasm_pdf_update_object'] =
			createExportWrapper('wasm_pdf_update_object'));
		var _wasm_pdf_update_stream = (Module['_wasm_pdf_update_stream'] =
			createExportWrapper('wasm_pdf_update_stream'));
		var _wasm_pdf_to_string = (Module['_wasm_pdf_to_string'] = createExportWrapper('wasm_pdf_to_string'));
		var _wasm_new_stream = (Module['_wasm_new_stream'] = createExportWrapper('wasm_new_stream'));
		var _fflush = createExportWrapper('fflush');
		var _setThrew = createExportWrapper('setThrew');
		var _emscripten_stack_init = () => (_emscripten_stack_init = wasmExports['emscripten_stack_init'])();
		var _emscripten_stack_get_free = () =>
			(_emscripten_stack_get_free = wasmExports['emscripten_stack_get_free'])();
		var _emscripten_stack_get_base = () =>
			(_emscripten_stack_get_base = wasmExports['emscripten_stack_get_base'])();
		var _emscripten_stack_get_end = () => (_emscripten_stack_get_end = wasmExports['emscripten_stack_get_end'])();
		var stackSave = createExportWrapper('stackSave');
		var stackRestore = createExportWrapper('stackRestore');
		var stackAlloc = createExportWrapper('stackAlloc');
		var _emscripten_stack_get_current = () =>
			(_emscripten_stack_get_current = wasmExports['emscripten_stack_get_current'])();
		var dynCall_iiijj = (Module['dynCall_iiijj'] = createExportWrapper('dynCall_iiijj'));
		var dynCall_iiiiiijji = (Module['dynCall_iiiiiijji'] = createExportWrapper('dynCall_iiiiiijji'));
		var dynCall_jii = (Module['dynCall_jii'] = createExportWrapper('dynCall_jii'));
		var dynCall_viij = (Module['dynCall_viij'] = createExportWrapper('dynCall_viij'));
		var dynCall_iij = (Module['dynCall_iij'] = createExportWrapper('dynCall_iij'));
		var dynCall_viiji = (Module['dynCall_viiji'] = createExportWrapper('dynCall_viiji'));
		var dynCall_ji = (Module['dynCall_ji'] = createExportWrapper('dynCall_ji'));
		var dynCall_viiijjiijjjjiiiiiiii = (Module['dynCall_viiijjiijjjjiiiiiiii'] =
			createExportWrapper('dynCall_viiijjiijjjjiiiiiiii'));
		var dynCall_jji = (Module['dynCall_jji'] = createExportWrapper('dynCall_jji'));
		var dynCall_iji = (Module['dynCall_iji'] = createExportWrapper('dynCall_iji'));
		var dynCall_vij = (Module['dynCall_vij'] = createExportWrapper('dynCall_vij'));
		var dynCall_jiii = (Module['dynCall_jiii'] = createExportWrapper('dynCall_jiii'));
		var dynCall_iiiij = (Module['dynCall_iiiij'] = createExportWrapper('dynCall_iiiij'));
		var dynCall_viiij = (Module['dynCall_viiij'] = createExportWrapper('dynCall_viiij'));
		var dynCall_iiiiiiiij = (Module['dynCall_iiiiiiiij'] = createExportWrapper('dynCall_iiiiiiiij'));
		var dynCall_viiiij = (Module['dynCall_viiiij'] = createExportWrapper('dynCall_viiiij'));
		var dynCall_viiiiiijj = (Module['dynCall_viiiiiijj'] = createExportWrapper('dynCall_viiiiiijj'));
		var dynCall_jiij = (Module['dynCall_jiij'] = createExportWrapper('dynCall_jiij'));
		var dynCall_iiij = (Module['dynCall_iiij'] = createExportWrapper('dynCall_iiij'));
		var dynCall_iiiiij = (Module['dynCall_iiiiij'] = createExportWrapper('dynCall_iiiiij'));
		var dynCall_iiji = (Module['dynCall_iiji'] = createExportWrapper('dynCall_iiji'));
		var dynCall_jiji = (Module['dynCall_jiji'] = createExportWrapper('dynCall_jiji'));

		function invoke_ii(index, a1) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iii(index, a1, a2) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_vi(index, a1) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_vii(index, a1, a2) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiii(index, a1, a2, a3) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viii(index, a1, a2, a3) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiii(index, a1, a2, a3, a4, a5) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_fiiii(index, a1, a2, a3, a4) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiiii(index, a1, a2, a3, a4, a5, a6) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiiiii(index, a1, a2, a3, a4, a5, a6, a7) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viif(index, a1, a2, a3) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiii(index, a1, a2, a3, a4) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiiii(index, a1, a2, a3, a4, a5, a6) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiff(index, a1, a2, a3, a4) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiffffff(index, a1, a2, a3, a4, a5, a6, a7, a8) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiffff(index, a1, a2, a3, a4, a5, a6) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiii(index, a1, a2, a3, a4, a5) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiiiiiiii(index, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8, a9, a10);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiiiiifi(index, a1, a2, a3, a4, a5, a6, a7, a8, a9) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8, a9);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiiiifi(index, a1, a2, a3, a4, a5, a6, a7, a8) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiifi(index, a1, a2, a3, a4, a5, a6) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiiiii(index, a1, a2, a3, a4, a5, a6, a7) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiiiiif(index, a1, a2, a3, a4, a5, a6, a7, a8) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiiffii(index, a1, a2, a3, a4, a5, a6, a7, a8) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiii(index, a1, a2, a3, a4) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiif(index, a1, a2, a3, a4) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viifff(index, a1, a2, a3, a4, a5) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_fii(index, a1, a2) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_fiii(index, a1, a2, a3) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiifii(index, a1, a2, a3, a4, a5, a6) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiiiiii(index, a1, a2, a3, a4, a5, a6, a7, a8) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iif(index, a1, a2) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiidi(index, a1, a2, a3, a4) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiiiiiiii(index, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8, a9, a10);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viff(index, a1, a2, a3) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiifiiii(index, a1, a2, a3, a4, a5, a6, a7, a8, a9) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8, a9);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiiiiii(index, a1, a2, a3, a4, a5, a6, a7, a8) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiiiiiii(index, a1, a2, a3, a4, a5, a6, a7, a8, a9) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8, a9);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiffffiii(index, a1, a2, a3, a4, a5, a6, a7, a8, a9) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8, a9);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiiiiiii(index, a1, a2, a3, a4, a5, a6, a7, a8, a9) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8, a9);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_fiiiii(index, a1, a2, a3, a4, a5) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiffiiiiiii(index, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_fi(index, a1) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_v(index) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)();
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiiiiiiiiiiiiii(
			index,
			a1,
			a2,
			a3,
			a4,
			a5,
			a6,
			a7,
			a8,
			a9,
			a10,
			a11,
			a12,
			a13,
			a14,
			a15,
			a16,
		) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12, a13, a14, a15, a16);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiiiiiiiiiii(index, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12, a13) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12, a13);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiiiiiiiii(index, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_fiffff(index, a1, a2, a3, a4, a5) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_ffi(index, a1, a2) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viffi(index, a1, a2, a3, a4) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_vif(index, a1, a2) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiid(index, a1, a2, a3, a4) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiifii(index, a1, a2, a3, a4, a5, a6, a7) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viid(index, a1, a2, a3) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_fiiif(index, a1, a2, a3, a4) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_fiiifiif(index, a1, a2, a3, a4, a5, a6, a7) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiffff(index, a1, a2, a3, a4, a5, a6, a7) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiiiiifiiifffffiii(
			index,
			a1,
			a2,
			a3,
			a4,
			a5,
			a6,
			a7,
			a8,
			a9,
			a10,
			a11,
			a12,
			a13,
			a14,
			a15,
			a16,
			a17,
			a18,
			a19,
		) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(
					a1,
					a2,
					a3,
					a4,
					a5,
					a6,
					a7,
					a8,
					a9,
					a10,
					a11,
					a12,
					a13,
					a14,
					a15,
					a16,
					a17,
					a18,
					a19,
				);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiifffffiii(index, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiiffiifiii(index, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiiffi(index, a1, a2, a3, a4, a5, a6, a7) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiiifii(index, a1, a2, a3, a4, a5, a6, a7, a8) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiiff(index, a1, a2, a3, a4, a5, a6) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viifi(index, a1, a2, a3, a4) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiiiifi(index, a1, a2, a3, a4, a5, a6, a7, a8) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiif(index, a1, a2, a3, a4) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiffii(index, a1, a2, a3, a4, a5, a6) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiif(index, a1, a2, a3, a4, a5) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiifi(index, a1, a2, a3, a4, a5) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiiiiiiiii(index, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11) {
			var sp = stackSave();
			try {
				return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiiiff(index, a1, a2, a3, a4, a5, a6, a7) {
			var sp = stackSave();
			try {
				getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiijj(index, a1, a2, a3, a4, a5, a6) {
			var sp = stackSave();
			try {
				return dynCall_iiijj(index, a1, a2, a3, a4, a5, a6);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiiijji(index, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10) {
			var sp = stackSave();
			try {
				return dynCall_iiiiiijji(index, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_jii(index, a1, a2) {
			var sp = stackSave();
			try {
				return dynCall_jii(index, a1, a2);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viij(index, a1, a2, a3, a4) {
			var sp = stackSave();
			try {
				dynCall_viij(index, a1, a2, a3, a4);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iij(index, a1, a2, a3) {
			var sp = stackSave();
			try {
				return dynCall_iij(index, a1, a2, a3);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_ji(index, a1) {
			var sp = stackSave();
			try {
				return dynCall_ji(index, a1);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiji(index, a1, a2, a3, a4, a5) {
			var sp = stackSave();
			try {
				dynCall_viiji(index, a1, a2, a3, a4, a5);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_vij(index, a1, a2, a3) {
			var sp = stackSave();
			try {
				dynCall_vij(index, a1, a2, a3);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_jiii(index, a1, a2, a3) {
			var sp = stackSave();
			try {
				return dynCall_jiii(index, a1, a2, a3);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiij(index, a1, a2, a3, a4, a5) {
			var sp = stackSave();
			try {
				return dynCall_iiiij(index, a1, a2, a3, a4, a5);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiij(index, a1, a2, a3, a4, a5) {
			var sp = stackSave();
			try {
				dynCall_viiij(index, a1, a2, a3, a4, a5);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiij(index, a1, a2, a3, a4, a5, a6) {
			var sp = stackSave();
			try {
				dynCall_viiiij(index, a1, a2, a3, a4, a5, a6);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiiiiij(index, a1, a2, a3, a4, a5, a6, a7, a8, a9) {
			var sp = stackSave();
			try {
				return dynCall_iiiiiiiij(index, a1, a2, a3, a4, a5, a6, a7, a8, a9);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_viiiiiijj(index, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10) {
			var sp = stackSave();
			try {
				dynCall_viiiiiijj(index, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_jiij(index, a1, a2, a3, a4) {
			var sp = stackSave();
			try {
				return dynCall_jiij(index, a1, a2, a3, a4);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiij(index, a1, a2, a3, a4) {
			var sp = stackSave();
			try {
				return dynCall_iiij(index, a1, a2, a3, a4);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		function invoke_iiiiij(index, a1, a2, a3, a4, a5, a6) {
			var sp = stackSave();
			try {
				return dynCall_iiiiij(index, a1, a2, a3, a4, a5, a6);
			} catch (e) {
				stackRestore(sp);
				if (e !== e + 0) throw e;
				_setThrew(1, 0);
			}
		}

		// include: postamble.js
		// === Auto-generated postamble setup entry stuff ===

		Module['ccall'] = ccall;
		Module['UTF8ToString'] = UTF8ToString;
		Module['stringToUTF8'] = stringToUTF8;
		Module['lengthBytesUTF8'] = lengthBytesUTF8;
		var missingLibrarySymbols = [
			'writeI53ToI64',
			'writeI53ToI64Clamped',
			'writeI53ToI64Signaling',
			'writeI53ToU64Clamped',
			'writeI53ToU64Signaling',
			'readI53FromI64',
			'readI53FromU64',
			'convertI32PairToI53',
			'convertU32PairToI53',
			'ydayFromDate',
			'inetPton4',
			'inetNtop4',
			'inetPton6',
			'inetNtop6',
			'readSockaddr',
			'writeSockaddr',
			'getCallstack',
			'emscriptenLog',
			'convertPCtoSourceLocation',
			'runMainThreadEmAsm',
			'jstoi_q',
			'listenOnce',
			'autoResumeAudioContext',
			'dynCallLegacy',
			'getDynCaller',
			'dynCall',
			'handleException',
			'runtimeKeepalivePush',
			'runtimeKeepalivePop',
			'callUserCallback',
			'maybeExit',
			'asmjsMangle',
			'HandleAllocator',
			'getNativeTypeSize',
			'STACK_SIZE',
			'STACK_ALIGN',
			'POINTER_SIZE',
			'ASSERTIONS',
			'cwrap',
			'uleb128Encode',
			'sigToWasmTypes',
			'generateFuncType',
			'convertJsFunctionToWasm',
			'getEmptyTableSlot',
			'updateTableMap',
			'getFunctionAddress',
			'addFunction',
			'removeFunction',
			'reallyNegative',
			'unSign',
			'strLen',
			'reSign',
			'formatString',
			'intArrayToString',
			'AsciiToString',
			'UTF16ToString',
			'stringToUTF16',
			'lengthBytesUTF16',
			'UTF32ToString',
			'stringToUTF32',
			'lengthBytesUTF32',
			'stringToNewUTF8',
			'registerKeyEventCallback',
			'maybeCStringToJsString',
			'findEventTarget',
			'getBoundingClientRect',
			'fillMouseEventData',
			'registerMouseEventCallback',
			'registerWheelEventCallback',
			'registerUiEventCallback',
			'registerFocusEventCallback',
			'fillDeviceOrientationEventData',
			'registerDeviceOrientationEventCallback',
			'fillDeviceMotionEventData',
			'registerDeviceMotionEventCallback',
			'screenOrientation',
			'fillOrientationChangeEventData',
			'registerOrientationChangeEventCallback',
			'fillFullscreenChangeEventData',
			'registerFullscreenChangeEventCallback',
			'JSEvents_requestFullscreen',
			'JSEvents_resizeCanvasForFullscreen',
			'registerRestoreOldStyle',
			'hideEverythingExceptGivenElement',
			'restoreHiddenElements',
			'setLetterbox',
			'softFullscreenResizeWebGLRenderTarget',
			'doRequestFullscreen',
			'fillPointerlockChangeEventData',
			'registerPointerlockChangeEventCallback',
			'registerPointerlockErrorEventCallback',
			'requestPointerLock',
			'fillVisibilityChangeEventData',
			'registerVisibilityChangeEventCallback',
			'registerTouchEventCallback',
			'fillGamepadEventData',
			'registerGamepadEventCallback',
			'registerBeforeUnloadEventCallback',
			'fillBatteryEventData',
			'battery',
			'registerBatteryEventCallback',
			'setCanvasElementSize',
			'getCanvasElementSize',
			'jsStackTrace',
			'stackTrace',
			'checkWasiClock',
			'wasiRightsToMuslOFlags',
			'wasiOFlagsToMuslOFlags',
			'createDyncallWrapper',
			'safeSetTimeout',
			'setImmediateWrapped',
			'clearImmediateWrapped',
			'polyfillSetImmediate',
			'getPromise',
			'makePromise',
			'idsToPromises',
			'makePromiseCallback',
			'ExceptionInfo',
			'findMatchingCatch',
			'Browser_asyncPrepareDataCounter',
			'setMainLoop',
			'getSocketFromFD',
			'getSocketAddress',
			'FS_unlink',
			'FS_mkdirTree',
			'_setNetworkCallback',
			'heapObjectForWebGLType',
			'toTypedArrayIndex',
			'webgl_enable_ANGLE_instanced_arrays',
			'webgl_enable_OES_vertex_array_object',
			'webgl_enable_WEBGL_draw_buffers',
			'webgl_enable_WEBGL_multi_draw',
			'emscriptenWebGLGet',
			'computeUnpackAlignedImageSize',
			'colorChannelsInGlTextureFormat',
			'emscriptenWebGLGetTexPixelData',
			'emscriptenWebGLGetUniform',
			'webglGetUniformLocation',
			'webglPrepareUniformLocationsBeforeFirstUse',
			'webglGetLeftBracePos',
			'emscriptenWebGLGetVertexAttrib',
			'__glGetActiveAttribOrUniform',
			'writeGLArray',
			'registerWebGlEventCallback',
			'runAndAbortIfError',
			'ALLOC_NORMAL',
			'ALLOC_STACK',
			'allocate',
			'writeStringToMemory',
			'writeAsciiToMemory',
			'setErrNo',
			'demangle',
		];
		missingLibrarySymbols.forEach(missingLibrarySymbol);

		var unexportedSymbols = [
			'run',
			'addOnPreRun',
			'addOnInit',
			'addOnPreMain',
			'addOnExit',
			'addOnPostRun',
			'addRunDependency',
			'removeRunDependency',
			'FS_createFolder',
			'FS_createPath',
			'FS_createLazyFile',
			'FS_createLink',
			'FS_createDevice',
			'FS_readFile',
			'out',
			'err',
			'callMain',
			'abort',
			'wasmMemory',
			'wasmExports',
			'stackAlloc',
			'stackSave',
			'stackRestore',
			'getTempRet0',
			'setTempRet0',
			'writeStackCookie',
			'checkStackCookie',
			'convertI32PairToI53Checked',
			'ptrToString',
			'zeroMemory',
			'exitJS',
			'getHeapMax',
			'growMemory',
			'ENV',
			'MONTH_DAYS_REGULAR',
			'MONTH_DAYS_LEAP',
			'MONTH_DAYS_REGULAR_CUMULATIVE',
			'MONTH_DAYS_LEAP_CUMULATIVE',
			'isLeapYear',
			'arraySum',
			'addDays',
			'ERRNO_CODES',
			'ERRNO_MESSAGES',
			'DNS',
			'Protocols',
			'Sockets',
			'initRandomFill',
			'randomFill',
			'timers',
			'warnOnce',
			'UNWIND_CACHE',
			'readEmAsmArgsArray',
			'readEmAsmArgs',
			'runEmAsmFunction',
			'jstoi_s',
			'getExecutableName',
			'keepRuntimeAlive',
			'asyncLoad',
			'alignMemory',
			'mmapAlloc',
			'wasmTable',
			'noExitRuntime',
			'getCFunc',
			'freeTableIndexes',
			'functionsInTableMap',
			'setValue',
			'getValue',
			'PATH',
			'PATH_FS',
			'UTF8Decoder',
			'UTF8ArrayToString',
			'stringToUTF8Array',
			'intArrayFromString',
			'stringToAscii',
			'UTF16Decoder',
			'stringToUTF8OnStack',
			'writeArrayToMemory',
			'JSEvents',
			'specialHTMLTargets',
			'findCanvasEventTarget',
			'currentFullscreenStrategy',
			'restoreOldWindowedStyle',
			'ExitStatus',
			'getEnvStrings',
			'doReadv',
			'doWritev',
			'promiseMap',
			'uncaughtExceptionCount',
			'exceptionLast',
			'exceptionCaught',
			'Browser',
			'getPreloadedImageData__data',
			'wget',
			'SYSCALLS',
			'preloadPlugins',
			'FS_createPreloadedFile',
			'FS_modeStringToFlags',
			'FS_getMode',
			'FS_stdin_getChar_buffer',
			'FS_stdin_getChar',
			'FS',
			'FS_createDataFile',
			'MEMFS',
			'TTY',
			'PIPEFS',
			'SOCKFS',
			'tempFixedLengthArray',
			'miniTempWebGLFloatBuffers',
			'miniTempWebGLIntBuffers',
			'GL',
			'AL',
			'GLUT',
			'EGL',
			'GLEW',
			'IDBStore',
			'SDL',
			'SDL_gfx',
			'allocateUTF8',
			'allocateUTF8OnStack',
		];
		unexportedSymbols.forEach(unexportedRuntimeSymbol);

		var calledRun;

		dependenciesFulfilled = function runCaller() {
			// If run has never been called, and we should call run (INVOKE_RUN is true, and Module.noInitialRun is not false)
			if (!calledRun) run();
			if (!calledRun) dependenciesFulfilled = runCaller; // try this again later, after new deps are fulfilled
		};

		function stackCheckInit() {
			// This is normally called automatically during __wasm_call_ctors but need to
			// get these values before even running any of the ctors so we call it redundantly
			// here.
			_emscripten_stack_init();
			// TODO(sbc): Move writeStackCookie to native to to avoid this.
			writeStackCookie();
		}

		function run() {
			if (runDependencies > 0) {
				return;
			}

			stackCheckInit();

			preRun();

			// a preRun added a dependency, run will be called later
			if (runDependencies > 0) {
				return;
			}

			function doRun() {
				// run may have just been called through dependencies being fulfilled just in this very frame,
				// or while the async setStatus time below was happening
				if (calledRun) return;
				calledRun = true;
				Module['calledRun'] = true;

				if (ABORT) return;

				initRuntime();

				readyPromiseResolve(Module);
				if (Module['onRuntimeInitialized']) Module['onRuntimeInitialized']();

				assert(
					!Module['_main'],
					'compiled without a main, but one is present. if you added it from JS, use Module["onRuntimeInitialized"]',
				);

				postRun();
			}

			if (Module['setStatus']) {
				Module['setStatus']('Running...');
				setTimeout(function () {
					setTimeout(function () {
						Module['setStatus']('');
					}, 1);
					doRun();
				}, 1);
			} else {
				doRun();
			}
			checkStackCookie();
		}

		function checkUnflushedContent() {
			// Compiler settings do not allow exiting the runtime, so flushing
			// the streams is not possible. but in ASSERTIONS mode we check
			// if there was something to flush, and if so tell the user they
			// should request that the runtime be exitable.
			// Normally we would not even include flush() at all, but in ASSERTIONS
			// builds we do so just for this check, and here we see if there is any
			// content to flush, that is, we check if there would have been
			// something a non-ASSERTIONS build would have not seen.
			// How we flush the streams depends on whether we are in SYSCALLS_REQUIRE_FILESYSTEM=0
			// mode (which has its own special function for this; otherwise, all
			// the code is inside libc)
			var oldOut = out;
			var oldErr = err;
			var has = false;
			out = err = (x) => {
				has = true;
			};
			try {
				// it doesn't matter if it fails
				_fflush(0);
				// also flush in the JS FS layer
				['stdout', 'stderr'].forEach(function (name) {
					var info = FS.analyzePath('/dev/' + name);
					if (!info) return;
					var stream = info.object;
					var rdev = stream.rdev;
					var tty = TTY.ttys[rdev];
					if (tty?.output?.length) {
						has = true;
					}
				});
			} catch (e) {}
			out = oldOut;
			err = oldErr;
			if (has) {
				warnOnce(
					'stdio streams had content in them that was not flushed. you should set EXIT_RUNTIME to 1 (see the Emscripten FAQ), or make sure to emit a newline when you printf etc.',
				);
			}
		}

		if (Module['preInit']) {
			if (typeof Module['preInit'] == 'function') Module['preInit'] = [Module['preInit']];
			while (Module['preInit'].length > 0) {
				Module['preInit'].pop()();
			}
		}

		run();

		// end include: postamble.js

		return moduleArg.ready;
	};
})();
export default libmupdf_wasm;
