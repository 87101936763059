/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description custom hook to add pages to draft
 */

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import * as mupdf from '@dexit/common/mupdf/mupdf.js';
import pdfWorkerHelper from 'components/pdf/worker-helper';
import { useIndexingActions, useIndexingValues } from 'store/indexing';

const useAddPagesToDraft = (): [
	(
		draftID: string,
		pagesToBeMoved: any,
		isFilesUploaded?: boolean,
	) => Promise<
		{
			id: string;
			pageNumber: number;
			filePath: string | undefined;
			isSelected: boolean;
		}[]
	>,
] => {
	const { draftDocuments } = useIndexingValues();
	const { updateDraftDocument, uploadFile } = useIndexingActions();

	const addPagesToDraft = async (draftID: string, pagesToBeMoved: any, isFilesUploaded?: boolean) => {
		updateDraftDocument(draftID, { isUpdating: true });
		const draftDoc = _.find(draftDocuments, { id: draftID });
		const pdfBytes = await pdfWorkerHelper.mergePDFToBaseFile(draftDoc?.blobUrl, pagesToBeMoved, isFilesUploaded);
		URL.revokeObjectURL(draftDoc?.blobUrl);
		const blobUrl = URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }));
		uploadFile({ filePath: draftDoc?.filePath ?? '', blobUrl });
		const pdf = new mupdf.PDFDocument(pdfBytes);
		const pageCount = pdf.countPages();
		const newPages = _.map(_.range(pageCount), (index) => ({
			id: uuidv4(),
			pageNumber: index + 1,
			filePath: draftDoc?.filePath,
			isSelected: false,
		}));
		updateDraftDocument(draftID, {
			blobUrl,
			pages: newPages,
			isUpdating: false,
		});
		return newPages;
	};

	return [addPagesToDraft];
};

export default useAddPagesToDraft;
