/**
 * @author Tanay Wagh <tanay.wagh@314ecorp.com>
 * @description Component
 */

import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';

import PageConfigProvider from 'components/page/Provider';

const PAGE_COMPONENTS: Record<string, any> = {
	BatchIndex: import('pages/BatchIndex'),
	Queues: import('pages/Queues'),
	BulkImport: import('pages/BulkImport'),
	ROILogs: import('pages/ROILogs'),
	Reports: import('pages/Reports'),
	Faxes: import('pages/Faxes'),
	Retrieval: import('pages/Retrieval'),
	DocViewer: import('pages/DocViewer'),
	EditIndexInfo: import('pages/EditIndexInfo'),
	CreateUnsignedCopy: import('pages/CreateUnsignedCopy'),
	ReleaseOfInformation: import('pages/ReleaseOfInformation'),
	BulkImportDocuments: import('pages/BulkImportDocuments'),
	SendFaxes: import('pages/SendFaxes'),
	WorkflowTable: import('pages/WorkflowTable'),
	TaskDetails: import('pages/TaskDetails'),
	ViewFaxInfo: import('pages/ViewFaxInfo'),
	Tasks: import('pages/Tasks'),
	Workflows: import('pages/Workflows'),
	Notifications: import('pages/Notifications'),
	AdminSettings: import('pages/AdminSettings'),
	Workspace: import('pages/Workspace'),
	ManageAccess: import('pages/ManageAccess'),
	FaxNumbers: import('pages/FaxNumbers'),
	DocumentTypes: import('pages/DocumentTypes'),
	ManageAttributes: import('pages/ManageAttributes'),
	ManageLists: import('pages/ManageLists'),
	QueuesAdmin: import('pages/QueuesAdmin'),
	Templates: import('pages/Templates'),
	StampSetting: import('pages/StampSetting'),
	CustomAttributeForm: import('pages/CustomAttributeForm'),
	DocumentTypeForm: import('pages/DocumentTypeForm'),
	UserForm: import('pages/UserForm'),
	ManageQueues: import('pages/ManageQueues'),
};

interface IProps extends Record<string, any> {
	item: MenuItem;
}
const LoadablePage: React.FC<IProps> = (props) => {
	const { item, ...restProps } = props;
	const LoadableComponent = lazy(() => (item.component ? PAGE_COMPONENTS[item.component] : <>Not Found</>));
	return (
		<PageConfigProvider config={item}>
			<Suspense fallback={<Spin />}>{LoadableComponent ? <LoadableComponent {...restProps} /> : <></>}</Suspense>
		</PageConfigProvider>
	);
};

export default LoadablePage;
