/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Integration config
 */

import Environment from '@dexit/common/utils/Environment';
import {
	AUTH_URL_INTEGRATION,
	DEFAULT_SERVER_URL,
	HIGHLIGHT_PROJECT_ID,
	MARKER_PROJECT_ID,
	MATOMO_URL_INTEGRATION,
} from '@dexit/common/constants/url';

const hostname = Environment.isLocalhost() ? new URL(DEFAULT_SERVER_URL).hostname : globalThis.location.hostname;
export const realm = hostname.split('.')[0];

const config: EnvConfig = {
	keycloak: {
		config: {
			realm,
			url: AUTH_URL_INTEGRATION,
			clientId: 'dexit',
		},
		initOptions: {
			onLoad: 'login-required',
			checkLoginIframe: false,
			token: '',
			enableLogging: true,
		},
	},

	marker: {
		projectID: MARKER_PROJECT_ID,
	},
	analytics: {
		matomo: {
			urlBase: MATOMO_URL_INTEGRATION,
			siteId: 17,
			// disabled: Environment.isLocalhost(),
		},
	},
	highlight: {
		projectID: HIGHLIGHT_PROJECT_ID,
		debug: {
			manualStart: Environment.isLocalhost() || Environment.isIntegration(),
			environment: Environment.getHighlightEnvironment(),
			networkRecording: { recordHeadersAndBody: true },
		},
	},
	dwt: {
		WebTwainId: 'dwtObject',
		productKey:
			't01898AUAAGa3FVxoH3EpDNZupY1MhKJmJkVAPGWhidDcxEOQXLIZM0eHiNJzcqrtmrYRSSlohkaElefgit6nNY5NPADjY3n1y6kKTivvNJR3soJTj5xAtwzTutthmTdP0AS8F8D247ADzIG1lhPwiVtt9AB5gDmAeTXQA25Xcf35wjkg+dd/NnR2qoLTyjvzgJRxsoJTj5xjQJqE0I+r7deAMD85B0AeYLcAt4vsEhCcAXmAXYDQGFqLHADhVipI',
		resourcesPath: 'dwt-resources',
		serverUrl: 'http://dexit2.scannerproxy.com:18602',
		chunkSize: 5,
	},
	novu: {
		backendUrl: 'https://alerting.314ecorp.tech',
		socketUrl: 'https://ws-alerting.314ecorp.tech',
	},
	faro: {
		url: 'https://collector.314ecorp.tech/collect',
		apiKey: 'tX4Pe@NE',
	},
};

export default config;
