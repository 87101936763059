/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BatchRequestModel } from '../Models';
// @ts-ignore
import { CreateDraftsResponseModel } from '../Models';
// @ts-ignore
import { DraftsRequestModel } from '../Models';
// @ts-ignore
import { GetBatchesResponseModel } from '../Models';
// @ts-ignore
import { GetDraftsResponseModel } from '../Models';
// @ts-ignore
import { QueueRequestModel } from '../Models';
// @ts-ignore
import { QueueResponseModel } from '../Models';
// @ts-ignore
import { ResponseModel } from '../Models';
// @ts-ignore
import { UserModel } from '../Models';
// @ts-ignore
import { ValidateResponseModel } from '../Models';
/**
 * QueuesApi - axios parameter creator
 * @export
 */
export const QueuesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {string} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchProcessing: async (queueId: string, batchId: string, operation?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('batchProcessing', 'queueId', queueId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('batchProcessing', 'batchId', batchId)
            const localVarPath = `/api/v1/queues/{queue_id}/batches/{batch_id}/batchProcessing`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queueId)))
                .replace(`{${"batch_id"}}`, encodeURIComponent(String(batchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (operation !== undefined) {
                localVarQueryParameter['operation'] = operation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} queueId 
         * @param {BatchRequestModel} batchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatch: async (queueId: string, batchRequestModel: BatchRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('createBatch', 'queueId', queueId)
            // verify required parameter 'batchRequestModel' is not null or undefined
            assertParamExists('createBatch', 'batchRequestModel', batchRequestModel)
            const localVarPath = `/api/v1/queues/{queue_id}/batches`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {DraftsRequestModel} draftsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDrafts: async (queueId: string, batchId: string, draftsRequestModel: DraftsRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('createDrafts', 'queueId', queueId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('createDrafts', 'batchId', batchId)
            // verify required parameter 'draftsRequestModel' is not null or undefined
            assertParamExists('createDrafts', 'draftsRequestModel', draftsRequestModel)
            const localVarPath = `/api/v1/queues/{queue_id}/batches/{batch_id}/drafts`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queueId)))
                .replace(`{${"batch_id"}}`, encodeURIComponent(String(batchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(draftsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QueueRequestModel} queueRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQueue: async (queueRequestModel: QueueRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueRequestModel' is not null or undefined
            assertParamExists('createQueue', 'queueRequestModel', queueRequestModel)
            const localVarPath = `/api/v1/queues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queueRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatch: async (queueId: string, batchId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('deleteBatch', 'queueId', queueId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('deleteBatch', 'batchId', batchId)
            const localVarPath = `/api/v1/queues/{queue_id}/batches/{batch_id}`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queueId)))
                .replace(`{${"batch_id"}}`, encodeURIComponent(String(batchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {string} draftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraft: async (queueId: string, batchId: string, draftId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('deleteDraft', 'queueId', queueId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('deleteDraft', 'batchId', batchId)
            // verify required parameter 'draftId' is not null or undefined
            assertParamExists('deleteDraft', 'draftId', draftId)
            const localVarPath = `/api/v1/queues/{queue_id}/batches/{batch_id}/drafts/{draft_id}`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queueId)))
                .replace(`{${"batch_id"}}`, encodeURIComponent(String(batchId)))
                .replace(`{${"draft_id"}}`, encodeURIComponent(String(draftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFiles: async (requestBody: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteFiles', 'requestBody', requestBody)
            const localVarPath = `/api/v1/queues/deleteFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatch: async (queueId: string, batchId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('getBatch', 'queueId', queueId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('getBatch', 'batchId', batchId)
            const localVarPath = `/api/v1/queues/{queue_id}/batches/{batch_id}`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queueId)))
                .replace(`{${"batch_id"}}`, encodeURIComponent(String(batchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchStatus: async (queueId: string, batchId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('getBatchStatus', 'queueId', queueId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('getBatchStatus', 'batchId', batchId)
            const localVarPath = `/api/v1/queues/{queue_id}/batches/{batch_id}/batchStatus`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queueId)))
                .replace(`{${"batch_id"}}`, encodeURIComponent(String(batchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} queueId 
         * @param {Array<string>} [batchIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatches: async (queueId: string, batchIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('getBatches', 'queueId', queueId)
            const localVarPath = `/api/v1/queues/{queue_id}/batches`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (batchIds) {
                localVarQueryParameter['batch_ids'] = batchIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {string} draftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraft: async (queueId: string, batchId: string, draftId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('getDraft', 'queueId', queueId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('getDraft', 'batchId', batchId)
            // verify required parameter 'draftId' is not null or undefined
            assertParamExists('getDraft', 'draftId', draftId)
            const localVarPath = `/api/v1/queues/{queue_id}/batches/{batch_id}/drafts/{draft_id}`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queueId)))
                .replace(`{${"batch_id"}}`, encodeURIComponent(String(batchId)))
                .replace(`{${"draft_id"}}`, encodeURIComponent(String(draftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrafts: async (queueId: string, batchId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('getDrafts', 'queueId', queueId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('getDrafts', 'batchId', batchId)
            const localVarPath = `/api/v1/queues/{queue_id}/batches/{batch_id}/drafts`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queueId)))
                .replace(`{${"batch_id"}}`, encodeURIComponent(String(batchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} queueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueueUser: async (queueId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('getQueueUser', 'queueId', queueId)
            const localVarPath = `/api/v1/queues/{queue_id}/users`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueues: async (id?: string, isActive?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/queues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {boolean} [isLock] 
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockBatch: async (queueId: string, batchId: string, isLock?: boolean, all?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('lockBatch', 'queueId', queueId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('lockBatch', 'batchId', batchId)
            const localVarPath = `/api/v1/queues/{queue_id}/batches/{batch_id}/lockBatch`
                .replace(`{${"queue_id"}}`, encodeURIComponent(String(queueId)))
                .replace(`{${"batch_id"}}`, encodeURIComponent(String(batchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (isLock !== undefined) {
                localVarQueryParameter['is_lock'] = isLock;
            }

            if (all !== undefined) {
                localVarQueryParameter['all_'] = all;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filePath 
         * @param {string} queueId 
         * @param {string} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveFileToQueue: async (filePath: string, queueId: string, source: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filePath' is not null or undefined
            assertParamExists('moveFileToQueue', 'filePath', filePath)
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('moveFileToQueue', 'queueId', queueId)
            // verify required parameter 'source' is not null or undefined
            assertParamExists('moveFileToQueue', 'source', source)
            const localVarPath = `/api/v1/queues/moveFileToQueue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (filePath !== undefined) {
                localVarQueryParameter['file_path'] = filePath;
            }

            if (queueId !== undefined) {
                localVarQueryParameter['queue_id'] = queueId;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateQueue: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('validateQueue', 'name', name)
            const localVarPath = `/api/v1/queues/validateQueue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QueuesApi - functional programming interface
 * @export
 */
export const QueuesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QueuesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {string} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchProcessing(queueId: string, batchId: string, operation?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchProcessing(queueId, batchId, operation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.batchProcessing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} queueId 
         * @param {BatchRequestModel} batchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBatch(queueId: string, batchRequestModel: BatchRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBatch(queueId, batchRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.createBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {DraftsRequestModel} draftsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDrafts(queueId: string, batchId: string, draftsRequestModel: DraftsRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDraftsResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDrafts(queueId, batchId, draftsRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.createDrafts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {QueueRequestModel} queueRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createQueue(queueRequestModel: QueueRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createQueue(queueRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.createQueue']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBatch(queueId: string, batchId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBatch(queueId, batchId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.deleteBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {string} draftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDraft(queueId: string, batchId: string, draftId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDraft(queueId, batchId, draftId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.deleteDraft']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFiles(requestBody: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFiles(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.deleteFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatch(queueId: string, batchId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBatchesResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatch(queueId, batchId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.getBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatchStatus(queueId: string, batchId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatchStatus(queueId, batchId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.getBatchStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} queueId 
         * @param {Array<string>} [batchIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatches(queueId: string, batchIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetBatchesResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatches(queueId, batchIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.getBatches']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {string} draftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraft(queueId: string, batchId: string, draftId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDraftsResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraft(queueId, batchId, draftId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.getDraft']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDrafts(queueId: string, batchId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetDraftsResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDrafts(queueId, batchId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.getDrafts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} queueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQueueUser(queueId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQueueUser(queueId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.getQueueUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQueues(id?: string, isActive?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QueueResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQueues(id, isActive, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.getQueues']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {boolean} [isLock] 
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockBatch(queueId: string, batchId: string, isLock?: boolean, all?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockBatch(queueId, batchId, isLock, all, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.lockBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} filePath 
         * @param {string} queueId 
         * @param {string} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveFileToQueue(filePath: string, queueId: string, source: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveFileToQueue(filePath, queueId, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.moveFileToQueue']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateQueue(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateQueue(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueuesApi.validateQueue']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QueuesApi - factory interface
 * @export
 */
export const QueuesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QueuesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {string} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchProcessing(queueId: string, batchId: string, operation?: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.batchProcessing(queueId, batchId, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} queueId 
         * @param {BatchRequestModel} batchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatch(queueId: string, batchRequestModel: BatchRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.createBatch(queueId, batchRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {DraftsRequestModel} draftsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDrafts(queueId: string, batchId: string, draftsRequestModel: DraftsRequestModel, options?: any): AxiosPromise<CreateDraftsResponseModel> {
            return localVarFp.createDrafts(queueId, batchId, draftsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {QueueRequestModel} queueRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQueue(queueRequestModel: QueueRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.createQueue(queueRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatch(queueId: string, batchId: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteBatch(queueId, batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {string} draftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraft(queueId: string, batchId: string, draftId: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteDraft(queueId, batchId, draftId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFiles(requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFiles(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatch(queueId: string, batchId: string, options?: any): AxiosPromise<GetBatchesResponseModel> {
            return localVarFp.getBatch(queueId, batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchStatus(queueId: string, batchId: string, options?: any): AxiosPromise<File> {
            return localVarFp.getBatchStatus(queueId, batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} queueId 
         * @param {Array<string>} [batchIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatches(queueId: string, batchIds?: Array<string>, options?: any): AxiosPromise<Array<GetBatchesResponseModel>> {
            return localVarFp.getBatches(queueId, batchIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {string} draftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraft(queueId: string, batchId: string, draftId: string, options?: any): AxiosPromise<GetDraftsResponseModel> {
            return localVarFp.getDraft(queueId, batchId, draftId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrafts(queueId: string, batchId: string, options?: any): AxiosPromise<Array<GetDraftsResponseModel>> {
            return localVarFp.getDrafts(queueId, batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} queueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueueUser(queueId: string, options?: any): AxiosPromise<Array<UserModel>> {
            return localVarFp.getQueueUser(queueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueues(id?: string, isActive?: boolean, options?: any): AxiosPromise<Array<QueueResponseModel>> {
            return localVarFp.getQueues(id, isActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} queueId 
         * @param {string} batchId 
         * @param {boolean} [isLock] 
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockBatch(queueId: string, batchId: string, isLock?: boolean, all?: boolean, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.lockBatch(queueId, batchId, isLock, all, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filePath 
         * @param {string} queueId 
         * @param {string} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveFileToQueue(filePath: string, queueId: string, source: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.moveFileToQueue(filePath, queueId, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateQueue(name: string, options?: any): AxiosPromise<ValidateResponseModel> {
            return localVarFp.validateQueue(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QueuesApi - interface
 * @export
 * @interface QueuesApi
 */
export interface QueuesApiInterface {
    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {string} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    batchProcessing(queueId: string, batchId: string, operation?: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} queueId 
     * @param {BatchRequestModel} batchRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    createBatch(queueId: string, batchRequestModel: BatchRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {DraftsRequestModel} draftsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    createDrafts(queueId: string, batchId: string, draftsRequestModel: DraftsRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<CreateDraftsResponseModel>;

    /**
     * 
     * @param {QueueRequestModel} queueRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    createQueue(queueRequestModel: QueueRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    deleteBatch(queueId: string, batchId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {string} draftId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    deleteDraft(queueId: string, batchId: string, draftId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    deleteFiles(requestBody: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    getBatch(queueId: string, batchId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetBatchesResponseModel>;

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    getBatchStatus(queueId: string, batchId: string, options?: RawAxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {string} queueId 
     * @param {Array<string>} [batchIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    getBatches(queueId: string, batchIds?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetBatchesResponseModel>>;

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {string} draftId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    getDraft(queueId: string, batchId: string, draftId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetDraftsResponseModel>;

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    getDrafts(queueId: string, batchId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetDraftsResponseModel>>;

    /**
     * 
     * @param {string} queueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    getQueueUser(queueId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<UserModel>>;

    /**
     * 
     * @param {string} [id] 
     * @param {boolean} [isActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    getQueues(id?: string, isActive?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<QueueResponseModel>>;

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {boolean} [isLock] 
     * @param {boolean} [all] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    lockBatch(queueId: string, batchId: string, isLock?: boolean, all?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} filePath 
     * @param {string} queueId 
     * @param {string} source 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    moveFileToQueue(filePath: string, queueId: string, source: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApiInterface
     */
    validateQueue(name: string, options?: RawAxiosRequestConfig): AxiosPromise<ValidateResponseModel>;

}

/**
 * QueuesApi - object-oriented interface
 * @export
 * @class QueuesApi
 * @extends {BaseAPI}
 */
export class QueuesApi extends BaseAPI implements QueuesApiInterface {
    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {string} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public batchProcessing(queueId: string, batchId: string, operation?: string, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).batchProcessing(queueId, batchId, operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} queueId 
     * @param {BatchRequestModel} batchRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public createBatch(queueId: string, batchRequestModel: BatchRequestModel, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).createBatch(queueId, batchRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {DraftsRequestModel} draftsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public createDrafts(queueId: string, batchId: string, draftsRequestModel: DraftsRequestModel, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).createDrafts(queueId, batchId, draftsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QueueRequestModel} queueRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public createQueue(queueRequestModel: QueueRequestModel, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).createQueue(queueRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public deleteBatch(queueId: string, batchId: string, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).deleteBatch(queueId, batchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {string} draftId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public deleteDraft(queueId: string, batchId: string, draftId: string, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).deleteDraft(queueId, batchId, draftId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public deleteFiles(requestBody: Array<string>, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).deleteFiles(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public getBatch(queueId: string, batchId: string, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).getBatch(queueId, batchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public getBatchStatus(queueId: string, batchId: string, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).getBatchStatus(queueId, batchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} queueId 
     * @param {Array<string>} [batchIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public getBatches(queueId: string, batchIds?: Array<string>, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).getBatches(queueId, batchIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {string} draftId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public getDraft(queueId: string, batchId: string, draftId: string, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).getDraft(queueId, batchId, draftId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public getDrafts(queueId: string, batchId: string, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).getDrafts(queueId, batchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} queueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public getQueueUser(queueId: string, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).getQueueUser(queueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {boolean} [isActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public getQueues(id?: string, isActive?: boolean, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).getQueues(id, isActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} queueId 
     * @param {string} batchId 
     * @param {boolean} [isLock] 
     * @param {boolean} [all] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public lockBatch(queueId: string, batchId: string, isLock?: boolean, all?: boolean, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).lockBatch(queueId, batchId, isLock, all, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filePath 
     * @param {string} queueId 
     * @param {string} source 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public moveFileToQueue(filePath: string, queueId: string, source: string, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).moveFileToQueue(filePath, queueId, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public validateQueue(name: string, options?: RawAxiosRequestConfig) {
        return QueuesApiFp(this.configuration).validateQueue(name, options).then((request) => request(this.axios, this.basePath));
    }
}

