/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Default Pdf Render
 */

import * as pdfJS from 'pdfjs-dist/legacy/build/pdf.mjs';
import * as tf from '@tensorflow/tfjs';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import log from 'loglevel';
import { Spin, Typography } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import RectangleAnnotation from 'components/indexing/RectangleAnnotation';
import useOnScreen from 'components/indexing/useOnScreen';
import { useAnnotationActions, useAnnotationValues } from 'store/annotation';
import {
	DET_CONFIG,
	createEnlargedImage,
	extractBoundingBoxesFromHeatmap,
	getHeatMapFromImage,
	getRecognitionResult,
} from 'utils/docTrUtils';
import { useModelStore } from 'store/modelStore';

const outputScale = globalThis.devicePixelRatio || 1;

interface IProps {
	id: string;
	pageNum: number;
	pdf: pdfJS.PDFDocumentProxy;
	pageToView: { pageNum: number; scroll: boolean };
	pdfRenderScale: number;
	setPageToView: (
		pageNum?: number,
		scroll?: boolean,
	) => {
		pageNum: number;
		scroll: boolean;
	};
}

const RenderPdf: React.FC<IProps> = (props) => {
	const { id, pageNum, pdf, pageToView, pdfRenderScale, setPageToView } = props;

	const [page, setPage] = useState<pdfJS.PDFPageProxy | null>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [currentElementRef, setCurrentElementRef] = useState<HTMLDivElement | null>(null);
	const [startX, setStartX] = useState(0);
	const [startY, setStartY] = useState(0);
	const [currentRectId, setCurrentRectId] = useState<string | null>(null);

	const { rectangles, focusedField, isRectangleAnnotationMode, drawingAnnotationId, hoveredAnnotationId } =
		useAnnotationValues();

	const { addRectangle, removeRectangle, updateRectangle, setDrawingAnnotationId } = useAnnotationActions();

	const canvasRef = useRef<HTMLCanvasElement>(null);
	const { isIntersecting } = useOnScreen(canvasRef);

	useEffect(() => {
		if (isIntersecting) {
			setPageToView(pageNum, false);
		}
	}, [isIntersecting]);

	// Using already loaded DocTr models
	const loadedDetectionModel = useModelStore((state) => state.detectionModel);
	const loadedRecognitionModel = useModelStore((state) => state.recognitionModel);

	//  store them in refs to avoid re-renders
	const detectionModel = useRef<tf.GraphModel | null>(null);
	const recognitionModel = useRef<tf.GraphModel | null>(null);

	useEffect(() => {
		if (detectionModel) {
			detectionModel.current = loadedDetectionModel;
		}
		if (recognitionModel) {
			recognitionModel.current = loadedRecognitionModel;
		}
	}, [detectionModel, recognitionModel, loadedDetectionModel, loadedRecognitionModel]);

	useEffect(() => {
		if (canvasRef.current && pageToView.pageNum === pageNum) {
			canvasRef.current.scrollIntoView();
		}
	}, [pageToView]);

	const renderPage = (page: pdfJS.PDFPageProxy) => {
		const viewport = page.getViewport({ scale: pdfRenderScale });
		const canvas: HTMLCanvasElement = canvasRef.current ?? new HTMLCanvasElement();
		const canvasContext = canvas.getContext('2d');
		if (!canvasContext) {
			return;
		}

		canvas.width = _.floor(viewport.width * outputScale);
		canvas.height = _.floor(viewport.height * outputScale);
		canvas.style.width = `${_.floor(viewport.width)}px`;
		canvas.style.height = `${_.floor(viewport.height)}px`;

		const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : undefined;
		const renderContext = { canvasContext, viewport, transform };

		const renderTask = page.render(renderContext);
		renderTask.promise.then(
			() => {
				setLoading(false);
				setError(false);
				log.info('Page rendered');
			},
			(reason: any) => {
				setError(true);
				setLoading(false);
				log.error(reason);
			},
		);
	};

	const loadPage = async () => {
		try {
			const page = await pdf.getPage(pageNum);
			setPage(page);
		} catch (err) {
			setError(true);
			setLoading(false);
			log.error(err);
		}
	};

	useEffect(() => {
		if (page) {
			renderPage(page);
		}
	}, [page, pdfRenderScale]);

	useEffect(() => {
		void loadPage();
	}, [pdf]);

	const setRectRef = useCallback((node: HTMLDivElement | null) => {
		setCurrentElementRef(node);
	}, []);

	const onMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();
		if (!canvasRef.current) return;

		const rectId = uuidv4();
		setDrawingAnnotationId(rectId);
		const { clientX: x, clientY: y } = event;
		const { top, left } = canvasRef.current.getBoundingClientRect();

		addRectangle({
			id: rectId,
			left: (x - left) / pdfRenderScale,
			top: (y - top) / pdfRenderScale,
			pageIndex: pageNum - 1,
			field: focusedField?.slice(0, 2) ?? [],
		});

		setCurrentRectId(rectId);
		setStartX(event.clientX);
		setStartY(event.clientY);
	};

	const onMouseUp = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();
		if (!canvasRef.current || !currentRectId) return;

		if (currentElementRef) {
			const ele: HTMLDivElement = currentElementRef;
			const { width, height } = ele.getBoundingClientRect();

			if (width === 0 || height === 0) {
				removeRectangle(currentRectId);
			} else {
				const currentRect = _.find(rectangles, { id: currentRectId });
				if (!currentRect) return;

				const ctx = canvasRef.current.getContext('2d');
				if (!ctx) return;

				const canvasRect = canvasRef.current.getBoundingClientRect();
				const scaleX = canvasRef.current.width / canvasRect.width;
				const scaleY = canvasRef.current.height / canvasRect.height;

				const rectWidth = Math.floor(width * scaleX);
				const rectHeight = Math.floor(height * scaleY);
				const sx = Math.floor((currentRect.left ?? 0) * pdfRenderScale * scaleX);
				const sy = Math.floor((currentRect.top ?? 0) * pdfRenderScale * scaleY);

				const imageData = ctx.getImageData(sx, sy, rectWidth, rectHeight);
				if (imageData && recognitionModel.current && detectionModel.current) {
					const canvasTemp = document.createElement('canvas');
					canvasTemp.width = rectWidth;
					canvasTemp.height = rectHeight;
					const ctxTemp = canvasTemp.getContext('2d');
					ctxTemp?.putImageData(imageData, 0, 0);

					const htmlImageElement = await new Promise<HTMLImageElement>((resolve) => {
						const img = new Image();
						img.onload = () => resolve(img);
						img.src = canvasTemp.toDataURL();
					});

					const enlargedImageElement = await createEnlargedImage(htmlImageElement, rectWidth, rectHeight);
					let heatmapCanvas: any = null;
					if (detectionModel.current) {
						heatmapCanvas = await getHeatMapFromImage({
							detectionModel: detectionModel.current,
							imageObject: enlargedImageElement,
							size: [DET_CONFIG.db_mobilenet_v2.height, DET_CONFIG.db_mobilenet_v2.width],
						});
					}
					let boundingBoxes: any = [];
					if (heatmapCanvas) {
						boundingBoxes = await extractBoundingBoxesFromHeatmap([
							DET_CONFIG.db_mobilenet_v2.height,
							DET_CONFIG.db_mobilenet_v2.width,
						]);
					}
					const recognizedWords = await Promise.all(
						boundingBoxes.map((box: any) =>
							getRecognitionResult(box, enlargedImageElement, recognitionModel.current!),
						),
					);
					updateRectangle({
						id: currentRectId,
						width: width / pdfRenderScale,
						height: height / pdfRenderScale,
						extractedText: recognizedWords.join(' '),
						newlyAdded: true,
					});
				}
			}
		} else {
			removeRectangle(currentRectId);
		}

		setDrawingAnnotationId(null);
		setCurrentRectId(null);
		setCurrentElementRef(null);
	};

	const onMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();
		if (!currentElementRef || !drawingAnnotationId || !canvasRef.current) return;

		const { clientX: x, clientY: y } = event;
		const { top, left } = canvasRef.current.getBoundingClientRect();
		const width = Math.abs(x - startX);
		const height = Math.abs(y - startY);

		const ele = currentElementRef;
		ele.style.width = `${width}px`;
		ele.style.height = `${height}px`;
		ele.style.left = `${Math.min(x, startX) - left}px`;
		ele.style.top = `${Math.min(y, startY) - top}px`;
	};

	if (error) {
		return <Typography.Title level={3}> We&apos;re sorry, unable to open file...!</Typography.Title>;
	}

	return (
		<Spin spinning={loading}>
			<div
				role='button'
				className='position-relative'
				onKeyDown={(e) => e.stopPropagation()}
				onMouseDown={isRectangleAnnotationMode && !hoveredAnnotationId ? onMouseDown : undefined}
				onMouseUp={isRectangleAnnotationMode ? onMouseUp : undefined}
				onMouseMove={isRectangleAnnotationMode ? onMouseMove : undefined}
			>
				<canvas
					id={id}
					key={id}
					ref={canvasRef}
					style={{ height: '65vh' }}
					className={classNames({
						'cursor-crosshair': isRectangleAnnotationMode,
						'cursor-default': !isRectangleAnnotationMode,
					})}
				/>
				<canvas id='heatmap' width='512' height='512' style={{ display: 'none' }}></canvas>
				{_.map(
					_.filter(
						rectangles,
						({ pageIndex: rectPageIndex, field: rectField }) =>
							_.isEqual(rectPageIndex, pageNum - 1) && _.isEqual(rectField, focusedField?.slice(0, 2)),
					),
					(rectangle) => (
						<RectangleAnnotation
							key={rectangle.id}
							currentRect={rectangle}
							pdfRenderScale={pdfRenderScale}
							refCallback={setRectRef}
						/>
					),
				)}
			</div>
		</Spin>
	);
};

export default RenderPdf;
