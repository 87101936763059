/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description bootstrap
 */

import _ from 'lodash';
import { message } from 'antd';

import api from 'client/index';
import useAppStore, { Actions } from 'store/app';
import useUserStore from 'store/user';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIMEZONE, DEFAULT_TIME_FORMAT } from 'constants/defaults';
import { WEB_ITEMS, EXTRA_PATHS } from '../configs/menu-config';

const filterEnabledResourceBasedOnRole = (config: any[], roles?: string[]) => {
	return _.filter(config, (item) => {
		const hasPermission = _.isEmpty(item.role) || _.size(_.intersection(roles, item.role)) > 0;
		// Menu item is enabled to show in the Navigation
		const isEnabled = item.enabled !== false;
		return hasPermission && isEnabled;
	});
};

const setDateTimeSettings = (settings: Record<string, string>, setFormats: Actions['setFormats']) => {
	const dateTimeSettings = _.get(settings, 'date-time-settings');
	const dateFormat = _.get(dateTimeSettings, 'dateformat', DEFAULT_DATE_FORMAT);
	const timeFormat = _.get(dateTimeSettings, 'timeformat', DEFAULT_TIME_FORMAT);
	const timezone = _.get(dateTimeSettings, 'timezone', DEFAULT_TIMEZONE);
	setFormats(dateFormat, timeFormat, timezone);
};

const initialize = async (user: User): Promise<void> => {
	const { setStatus, setWebConfig, setFormats, setDefaultTemplate, setNovuConfig } = useAppStore.getState();
	try {
		const { setUser } = useUserStore.getState();
		setUser(user);

		const { roles } = user;
		const navigationConfig = filterEnabledResourceBasedOnRole(WEB_ITEMS, roles);
		const visibleItems = filterEnabledResourceBasedOnRole(EXTRA_PATHS, roles);
		if (_.size(navigationConfig) === 0 || _.size(_.flatMap(_.map(navigationConfig, 'visible'))) === 0) {
			throw new Error('ACCESS_FORBIDDEN');
		}
		setWebConfig(navigationConfig, Object.freeze(_.concat(navigationConfig, visibleItems)));
	} catch {
		setStatus({
			isLoading: false,
			isError: true,
			status: 403,
			message: 'Access Forbidden',
		});
		return;
	}

	try {
		const { data: settings } = await api.CustomerSettingsApi.customersettingsGet();
		setDateTimeSettings(settings, setFormats);
		if (_.get(settings, 'default-template')) {
			setDefaultTemplate(_.get(settings, 'default-template'));
		}
		setStatus({
			isLoading: false,
			isError: false,
			status: 200,
			message: 'Success! Welcome back to Dexit',
		});
	} catch (e) {
		setStatus({
			isLoading: false,
			isError: true,
			status: 503,
			message: e instanceof Error ? e.message : 'OOPS! Something went wrong',
		});
		return;
	}

	try {
		const { data: novuConfig } = await api.NovuIntegrationApi.getSubscriberID();
		setNovuConfig({
			subscriberId: user.id ?? _.get(novuConfig, 'subscriber_id', ''),
			environmentIdentifier: _.get(novuConfig, 'environment_identifier', ''),
			slackIntegrationUrl: _.get(novuConfig, 'slack_sharable_url', ''),
		});
	} catch (e) {
		await message.warning('Notification Services will not work for now, we are facing some problem');
		return;
	}
};

export { setDateTimeSettings };
export default initialize;
