/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Page
 */

import React, { ReactElement, useEffect } from 'react';
import _ from 'lodash';
import { Breadcrumb, BreadcrumbProps, Button, Typography } from 'antd';
import { Left } from '@icon-park/react';
import { Link } from 'react-router-dom';
import { PageHeader, PageHeaderProps } from '@ant-design/pro-layout';
import { UseQueryResult } from '@tanstack/react-query';
import { faro } from '@grafana/faro-react';

import QueryBoundary from '../query-boundary';
import matomoconfig from 'configs/matomo.config';
import useMatomo from '@dexit/admin/src/matomo/useMatomo';
import usePageConfig from './usePageConfig';
import { getRecursiveChildText } from './utils';

interface IPageProps {
	showTitle?: boolean;
	extra?: React.ReactNode[];
	breadcrumb?: MenuItem['title'] | ReactElement<any, any>;
	titleIcon?: React.JSX.Element;
	subTitle?: string;
}

const Page: React.FC<IPageProps & PageHeaderProps & Partial<UseQueryResult>> = (props) => {
	const { children, breadcrumb, subTitle, className, titleIcon, onBack, showTitle = true, ...restProps } = props;
	const menuItem = usePageConfig();
	const pageTitle = breadcrumb ?? menuItem?.title;
	const { trackEvent } = useMatomo();

	useEffect(() => {
		if (pageTitle) {
			if (_.isString(pageTitle)) {
				document.title = `${pageTitle} | Dexit`;
			} else if (React.isValidElement(_.get(_.last(pageTitle), 'text'))) {
				document.title = `${getRecursiveChildText(_.get(_.last(pageTitle), 'text'))} | Dexit`;
			} else {
				document.title = `${_.get(_.last(pageTitle), 'text')} | Dexit`;
			}
		} else if (subTitle) {
			document.title = `${subTitle} | Dexit`;
		} else {
			document.title = 'Dexit';
		}
		faro.api.setView({ name: document.title ?? 'Dexit' });
	}, [pageTitle, subTitle]);

	useEffect(() => {
		if (menuItem.matomoTitle) {
			trackEvent({
				category: menuItem.matomoTitle,
				action: matomoconfig.actions.PAGEVIEW,
				name: menuItem.matomoTitle,
			});
		}
	}, []);

	const items: BreadcrumbProps['items'] = _.map(pageTitle, (breadcrumbTitle, index) => ({
		title: (
			<Typography.Text
				style={{
					fontWeight: _.isEqual(index, pageTitle.length - 1) ? '700' : '400',
					color: _.isEqual(index, pageTitle.length - 1) ? '#323837' : '#00000073',
					fontSize: 16,
				}}
			>
				{_.has(breadcrumbTitle, 'url') ? (
					<Link
						to={{
							pathname: breadcrumbTitle.url,
							search: breadcrumbTitle.search,
						}}
						style={{ color: '#00000073' }}
					>
						{breadcrumbTitle.text}
					</Link>
				) : (
					_.get(breadcrumbTitle, 'text')
				)}
			</Typography.Text>
		),
		key: index,
	}));
	const getTitle = () => {
		if (_.isString(pageTitle)) {
			return titleIcon ? (
				<div className='flex-align-center' style={{ gap: '8px' }}>
					<span>{titleIcon}</span>
					<span>{pageTitle}</span>
				</div>
			) : (
				pageTitle
			);
		} else {
			return <Breadcrumb separator='/' items={items} />;
		}
	};
	return (
		<PageHeader
			className={className}
			title={showTitle ? getTitle() : undefined}
			backIcon={
				<Button type='text' style={{ minWidth: 0, marginTop: 1 }} icon={<Left size='20' fill='#595959' />} />
			}
			onBack={onBack}
			{...restProps}
		>
			<QueryBoundary {...restProps}>{children}</QueryBoundary>
		</PageHeader>
	);
};

export default Page;
