/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description PDF Worker Types
 */

export const PDF_OPERATIONS = {
	ROTATE: 'rotate',
	REARRANGE: 'rearrange',
	DELETE: 'delete',
	MERGE_TO_BASE_FILE: 'mergeToBaseFile',
	MERGE_TO_NEW_FILE: 'mergeToNewFile',
	MERGE_UPLOADED_FILES: 'mergeUploadedFiles',
} as const;

export type PDFOperationType = (typeof PDF_OPERATIONS)[keyof typeof PDF_OPERATIONS];

export interface WorkerMessage {
	type: string;
	data?: Uint8Array;
	error?: string;
}

export interface WorkerTask {
	type: PDFOperationType;
	url?: string;
	pageNumbers?: number[];
	rotationAngle?: number;
	oldIndex?: number;
	newIndex?: number;
	baseFileUrl?: string;
	files?: any;
	isFilesUploaded?: boolean;
}
