/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BulkIndexRequestModel } from '../Models';
// @ts-ignore
import { CreateDocumentResponseModel } from '../Models';
// @ts-ignore
import { DocumentMetadataResponseModel } from '../Models';
// @ts-ignore
import { DocumentUploadRequestModel } from '../Models';
// @ts-ignore
import { DocumentUploadResponseModel } from '../Models';
// @ts-ignore
import { IndexDocumentRequestModel } from '../Models';
// @ts-ignore
import { IndexingErrorResponseModel } from '../Models';
// @ts-ignore
import { PresignedUrlViewResponseModel } from '../Models';
// @ts-ignore
import { ResponseModel } from '../Models';
// @ts-ignore
import { RetrieveIn } from '../Models';
// @ts-ignore
import { RetrieveResponse } from '../Models';
// @ts-ignore
import { RetrieveResponseData } from '../Models';
/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BulkIndexRequestModel} bulkIndexRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkIndexBatch: async (bulkIndexRequestModel: BulkIndexRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkIndexRequestModel' is not null or undefined
            assertParamExists('bulkIndexBatch', 'bulkIndexRequestModel', bulkIndexRequestModel)
            const localVarPath = `/api/v1/document/bulkIndexBatch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkIndexRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentsByIds: async (requestBody: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteDocumentsByIds', 'requestBody', requestBody)
            const localVarPath = `/api/v1/document/deleteDocumentsByIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentIndexInfoById: async (documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentIndexInfoById', 'documentId', documentId)
            const localVarPath = `/api/v1/document/getDocumentIndexInfoById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (documentId !== undefined) {
                localVarQueryParameter['document_id'] = documentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentMetadataById: async (documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentMetadataById', 'documentId', documentId)
            const localVarPath = `/api/v1/document/getDocumentMetadataById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (documentId !== undefined) {
                localVarQueryParameter['document_id'] = documentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IndexDocumentRequestModel} indexDocumentRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexDocument: async (indexDocumentRequestModel: IndexDocumentRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indexDocumentRequestModel' is not null or undefined
            assertParamExists('indexDocument', 'indexDocumentRequestModel', indexDocumentRequestModel)
            const localVarPath = `/api/v1/document/indexDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indexDocumentRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentUploadRequestModel} documentUploadRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presignedURLupload: async (documentUploadRequestModel: DocumentUploadRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentUploadRequestModel' is not null or undefined
            assertParamExists('presignedURLupload', 'documentUploadRequestModel', documentUploadRequestModel)
            const localVarPath = `/api/v1/document/presignedUrlUpload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentUploadRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presignedURLview: async (filePath: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filePath' is not null or undefined
            assertParamExists('presignedURLview', 'filePath', filePath)
            const localVarPath = `/api/v1/document/presignedUrlView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (filePath !== undefined) {
                localVarQueryParameter['file_path'] = filePath;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RetrieveIn} retrieveIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieve: async (retrieveIn: RetrieveIn, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'retrieveIn' is not null or undefined
            assertParamExists('retrieve', 'retrieveIn', retrieveIn)
            const localVarPath = `/api/v1/document/retrieve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(retrieveIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BulkIndexRequestModel} bulkIndexRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkIndexBatch(bulkIndexRequestModel: BulkIndexRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkIndexBatch(bulkIndexRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.bulkIndexBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumentsByIds(requestBody: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumentsByIds(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.deleteDocumentsByIds']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentIndexInfoById(documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetrieveResponseData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentIndexInfoById(documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.getDocumentIndexInfoById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentMetadataById(documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentMetadataResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentMetadataById(documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.getDocumentMetadataById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IndexDocumentRequestModel} indexDocumentRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexDocument(indexDocumentRequestModel: IndexDocumentRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDocumentResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexDocument(indexDocumentRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.indexDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DocumentUploadRequestModel} documentUploadRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async presignedURLupload(documentUploadRequestModel: DocumentUploadRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentUploadResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.presignedURLupload(documentUploadRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.presignedURLupload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async presignedURLview(filePath: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresignedUrlViewResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.presignedURLview(filePath, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.presignedURLview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RetrieveIn} retrieveIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieve(retrieveIn: RetrieveIn, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetrieveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieve(retrieveIn, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.retrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {BulkIndexRequestModel} bulkIndexRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkIndexBatch(bulkIndexRequestModel: BulkIndexRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.bulkIndexBatch(bulkIndexRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentsByIds(requestBody: Array<string>, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteDocumentsByIds(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentIndexInfoById(documentId: string, options?: any): AxiosPromise<RetrieveResponseData> {
            return localVarFp.getDocumentIndexInfoById(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentMetadataById(documentId: string, options?: any): AxiosPromise<DocumentMetadataResponseModel> {
            return localVarFp.getDocumentMetadataById(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndexDocumentRequestModel} indexDocumentRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexDocument(indexDocumentRequestModel: IndexDocumentRequestModel, options?: any): AxiosPromise<CreateDocumentResponseModel> {
            return localVarFp.indexDocument(indexDocumentRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentUploadRequestModel} documentUploadRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presignedURLupload(documentUploadRequestModel: DocumentUploadRequestModel, options?: any): AxiosPromise<Array<DocumentUploadResponseModel>> {
            return localVarFp.presignedURLupload(documentUploadRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presignedURLview(filePath: string, options?: any): AxiosPromise<PresignedUrlViewResponseModel> {
            return localVarFp.presignedURLview(filePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RetrieveIn} retrieveIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieve(retrieveIn: RetrieveIn, options?: any): AxiosPromise<RetrieveResponse> {
            return localVarFp.retrieve(retrieveIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentApi - interface
 * @export
 * @interface DocumentApi
 */
export interface DocumentApiInterface {
    /**
     * 
     * @param {BulkIndexRequestModel} bulkIndexRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    bulkIndexBatch(bulkIndexRequestModel: BulkIndexRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    deleteDocumentsByIds(requestBody: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    getDocumentIndexInfoById(documentId: string, options?: RawAxiosRequestConfig): AxiosPromise<RetrieveResponseData>;

    /**
     * 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    getDocumentMetadataById(documentId: string, options?: RawAxiosRequestConfig): AxiosPromise<DocumentMetadataResponseModel>;

    /**
     * 
     * @param {IndexDocumentRequestModel} indexDocumentRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    indexDocument(indexDocumentRequestModel: IndexDocumentRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<CreateDocumentResponseModel>;

    /**
     * 
     * @param {DocumentUploadRequestModel} documentUploadRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    presignedURLupload(documentUploadRequestModel: DocumentUploadRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<Array<DocumentUploadResponseModel>>;

    /**
     * 
     * @param {string} filePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    presignedURLview(filePath: string, options?: RawAxiosRequestConfig): AxiosPromise<PresignedUrlViewResponseModel>;

    /**
     * 
     * @param {RetrieveIn} retrieveIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    retrieve(retrieveIn: RetrieveIn, options?: RawAxiosRequestConfig): AxiosPromise<RetrieveResponse>;

}

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI implements DocumentApiInterface {
    /**
     * 
     * @param {BulkIndexRequestModel} bulkIndexRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public bulkIndexBatch(bulkIndexRequestModel: BulkIndexRequestModel, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).bulkIndexBatch(bulkIndexRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public deleteDocumentsByIds(requestBody: Array<string>, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).deleteDocumentsByIds(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public getDocumentIndexInfoById(documentId: string, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).getDocumentIndexInfoById(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public getDocumentMetadataById(documentId: string, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).getDocumentMetadataById(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndexDocumentRequestModel} indexDocumentRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public indexDocument(indexDocumentRequestModel: IndexDocumentRequestModel, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).indexDocument(indexDocumentRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentUploadRequestModel} documentUploadRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public presignedURLupload(documentUploadRequestModel: DocumentUploadRequestModel, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).presignedURLupload(documentUploadRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public presignedURLview(filePath: string, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).presignedURLview(filePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RetrieveIn} retrieveIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public retrieve(retrieveIn: RetrieveIn, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).retrieve(retrieveIn, options).then((request) => request(this.axios, this.basePath));
    }
}

