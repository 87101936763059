/**
 * @author Sanjay <sanjay.krishnamurthy@314ecorp.com>
 * @description roles-config
 */

import { SignalFilled } from '@ant-design/icons';

import BranchOne from 'components/icons/BranchOne';
import BulkImportOutlined from 'components/icons/BulkImportOutlined';
import FaxesOutlined from 'components/icons/FaxesOutlined';
import QueuesOutlined from 'components/icons/QueuesOutlined';
import ROIOutlined from 'components/icons/ROIOutlined';
import TasksOutlined from 'components/icons/TasksOutlined';
import { ENTITY } from 'constants/entity';
import { ROLES } from 'constants/roles';

const URL_CONFIG = {
	retrieval: '/retrieval',
	queues: '/queues',
	bulkImport: '/bulk-import',
	faxes: '/faxes',
	ROI: '/releaseOfInformation',
	roilogs: '/roilogs',
	workflow: '/workflow',
	reports: '/reports',
	tasks: '/tasks',
};

const WEB_ITEMS: MenuItem[] = [
	/** HIM User Menu */
	{
		key: 'queues',
		title: 'Queues',
		matomoTitle: 'Queues',
		url: URL_CONFIG.queues,
		role: [ROLES.documentIndexing],
		icon: <QueuesOutlined />,
		component: 'Queues',
		enabled: true,
	},
	{
		key: 'bulk-import',
		title: 'Bulk Import',
		matomoTitle: 'Bulk Import',
		url: URL_CONFIG.bulkImport,
		role: [ROLES.manageBulkImport],
		icon: <BulkImportOutlined />,
		component: 'BulkImport',
		enabled: false,
	},
	{
		key: 'roilogs',
		title: 'ROI',
		matomoTitle: 'ROI Logs',
		url: URL_CONFIG.roilogs,
		role: [ROLES.releaseOfInformation],
		icon: <ROIOutlined />,
		component: 'ROILogs',
		enabled: true,
	},
	{
		key: 'workflow',
		title: 'Workflow',
		matomoTitle: 'Workflow',
		url: URL_CONFIG.workflow,
		role: [ROLES.manageWorkflow],
		icon: <BranchOne />,
		component: 'WorkflowTable',
		enabled: true,
	},
	{
		key: 'reports',
		title: 'Reports',
		matomoTitle: 'Reports',
		url: URL_CONFIG.reports,
		role: [ROLES.reports],
		icon: <SignalFilled style={{ fontSize: 14 }} />,
		component: 'Reports',
		enabled: true,
	},
	{
		key: 'faxes',
		title: 'Faxes',
		matomoTitle: 'Faxes',
		url: URL_CONFIG.faxes,
		role: [ROLES.standaloneLaunch],
		icon: <FaxesOutlined />,
		component: 'Faxes',
		enabled: true,
	},
	{
		key: 'tasks',
		title: 'Tasks',
		matomoTitle: 'Tasks',
		url: URL_CONFIG.tasks,
		role: [ROLES.standaloneLaunch],
		icon: <TasksOutlined />,
		component: 'Tasks',
		enabled: true,
	},
];

const EXTRA_PATHS: MenuItem[] = [
	/** HIM User Queues*/
	{
		key: 'queue-batches',
		title: 'Batch Scanning',
		role: [ROLES.documentIndexing],
		url: `${URL_CONFIG.queues}/:queueId/:batchId`,
		component: 'BatchIndex',
		fullscreen: true,
		enabled: true,
	},

	/** Retrieval */
	{
		key: 'retrieval',
		title: 'Search Results',
		matomoTitle: 'Search',
		url: URL_CONFIG.retrieval,
		role: [ROLES.standaloneLaunch],
		component: 'Retrieval',
		enabled: true,
	},
	{
		key: 'retrieve-doc-view',
		title: 'Document',
		url: ENTITY.retrieve.url,
		role: [ROLES.standaloneLaunch],
		component: 'DocViewer',
		enabled: true,
	},
	{
		key: 'retrieve-edit-index-info',
		title: 'Document',
		matomoTitle: 'Edit Document',
		url: `${ENTITY.retrieve.url}/edit-index-info`,
		role: [ROLES.documentIndexing],
		component: 'EditIndexInfo',
		fullscreen: true,
		enabled: true,
	},

	/** Unsigned Copy */
	{
		key: 'unsigned-copy',
		title: 'Create Unsigned Copy',
		matomoTitle: 'Create Unsigned Copy',
		url: ENTITY.unsignedCopy.url,
		role: [ROLES.documentIndexing],
		component: 'CreateUnsignedCopy',
		enabled: true,
	},

	/** Release of Information */
	{
		key: 'release-of-information',
		title: 'New Release of Information',
		matomoTitle: 'New ROI',
		url: `${URL_CONFIG.ROI}/create`,
		role: [ROLES.releaseOfInformation],
		component: 'ReleaseOfInformation',
		fullscreen: true,
		enabled: true,
	},

	/** Workflow */
	{
		key: 'create-workflow',
		title: 'Create Workflow',
		matomoTitle: 'Settings - Create Workflow',
		url: `${URL_CONFIG.workflow}/create`,
		component: 'Workflows',
		enabled: true,
	},
	{
		key: 'update-workflow',
		title: 'Update Workflow',
		matomoTitle: 'Settings - Update Workflow',
		url: `${URL_CONFIG.workflow}/:id`,
		component: 'Workflows',
		enabled: true,
	},

	/** Bulk Import */
	{
		key: 'bulkImport-documents',
		title: [{ text: 'Bulk Import', url: URL_CONFIG.bulkImport }, { text: 'Process' }],
		matomoTitle: 'Bulk Import Documents',
		url: `${ENTITY.bulkImport.url}/documents`,
		role: [ROLES.manageBulkImport],
		component: 'BulkImportDocuments',
		enabled: true,
	},
	{
		key: 'bulkImport-doc-view',
		title: 'Document',
		matomoTitle: 'Document Viewer',
		url: ENTITY.bulkImport.url,
		role: [ROLES.standaloneLaunch],
		component: 'DocViewer',
		enabled: true,
	},
	{
		key: 'bulkImport-edit-index-info',
		title: 'Document',
		matomoTitle: 'Edit Document',
		url: `${ENTITY.bulkImport.url}/edit-index-info`,
		role: [ROLES.documentIndexing],
		component: 'EditIndexInfo',
		fullscreen: true,
		enabled: true,
	},

	/** Fax Numbers */
	{
		key: 'send-fax',
		title: 'Send Fax',
		matomoTitle: 'Send Fax',
		url: `${URL_CONFIG.faxes}/send`,
		role: [ROLES.standaloneLaunch],
		component: 'SendFaxes',
		fullscreen: true,
		enabled: true,
	},
	{
		key: 'edit-fax',
		title: 'Send Fax',
		matomoTitle: 'Send Fax',
		url: `${ENTITY.faxes.url}/update`,
		role: [ROLES.standaloneLaunch],
		component: 'SendFaxes',
		fullscreen: true,
		enabled: true,
	},
	{
		key: 'view-fax',
		title: 'View Fax',
		matomoTitle: 'View Fax',
		url: `${ENTITY.faxes.url}/view`,
		role: [ROLES.standaloneLaunch],
		component: 'ViewFaxInfo',
		fullscreen: true,
		enabled: true,
	},

	/** Tasks */
	{
		key: 'task-details',
		title: 'Task Details',
		matomoTitle: 'Tasks',
		url: `${URL_CONFIG.tasks}/:taskNumber`,
		role: [ROLES.standaloneLaunch],
		component: 'TaskDetails',
		enabled: true,
	},
];

export { WEB_ITEMS, URL_CONFIG, EXTRA_PATHS };
