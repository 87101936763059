/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description PDF.js global configuration and worker initialization
 */

import * as pdfJS from 'pdfjs-dist/legacy/build/pdf.mjs';

let pdfJSWorker: pdfJS.PDFWorker | null = null;
if (pdfJS.GlobalWorkerOptions.workerSrc === '') {
	pdfJS.GlobalWorkerOptions.workerSrc = new URL(
		'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
		import.meta.url,
	).toString();
}

const getPdfWorker = (): pdfJS.PDFWorker => {
	if (!pdfJSWorker) {
		pdfJSWorker = new pdfJS.PDFWorker();
	}
	return pdfJSWorker;
};

export { pdfJS, getPdfWorker };
