/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Entry point to Dexit
 */

import { H } from 'highlight.run';
import { createRoot } from 'react-dom/client';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import config from 'configs/index';
import env from '@dexit/common/utils/Environment';
import { initializeFaro } from 'components/faro/initializeFaro';

import App from './components/app/App';

H.init(config.highlight.projectID, config.highlight.debug);
// serviceWorkerRegistration.register();
initializeFaro();

if (typeof globalThis !== 'undefined' && env.isLocalhost()) {
	import('react-scan').then(({ scan }) => {
		scan({ enabled: true, log: false });
	});
}

const root = createRoot(document.getElementById('root') ?? document.body);
root.render(<App />);
