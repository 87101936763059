/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Rectangle Annotation
 */

import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import { Error as ErrorIcon } from '@icon-park/react';

import { RectAnnotation, useAnnotationActions, useAnnotationValues } from 'store/annotation';

interface IProps {
	pdfRenderScale: number;
	currentRect: RectAnnotation;
	refCallback: (node: HTMLDivElement | null) => void;
}

const RectangleAnnotation: React.FC<IProps> = (props) => {
	const { currentRect, pdfRenderScale, refCallback } = props;
	const rectRef = useRef<HTMLDivElement | null>(null);

	const { removeRectangle, setEditingAnnotationId, setHoveredAnnotationId } = useAnnotationActions();
	const { editingAnnotationId, drawingAnnotationId } = useAnnotationValues();

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Delete' || e.key === 'Backspace') {
			if (editingAnnotationId) {
				removeRectangle(editingAnnotationId);
				setEditingAnnotationId(null);
				setHoveredAnnotationId(null);
			}
		}
	};

	useEffect(() => {
		if (refCallback) {
			refCallback(rectRef.current);
		}
	}, [rectRef, refCallback]);

	return (
		<div
			key={currentRect.id}
			style={{
				width: `${(currentRect.width ?? 0) * pdfRenderScale}px`,
				height: `${(currentRect.height ?? 0) * pdfRenderScale}px`,
				left: `${(currentRect.left ?? 0) * pdfRenderScale}px`,
				top: `${(currentRect.top ?? 0) * pdfRenderScale}px`,
			}}
			className={classNames('rect-wrapper resizable-rect', {
				'rect-drawing': drawingAnnotationId === currentRect.id,
			})}
			tabIndex={0}
			ref={rectRef}
			onClick={() => {
				if (editingAnnotationId !== currentRect.id) {
					setEditingAnnotationId(currentRect.id);
				} else {
					setEditingAnnotationId(null);
				}
			}}
			onMouseEnter={() => {
				setHoveredAnnotationId(currentRect.id);
			}}
			onMouseLeave={() => {
				setHoveredAnnotationId(null);
			}}
			onKeyDown={handleKeyDown}
		>
			<div className='rect-div' />
			{editingAnnotationId === currentRect.id && (
				<div className='rect-controls'>
					<Button
						type='primary'
						shape='circle'
						size='small'
						icon={<ErrorIcon theme='filled' size={8} strokeWidth={1} />}
						onClick={(e) => {
							e.stopPropagation();
							setHoveredAnnotationId(null);
							setEditingAnnotationId(null);
							removeRectangle(currentRect.id);
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default RectangleAnnotation;
