/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description RAQB config
 */

import React from 'react';
import _ from 'lodash';
import log from 'loglevel';
import {
	AntdConfig,
	ButtonProps,
	Config,
	ConfigContext,
	ConjsProps,
	Fields,
	Operators,
	Settings,
	Types,
	Widgets,
} from '@react-awesome-query-builder/antd';

import AutoSelect from './AutoSelect';
import RenderButtons from './RenderButtons';
import RenderConjunctions from './RenderConjunctions';
import api from 'client/index';
import { FileGroups } from 'components/document/utils';

const getConfig = (dateFormat: string, timeFormat: string, faxSearch?: boolean, workflowItems?: any): Config => {
	const searchFields: Fields = {
		username: {
			label: 'Uploaded By',
			type: 'select',
			fieldSettings: {
				useAsyncSearch: false,
				showSearch: true,
				asyncFetch: async () => {
					try {
						const { data } = await api.UserApi.readAllUsers();
						return {
							values: _.map(data, (user) => ({
								value: user.username,
								title: _.join(_.compact([user.firstname, user.lastname]), ' '),
							})),
							hasMore: false,
						};
					} catch (ex) {
						log.error(ex);
						return {
							values: [],
							hasMore: false,
						};
					}
				},
			},
		},
		uploadDate: {
			label: 'Uploaded On',
			type: 'date',
		},
		documentId: {
			label: 'Document ID',
			type: 'text',
		},
		documentType: {
			label: 'Document Type',
			type: 'select',
			fieldSettings: {
				useAsyncSearch: false,
				showSearch: true,
				asyncFetch: async () => {
					try {
						const { data } = await api.DocumentTypeApi.getDocumentType();
						return {
							values: _.map(data, (documentType) => ({
								value: documentType.name,
								title: documentType.name,
							})),
							hasMore: false,
						};
					} catch (ex) {
						log.error(ex);
						return {
							values: [],
							hasMore: false,
						};
					}
				},
			},
		},
		patient: {
			type: '!group',
			label: 'Patient',
			hideForSelect: faxSearch,
			subfields: {
				mrn: {
					label: 'MRN',
					type: 'text',
				},
				mrn_system: {
					label: 'MRN Source',
					type: 'select',
					fieldSettings: {
						useAsyncSearch: false,
						showSearch: true,
						asyncFetch: async () => {
							try {
								const { data } = await api.ResourceApi.getAllIdentifierSystems();
								return {
									values: _.map(data, (documentType) => ({
										value: documentType.system ?? '',
										title: documentType.code ?? '',
									})),
									hasMore: false,
								};
							} catch (ex) {
								log.error(ex);
								return {
									values: [],
									hasMore: false,
								};
							}
						},
					},
				},
				firstname: {
					label: 'First Name',
					type: 'text',
				},
				lastname: {
					label: 'Last Name',
					type: 'text',
				},
				birthDate: {
					label: 'DOB',
					type: 'date',
				},
			},
		},
		encounter: {
			type: '!group',
			label: 'Encounter',
			hideForSelect: faxSearch,
			subfields: {
				id: {
					label: 'Encounter ID (CSN)',
					type: 'text',
				},
				date: {
					label: 'Date of encounter',
					type: 'date',
					defaultOperator: 'between',
					operators: ['between'],
				},
				type: {
					label: 'Encounter type',
					type: 'text',
				},
			},
		},
		review: {
			label: 'Document Review',
			type: '!group',
			subfields: {
				inreview: {
					label: 'In Review',
					type: 'boolean',
					defaultValue: true,
				},
				requestdate: {
					label: 'Request Date',
					type: 'date',
				},
				requestedby: {
					label: 'Requested By',
					type: 'select',

					fieldSettings: {
						useAsyncSearch: false,
						showSearch: true,
						asyncFetch: async () => {
							try {
								const { data } = await api.UserApi.readAllUsers();
								return {
									values: _.map(data, (user) => ({
										value: user.username,
										title: _.join(_.compact([user.firstname, user.lastname]), ' '),
									})),
									hasMore: false,
								};
							} catch (ex) {
								log.error(ex);
								return {
									values: [],
									hasMore: false,
								};
							}
						},
					},
				},
			},
		},
		deficiencies: {
			label: 'Deficiencies',
			type: '!group',
			subfields: {
				hasdeficiencies: {
					label: 'Has Deficiencies',
					type: 'boolean',
					defaultValue: true,
				},
				status: {
					label: 'Status',
					type: 'select',
					fieldSettings: {
						listValues: ['Assigned', 'Signed'],
					},
					defaultOperator: 'select',
				},
				requestedby: {
					label: 'Requested By',
					type: 'select',

					defaultOperator: 'select_any_in',
					fieldSettings: {
						useAsyncSearch: false,
						showSearch: true,
						asyncFetch: async () => {
							try {
								const { data } = await api.UserApi.readAllUsers();
								return {
									values: _.map(data, (user) => ({
										value: user.username,
										title: _.join(_.compact([user.firstname, user.lastname]), ' '),
									})),
									hasMore: false,
								};
							} catch (ex) {
								log.error(ex);
								return {
									values: [],
									hasMore: false,
								};
							}
						},
					},
				},
				assignee: {
					label: 'Assignee',
					type: 'select',

					fieldSettings: {
						useAsyncSearch: false,
						showSearch: true,
						asyncFetch: async () => {
							try {
								const { data } = await api.UserApi.readAllUsers();
								return {
									values: _.map(data, (user) => ({
										value: user.username,
										title: _.join(_.compact([user.firstname, user.lastname]), ' '),
									})),
									hasMore: false,
								};
							} catch (ex) {
								log.error(ex);
								return {
									values: [],
									hasMore: false,
								};
							}
						},
					},
				},
			},
		},
		text: {
			label: 'Text',
			type: 'text',
			defaultOperator: 'like',
			operators: ['like', 'not_like'],
		},
		filetype: {
			label: 'File Type',
			type: 'select',
			hideForSelect: faxSearch,
			fieldSettings: {
				showSearch: true,
				listValues: _.map(_.flatMap(FileGroups), (value) => ({ value, title: _.startCase(value) })),
			},
			defaultOperator: 'select_any_in',
		},
	};

	const workflowFields: Fields = _.reduce(
		workflowItems,
		(result: Fields, item: { key: string; label: string; children: any[] }) => {
			result[item.key] = {
				label: item.label,
				type: '!group',
				subfields: _.reduce(
					item.children,
					(subResult: Fields, field: { key: string; label: string }) => {
						subResult[field.key] = {
							label: field.label,
							type: 'text',
						};
						return subResult;
					},
					{} as Fields,
				),
			};
			return result;
		},
		{} as Fields,
	);
	const fields: Fields = workflowItems ? workflowFields : searchFields;

	const settings: Settings = {
		...AntdConfig.settings,
		renderSize: 'medium',
		renderButton: (props: ButtonProps, context?: ConfigContext) => {
			if (!context) {
				throw new Error('Context is undefined');
			}
			const {
				RCE,
				W: { Button },
			} = context;
			if (props) {
				return <RenderButtons {...props} />;
			}

			return RCE(Button, props);
		},
		renderConjs: (props: ConjsProps, context?: ConfigContext) => {
			if (!context) {
				throw new Error('Context is undefined');
			}
			const {
				RCE,
				W: { Conjs },
			} = context;
			if (props) {
				return <RenderConjunctions {...props} />;
			}

			return RCE(Conjs, props);
		},
		canCompareFieldWithField: () => false,
		renderValueSources: () => React.createElement('div'),
		removeIncompleteRulesOnLoad: false,
	};

	const operators: Operators = {
		...AntdConfig.operators,
		equal: {
			...AntdConfig.operators.equal,
			label: 'Is',
		},
		not_equal: {
			...AntdConfig.operators.not_equal,
			label: 'Is not',
		},
		less: {
			...AntdConfig.operators.less,
			label: 'Less than',
		},
		less_or_equal: {
			...AntdConfig.operators.less_or_equal,
			label: 'Less or Equal',
		},
		greater: {
			...AntdConfig.operators.greater,
			label: 'Greater than',
		},
		greater_or_equal: {
			...AntdConfig.operators.greater_or_equal,
			label: 'Greater or Equals',
		},
		select_equals: {
			...AntdConfig.operators.select_equals,
			label: 'Is',
		},
		select_not_equals: {
			...AntdConfig.operators.select_not_equals,
			label: 'Is Not',
		},
	};

	const widgets: Widgets = {
		...AntdConfig.widgets,
		date: {
			...AntdConfig.widgets.date,
			dateFormat,
		},
		datetime: {
			...AntdConfig.widgets.datetime,
			dateFormat,
			timeFormat,
		},
		select: {
			...AntdConfig.widgets.select,
			customProps: {
				...AntdConfig.widgets.select.customProps,
				optionFilterProp: 'children',
				allowClear: true,
			},
			factory: (props: any, context?: ConfigContext) => {
				if (!context) {
					throw new Error('Context is undefined');
				}
				const {
					RCE,
					W: { SelectWidget },
				} = context;
				return props.asyncFetch || props.showSearch ? RCE(AutoSelect, props) : RCE(SelectWidget, props);
			},
		},
		multiselect: {
			...AntdConfig.widgets.multiselect,
			customProps: {
				...AntdConfig.widgets.multiselect.customProps,
				optionFilterProp: 'children',
				allowClear: true,
			},
			factory: (props: any, context?: ConfigContext) => {
				if (!context) {
					throw new Error('Context is undefined');
				}
				const {
					RCE,
					W: { MultiSelectWidget },
				} = context;
				return props.asyncFetch || props.showSearch
					? RCE(AutoSelect, { ...props, multiple: true })
					: RCE(MultiSelectWidget, props);
			},
		},
	};

	const types: Types = {
		...AntdConfig.types,
		text: {
			...AntdConfig.types.text,
			excludeOperators: ['proximity'],
		},
		boolean: _.merge({}, AntdConfig.types.boolean, {
			widgets: {
				boolean: {
					widgetProps: {
						hideOperator: true,
						operatorInlineLabel: 'is',
					},
					opProps: {
						equal: {
							label: 'is',
						},
						not_equal: {
							label: 'is not',
						},
					},
				},
			},
		}),
	};

	return {
		...AntdConfig,
		settings,
		operators,
		widgets,
		types,
		fields,
	};
};

export { getConfig };
