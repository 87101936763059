/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Header
 */

import { Link } from 'react-router-dom';
import { Space } from 'antd';

import AddFiles from 'components/upload/AddFiles';
import NotificationCenter from 'components/notification/NotificationCenter';
import UniversalSearchWrapper from './UniversalSearchWrapper';
import UserProfile from './UserProfile';
import { ROOT_URL } from 'constants/index';

const logoImg = {
	height: 47,
	width: 124,
	src: './assets/svg/DexitNew.svg',
};

const Header: React.FC = () => {
	return (
		<div className='flex-space-between'>
			<div className='flex-gap-8 full-height'>
				<Link to={ROOT_URL} className='flex-center'>
					<img alt={'Dexit Logo'} {...logoImg} />
				</Link>
				<div style={{ width: '30vw' }} className='full-height flex-center'>
					<UniversalSearchWrapper />
				</div>
			</div>
			<Space size={20}>
				<AddFiles buttonText='Add files' />
				<NotificationCenter />
				<UserProfile />
			</Space>
		</div>
	);
};

export default Header;
