/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Universal Search Wrapper
 */

import React, { useContext } from 'react';

import UniversalSearch from './UniversalSearch';
import { RetrieveContext } from 'components/page/RetrieveContext';

const UniversalSearchWrapper: React.FC = () => {
	const { query, setQuery, setPaginationAndSort } = useContext(RetrieveContext);

	return <UniversalSearch query={query} setQuery={setQuery} setPaginationAndSort={setPaginationAndSort} />;
};

export default UniversalSearchWrapper;
