/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Advanced Search Modal
 */

import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { Button, Modal, Space, Tabs, TabsProps } from 'antd';
import { ImmutableTree, JsonTree, Utils } from '@react-awesome-query-builder/antd';

import QueryBuilder from 'components/query-builder/QueryBuilder';
import SaveSearchForm from './SaveSearchForm';
import SavedSearch from './SavedSearch';
import { SavedSearchResponseModel } from '@dexit/common/openapi';
import { filterQuery } from 'components/header/utils';
import { useUserValues } from 'store/user';

interface IProps {
	open: boolean;
	queryTree: ImmutableTree;
	isFaxSearch?: boolean;
	onChangeQueryTree: React.Dispatch<React.SetStateAction<ImmutableTree>>;
	onOpenChange: (open: boolean) => void;
	onSearch: (value: ImmutableTree) => void;
}

const tabs = {
	search: {
		key: 'search',
		name: 'Search',
	},
	savedSearch: {
		key: 'savedSearch',
		name: 'Saved Search',
	},
};

const AdvancedSearchModal: React.FC<IProps> = (props) => {
	const { open, queryTree, isFaxSearch, onChangeQueryTree, onOpenChange, onSearch } = props;

	const [record, setRecord] = useState<SavedSearchResponseModel | null>(null);
	const [activeTab, setActiveTab] = useState('search');
	const [isNew, setIsNew] = useState(true);
	const [openSaveForm, setOpenSaveForm] = useState(false);

	const [tempQueryTree, setTempQueryTree] = useState<ImmutableTree>(queryTree);

	const { user } = useUserValues();

	const isQueryEmpty = useMemo(
		() => (tempQueryTree ? _.isEmpty(filterQuery(Utils.getTree(tempQueryTree))) : true),
		[tempQueryTree],
	);

	const handleTabChange = (activeKey: string) => {
		setActiveTab(activeKey);
	};

	const handleSearch = () => {
		onSearch(tempQueryTree);
		onChangeQueryTree(tempQueryTree);
		onOpenChange(false);
	};

	const handleSelect = (item: SavedSearchResponseModel) => {
		setTempQueryTree(Utils.loadTree(item.data as JsonTree));
		setActiveTab(tabs.search.key);
		setRecord(item);
	};
	const handleSave = (addNew: boolean) => {
		setOpenSaveForm(true);
		setIsNew(addNew);
	};
	const handleSaveFormCancel = () => {
		setOpenSaveForm(false);
		setIsNew(true);
	};
	const handleCancel = () => {
		setRecord(null);
		onOpenChange(false);
	};

	const getTabs = () => {
		const tabItems: TabsProps['items'] = [
			{
				key: tabs.search.key,
				label: tabs.search.name,
				children: <QueryBuilder tree={tempQueryTree} isFaxSearch={isFaxSearch} onChange={setTempQueryTree} />,
			},
		];
		if (!isFaxSearch) {
			tabItems.push({
				key: tabs.savedSearch.key,
				label: tabs.savedSearch.name,
				children: <SavedSearch onSelect={handleSelect} />,
			});
		}
		return tabItems;
	};

	return (
		<>
			<Modal
				open={open}
				width='60%'
				maskClosable={false}
				className='advance-search-styles'
				onCancel={() => onOpenChange(false)}
				footer={
					_.isEqual(activeTab, tabs.search.key) ? (
						<div className='flex-space-between'>
							<Space>
								<Button
									hidden={!!record && record.user?.id !== user?.id}
									disabled={isQueryEmpty}
									onClick={() => handleSave(_.isEmpty(record))}
								>
									Save Search
								</Button>
								<Button hidden={!record} disabled={isQueryEmpty} onClick={() => handleSave(true)}>
									Save As
								</Button>
							</Space>
							<Space>
								<Button type='text' onClick={handleCancel}>
									Cancel
								</Button>
								<Button type='primary' onClick={handleSearch}>
									Apply Filter
								</Button>
							</Space>
						</div>
					) : null
				}
			>
				<Tabs size='small' activeKey={activeTab} items={getTabs()} onChange={handleTabChange} />
			</Modal>
			{openSaveForm && (
				<SaveSearchForm
					open={openSaveForm}
					query={Utils.getTree(tempQueryTree)}
					savedSearchRecord={record}
					isNew={isNew}
					onCancel={handleSaveFormCancel}
				/>
			)}
		</>
	);
};

export default AdvancedSearchModal;
