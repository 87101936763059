/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Indexing store
 */

import _ from 'lodash';
import axios from 'axios';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { useEffect } from 'react';

import api from 'client/index';
import pdfWorkerHelper from 'components/pdf/worker-helper';
import { Stages, StampResponseModel } from '@dexit/common/openapi';

export interface Page {
	id: string;
	pageNumber?: number;
	thumbnailImg?: string;
	isSelected?: boolean;
	isUpdating?: boolean;
	filePath?: string;
}

export interface Batch {
	id: string;
	files: { fileId?: string; filePath: string; blobUrl?: string }[];
	stage: Stages;
	fileType: string;
}

export interface DraftDocument {
	id: string;
	name?: string;
	filePath?: string;
	indexInfo?: object;
	pages?: Page[];
	isUpdating: boolean;
	isError: boolean;
	isInaccessibleDocType: boolean;
	error?: string;
	blobUrl?: any;
}

export enum Section {
	viewer = 'viewer',
	indexInfo = 'indexInfo',
}

export interface DraftFormError {
	name: string;
	message: string;
}
export interface DraftErrors {
	id: string;
	formErrors?: DraftFormError[];
}
export enum ViewerType {
	batch = 'BatchViewer',
	draft = 'DraftDocumentViewer',
}

export interface OpenAndPagesToMove {
	open: boolean;
	pagesToMove: Page[];
	moveTo: 'batch' | 'draft';
	draftID?: string;
}

interface UpdateDraftTrigger {
	pagesToMove: Page[];
	draftID: string;
}
interface UploadFileTrigger {
	filePath: string;
	blobUrl: string;
	draftid?: string;
}
interface OpenDocumentTypeSelection {
	open: boolean;
	pagesToMove?: Page[];
	fromAnotherDraft?: boolean;
}

type State = {
	openAndPagesToMove: OpenAndPagesToMove;
	openDocumentTypeSelection: OpenDocumentTypeSelection;
	visiblePages: string[];
	currentDraftPages: Page[];
	batch: Batch | null;
	pdfRenderScale: number;
	viewerType: ViewerType;
	queueId?: string;
	batchId?: string;
	pages: Page[];
	draftDocuments: DraftDocument[];
	selectedDraftId?: string;
	queueStages: Stages[];
	isDraggingPage: boolean;
	isDraggingBatchPage: boolean;
	pageToView: { id: string; scroll: boolean };
	lockedPatient: string | null;
	lockedEncounter: string | null;
	currentSection: Section;
	draftDocErrors: DraftErrors[];
	deleteFileTrigger: string | null;
	uploadFileTrigger: UploadFileTrigger | null;
	updateDraftTrigger: UpdateDraftTrigger | null;
	stampsData: StampResponseModel[];
};
type Actions = {
	setOpenAndPagesToMove: (openAndPagesToMove: OpenAndPagesToMove) => { openAndPagesToMove: OpenAndPagesToMove };
	setOpenDocumentTypeSelection: (openDocumentTypeSelection: OpenDocumentTypeSelection) => {
		openDocumentTypeSelection: OpenDocumentTypeSelection;
	};
	setVisiblePages: (ids: string[]) => { ids: string[] };
	addToVisiblePages: (id: string) => { id: string };
	removeFromVisiblePages: (id: string) => { id: string };
	setCurrentDraftPages: (pages: Page[]) => { pages: Page[] };
	setBatch: (batch: Batch) => { batch: Batch };
	updateBatch: (batch: Partial<Batch>) => { batch: Partial<Batch> };
	updateCurrentDraftPages: (page: Page) => { page: Page };
	setPdfRenderScale: (scale: number) => { scale: number };
	setPageToView: (id?: string, scroll?: boolean) => { id: string; scroll: boolean };
	setBatchId: (batchId?: string) => { batchId?: string };
	setQueueId: (queueId?: string) => { queueId?: string };
	setSelectedDraftId: (selectedDraftId?: string) => { selectedDraftId?: string };
	setViewerType: (type: ViewerType) => { type: ViewerType };
	setStagesInQueue: (stagesInQueue: Stages[]) => { stagesInQueue: Stages[] };
	setIsDraggingPage: (isDraggingPage: boolean) => { isDraggingPage: boolean };
	setIsDraggingBatchPage: (isDraggingBatchPage: boolean) => { isDraggingBatchPage: boolean };

	setPages: (pages: Page[]) => { pages: Page[] };
	selectPages: (pageIds: string[]) => { pageIds: string[] };
	unSelectPages: (pageIds: string[]) => { pageIds: string[] };
	updatePage: (page: Page) => { page: Page };

	setDraftDocuments: (docs: DraftDocument[]) => { docs: DraftDocument[] };
	addToDraftDocuments: (docs: DraftDocument[]) => { docs: DraftDocument[] };
	deleteDraftDocument: (draftId: string) => { draftId: string };
	updateDraftDocument: (
		id: string,
		doc: Omit<Partial<DraftDocument>, 'id'>,
	) => { id: string; doc: Omit<Partial<DraftDocument>, 'id'> };

	setLockedPatient: (lockedPatient: string | null) => { lockedPatient: string | null };
	setLockedEncounter: (lockedEncounter: string | null) => { lockedEncounter: string | null };

	setCurrentSection: (section: Section) => { section: Section };

	setDraftErrors: (
		id: string,
		draftFormErrors: DraftFormError[],
	) => { id: string; draftFormErrors: DraftFormError[] };
	updateDraftErrors: (id: string, draftDocErrors: DraftErrors) => { id: string; draftDocErrors: DraftErrors };

	updateDraftDocumentTrigger: (updateDraftTrigger: UpdateDraftTrigger | null) => {
		updateDraftTrigger: UpdateDraftTrigger | null;
	};
	deleteFile: (deleteFileTrigger: string | null) => { deleteFileTrigger: string | null };
	uploadFile: (uploadFileTrigger: UploadFileTrigger | null) => { uploadFileTrigger: UploadFileTrigger | null };

	setStampsData: (stampsData: StampResponseModel[]) => { stampsData: StampResponseModel[] };
	resetStore: () => void;
};

const initialState: State = {
	openAndPagesToMove: { open: false, pagesToMove: [], moveTo: 'batch' },
	openDocumentTypeSelection: { open: false },
	visiblePages: [],
	currentDraftPages: [],
	batch: null,
	pdfRenderScale: 1,
	viewerType: ViewerType.batch,
	queueId: '',
	batchId: '',
	pages: [],
	draftDocuments: [],
	selectedDraftId: undefined,
	queueStages: [],
	isDraggingPage: false,
	isDraggingBatchPage: false,
	pageToView: { id: '', scroll: false },
	lockedPatient: null,
	lockedEncounter: null,
	currentSection: Section.viewer,
	draftDocErrors: [],
	deleteFileTrigger: null,
	uploadFileTrigger: null,
	updateDraftTrigger: null,
	stampsData: [],
};

const actions = (set: any): Actions => ({
	setOpenAndPagesToMove: (openAndPagesToMove) => set({ openAndPagesToMove }),
	setOpenDocumentTypeSelection: (openDocumentTypeSelection) => set({ openDocumentTypeSelection }),
	setVisiblePages: (visiblePages) => set({ visiblePages }),
	addToVisiblePages: (id) =>
		set((state: State) => {
			const newIds = [...state.visiblePages];
			if (!newIds.includes(id)) {
				newIds.push(id);
			}
			return { visiblePages: newIds };
		}),
	removeFromVisiblePages: (id) =>
		set((state: State) => {
			const newIds = [...state.visiblePages];
			if (newIds.includes(id)) {
				_.remove(newIds, (item) => item === id);
			}
			return { visiblePages: newIds };
		}),
	setCurrentDraftPages: (currentDraftPages) => set({ currentDraftPages }),
	updateCurrentDraftPages: (page) =>
		set((state: State) => {
			const newPages = [...state.currentDraftPages];
			const index = _.findIndex(newPages, (prevPage) => prevPage.id === page.id);
			if (index >= 0) {
				newPages[index] = { ...newPages[index], ...page };
			}
			return { currentDraftPages: newPages };
		}),
	setBatch: (batch) =>
		set((state: State) => {
			if (_.isEmpty(batch.files) && state.batch?.files) {
				state.batch.files.forEach((file) => {
					if (file.blobUrl) {
						URL.revokeObjectURL(file.blobUrl);
					}
				});
			}
			return { batch };
		}),
	updateBatch: (batch) =>
		set((state: State) => {
			if (_.isEmpty(batch.files) && state.batch?.files) {
				state.batch.files.forEach((file) => {
					if (file.blobUrl) {
						URL.revokeObjectURL(file.blobUrl);
					}
				});
			}
			return { batch: { ...state.batch, ...batch } };
		}),
	setPdfRenderScale: (pdfRenderScale) => set({ pdfRenderScale }),
	setPageToView: (id, scroll) => set({ pageToView: { id, scroll } }),
	setBatchId: (batchId) => set({ batchId }),
	setQueueId: (queueId) => set({ queueId }),
	setSelectedDraftId: (selectedDraftId) => set({ selectedDraftId }),
	setViewerType: (viewerType) => set({ viewerType }),
	setStagesInQueue: (queueStages) => set({ queueStages }),
	setIsDraggingPage: (isDraggingPage) => set({ isDraggingPage }),
	setIsDraggingBatchPage: (isDraggingBatchPage) => set({ isDraggingBatchPage }),
	setPages: (pages) => set({ pages }),
	selectPages: (pageIds) =>
		set((state: State) => ({
			pages: _.map(state.pages, (page) => (_.includes(pageIds, page.id) ? { ...page, isSelected: true } : page)),
		})),
	unSelectPages: (pageIds) =>
		set((state: State) => ({
			pages: _.map(state.pages, (page) => (_.includes(pageIds, page.id) ? { ...page, isSelected: false } : page)),
		})),
	updatePage: (page) =>
		set((state: State) => {
			const newPages: Page[] = [...state.pages];
			const index = _.findIndex(state.pages, (prevPage) => prevPage.id === page.id);
			if (index >= 0) {
				newPages[index] = { ...newPages[index], ...page };
			}
			return {
				pages: newPages,
			};
		}),
	setDraftDocuments: (draftDocuments) =>
		set((state: State) => {
			if (_.isEmpty(draftDocuments) && state.draftDocuments) {
				state.draftDocuments.forEach((doc) => {
					if (doc.blobUrl) {
						URL.revokeObjectURL(doc.blobUrl);
					}
				});
			}
			return { draftDocuments };
		}),
	addToDraftDocuments: (docs) =>
		set((state: State) => ({
			draftDocuments: [...state.draftDocuments, ...docs],
		})),
	deleteDraftDocument: (draftId) =>
		set((state: State) => ({ draftDocuments: _.filter(state.draftDocuments, (draft) => draft.id !== draftId) })),
	updateDraftDocument: (id, doc) =>
		set((state: State) => ({
			draftDocuments: _.map(state.draftDocuments, (prevDoc) => {
				if (id === prevDoc.id) {
					return { ...prevDoc, ...doc };
				} else {
					return prevDoc;
				}
			}),
		})),
	setLockedPatient: (lockedPatient) => set({ lockedPatient }),
	setLockedEncounter: (lockedEncounter) => set({ lockedEncounter }),
	setCurrentSection: (currentSection) => set({ currentSection }),
	setDraftErrors: (id, draftFormErrors) =>
		set((state: State) => ({
			draftDocErrors: _.unionBy([{ id, formErrors: draftFormErrors }], state.draftDocErrors, 'id'),
		})),
	updateDraftErrors: (id, draftDocErrors) =>
		set((state: State) => ({
			draftDocErrors: _.map(state.draftDocErrors, (prevDoc) => {
				if (id === prevDoc.id) {
					return { ...prevDoc, ...draftDocErrors };
				} else {
					return prevDoc;
				}
			}),
		})),

	updateDraftDocumentTrigger: (updateDraftTrigger) => set({ updateDraftTrigger }),
	uploadFile: (uploadFileTrigger) => set({ uploadFileTrigger }),
	deleteFile: (deleteFileTrigger) => set({ deleteFileTrigger }),
	setStampsData: (stampsData) => set({ stampsData }),
	resetStore: () =>
		set((state: State) => {
			if (state.batch?.files) {
				state.batch.files.forEach((file) => {
					if (file.blobUrl) {
						URL.revokeObjectURL(file.blobUrl);
					}
				});
			}

			state.draftDocuments.forEach((doc) => {
				if (doc.blobUrl) {
					URL.revokeObjectURL(doc.blobUrl);
				}
			});

			return initialState;
		}),
});

const useIndexingValues = (): State =>
	useIndexingStore((state) => ({
		openAndPagesToMove: state.openAndPagesToMove,
		openDocumentTypeSelection: state.openDocumentTypeSelection,
		visiblePages: state.visiblePages,
		currentDraftPages: state.currentDraftPages,
		batch: state.batch,
		pdfRenderScale: state.pdfRenderScale,
		viewerType: state.viewerType,
		queueId: state.queueId,
		batchId: state.batchId,
		pages: state.pages,
		draftDocuments: state.draftDocuments,
		selectedDraftId: state.selectedDraftId,
		queueStages: state.queueStages,
		isDraggingPage: state.isDraggingPage,
		isDraggingBatchPage: state.isDraggingBatchPage,
		pageToView: state.pageToView,
		lockedPatient: state.lockedPatient,
		lockedEncounter: state.lockedEncounter,
		currentSection: state.currentSection,
		draftDocErrors: state.draftDocErrors,
		deleteFileTrigger: state.deleteFileTrigger,
		uploadFileTrigger: state.uploadFileTrigger,
		updateDraftTrigger: state.updateDraftTrigger,
		stampsData: state.stampsData,
	}));

const useIndexingActions = (): Actions =>
	useIndexingStore((state) => ({
		setOpenAndPagesToMove: state.setOpenAndPagesToMove,
		setOpenDocumentTypeSelection: state.setOpenDocumentTypeSelection,
		setVisiblePages: state.setVisiblePages,
		addToVisiblePages: state.addToVisiblePages,
		removeFromVisiblePages: state.removeFromVisiblePages,
		deleteFile: state.deleteFile,
		uploadFile: state.uploadFile,
		setCurrentDraftPages: state.setCurrentDraftPages,
		updateCurrentDraftPages: state.updateCurrentDraftPages,
		setBatch: state.setBatch,
		updateBatch: state.updateBatch,
		setPdfRenderScale: state.setPdfRenderScale,
		setPageToView: state.setPageToView,
		setBatchId: state.setBatchId,
		setQueueId: state.setQueueId,
		setSelectedDraftId: state.setSelectedDraftId,
		setViewerType: state.setViewerType,
		setStagesInQueue: state.setStagesInQueue,
		setIsDraggingPage: state.setIsDraggingPage,
		setIsDraggingBatchPage: state.setIsDraggingBatchPage,
		setPages: state.setPages,
		selectPages: state.selectPages,
		unSelectPages: state.unSelectPages,
		updatePage: state.updatePage,
		setDraftDocuments: state.setDraftDocuments,
		updateDraftDocumentTrigger: state.updateDraftDocumentTrigger,
		addToDraftDocuments: state.addToDraftDocuments,
		deleteDraftDocument: state.deleteDraftDocument,
		updateDraftDocument: state.updateDraftDocument,
		setLockedPatient: state.setLockedPatient,
		setLockedEncounter: state.setLockedEncounter,
		setCurrentSection: state.setCurrentSection,
		setDraftErrors: state.setDraftErrors,
		updateDraftErrors: state.updateDraftErrors,
		setStampsData: state.setStampsData,
		resetStore: state.resetStore,
	}));

const useIndexingStore = create<State & Actions>()(
	devtools(
		subscribeWithSelector((set) => ({
			...initialState,
			...actions(set),
		})),
		{ name: 'Indexing Store' },
	),
);

const useUnsubscribeOnUnmount = (): void => {
	useEffect(() => {
		const unSubscribeDeleteFile = useIndexingStore.subscribe(
			(state) => state.deleteFileTrigger,
			async (deleteFileTrigger) => {
				if (!deleteFileTrigger) {
					return;
				}
				try {
					await api.QueuesApi.deleteFiles([deleteFileTrigger]);
				} catch (error) {
					throw new Error(`Error deleting file: ${error}`);
				} finally {
					useIndexingStore.setState({ deleteFileTrigger: null });
				}
			},
		);

		const unSubscribeUploadFile = useIndexingStore.subscribe(
			(state) => state.uploadFileTrigger,
			async (uploadFileTrigger) => {
				if (!uploadFileTrigger) {
					return;
				}
				try {
					const config = {
						headers: {
							'content-type': 'multipart/form-data',
						},
					};
					const blob = await fetch(uploadFileTrigger.blobUrl).then((r) => r.blob());
					const { data } = await api.DocumentApi.presignedURLupload({
						file_names: [uploadFileTrigger.filePath],
					});
					const requests = data.map((item) => axios.put(item.url, blob, config));
					await axios.all(requests);
				} catch (error) {
					throw new Error(`Error uploading file: ${error}`);
				} finally {
					useIndexingStore.setState({ uploadFileTrigger: null });
				}
			},
		);

		const unSubscribeUpdateDraft = useIndexingStore.subscribe(
			(state) => state.updateDraftTrigger,
			async (updateDraftTrigger) => {
				if (!updateDraftTrigger) {
					return;
				}
				const { pagesToMove: pages, draftID } = updateDraftTrigger;
				if (_.isEmpty(pages)) {
					return;
				}
				const {
					draftDocuments,
					batch,
					queueId,
					pages: allPages,
					deleteFile,
					uploadFile,
					updateBatch,
					updateDraftDocument,
				} = useIndexingStore.getState();

				try {
					if (!queueId) {
						throw new Error('Queue ID  not found');
					}
					updateDraftDocument(draftID, { isUpdating: true, isError: false });
					const draft = _.find(draftDocuments, (doc) => doc.id === draftID);
					if (!draft) {
						throw new Error('Draft not found');
					}
					const pageBlobUrl = batch?.files[0].blobUrl;
					const pagesToBeMerged = _.groupBy(
						_.map(pages, (page) => ({ ...page, blobUrl: pageBlobUrl })),
						(page) => page.blobUrl,
					);

					const sourcePagesGroup = _.groupBy(
						_.map(allPages, (page) => ({
							...page,
							blobUrl: pageBlobUrl,
						})),
						(page) => page.blobUrl,
					);

					const mergedPdfBytes = await pdfWorkerHelper.mergePDFToBaseFile(draft.blobUrl, pagesToBeMerged);
					URL.revokeObjectURL(draft.blobUrl);
					const newBlobUrl = URL.createObjectURL(new Blob([mergedPdfBytes], { type: 'application/pdf' }));

					updateDraftDocument(draftID, {
						isUpdating: false,
						blobUrl: newBlobUrl,
						pages: _.map(_.concat(draft.pages ?? [], pages), (page, index) => ({
							id: page.id,
							pageNumber: index + 1,
							filePath: draft?.filePath,
							isSelected: false,
						})),
					});
					uploadFile({ filePath: draft.filePath ?? '', blobUrl: newBlobUrl, draftid: draftID });

					await api.QueuesApi.createBatch(queueId, {
						id: batch?.id,
						pages: allPages.length - pages.length,
					});
					for (const page in pagesToBeMerged) {
						if (pagesToBeMerged[page].length === sourcePagesGroup[page].length) {
							updateBatch({ files: [] });
							deleteFile(pagesToBeMerged[page][0].filePath ?? '');
							await api.QueuesApi.createBatch(queueId, {
								id: batch?.id,
								files: [],
							});
						} else {
							const pdfBytes = await pdfWorkerHelper.deletePDFPages(
								page,
								_.map(pagesToBeMerged[page], (item) => item.pageNumber ?? 0),
							);
							const file = {
								filePath: pagesToBeMerged[page][0].filePath ?? '',
								blobUrl: URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' })),
							};
							URL.revokeObjectURL(page);
							updateBatch({ files: [file] });
							uploadFile(file);
						}
					}
				} catch {
					updateDraftDocument(draftID ?? '', {
						isError: true,
						error: 'Error in updating draft document',
						isUpdating: false,
					});
				} finally {
					useIndexingStore.setState({ updateDraftTrigger: null });
				}
			},
		);
		return () => {
			unSubscribeDeleteFile();
			unSubscribeUploadFile();
			unSubscribeUpdateDraft();
		};
	}, []);
};

export default useIndexingStore;
export { useIndexingValues, useIndexingActions, useUnsubscribeOnUnmount };
