/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AllUserResponseModel } from '../Models';
// @ts-ignore
import { AttributeDetails } from '../Models';
// @ts-ignore
import { CreateOrDeleteResponseModel } from '../Models';
// @ts-ignore
import { CreateOrUpdateResponseModel } from '../Models';
// @ts-ignore
import { CreateRolesRequestModel } from '../Models';
// @ts-ignore
import { CreateUserRequestModel } from '../Models';
// @ts-ignore
import { CurrentUserResponseModel } from '../Models';
// @ts-ignore
import { DeleteRolesRequestModel } from '../Models';
// @ts-ignore
import { KeycloakUserCheckAvailabilityEnum } from '../Models';
// @ts-ignore
import { RolesResponseModel } from '../Models';
// @ts-ignore
import { UpdateAttributesRequestModel } from '../Models';
// @ts-ignore
import { UpdateRolesRequestModel } from '../Models';
// @ts-ignore
import { UpdateUserRequestModel } from '../Models';
// @ts-ignore
import { ValidateResponseModel } from '../Models';
// @ts-ignore
import { ValidateTokenResponseModel } from '../Models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('_delete', 'userId', userId)
            const localVarPath = `/api/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateRolesRequestModel} createRolesRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoles: async (createRolesRequestModel: CreateRolesRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRolesRequestModel' is not null or undefined
            assertParamExists('addRoles', 'createRolesRequestModel', createRolesRequestModel)
            const localVarPath = `/api/v1/user/addRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRolesRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} searchString 
         * @param {KeycloakUserCheckAvailabilityEnum} field 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUserAvailability: async (searchString: string, field: KeycloakUserCheckAvailabilityEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchString' is not null or undefined
            assertParamExists('checkUserAvailability', 'searchString', searchString)
            // verify required parameter 'field' is not null or undefined
            assertParamExists('checkUserAvailability', 'field', field)
            const localVarPath = `/api/v1/user/checkUserAvailability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (searchString !== undefined) {
                localVarQueryParameter['search_string'] = searchString;
            }

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserRequestModel} createUserRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createUserRequestModel: CreateUserRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequestModel' is not null or undefined
            assertParamExists('create', 'createUserRequestModel', createUserRequestModel)
            const localVarPath = `/api/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteRolesRequestModel} deleteRolesRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssignedRoles: async (deleteRolesRequestModel: DeleteRolesRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteRolesRequestModel' is not null or undefined
            assertParamExists('deleteAssignedRoles', 'deleteRolesRequestModel', deleteRolesRequestModel)
            const localVarPath = `/api/v1/user/assignedRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteRolesRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/getCurrentUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAttributes: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserAttributes', 'userId', userId)
            const localVarPath = `/api/v1/user/getUserAttributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllRoles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/allRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [role] 
         * @param {string} [username] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllUsers: async (userId?: string, role?: string, username?: string, searchString?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/allUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['search_string'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAssignedRoles: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('readAssignedRoles', 'userId', userId)
            const localVarPath = `/api/v1/user/assignedRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAvailableRoles: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('readAvailableRoles', 'userId', userId)
            const localVarPath = `/api/v1/user/availableRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resetPassword', 'userId', userId)
            const localVarPath = `/api/v1/user/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserRequestModel} updateUserRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (updateUserRequestModel: UpdateUserRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserRequestModel' is not null or undefined
            assertParamExists('updateUser', 'updateUserRequestModel', updateUserRequestModel)
            const localVarPath = `/api/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateAttributesRequestModel} updateAttributesRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAttributes: async (userId: string, updateAttributesRequestModel: UpdateAttributesRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserAttributes', 'userId', userId)
            // verify required parameter 'updateAttributesRequestModel' is not null or undefined
            assertParamExists('updateUserAttributes', 'updateAttributesRequestModel', updateAttributesRequestModel)
            const localVarPath = `/api/v1/user/updateUserAttributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAttributesRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateRolesRequestModel} updateRolesRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRoles: async (updateRolesRequestModel: UpdateRolesRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateRolesRequestModel' is not null or undefined
            assertParamExists('updateUserRoles', 'updateRolesRequestModel', updateRolesRequestModel)
            const localVarPath = `/api/v1/user/updateRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRolesRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateToken: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/api/v1/user/validateToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [username] 
         * @param {string} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUsername: async (username?: string, userEmail?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (userEmail !== undefined) {
                localVarQueryParameter['user_email'] = userEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrDeleteResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi._delete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateRolesRequestModel} createRolesRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRoles(createRolesRequestModel: CreateRolesRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreateOrDeleteResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRoles(createRolesRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.addRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} searchString 
         * @param {KeycloakUserCheckAvailabilityEnum} field 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkUserAvailability(searchString: string, field: KeycloakUserCheckAvailabilityEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkUserAvailability(searchString, field, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.checkUserAvailability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateUserRequestModel} createUserRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createUserRequestModel: CreateUserRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrUpdateResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createUserRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.create']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteRolesRequestModel} deleteRolesRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssignedRoles(deleteRolesRequestModel: DeleteRolesRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreateOrDeleteResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssignedRoles(deleteRolesRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.deleteAssignedRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUserResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getCurrentUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAttributes(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttributeDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAttributes(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUserAttributes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllRoles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RolesResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllRoles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.readAllRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [role] 
         * @param {string} [username] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllUsers(userId?: string, role?: string, username?: string, searchString?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AllUserResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllUsers(userId, role, username, searchString, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.readAllUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAssignedRoles(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RolesResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAssignedRoles(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.readAssignedRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAvailableRoles(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RolesResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAvailableRoles(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.readAvailableRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrDeleteResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.resetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateUserRequestModel} updateUserRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(updateUserRequestModel: UpdateUserRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrUpdateResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(updateUserRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateAttributesRequestModel} updateAttributesRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserAttributes(userId: string, updateAttributesRequestModel: UpdateAttributesRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrDeleteResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserAttributes(userId, updateAttributesRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUserAttributes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateRolesRequestModel} updateRolesRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserRoles(updateRolesRequestModel: UpdateRolesRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreateOrDeleteResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserRoles(updateRolesRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUserRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateToken(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateTokenResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateToken(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.validateToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [username] 
         * @param {string} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateUsername(username?: string, userEmail?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateUsername(username, userEmail, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.validateUsername']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(userId: string, options?: any): AxiosPromise<CreateOrDeleteResponseModel> {
            return localVarFp._delete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateRolesRequestModel} createRolesRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoles(createRolesRequestModel: CreateRolesRequestModel, options?: any): AxiosPromise<Array<CreateOrDeleteResponseModel>> {
            return localVarFp.addRoles(createRolesRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} searchString 
         * @param {KeycloakUserCheckAvailabilityEnum} field 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUserAvailability(searchString: string, field: KeycloakUserCheckAvailabilityEnum, options?: any): AxiosPromise<string> {
            return localVarFp.checkUserAvailability(searchString, field, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserRequestModel} createUserRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createUserRequestModel: CreateUserRequestModel, options?: any): AxiosPromise<CreateOrUpdateResponseModel> {
            return localVarFp.create(createUserRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteRolesRequestModel} deleteRolesRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssignedRoles(deleteRolesRequestModel: DeleteRolesRequestModel, options?: any): AxiosPromise<Array<CreateOrDeleteResponseModel>> {
            return localVarFp.deleteAssignedRoles(deleteRolesRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<CurrentUserResponseModel> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAttributes(userId: string, options?: any): AxiosPromise<Array<AttributeDetails>> {
            return localVarFp.getUserAttributes(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllRoles(options?: any): AxiosPromise<Array<RolesResponseModel>> {
            return localVarFp.readAllRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [role] 
         * @param {string} [username] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllUsers(userId?: string, role?: string, username?: string, searchString?: string, options?: any): AxiosPromise<Array<AllUserResponseModel>> {
            return localVarFp.readAllUsers(userId, role, username, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAssignedRoles(userId: string, options?: any): AxiosPromise<Array<RolesResponseModel>> {
            return localVarFp.readAssignedRoles(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAvailableRoles(userId: string, options?: any): AxiosPromise<Array<RolesResponseModel>> {
            return localVarFp.readAvailableRoles(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(userId: string, options?: any): AxiosPromise<CreateOrDeleteResponseModel> {
            return localVarFp.resetPassword(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserRequestModel} updateUserRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(updateUserRequestModel: UpdateUserRequestModel, options?: any): AxiosPromise<CreateOrUpdateResponseModel> {
            return localVarFp.updateUser(updateUserRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateAttributesRequestModel} updateAttributesRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAttributes(userId: string, updateAttributesRequestModel: UpdateAttributesRequestModel, options?: any): AxiosPromise<CreateOrDeleteResponseModel> {
            return localVarFp.updateUserAttributes(userId, updateAttributesRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateRolesRequestModel} updateRolesRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRoles(updateRolesRequestModel: UpdateRolesRequestModel, options?: any): AxiosPromise<Array<CreateOrDeleteResponseModel>> {
            return localVarFp.updateUserRoles(updateRolesRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateToken(options?: any): AxiosPromise<ValidateTokenResponseModel> {
            return localVarFp.validateToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [username] 
         * @param {string} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUsername(username?: string, userEmail?: string, options?: any): AxiosPromise<ValidateResponseModel> {
            return localVarFp.validateUsername(username, userEmail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - interface
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    _delete(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<CreateOrDeleteResponseModel>;

    /**
     * 
     * @param {CreateRolesRequestModel} createRolesRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    addRoles(createRolesRequestModel: CreateRolesRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<Array<CreateOrDeleteResponseModel>>;

    /**
     * 
     * @param {string} searchString 
     * @param {KeycloakUserCheckAvailabilityEnum} field 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    checkUserAvailability(searchString: string, field: KeycloakUserCheckAvailabilityEnum, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {CreateUserRequestModel} createUserRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    create(createUserRequestModel: CreateUserRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<CreateOrUpdateResponseModel>;

    /**
     * 
     * @param {DeleteRolesRequestModel} deleteRolesRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    deleteAssignedRoles(deleteRolesRequestModel: DeleteRolesRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<Array<CreateOrDeleteResponseModel>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getCurrentUser(options?: RawAxiosRequestConfig): AxiosPromise<CurrentUserResponseModel>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserAttributes(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AttributeDetails>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    readAllRoles(options?: RawAxiosRequestConfig): AxiosPromise<Array<RolesResponseModel>>;

    /**
     * 
     * @param {string} [userId] 
     * @param {string} [role] 
     * @param {string} [username] 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    readAllUsers(userId?: string, role?: string, username?: string, searchString?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AllUserResponseModel>>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    readAssignedRoles(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<RolesResponseModel>>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    readAvailableRoles(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<RolesResponseModel>>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    resetPassword(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<CreateOrDeleteResponseModel>;

    /**
     * 
     * @param {UpdateUserRequestModel} updateUserRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateUser(updateUserRequestModel: UpdateUserRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<CreateOrUpdateResponseModel>;

    /**
     * 
     * @param {string} userId 
     * @param {UpdateAttributesRequestModel} updateAttributesRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateUserAttributes(userId: string, updateAttributesRequestModel: UpdateAttributesRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<CreateOrDeleteResponseModel>;

    /**
     * 
     * @param {UpdateRolesRequestModel} updateRolesRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateUserRoles(updateRolesRequestModel: UpdateRolesRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<Array<CreateOrDeleteResponseModel>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    validateToken(options?: RawAxiosRequestConfig): AxiosPromise<ValidateTokenResponseModel>;

    /**
     * 
     * @param {string} [username] 
     * @param {string} [userEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    validateUsername(username?: string, userEmail?: string, options?: RawAxiosRequestConfig): AxiosPromise<ValidateResponseModel>;

}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public _delete(userId: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration)._delete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateRolesRequestModel} createRolesRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addRoles(createRolesRequestModel: CreateRolesRequestModel, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).addRoles(createRolesRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} searchString 
     * @param {KeycloakUserCheckAvailabilityEnum} field 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public checkUserAvailability(searchString: string, field: KeycloakUserCheckAvailabilityEnum, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).checkUserAvailability(searchString, field, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserRequestModel} createUserRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public create(createUserRequestModel: CreateUserRequestModel, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).create(createUserRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteRolesRequestModel} deleteRolesRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteAssignedRoles(deleteRolesRequestModel: DeleteRolesRequestModel, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteAssignedRoles(deleteRolesRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getCurrentUser(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserAttributes(userId: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserAttributes(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readAllRoles(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).readAllRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userId] 
     * @param {string} [role] 
     * @param {string} [username] 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readAllUsers(userId?: string, role?: string, username?: string, searchString?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).readAllUsers(userId, role, username, searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readAssignedRoles(userId: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).readAssignedRoles(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readAvailableRoles(userId: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).readAvailableRoles(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resetPassword(userId: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).resetPassword(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserRequestModel} updateUserRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(updateUserRequestModel: UpdateUserRequestModel, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(updateUserRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UpdateAttributesRequestModel} updateAttributesRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserAttributes(userId: string, updateAttributesRequestModel: UpdateAttributesRequestModel, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserAttributes(userId, updateAttributesRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateRolesRequestModel} updateRolesRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserRoles(updateRolesRequestModel: UpdateRolesRequestModel, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserRoles(updateRolesRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public validateToken(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).validateToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [username] 
     * @param {string} [userEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public validateUsername(username?: string, userEmail?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).validateUsername(username, userEmail, options).then((request) => request(this.axios, this.basePath));
    }
}

