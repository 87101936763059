/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description worker-helper
 */

import { UploadFile } from 'antd';
import { WorkerMessage, WorkerTask, PDF_OPERATIONS } from './types';

let worker: Worker | null = null;

const initialize = (): void => {
	if (worker) return;
	worker = new Worker(new URL('./worker.ts', import.meta.url), { type: 'module' });
};

const terminate = (): void => {
	if (worker) {
		worker.terminate();
		worker = null;
	}
};

const executeWorkerTask = (message: WorkerTask): Promise<Uint8Array> => {
	return new Promise((resolve, reject) => {
		if (!worker) {
			initialize();
		}
		if (!worker) {
			reject(new Error('Worker not initialized'));
			return;
		}

		const handleMessage = (e: MessageEvent<WorkerMessage>) => {
			const { type, data, error } = e.data;

			if (error) {
				worker?.removeEventListener('message', handleMessage);
				reject(new Error(error));
				return;
			}

			if (type === `${message.type}_complete` && data) {
				worker?.removeEventListener('message', handleMessage);
				resolve(data);
			}
		};

		worker.addEventListener('message', handleMessage);
		worker.postMessage(message);
	});
};

const rotatePDFPages = (url: string, pageNumbers: number[], rotationAngle: number): Promise<Uint8Array> => {
	return executeWorkerTask({
		type: PDF_OPERATIONS.ROTATE,
		url,
		pageNumbers,
		rotationAngle,
	});
};

const rearrangePDFPages = (url: string, oldIndex: number, newIndex: number): Promise<Uint8Array> => {
	return executeWorkerTask({
		type: PDF_OPERATIONS.REARRANGE,
		url,
		oldIndex,
		newIndex,
	});
};

const deletePDFPages = (url: string, pageNumbers: number[]): Promise<Uint8Array> => {
	return executeWorkerTask({
		type: PDF_OPERATIONS.DELETE,
		url,
		pageNumbers,
	});
};

const mergePDFToBaseFile = (baseFileUrl: string, files: any, isFilesUploaded?: boolean): Promise<Uint8Array> => {
	return executeWorkerTask({
		type: PDF_OPERATIONS.MERGE_TO_BASE_FILE,
		baseFileUrl,
		files,
		isFilesUploaded,
	});
};

const mergePDFToNewFile = (files: any): Promise<Uint8Array> => {
	return executeWorkerTask({
		type: PDF_OPERATIONS.MERGE_TO_NEW_FILE,
		files,
	});
};

const mergeUploadedFiles = (files: UploadFile<any>[]): Promise<Uint8Array> => {
	return executeWorkerTask({
		type: PDF_OPERATIONS.MERGE_UPLOADED_FILES,
		files,
	});
};

const pdfWorkerHelper = {
	initialize,
	terminate,
	rotatePDFPages,
	rearrangePDFPages,
	deletePDFPages,
	mergePDFToBaseFile,
	mergePDFToNewFile,
	mergeUploadedFiles,
};

export default pdfWorkerHelper;
